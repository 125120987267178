import envs from './envs';

/**
 * @deprecated
 * 由于业务壮大，原有的isQt不再用于判断是否客户端，改用isIdeationPCClient
 */
export function isQt() {
  return envs.VUE_APP_HYBRID_FRAMEWORK === 'qt';
}
export default isQt;

import i18n from '@/i18n';

export enum LibTypes {
  面料 = 'material',
  鞋楦 = 'shoe_last',
  鞋底 = 'sole',
  鞋跟 = 'heel',
  模型 = 'model',
  配件 = 'fitting',
  经典款式 = 'classicsStyle',
  印绣花 = 'embroidery',
  辅料 = 'subsidiary_material',
  图案 = 'pattern_craft',
}
export enum TypeGroupIdForServer {
  面料 = 'material',
  鞋楦 = 'shoe_last',
  鞋底 = 'sole',
  鞋跟 = 'heel',
  模型 = 'model',
  配件 = 'fitting',
  经典款式 = 'classics_style',
  印绣花 = 'embroidery',
  辅料 = 'subsidiary_material',
  图案 = 'pattern_craft',
}

export enum TypeGroupId {
  面料 = 'material',
  鞋楦 = 'shoe_last',
  鞋底 = 'sole',
  鞋跟 = 'heel',
  模型 = 'model',
  配件 = 'fitting',
  经典款式 = 'classicsStyle',
  印绣花 = 'embroidery',
  辅料 = 'subsidiary_material',
}

export const typeGroupIdToRequestUrlModel: Record<TypeGroupId, string> = {
  material: 'libMaterialInfo',
  shoe_last: 'shoeLast',
  sole: 'sole',
  heel: 'heel',
  fitting: 'fitting',
  model: 'model',
  classicsStyle: 'classicsStyle',
  embroidery: 'embroidery',
  subsidiary_material: 'subsidiaryMaterial',
};

export function typeGroupIdtoTypeGroupIdForServer(typeGroupId: TypeGroupId): TypeGroupIdForServer {
  const typeGroupIdtoTypeGroupIdForServerMap = {
    material: TypeGroupIdForServer.面料,
    shoe_last: TypeGroupIdForServer.鞋楦,
    sole: TypeGroupIdForServer.鞋底,
    heel: TypeGroupIdForServer.鞋跟,
    fitting: TypeGroupIdForServer.配件,
    model: TypeGroupIdForServer.模型,
    classicsStyle: TypeGroupIdForServer.经典款式,
    embroidery: TypeGroupIdForServer.印绣花,
    subsidiary_material: TypeGroupIdForServer.辅料,
  };

  return typeGroupIdtoTypeGroupIdForServerMap[typeGroupId];
}


export const libTypeObj = {
  面料: 'material',
  鞋楦: 'shoe_last',
  鞋底: 'sole',
  鞋跟: 'heel',
  模型: 'model',
  配件: 'fitting',
  经典款式: 'classics_style',
  印绣花: 'embroidery',
  辅料: 'subsidiary_material',
  material: i18n.t('enum.fabric'),
  shoe_last: i18n.t('enum.shoe_last'),
  sole: i18n.t('enum.sole'),
  heel: i18n.t('enum.heel'),
  model: i18n.t('enum.model'),
  fitting: i18n.t('enum.fitting'),
  classics_style: i18n.t('enum.class_style'),
  embroidery: i18n.t('enum.embroidery'),
  subsidiary_material: i18n.t('enum.subsidiary_material'),
};

export function getMiddlePath(typeGroupId: TypeGroupId): string {
  return typeGroupIdToRequestUrlModel[typeGroupId];
}
export default {};

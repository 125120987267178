import { simpleStore } from '@/packages/container';

// Model
type Entry =
  | { _tag: 'one-entry', value: string }
  | { _tag: 'self-entry' };

// Constructor
export const one = (a: string): Entry => ({
  _tag: 'one-entry',
  value: a,
});
export const self = (): Entry => ({
  _tag: 'self-entry',
});
export const create = (appId?: string, a?: string) => {
  if (a === 'ba9ff5528c1647ec') return one('800');
  if (a === 'verseset') return one('300');
  if (a === 'fim') return one('100');
  if (appId) return one(appId);
  return one('100');
  // return self();
};

export const fold = <A>(onOne: (v: string) => A, onSelf: () => A) => (a: Entry) => {
  if (a._tag === 'one-entry') return onOne(a.value);
  if (a._tag === 'self-entry') return onSelf();
};

// ======================================

const useEntryStore = simpleStore('entry-app-id', () => ({
  value: self(),
}));

export const useEntry = () => {
  const entryStore = useEntryStore();

  const setEntry = (a: Entry) => {
    entryStore.value = a;
  };
  const getEntry = () => entryStore.value;

  return {
    setEntry,
    getEntry,
  };
};

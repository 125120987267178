<template>
  <div
    class="pswp"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    ref="viewerWrapper"
    @keydown.arrow-left.stop="prev"
    @keydown.arrow-right.stop="next"
  >
    <!-- Background of PhotoSwipe.
         It's a separate element as animating opacity is faster than rgba(). -->
    <div class="pswp__bg" :style="{ backgroundColor: backgroundColor }"></div>

    <!-- Slides wrapper with overflow:hidden. -->
    <div class="pswp__scroll-wrap">
      <!-- Container that holds slides.
            PhotoSwipe keeps only 3 of them in the DOM to save memory.
            Don't modify these 3 pswp__item elements, data is added later on. -->
      <div class="pswp__container">
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
      </div>

      <!-- Default (PhotoSwipeUIDefault) interface on top of sliding area. Can be changed. -->
      <div class="pswp__ui pswp__ui--hidden">
        <div class="pswp__top-bar">
          <!--  Controls are self-explanatory. Order can be changed. -->

          <div class="pswp__counter"></div>

          <button class="pswp__button pswp__button--close" :title="$t('public.img_viewer.close')"></button>

          <!-- <button class="pswp__button pswp__button--share" title="Share"></button> -->

          <!-- <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button> -->

          <button
            class="pswp__button pswp__button--zoom"
            style="display: none !important"
            :title="$t('public.img_viewer.zoom')"
          ></button>

          <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
          <!-- element will get class pswp__preloader--active when preloader is running -->
          <div class="pswp__preloader">
            <div class="pswp__preloader__icn">
              <div class="pswp__preloader__cut">
                <div class="pswp__preloader__donut"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
          <div class="pswp__share-tooltip"></div>
        </div>

        <button
          @click.stop.prevent="prev"
          class="pswp__button pswp__button--arrow--left"
          :title="$t('public.img_viewer.prev')"
        ></button>

        <!-- <el-button :style="{
          position: 'absolute', top: '50vh', left: 0, zIndex: 200
        }"
          :title="$t('public.img_viewer.prev')" @click.stop="prev">
        </el-button>
        <el-button :style="{
          position: 'absolute', top: '50vh', right: 0, zIndex: 200
        }"
          :title="$t('public.img_viewer.next')" @click.stop="next">
        </el-button> -->
        <!-- <button
          @click.stop.prevent="next"
          class="pswp__button pswp__button--arrow--right"
          :title="$t('public.img_viewer.next')"
        ></button> -->
        <button
          @click.stop.prevent="next"
          class="pswp__button pswp__button--arrow--right"
          :title="$t('public.img_viewer.next')"
        ></button>

        <div
          class="ruler-container flex-center flex-column"
          v-if="showRuler"
          :style="{
            left: `calc(50vw - ${rulerInfo.height / 2}px)`,
          }"
        >
          <span
            :style="{
              textShadow: '0 0 5px #000',
            }"
            >{{ rulerCentimeters }}mm</span
          >
          <div
            :style="{
              boxShadow: '0 0 5px #000',
              marginTop: '10px',
              height: '3px',
              width: `${rulerInfo.height}px`,
              border: '1px solid #fff',
              borderTop: 0,
            }"
          ></div>
        </div>

        <div class="pswp__caption">
          <div class="pswp__caption__center"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhotoSwipe from 'photoswipe';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
// import { getDimension } from '..';
import PhotoSwipeUIDefault from './photoswipe-ui';

const autoModeConfigDefault = {
  container: document.body,
};

function cutZero(num) {
  let s = `${num}`;
  while (s.includes('.') && s[s.length - 1] === '0') {
    s = s.slice(0, -1);
  }
  if (s[s.length - 1] === '.') s = s.slice(0, -1);
  return +s;
}


const Component = {
  install(Vue, mixin, autoModeConfig = false) {
    if (typeof autoModeConfig === 'string') {
      autoModeConfig = {
        ...autoModeConfigDefault,
        selector: '',
      };
    }
    if (autoModeConfig === true) {
      autoModeConfig = autoModeConfigDefault;
    }
    const injector = new Vue({ ...Component, ...mixin });
    injector.$mount();
    Vue.prototype.$ImageViewer = injector;

    if (!autoModeConfig) return;

    // 给body加mutationObserver观测
    const observer = new MutationObserver(mutationsList => {
      // eslint-disable-next-line
      for (const mutation of mutationsList) {
        const nodes = mutation.addedNodes;
        if (nodes.length !== 0) {
          // eslint-disable-next-line
          for (let node of nodes) {
            if (node.dataset.trigger) {
              node.onclick = () => {
                injector.settings({
                  trigger: node,
                });
              };
            }
          }
        }
      }
    });
    observer.observe(autoModeConfig.container, {
      childList: true,
      subtree: true,
    });
  },
  props: {
    getDimension: {
      type: Function,
    },
    targetElems: {
      default: () => (trigger) => {
        const key = trigger.dataset.trigger;
        return `[data-trigger-target="${key}"]`;
      },
    },
    beforeShow: {
      type: Function,
      default: (options) => options,
    },
  },
  data() {
    this.cacheList = [];
    return {
      srcList: [],
      viewerObject: {},
      showRuler: false,
      backgroundColor: '',
      rulerInfo: {
        height: 0,
        baseCentimeters: 0,
        ratio: 1,
      },
    };
  },
  computed: {
    rulerCentimeters() {
      return cutZero((this.rulerInfo.baseCentimeters * 10 / this.rulerInfo.ratio).toFixed(3));
    },
  },
  methods: {
    setRuler(rulerSetting, ratio = 1) {
      // 根据dpi和像素算出像素对应的物理尺寸（单位厘米）
      this.rulerInfo.height = rulerSetting.rulerHeight;
      this.rulerInfo.baseCentimeters = rulerSetting.rulerHeight / rulerSetting.dpi * 2.54;
      this.rulerInfo.ratio = ratio;
      if (!this.showRuler) this.showRuler = true;
    },
    getLoopedIndex(leng, index) {
      if (index > leng - 1) return index - leng;
      if (index < 0) return index + leng;
      return index;
    },
    /**
     * 在调用photoswipe的prev之前，先找到真正的prev对象，获取其宽高，设置到对应位置，
     * 再执行prev。这么做是因为宽高一开始默认都设为了0。否则一组图片要拿宽高，会比较耗性能。
     * 这么做相当于懒加载宽高。
     */
    async prev() {
      const {
        items, prev, getCurrentIndex, itemHolders, setContent,
      } = this.viewerObject;
      const prevIndex = this.getLoopedIndex(items.length, getCurrentIndex() - 1);
      const prevItem = items[prevIndex];
      if (prevItem?.w === 0) {
        const { width: w, height: h } = await this.getDimension(prevItem.src);
        prevItem.w = w;
        prevItem.h = h;
        /**
         * 只有两张图且首次打开的是第一张图时，photoswipe会给itemHolders分别赋值[null，第一张图对象，第二张图对象]，这种情况下，应该setContent到第二张图的位置，所以对象的index为2
         */
        setContent(itemHolders[items.length === 2 && prevIndex === 1 ? 2 : 0], prevIndex);
      }
      prev();
    },
    async next() {
      const {
        items, next, getCurrentIndex, itemHolders, setContent,
      } = this.viewerObject;
      const nextIndex = this.getLoopedIndex(items.length, getCurrentIndex() + 1);
      const nextItem = items[nextIndex];
      if (nextItem?.w === 0) {
        const { width: w, height: h } = await this.getDimension(nextItem.src);
        nextItem.w = w;
        nextItem.h = h;
        /**
         * 只有两张图且首次打开的是第二张图时，photoswipe会给itemHolders分别赋值[第一张图对象，第二张图对象，null]，这种情况下，应该setContent到第一张图的位置，所以对象的index为0
         */
        setContent(itemHolders[items.length === 2 && nextIndex === 0 ? 0 : 2], nextIndex);
      }
      next();
    },
    async settings(...args) {
      return this.show(...args);
    },
    clearRulerInfo() {
      this.showRuler = false;
      this.rulerInfo = {
        height: 0,
        baseCentimeters: 0,
        ratio: 1,
      };
    },
    async show(srcList, options, rulerSetting = null, backgroundColor = null) {
      if (backgroundColor) this.backgroundColor = backgroundColor;
      document.querySelector('.pswp__bg').style.backgroundColor = backgroundColor || '';
      // options相关
      if (typeof srcList === 'object' && !Array.isArray(srcList)) {
        options = srcList;
        srcList = undefined;
      }
      options = {
        ...options,
        srcList: srcList ?? options.srcList,
      };
      if (typeof options.srcList === 'function' && options.trigger) {
        options.srcList = srcList(options.trigger);
      }
      // srcList相关
      if (typeof options.srcList === 'string') {
        options.srcList = [options.srcList];
      }
      if (options.srcList.length < 1) {
        throw new Error('srcList can not be empty.');
      }
      if (typeof options.srcList[0] === 'string') {
        options.srcList = options.srcList.map(each => { return { src: each }; });
      }

      options.srcList = options.srcList.map(item => ({ msrc: item.src, ...item }));

      if (rulerSetting) {
        options.srcList = options.srcList.map(item => ({
          rulerSetting,
          // 如果item中有rulerSetting应该覆盖全局设置
          ...item,
        }));
      }

      if ((typeof options.targetElems === 'function' || !options.targetElems) && options.trigger) {
        const targetElems = options.targetElems || this.targetElems;
        options.targetElems = targetElems(options.trigger);
      }
      if (typeof options.targetElems === 'string') {
        options.targetElems = document.querySelectorAll(options.targetElems);
      }

      options.targetElems = options.targetElems ?? options.srcList.map(() => document.body);


      if (options.index == null && options.trigger) {
        options.index = Array.from(options.srcList).findIndex(image => image === options.trigger);
      }

      options.index = options.index ?? 0;

      options = await this.beforeShow(options);

      options.srcList = options.srcList.map(({
        src, msrc, w, h, ...attr
      }) => ({
        ...attr,
        src,
        msrc,
        w: w ?? 0,
        h: h ?? 0,
      }));

      this.viewerObject = new PhotoSwipe(
        this.$refs.viewerWrapper,
        PhotoSwipeUIDefault,
        options.srcList,
        {
          index: options.index,
          closeOnScroll: false,
          closeElClasses: ['ui'],
          maxSpreadZoom: 10,
          getDoubleTapZoom(isMouseClick, item) {
            const currZoomLever = item.initialZoomLevel;
            if (isMouseClick) {
              return (currZoomLever > 1 && currZoomLever < 0.7) ? 1 : 1.2;
            }
            return item.initialZoomLevel < 0.7 ? 1 : 1.2;
          },
          getThumbBoundsFn: (_index) => {
            try {
              const targetElem = options.targetElems[_index];
              const rect = targetElem.getBoundingClientRect();
              const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
              return {
                x: rect.left,
                y: rect.top + pageYScroll,
                w: rect.width,
              };
            } catch (e) {
              console.error(e);
              return {};
            }
          },
        },
      );
      const currentItem = options.srcList[options.index];
      const { width: w, height: h } = await this.getDimension(currentItem.src);
      currentItem.w = w;
      currentItem.h = h;

      // this.viewerObject.listen('afterInit', () => {
      //   console.log('image init');
      //   const { rulerSetting, fitRatio } = this.viewerObject.currItem;
      //   if (rulerSetting) this.setRuler(rulerSetting, fitRatio);
      // });
      this.viewerObject.listen('afterChange', () => {
        // console.log('image changed');
        const { rulerSetting: _r, fitRatio, backgroundColor: _bg } = this.viewerObject.currItem;
        if (_r) this.setRuler(_r, fitRatio);
        else this.showRuler = false;
        document.querySelector('.pswp__bg').style.backgroundColor = _bg;
      });
      this.viewerObject.init();
      window.viewerObject = this.viewerObject;

      const hackZoomTo = () => {
        const old = this.viewerObject.zoomTo;
        const newZoomTo = (...args) => {
          console.log(args);
          // eslint-disable-next-line prefer-destructuring
          if (this.showRuler) this.rulerInfo.ratio = args[0];
          old(...args);
        };
        this.viewerObject.zoomTo = newZoomTo;
      };
      // 进入动画结束后，魔改原有zoomTo函数，以便获得新的比率以变更标尺
      this.viewerObject.listen('initialZoomInEnd', hackZoomTo);
      this.viewerObject.listen('initialZoomOutEnd', hackZoomTo);

      // 监听到关闭事件时，注销所有自定义绑定的事件
      this.viewerObject.listen('close', () => {
        // document.removeEventListener('mousewheel', this._attactMouseWheelEvent);
        document.removeEventListener('keydown', this._attactKeyDownEvent);

        this.clearRulerInfo();
      });


      // 绑定事件在 this.$refs.viewerWrapper 上没有任何响应，没有找到合适的解决方案
      // 暂先绑定事件在 document 上，但浏览模式关闭时移除绑定的事件
      // document.addEventListener('mousewheel', this._attactMouseWheelEvent);
      document.addEventListener('keydown', this._attactKeyDownEvent);

      // photoSwipe自带捕获鼠标中间滚动的事件
      this.viewerObject.handleMouseWheel = (e) => {
        this.scaleImage(this.viewerObject, -e.deltaY / 250);
      };

      return new Promise((resolve) => {
        this.viewerObject.listen('close', () => {
          resolve();
        });
      });
    },
    // 放大或缩小图片
    scaleImage(photoSwipeObj, scaleSize) {
      const currZoomLever = photoSwipeObj.getZoomLevel();
      // console.log(currZoomLever);
      let finalZoomLever = currZoomLever + scaleSize;
      // 设置最小和最大的缩放值
      if (finalZoomLever <= 0.2) finalZoomLever = 0.2;
      if (finalZoomLever >= 10) finalZoomLever = 10;
      // console.log(finalZoomLever);

      const scaleOriginPoint = {
        x: photoSwipeObj.viewportSize.x / 2,
        y: photoSwipeObj.viewportSize.y / 2,
      };

      photoSwipeObj.zoomTo(
        finalZoomLever,
        scaleOriginPoint,
        500,
      );
    },
    // 处理鼠标滚动的事件（废弃，直接用handleMouseWheel）#:22
    // _attactMouseWheelEvent(e) {
    //   console.log(e.deltaY);
    //   this.scaleImage(this.viewerObject, e.deltaY / 50);
    // },
    // 处理键盘点击的事件
    _attactKeyDownEvent(e) {
      const offset = 0.4;

      switch (e.keyCode) {
        // +号
        case 187:
          this.scaleImage(this.viewerObject, offset);
          break;
        // -号
        case 189:
          this.scaleImage(this.viewerObject, -offset);
          break;
        default:
          console.warn('Not match keyCode');
      }
    },
  },
};
export default Component;
</script>

<style lang="scss" scoped>
.pswp__bg {
  background-color: #fff;
}
#{$dark-theme-selector} {
  .pswp__bg {
    background-color: #262626;
  }
  .pswp__counter {
    color: #fff;
  }
}
.pswp {
  z-index: 2000 !important;
}
.ruler-container {
  padding: 20px;
  // box-shadow: 0 0 20px #000;
  position: absolute;
  bottom: 50px;
  // right: calc(50vw - 50px);
  color: #fff;
}

.pswp__button--arrow--right {
  right: 36px;
  &::before {
    background: url("~@/assets/img/public/icon_arrow_right.svg") 10px 10px no-repeat;
    background-color: rgba(0, 0, 0, 0.4);
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
}
.pswp__button--arrow--left {
  left: 36px;
  &::before {
    background: url("~@/assets/img/public/icon_arrow_left.svg") 10px 10px no-repeat;
    background-color: rgba(31, 15, 15, 0.4);
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
}

.pswp__top-bar {
  background-color: transparent;
}

.pswp__counter {
  left: 23px;
  color: #262626;
}

.pswp__button.pswp__button--close {
  background: url("~@/assets/img/public/icon_close.svg") 12px 12px no-repeat;
}
</style>

<template>
  <div class="upload-width-previewer">
    <!-- 展示文件框 -->
    <ul class="flex">
      <li v-for="(file, i) in fileList" :key="i" class="file-list-item" :style="{ width: `${width}px` }">
        <DeletableBox
          :width="width"
          :height="height"
          :disabled="disabled"
          @delete="handleDeleteFile(i)"
          canPreview
          @preview="handlePreviewImage(i)"
        >
          <base-image :src="file.url" :lazy="false" fit="cover" class="file-item-image" />
        </DeletableBox>
      </li>
    </ul>

    <UploadArea
      v-if="fileList.length < max"
      :multiple="multiple"
      :accept="accept"
      :max-size="maxSize"
      :disabled="disabled"
      @upload="handleUploadFile"
    >
      <div class="upload-area-box" :style="{ width: `${width}px`, height: `${height}px` }">
        <i class="iconfont icon-upload-cloud-o" />
        <p class="upload-text">{{ $t("public.upload") }}</p>
      </div>
    </UploadArea>
  </div>
</template>

<script>
import { i18n } from '@/i18n';

import UploadArea from '@/components/upload/index.vue';
import DeletableBox from '@/components/layout/DeletableBox.vue';

export default {
  name: 'UploadWithPreviewer',
  components: {
    UploadArea,
    DeletableBox,
  },
  props: {
    urls: { // 图片集合
      type: Array,
      default: () => [],
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 100,
    },
    accept: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxSize: {
      type: Number,
      default: 100 * 1024,
    },
    max: { // 上传文件最大数量
      type: Number,
      default: 6,
    },
    exceedMaxErr: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileList: [],
    };
  },
  watch: {
    urls: {
      handler(arr) {
        this.fileList = arr.map(item => {
          return { file: '', url: item };
        });
      },
      immediate: true,
    },
  },
  methods: {
    handleUploadFile(files) {
      // 处理files不是数组报错问题
      if (!Array.isArray(files)) {
        this.fileList = [this._formatFileItem(files)];
      } else {
        this.fileList.push(...files.map(file => this._formatFileItem(file)));
        if (this.fileList.length > this.max) {
          this.fileList = this.fileList.slice(0, this.max);
          this.exceedMaxErr && this.$message.warning(i18n.t('upload.msg.exceed_max', { max: this.max }));
        }
      }
      this.$emit('change', this.fileList);
    },
    // 删除上传的图片
    handleDeleteFile(i) {
      this.fileList.splice(i, 1);
      this.$emit('change', this.fileList);
    },
    // 处理放大图片功能
    handlePreviewImage(i) {
      this.$ImageViewer.show(this.fileList.map(file => file.url), {
        index: i,
        targetElems: '.file-item-image',
      });
    },
    _formatFileItem(file) {
      return {
        file,
        url: window.URL.createObjectURL(file),
      };
    },
  },
};
</script>

 <style lang="scss" scoped>
#{$dark-theme-selector} {
  .upload-area-box {
    background-color: #292929;
    color: $--color-dark-font-2;
    border: 1px solid $--color-dark-border;
    &:hover {
      color: #2878ff;
      border-color: #2878ff;
    }
  }
}
</style>

<style lang="scss" scoped>
.file-item-image {
  width: 100%;
  height: 100%;
}

.upload-width-previewer {
  display: flex;
  align-items: center;
  // flex-wrap: wrap;
}

.upload-area-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: #7f7f7f;
  background-color: #fcfcfc;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: all linear 150ms;
  &:hover {
    color: #2878ff;
    border-color: #2878ff;
  }
  .upload-text {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 400;
  }
  .iconfont {
    font-size: 25px;
    font-weight: 500;
  }
}

.file-list-item {
  margin-right: 10px;
  border-radius: 4px;
  overflow: hidden;
}
</style>

import { parse } from 'query-string';

export enum PlatformType {
  FIM = '100',
  MATERIAL = '200',
  CAD_2D = '500',
  CAD_3D = '600',
}
export function getAppId(): PlatformType {
  const query = parse(window.location.search.substr(1));
  if (query._appId === '500') {
    return PlatformType.CAD_2D;
  }
  if (query._appId === '600') {
    return PlatformType.CAD_3D;
  }
  return PlatformType.FIM;
}
export default PlatformType;


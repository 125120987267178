import { simpleStore } from '@/packages/container';
import axios from '@/utils/axios';
import * as Entry from '@/packages/Entry';

export type AppIdConfig = {
  id: string,
  code: string,
  appId: string,
  description: string,
  salesPhone: string,
  redirectUrl?: string,
  openRegister: 0 | 1,
  showPermissions: 0 | 1,
  supportCooAcount: 0 | 1,
  protocolRespList?: {
    type: 1 | 2 | 3,
    language: 2 | 3,
    url: string,
  }[],
};

type ApplicationLink = {
  links?: string,
  name: string,
  accessLevel: 0 | 1 | 2,
};

const useStore = simpleStore('app-id-config', () => ({
  all: [] as AppIdConfig[],
  applicationLink: [] as ApplicationLink[],
}));

export const useAppIdConfig = () => {
  const state = useStore();
  const { getEntry } = Entry.useEntry();

  const init = async () => {
    const { data } = await axios({
      method: 'GET',
      url: '/authcenter/v1.0/platform/all',
    }).catch(() => ({ data: [] }));
    state.all = data;
  };
  const getAll = () => state.all;
  const get = (appId: string) => {
    return state.all.find(b => b.appId === appId);
  };
  const hasPermission = (appId: string) => {
    const target = state.all.find(b => b.appId === appId);
    return target ? !!target.showPermissions : true;
  };
  const supportCooAcount = (appId: string) => {
    const target = state.all.find(b => b.appId === appId);
    return target ? !!target.supportCooAcount : true;
  };
  const getName = (appId: string) => {
    const target = state.all.find(b => b.appId === appId);
    return target?.description || appId;
  };
  const getCurrent = () => {
    const entry = getEntry();
    if (entry._tag === 'one-entry') {
      return get(entry.value);
    }
  };
  const getCurrentPlatformId = () => {
    return getCurrent()?.id;
  };


  return {
    init,
    getAll,
    get,
    hasPermission,
    supportCooAcount,
    getName,
    getCurrentPlatformId,
  };
};

export const useApplicationLink = () => {
  const state = useStore();
  const { getEntry } = Entry.useEntry();

  const initSource = async () => {
    if (state.applicationLink.length > 0) return;

    const entry = getEntry();
    if (entry._tag === 'self-entry') {
      state.applicationLink = [];
    }
    if (entry._tag === 'one-entry') {
      const { data } = await axios({
        method: 'POST',
        url: '/authcenter/v1.0/platform/query/extern/links',
        data: {
          appIds: [entry.value],
        },
        // 不是什么重要信息，报错就不要了
      }).catch(() => ({ data: [] }));
      state.applicationLink = data;
    }
  };
  const getSource = () => state.applicationLink;

  return {
    initSource,
    getSource,
  };
};

import Vue from 'vue';
import can from '@/utils/can';
import AppError from '@/utils/error';
import { upperCamelCase } from '_/case';
import errHandler from '@/utils/error-handler';

const comName2ClassNoAuth = {
  ElButton: 'is-disabled',
  ElRadio: 'is-disabled',
};

export default {
  functional: true,
  render(h, {
    parent,
    scopedSlots,
    children,
    data: {
      on,
      ...data
    },
    props: {
      op,
      nativeOn,
      error = new AppError({ code: '100403', level: -1 }),
      props: innerProps,
      default: defaults,
      classNoAuth,
      addDisabled = false,
      tag: Tag,
      ...props
    },
  }) {
    props = { ...props };
    const workspace = 'workspace' in props;
    delete props.props;

    props = { ...props, ...(innerProps || {}) };

    if (typeof Tag === 'string') {
      Tag = parent.$options.components[Tag] ?? Vue.options.components[Tag] ?? Tag;
    }

    const name = typeof Tag === 'string' ? upperCamelCase(Tag) : Tag.name;
    if (name) {
      classNoAuth = classNoAuth ?? comName2ClassNoAuth[name] ?? 'no-permission';
    }

    let type = 'system';

    if (workspace) {
      type = 'workspace';
    }

    const s = /^cant:/;
    on = { ...(on || {}) };
    const cantOnKeys = Object.keys(on)
      .filter(key => key.search(s) !== -1);

    let cantOn = cantOnKeys.reduce((memo, key) => {
      memo[key.replace(s, '')] = on[key];
      return memo;
    }, {});

    if (!cantOnKeys.length) {
      cantOn = {
        click() {
          if (!(error instanceof Error)) {
            error = new AppError(error);
          }
          errHandler(error);
        },
      };
    }


    if (!can.call(parent, type, op, defaults)) {
      on = cantOn;
      nativeOn = {};
      if (addDisabled) {
        props = { ...props, disabled: true };
      }
      console.log(props);
      data.class = data.class || [];
      data.class.push(classNoAuth);
    }

    return h(Tag, {
      scopedSlots,
      props,
      nativeOn,
      on,
      ...data,
    }, children);
  },
};

/* eslint-disable class-methods-use-this */
import tokenUtils from '@/utils/token';
import companyUtils from '@/utils/company';
import { UserOrm, User } from './User';
import modelOf from './utils/model-of';
import Company, { CompanyOrm } from './Company';


export class Context {
  id!: number;
  timestamp?: number;
  _userId?: string;
  user?: User | null;
  company?: Company;
  get _orgId() {
    return companyUtils.get();
  }
  constructor(mixin: Partial<Context>) {
    Object.assign(this, mixin);
  }
}

export const ContextOrm = modelOf(Context, {
  entity: 'context',
  primaryKey: 'id',
  fields() {
    return {
      id: this.attr(0),
      timestamp: this.attr(undefined),
      _userId: this.attr(tokenUtils.get()),
      company: this.belongsTo(CompanyOrm, '_orgId'),
      user: this.belongsTo(UserOrm, '_userId'),
    };
  },
});

export default Context;

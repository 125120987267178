<template lang="html">
  <div class="upload-style-normal">
    <!-- 上传预览图 -->
    <div v-if="buffer.imgUrl" class="previewer-box">
      <img :src="buffer.imgUrl" class="previewer">
      <div class="previewer-veil">
        <i class="iconfont icon-file-delete" @click.stop="vm.$emit('delete')" />
        <i
          v-if="$listeners['toggle-visible']"
          class="iconfont"
          :class="visible ? 'icon-eye-slash' : 'icon-eye'"
          @click.stop="toggleVisible" />
      </div>
    </div>
    <!-- 上传按钮 -->
    <div v-else class="upload-area">
      <i class="iconfont icon-upload-cloud-o" />
      <p class="upload-text">{{ $t('public.upload') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['vm', 'buffer'],
  data() {
    return {
      visible: true,
    };
  },
  methods: {
    toggleVisible() {
      this.visible = !this.visible;
      this.vm.$emit('toggle-visible', this.visible);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-style-normal {
  width: 120px;
  height: 120px;
  overflow: hidden;
}

.previewer-box {
  position: relative;
  width: 100%;
  height: 100%;

  &:hover .previewer-veil {
    display: flex;
  }

  .previewer {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .previewer-veil {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;


    .iconfont {
      position: absolute;
      padding: 0 5px;
      left: 0;
      top: 6px;
      z-index: 2;
      font-size: 16px;
      transition: all linear 100ms;


    }

    .icon-file-delete {
      left: auto;
      right: 0;
      top: 5px;
    }
  }
}

.upload-area {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 8px;
  transition: all linear 150ms;
  border: 1px solid;
  padding-top: 10px;
}


.upload-text {
  margin-top: 5px;
  font-size: 12px;
}

.iconfont {
  font-size: 34px;
  font-weight: 400;
}

@include theme using($t) {
  .upload-area {
    border-color: _($t, fill, border);
    background-color: _($t, fill, 1);

    .upload-text {
      color: _($t, text, placeholder);
    }

    &:hover {
      color: _($t, fim, brand, 6);
      border-color: _($t, fim, brand, 6);

      .upload-text {
        color: _($t, fim, brand, 6);
      }
    }
  }


  .previewer-veil {
    // background-color: rgba(0, 0, 0, 0.6);

    .iconfont {
      // color: #fff;

      &:hover {
        color: _($t, fim, brand, 6);
      }
    }
  }
}
</style>

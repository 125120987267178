// Library
import originalAxios, {
  AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse,
} from 'axios';
import 'webcrypto-shim';
import envs, { getHostParamsFromPath } from '@/utils/envs';
import {
  ab2base64,
  base64toAb,
  utf82u8a,
} from '_/blob-utils';
import { parseUrl } from 'query-string';
import history from '@/utils/history';
// eslint-disable-next-line
import { unique_session_id, refreshRole } from '@/log/store';
import { getAppId, customize, customizeNames } from '@/settings';
import token from './token';
import AppError from './error';
import companyUtils from './company';
import getAdapter from 'axios/unsafe/adapters/adapters';


export const module2terminalid = () => {
  const redirectUrl = parseUrl(globalThis.location.href).query.redirect as string;
  const redirectLocation = redirectUrl ? history.parse(redirectUrl) : null;
  const redirectModule = redirectLocation ? getHostParamsFromPath(redirectLocation?.fullPathWithDomain)[5] : null; // srm
  switch (redirectModule) {
    case 'srm':
      return '206';
    case 'entlib':
      return '207';
    case 'opt':
      return '205';
    default:
      return undefined;
  }
};

export const queryTerminalId = parseUrl(globalThis.location.href).query.terminal_id;

// const terminalId = queryTerminalId ?? module2terminalid() ?? '200';

// 请求缓存store，避免某个请求在同一请求周期内重复请求
interface ReqCache {
  [propName: string]: Promise<any>
}

const reqCache: ReqCache = {};

interface SDResponse<T> extends AxiosResponse<T> {
  code: number
  message: string
}

export interface SDRequestPromise<T = any, M = object> extends Promise<SDResponse<T> & M> {
}


interface SDRequestInstance extends AxiosInstance {
  <T = any, M = object>(config: AxiosRequestConfig): SDRequestPromise<T, M>;
  <T = any, M = object>(url: string, config?: AxiosRequestConfig): SDRequestPromise<T, M>;
  request<T = any, M = object>(config: AxiosRequestConfig): SDRequestPromise<T, M>;
  get<T = any, M = object>(url: string, config?: AxiosRequestConfig): SDRequestPromise<T, M>;
  delete<T = any, M = object>(url: string, config?: AxiosRequestConfig): SDRequestPromise<T, M>;
  head<T = any, M = object>(url: string, config?: AxiosRequestConfig): SDRequestPromise<T, M>;
  post<T = any, M = object>(url: string, data?: any, config?: AxiosRequestConfig): SDRequestPromise<T, M>;
  put<T = any, M = object>(url: string, data?: any, config?: AxiosRequestConfig): SDRequestPromise<T, M>;
  patch<T = any, M = object>(url: string, data?: any, config?: AxiosRequestConfig): SDRequestPromise<T, M>;
}

// // 创建新的axios实例代替全局覆盖axios配置
export const axios = originalAxios.create({
  // baseURL: 'http://dev.4dshoetech.local/backend/',
  baseURL: `//${envs.VUE_APP_API_DOMAIN}/backend/`,
  adapter: (config: any) => {
    const adapter = getAdapter.getAdapter(originalAxios.defaults.adapter);
    if (!adapter) {
      // 极端情况下会出现adapter为undefined的情况，浏览器环境一般不会，这里只是做保护避免ts报错
      // 详见https://github.com/axios/axios/blob/master/dist/axios.js. 跳至function getDefaultAdapter()
      return Promise.reject(new AppError({
        message: 'Browser error. Can not use XHR adapter.',
      }));
    }
    if (config.method.toLocaleLowerCase() === 'get') {
      let reqKey = config.url;
      if (config.params) {
        reqKey += `,__${JSON.stringify(config.params)}`;
      }
      if (config.headers) {
        reqKey += `,__${JSON.stringify(config.headers)}`;
      }
      // 当前请求周期有相同请求，直接返回同一个(Promise)
      if (reqCache[reqKey]) {
        return reqCache[reqKey];
      }
      const res = adapter(config);
      reqCache[reqKey] = res;
      // 请求结束后，删除缓存
      res.finally(() => {
        delete reqCache[reqKey];
      });
      return res;
    }
    return adapter(config);
  },
}) as SDRequestInstance;

/**
 *
 * @param {string} url
 */
function isNeedToken(url: string) {
  return !url.includes('authcenter/user/login');
}

// Request interception
axios.interceptors.request.use((config) => {
  const _token = token.get();
  // const _mobileToken = (SessionStorageUtil.getAuth()[first(parseUrl(globalThis.location.href).query.uuid)!] || {}).token;
  if (_token && config.url) {
    if (isNeedToken(config.url) && !config.headers.token) {
      config.headers.token = _token;
    }
    // if (config.url.includes('mobile-ideation/mobile-share') && _mobileToken) {
    //   config.headers.token = _mobileToken;
    // }
  }


  let url = config.url ?? '';
  url = url.startsWith('/') ? url : `/${url}`;
  if (!url.includes('//')) {
    let baseUrl = config.baseURL ?? '';
    baseUrl = baseUrl.endsWith('/') ? baseUrl.substr(0, baseUrl.length - 1) : baseUrl;
    url = `${baseUrl}${url}`;
  }

  if (url.includes('/backend/')) {
    const companyId = companyUtils.get();
    if (companyId && config.url && !config.url.includes('authcenter/user/info/get')) {
      config.headers['Sdtc-Tenant-Id'] ??= companyId;
    }
    // config.headers['Sdtc-Tenant-Id'] = '12345678910';
    if (!config.headers['Sdtc-App-Id'] && getAppId()) {
      config.headers['Sdtc-App-Id'] = getAppId();
    }
    config.headers['Sdtc-Terminal-Id'] ??= queryTerminalId ?? module2terminalid() ?? customize(customizeNames.terminalId) ?? '200';
    config.headers.groupAppId = config.headers.groupAppId || '';
  }

  // eslint-disable-next-line
  if (unique_session_id.value) {
    // eslint-disable-next-line
    config.headers['unique_session_id'] = unique_session_id.value;
  }
  /**
   * 日志记录增加字段
   */
  if (typeof refreshRole.value !== 'undefined') {
    config.headers.role ??= refreshRole.value;
  }
  config.headers.local_time = Date.now();

  return config;
}, error => Promise.reject(error));

const defaultError = { code: 500, message: 'System Error' };

function pipeAxios(data: any) {
  if (data.code !== 200) {
    data = new AppError(data);
  }
  if (data instanceof Error) {
    // if (data.code === 401 && global.IS_USER_UPDATED) {
    //   AppError.handler(data, true);
    // }
    return Promise.reject(new AppError(data));
  }
  return data;
}

// Response Interception
axios.interceptors.response.use(
  response => {
    if (!response.headers['content-type'].includes('json')) {
      return response;
    }
    if (response.request.responseURL.indexOf('/data-transit/data/snow/id') > -1) { // 应用第一个请求获取雪花id
      originalAxios.defaults.headers['Sdtc-Session-Id'] = response.data.data;// 同步埋点标志
    }
    let data = response.data ?? defaultError;
    data = { ...data };
    return pipeAxios({ ...data, hash: response.headers.track_code });
  },
  err => {
    let response;
    if (err.response) {
      ({ response } = err);
    } else if (err.message.includes('timeout')) {
      response = { status: 408, data: { code: 408, message: err.message }, headers: {} };
    }
    response = response ?? { status: 426, data: { code: 426, message: err.message }, headers: {} };
    const data = typeof response.data === 'string' ? {
      message: response.data,
      code: response.status,
    } : response.data;
    return pipeAxios({ ...data, hash: response.headers.track_code });
  },
);

let publicKey: AxiosPromise<any>;

async function getPublicKey() {
  return window.crypto.subtle.importKey(
    'spki',
    base64toAb((await axios.get('/authcenter/encrypt/key/public')).data),
    {
      name: 'RSA-OAEP',
      hash: 'SHA-1',
    },
    true,
    ['encrypt'],
  );
}


async function encrypt(key: any, data: any) {
  return ab2base64(await window.crypto.subtle.encrypt(
    {
      name: 'RSA-OAEP',
      // @ts-ignore
      // 兼容低版本的Edge https://www.it1352.com/681587.html
      hash: { name: 'SHA-1' },
    },
    key,
    utf82u8a((JSON.stringify(data))),
  ));
}

export const axiosEncrypt = async (config = axios.defaults) => {
  if (!window.crypto.subtle) {
    return axios(config);
  }


  publicKey = publicKey ?? getPublicKey();

  // 转换为key对象
  console.log(config.data);
  config.data = await encrypt(await publicKey, config.data);
  config.headers = { ...config.headers, 'content-type': 'application/ecrypted-json' };

  return axios(config);
};


export default axios;

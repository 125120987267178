import Company, { CompanyOrm } from './Company';
import modelOf from './utils/model-of';

export class Permission {
  code !: string;
  groupAppId?: string;
  companyId?: string;
  company?: Company;
  name !: string;
  value !: boolean;
  // workspace?: Workspace;
}

export const PermissionOrm = modelOf(Permission, {
  entity: 'permissions',
  primaryKey: ['groupAppId', 'code', 'companyId'],
  fields() {
    return {
      code: this.attr(''),
      groupAppId: this.attr(''),
      companyId: this.attr(''),
      name: this.attr(undefined),
      value: this.attr(undefined),
      company: this.belongsTo(CompanyOrm, 'id'),
    };
  },
});

export default Permission;

/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable class-methods-use-this */
import sourceHelperSwRegister from 'service-worker-loader?publicPath=/!./resource-helper-sw';

export class SWActiveError extends Error { }

function untilSwActive(swr: ServiceWorkerRegistration) {
  return new Promise<ServiceWorker>((resolve) => {
    const sw = swr.installing ?? swr.waiting ?? swr.active;
    if (!sw) {
      throw new SWActiveError();
    }
    if (sw.state === 'activated') {
      resolve(sw);
    }
    sw.addEventListener('statechange', () => {
      if (sw.state === 'activated') {
        resolve(sw);
      }
    });
  });
}

class ResourceHelper {
  async activate() {
    // 开发环境会报ssl错误，所以try-catch一下, 证书不符合则不启用worker
    try {
      const swr = await sourceHelperSwRegister({ scope: '/' });
      await untilSwActive(swr);
    } catch (e) { }
  }
}

export default new ResourceHelper();




import axios from '@/utils/axios';
import {
  computed,
  defineComponent, ref, watch,
} from 'vue';
import i18n from '@/i18n';
import { set, parse } from '@4dst-saas/public-utils/dist/key-serializer';
import { omitUndefined } from '@4dst-saas/public-utils';
import NumberType from '@4dst-saas/form-utils/dist/components/jsonschema2form/NumberType';
import getMiddlePath, { TypeGroupId } from '@/loaders/enums/base';
import { debounce } from 'throttle-debounce';

import { HeadFilterConfig } from './utils';

export type Field = {
  queryField: string,
  isTextField: boolean,
  isDropbox: boolean,
  field: string,
};

type Option = {
  name: string
  id: string
  count?: number
};

/* VXE 表头通用筛选组件 */
const VexTableHeadFilter = defineComponent({
  isFooter: false,
  name: 'VexTableHeadFilter',
  components: { NumberType },
  props: {
    property: {
      type: String,
      required: true,
    },
    filterType: {
      type: String as () => HeadFilterConfig['filterType'],
      default: false,
      required: true,
    },
    options: {
      type: Array as () => Option[],
      default: () => ([]),
    },
    staticOptions: {
      type: Object as () => Record<string, unknown>,
      default: () => ({}),
    },
    typeGroupId: {
      type: String as () => TypeGroupId,
      required: true,
    },
    headerFilterSearchParams: {
      type: Object,
      default: () => ({}),
    },
    filterParams: {
      type: Object,
      default: () => ({}),
    },
    params: Object,
  },
  render() {
    const btns = <div
      class="flex-space-between flex-center"
      style="height: 60px;border-top: 1px solid #e3e3e3;padding: 0 20px; flex-shrink: 0">
      <el-button size="mini" class="flex-1" onclick={this.reset}>{i18n.t('components.btn_clear')}</el-button>
      <el-button size="mini" class="flex-1" type="primary" onclick={this.confirm}>{i18n.t('public.dialog.confirm')}</el-button>
    </div>;

    const selectView = <div class="vxh-filter flex-column flex-1" style="position: relative">
      {
        this.filterType === 'text'
          ? <el-input
            class="input-container"
            placeholder={i18n.t('public.form.placeholder_keyword')}
            size="small"
            clearable
            onInput={() => { this.searchOptions(); }}
            vModel={this.tempKeyword}
          >
          </el-input> : null
      }
      <div class="list-container flex-column flex-1 scrollable scrollable-y">
        {
          this.list.length ? null : <span>{i18n.t('components.no_data')}</span>
        }
        {
          ['select', 'text', 'file'].includes(this.filterType) && this.list.length ? <el-checkbox
            indeterminate={this.isIndeterminateComputed}
            style="text-align: left"
            v-model={this.checkedAll}>
            {i18n.t('components.select_all')}
          </el-checkbox> : null
        }
        <el-checkbox-group class="flex-column flex-1" style={{ gap: '17px' }} vModel={this.selected}>
          {
            this.list.map(l => {
              return <el-checkbox label={l.id} style="text-align: left">
                <span class="checkbox-label">{l.name}{
                  this.filterType === 'text' ? `(${l.count})` : ''
                }</span>
              </el-checkbox>;
            })
          }
        </el-checkbox-group>
      </div>
      {btns}
    </div>;

    const numberRangeView = <div class="vxh-filter flex-column">
      <div class="flex-space-between flex-center" style="padding:20px">
        <NumberType size="mini" vModel={this.numberRange[0]} min={-99999} max={99999}></NumberType>
        <div style="width: 20px; height: 2px; background: #d8d8d8;margin: 0 4px"></div>
        <NumberType size="mini" vModel={this.numberRange[1]} min={-99999} max={99999}></NumberType>
      </div>
      {btns}
    </div>;
    const dateRangeView = <div style="position: relative" class="vxh-filter flex-column">
      <div class="flex-space-between flex-center" style="padding:20px">
        <el-date-picker
          appendToBody={false}
          style="width: 100%"
          value-format="timestamp"
          vModel={this.dateRange}
          size="small"
          type="daterange"
          clearable={false}
          start-placeholder={i18n.t('approve.start_date')}
          end-placeholder={i18n.t('approve.end_date')}
          default-time={['00:00:00', '23:59:59']}
        />
      </div>
      {btns}
    </div>;

    switch (this.filterType) {
      case 'number': return numberRangeView;
      case 'datetime': return dateRangeView;
      case 'text':
      case 'file':
      case 'select':
      default: return selectView;
    }
  },
  setup(props, { emit }) {
    /* 搜索keyword用英语","隔开 */
    const keyword = ref<string[]>([]);
    const tempKeyword = ref('');
    const list = ref<Option[]>([]);
    const selected = ref<string[]>([]);
    const dateRange = ref<Array<number | null>>([]);
    const numberRange = ref<number[]>([]);

    const currentHeaderFilterSearchParams = ref(JSON.parse(JSON.stringify(props.headerFilterSearchParams)));

    // 获取 获取筛选器选项 输入值，获取 筛选器 输出值
    const getSearchParam = (): { extraData?: Record<string, string>, headerParam?: Record<string, unknown> } => {
      const allSearchParams: Record<string, string | string[]> & { extraData?: Record<string, string> } = {};
      Object.entries(currentHeaderFilterSearchParams.value).forEach(([key, headerFilter]) => {
        // if (key === props.property) return;
        const currentSelected = headerFilter.selected ?? [];
        if (Array.isArray(currentSelected) && currentSelected?.includes('')) {
          currentSelected.splice(currentSelected.indexOf(''), 1);
          currentSelected.unshift('');
        }
        if (Array.isArray(currentSelected) && currentSelected?.length === 1000) {
          set(allSearchParams, key, [headerFilter.keyword.join(','), ...currentSelected]);
        } else {
          set(allSearchParams, key, currentSelected);
        }
      });
      omitUndefined(allSearchParams);
      if (allSearchParams.extraData) {
        omitUndefined(allSearchParams.extraData);
      }
      const headerParam = { ...allSearchParams };
      delete headerParam.extraData;

      return {
        ...props.filterParams,
        ...(allSearchParams.extraData ? { extraData: allSearchParams.extraData } : {}),
        headerParam,
      };
    };

    // 获取筛选器选项
    async function getSearchList(_keyword: string[] = [], isInit = false) {
      const isExtraData = parse(props.property)[0] === 'extraData';
      const filters = {
        ...props.staticOptions,
        ...getSearchParam(),
        currentField: parse(props.property).reverse()[0],
      };
      if (isExtraData) {
        set(filters, props.property, _keyword);
      } else {
        filters.headerParam = { ...filters.headerParam, [props.property]: _keyword };
      }
      const middlePath = getMiddlePath(props.typeGroupId) ?? props.typeGroupId;
      const res = (await axios.post<{ name: string, count: number }[]>(`/libcenter/${middlePath}/headerSearch`, filters)).data;
      list.value = res.map(({ name, count }) => (name ? { id: name, name, count } : { id: '', name: i18n.t('common.search_blank'), count }));
      if (currentHeaderFilterSearchParams.value[props.property]?.selected?.length && isInit) {
        // 取交集
        // selected.value = intersection(
        //   list.value.map(({ id }) => id), currentHeaderFilterSearchParams.value[props.property].selected,
        // );
        selected.value = currentHeaderFilterSearchParams.value[props.property].selected;
      } else {
        selected.value = list.value.map(({ id }) => id);
      }
    }

    // 初始化筛选器
    const initFilter = () => {
      keyword.value = [];
      if (props.filterType === 'text') {
        tempKeyword.value = props.headerFilterSearchParams[props.property]?.keyword ?? '';
        keyword.value.push(...tempKeyword.value.trim().split(',').filter(item => !!item));
        // selected.value = props.headerFilterSearchParams[props.property]?.selected as string[] | undefined ?? [];
        getSearchList(keyword.value, true);
      }
      if (props.filterType === 'select') {
        list.value = [{ id: '', name: i18n.t('common.search_blank') }, ...props.options];
        if (props.headerFilterSearchParams[props.property]?.selected?.length) {
          selected.value = props.headerFilterSearchParams[props.property]?.selected as string[];
        } else {
          selected.value = list.value.map(({ id }) => id);
        }
      }
      if (props.filterType === 'file') {
        list.value = props.options;
        if (props.headerFilterSearchParams[props.property]?.selected?.length) {
          selected.value = props.headerFilterSearchParams[props.property]?.selected as string[];
        } else {
          selected.value = list.value.map(({ id }) => id);
        }
      }
      if (props.filterType === 'datetime') {
        const { max, min } = props.headerFilterSearchParams[props.property]?.selected as { max?: number, min?: number } ?? {};
        if (max && min) {
          dateRange.value = [min, max];
        } else {
          dateRange.value = [];
        }
      }
      if (props.filterType === 'number') {
        const { max, min } = props.headerFilterSearchParams[props.property]?.selected as { max?: number, min?: number } ?? {};
        if (max && min) {
          numberRange.value = [min, max];
        } else {
          numberRange.value = [];
        }
      }
    };

    watch(() => props.property, (v) => {
      if (v) {
        initFilter();
      }
    }, {
      immediate: true,
    });

    const confirm = () => {
      // 整理筛选器数据结构
      let newHeaderFilterSearchParam: Record<string, unknown> | string[] = {};
      switch (props.filterType) {
        case 'text':
        case 'file':
        case 'select': {
          if (!keyword.value.length && selected.value.length === list.value.length) {
            newHeaderFilterSearchParam = [];
          } else {
            newHeaderFilterSearchParam = selected.value;
          }
          break;
        }
        case 'datetime': {
          newHeaderFilterSearchParam = { min: dateRange.value?.[0] || undefined, max: dateRange.value?.[1] || undefined };
          break;
        }
        case 'number': {
          if (typeof numberRange.value[0] === 'number') [newHeaderFilterSearchParam.min] = numberRange.value;
          if (typeof numberRange.value[1] === 'number') [, newHeaderFilterSearchParam.max] = numberRange.value;
          if (!newHeaderFilterSearchParam.min || !newHeaderFilterSearchParam.max) {
            newHeaderFilterSearchParam = {};
          }
          break;
        }
        default:
      }
      currentHeaderFilterSearchParams.value[props.property] = {
        ...(props.headerFilterSearchParams[props.property] ?? {}),
        selected: newHeaderFilterSearchParam,
        keyword: tempKeyword.value,
      };
      emit('changeHeaderFilterSerchParams', currentHeaderFilterSearchParams.value[props.property]);
      emit('change', getSearchParam());
      props.params?.$panel?.confirmFilter();
    };

    const reset = () => {
      currentHeaderFilterSearchParams.value[props.property] = {};
      emit('changeHeaderFilterSerchParams', currentHeaderFilterSearchParams.value[props.property]);
      tempKeyword.value = '';
      keyword.value = [];
      dateRange.value = [];
      numberRange.value = [];
      if (props.filterType === 'text') {
        getSearchList();
      }
      emit('change', getSearchParam());
      props.params?.$panel?.confirmFilter();
    };

    // 判断是否为全选状态
    const checkedAll = computed({
      get: () => {
        return list.value.length !== 0 && list.value.every(i => selected.value.includes(i.id));
      },
      set: (v) => {
        selected.value = v ? list.value.map(({ id }) => id) : [];
      },
    });

    // 非全选状态即为Indeterminate状态
    const isIndeterminateComputed = computed(() => {
      return !(checkedAll.value || selected.value.length === 0);
    });

    // 文本类型：处理keywork
    const searchOptions = debounce(500, () => {
      if (tempKeyword.value) {
        keyword.value = tempKeyword.value.trim().split(',').filter(item => !!item);
      } else {
        keyword.value = [];
      }
      currentHeaderFilterSearchParams.value[props.property] = {
        ...props.headerFilterSearchParams[props.property],
        // keyword: tempKeyword.value,
      };
      emit('changeHeaderFilterSerchParams', currentHeaderFilterSearchParams.value[props.property]);
      getSearchList(keyword.value);
    });


    return {
      reset,
      dateRange,
      numberRange,
      keyword,
      list,
      searchOptions,
      confirm,
      tempKeyword,
      selected,
      isIndeterminateComputed,
      checkedAll,
    };
  },

});

export default VexTableHeadFilter;

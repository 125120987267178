<script>
import Vue from 'vue';
import { i18n } from '@/i18n';

import UploadDrag from './src/upload-drag.vue';
import UploadFileInput from './src/upload-file-input.vue';

export default {
  name: 'UploadArea',
  components: {
    UploadDrag,
    UploadFileInput,
  },
  props: {
    // 上传区域的样式
    styleType: {
      type: String,
      default: 'normal',
    },
    // 上传文件最大限制
    maxSize: {
      type: Number,
      default: Infinity,
    },
    // 上传文件最小限制
    minSize: {
      type: Number,
      default: 1,
    },
    // 支持的格式：（可以用一下分隔符[,;、；|]）
    accept: {
      type: String,
      default: '',
    },
    // 支持拖拽状态
    draggable: {
      type: Boolean,
      default: true,
    },
    // 支持多文件上传状态
    multiple: {
      type: Boolean,
      default: false,
    },
    // 功能激活状态
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  provide() {
    return {
      vm: this.vm,
      buffer: this.buffer,
      accept: this.accept,
      multiple: this.multiple,
      styleType: this.styleType,
      disabled: this.disabled,
    };
  },
  data() {
    return {
      vm: new Vue(), // 示例化一个vue，做该部件的事件传递的中间者
      buffer: { // 中间对象，做该部件的部分参数传递的中间者
        imgUrl: '',
      },
      imgs: [],
      unmatchFiles: [], // 没有符合规范的问题
    };
  },
  mounted() {
    // 局部事件监听
    this.vm
      .$on('delete', () => this.handleDeleteFile())
      .$on('toggle-visible', (status) => this.handleToggleVisible(status));
  },
  destroyed() {
    this.vm = null;
  },
  methods: {
    async upload(files) {
      this.unmatchFiles = [];
      // 校验是否禁止状态
      if (this.disabled) return;

      // 如果当前上传的文件没有通过校验，不往下执行
      const matchFiles = await this._checkFiles(Array.from(files));

      if (!this.multiple) {
        this.buffer.imgUrl = window.URL.createObjectURL(matchFiles[0]);
        this.$emit('upload', matchFiles[0]);
        return;
      }

      this.$emit('upload', matchFiles);
    },
    // 处理删除文件事件
    handleDeleteFile() {
      this.buffer.imgUrl = '';
      this.$emit('delete');
    },
    // 处理重新上传文件事件
    handleToggleVisible(status) {
      this.$emit('toggle-visible', status);
    },
    // 检查文件是否符合上传规范
    _checkFiles(files) {
      return new Promise((resolve, reject) => {
        const result = [];

        files.forEach(file => {
          // 过滤文件夹
          if (file.size === 0 && file.type === '') {
            this.$message.warning('不允许上传文件夹');
            return;
          }
          // 过滤空文件
          if (file.size === 0) {
            this.$message.warning(i18n.t('upload.msg.exceed_size', { filename: file.name }));
            return;
          }

          // 校验文件是否符合上传格式; 如果没有格式要求，则不做格式校验
          if (this.accept) {
            const extensionList = this.accept
              .toLowerCase()
              .replace(/[\s/.]/g, '')
              .split(/[,;、；|]/);
            const extension = file.name.split('.').pop().toLowerCase();
            if (!extensionList.includes(extension)) {
              this.unmatchFiles.push(file);
              return;
            }
          }

          // 校验文件是否符合大小规范
          if (file.size > this.maxSize) {
            this.$message.warning(i18n.t('upload.msg.exceed_size', { filename: file.name }));
            return;
          }

          result.push(file);
        });

        // 根据没有符合格式的文件数量，显示不同的提示
        const unmatchFilesLength = this.unmatchFiles.length;
        if (unmatchFilesLength === 1) {
          this.$message.warning(i18n.t('upload.msg.invalid_file_format', { filename: this.unmatchFiles[0].name }));
        } else if (unmatchFilesLength >= 2) {
          this.$message.warning(i18n.t('upload.msg.invalid_files_format', { filesNum: unmatchFilesLength }));
        }

        // eslint-disable-next-line
        result.length > 0 ? resolve(result) : null;
      });
    },
  },
  render() {
    const { draggable, disabled } = this;

    // 容器的属性
    const data = {
      attrs: {
        id: 'dropzone',
      },
      class: {
        disabled,
      },
      on: {
        click: () => {
          this.$refs.uploadInput.triggerUpload();
        },
      },
    };

    return (
      <div {...data}>
        {draggable ? <upload-drag on-file={this.upload}>{this.$slots.default}</upload-drag>
          : this.$slots.default
        }
        <upload-file-input disabled={this.disabled} on-file={this.upload} ref="uploadInput"></upload-file-input>
      </div>
    );
  },
};
</script>

<style scoped>
.disabled {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: not-allowed;
}
</style>

/* eslint-disable class-methods-use-this */
import { getContextCompany } from '@/loaders/context';
import modelOf from './utils/model-of';
// import PackageInfo, { PackageOrm as PackageInfoOrm } from './Package';
import Company, { CompanyOrm, ResourceInfo, ResourceInfoOrm } from './Company';
import CompanyRole, { CompanyRoleOrm, UserCompanyRoleOrm } from './CompanyRole';

export class User {
  id!: string;
  account?: string;
  bindGroupAppId?: string;
  companyId?: string;
  daysRemaining?: number;
  department?: string;
  expireTime?: number;
  headImg?: string;
  mail?: string;
  name?: string;
  phone?: string;
  tag?: string;
  tradeFairWorkspace?: string;
  // 600-材料部主账号 601-材料主管 602-材料工程师 603-成本专员
  roleType?: string;

  mobile?: string;

  // 个人注册关联的公司
  company?: Company;


  // 被关联的公司
  companies!: Company[];

  /**
   * @deprecated 改用getContextCompany的businessType
   * @see {@link ./Company}
   */
  get businessType() {
    return getContextCompany()?.businessType;
  }
  roleId?: CompanyRole['id'];

  get resourceInfo() {
    return this.company?.resourceInfo;
  }
  get packageInfo() {
    return this.company?.packages?.[0];
  }
  constructor(mixin: Partial<User>) {
    Object.assign(this, mixin);
  }
}


export const UserOrm = modelOf(User, {
  entity: 'users',
  primaryKey: 'id',
  fields() {
    return {
      id: this.attr(''),
      account: this.attr(''),
      headImg: this.attr(undefined),
      name: this.attr(''),
      phone: this.attr(''),
      mobile: this.attr(''),
      mail: this.attr(undefined),
      companyId: this.attr(undefined),
      company: this.belongsTo(CompanyOrm, 'companyId'),
      companies: this.belongsToMany(CompanyOrm, UserCompanyRoleOrm, 'userId', 'orgId'),
      roleId: this.attr(undefined),
      role: this.belongsTo(CompanyRoleOrm, 'roleId'),


      bindGroupAppId: this.attr(''),
      daysRemaining: this.attr(0),
      department: this.attr(''),
      expireTime: this.attr(0),
      tag: this.attr(undefined),
      tradeFairWorkspace: this.attr(undefined),
      roleType: this.attr(undefined),
    };
  },
});

export default User;

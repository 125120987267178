
// import Catalog from '@/models/Catalog';
// import Material from '@/models/Material';
// import Workspace from '@/models/Workspace';

// import DataCollector, {
//   sameReducer, WithId,
// } from './data-collector';

import DataCollector, {
  WithId,
} from './data-collector';


// const catalogReducers = {
//   id: sameReducer,
//   name: sameReducer,
//   workspaceId: sameReducer,
//   appType: sameReducer,
//   fileType: sameReducer,
//   relateType: sameReducer,
//   relateId: sameReducer,
//   entryAppType: sameReducer,
//   parentFolderId: sameReducer,
// };

// const workspaceReducers = {
//   groupAppId: sameReducer,
// };

export function getTypedDataCollector(type: string, data: WithId | WithId[]) {
  // if (type === 'catalog') {
  //   return (DataCollector.create(data as Catalog, {
  //     reducers: catalogReducers,
  //   }));
  // } if (type === 'workspace') {
  //   return DataCollector.create(data as Workspace, { reducers: workspaceReducers });
  // } if (type === 'material') {
  //   /** @ts-ignore */
  //   return DataCollector.create(data as Material);
  // }
  return DataCollector.create(data);
}
export default getTypedDataCollector;


import * as Entry from '@/packages/Entry';
import * as AppIdConfig from '@/packages/AppIdConfig';
import * as Platform from '@/packages/Platform';

export const useLogin = () => {
  const { getEntry } = Entry.useEntry();
  const appIdConfig = AppIdConfig.useAppIdConfig();
  const { isValid: isValidPlatform } = Platform.useValidPlatform();


  const gotoPlatform = (appId: string) => {
    const tmp = isValidPlatform(appId);

    if (tmp._tag === 'valid_platform') return { _tag: 'goto_platform' } as const;
    return { _tag: 'invalid_platform' } as const;
  };

  const gotoNext = (redirect?: string) => {
    if (redirect) return { _tag: 'goto_redirect' } as const;

    const entry = getEntry();
    // debugger;

    if (entry._tag === 'self-entry') return { _tag: 'goto_platform_select' } as const;

    const target = appIdConfig.get(entry.value);

    if (target && target.redirectUrl) {
      const tmp = gotoPlatform(target.appId);

      if (tmp._tag === 'goto_platform') return { _tag: 'goto_platform_index', url: target.redirectUrl } as const;

      return { _tag: 'goto_platform_select' } as const;
    }

    return { _tag: 'goto_platform_select' } as const;
  };

  return {
    gotoPlatform,
    gotoNext,
  };
};

/**
 * // 配置项名称(name)说明
 * //
 * // AppId 配置, 默认是FIM业务线 100, 业务数据按照AppId来过滤
 * appId = 'appid',
 * // platformId
 * platformId = 'platformid',
 * // TerminalId 配置, 默认是FIM业务线 200, 用户行为分析id
 * terminalId = 'terminalid',
 * // 登录页面logo的url地址, 默认是Revofim, 尺寸: 160*36
 * loginLogo = 'logo_login',
 * // 个人中心页面logo的url地址, 默认是Revofim, 尺寸: 160*36
 * userProfileLogo = 'logo_user_profile',
 * // 登录页面背景的url地址，尺寸: 2331*1558
 * loginBackground = 'background_login',
 * // 是否隐藏忘记密码入口
 * hiddenForgetPassword = 'hidden_forget_password',
 * // 是否隐藏个人中心的header部分
 * hiddenHeader = 'hidden_user_header',
 * // 是否隐藏切换语言
 * hiddenSwitchLanguage = 'hidden_switch_language',
 * // 是否隐藏切换主题
 * hiddenSwitchTheme = 'hidden_switch_theme',
 * // 是否隐藏意见反馈
 * hiddenFeedback = 'hidden_feedback',
 * // 是否隐藏注册账号入口
 * hiddenRegister = 'hidden_register',
 * // 是否隐藏切换公司入口
 * hiddenSwitchCompany = 'hidden_switch_company',
 * // 是否禁用选择角色类型，默认是普通角色
 * disabledRoleTypeSelect = 'disabled_role_type_select',
 * // 是否禁用选择账号类型，默认是普通账号
 * disabledUserTypeSelect = 'disabled_user_type_select',
 *
 */

export default [
  {
    name: 'appid',
    data: {
      type: 'string',
      value: '100',
    },
  },
  {
    name: 'platformid',
    data: {
      type: 'number',
      value: 1,
    },
  },
  {
    name: 'init_platform',
    data: {
      type: 'boolean',
      value: true,
    },
  },
  {
    name: 'hidden_switch_language',
    data: {
      type: 'boolean',
      value: false,
    },
  },
  {
    name: 'hidden_switch_theme',
    data: {
      type: 'boolean',
      value: false,
    },
  },
  {
    name: 'hidden_back',
    data: {
      type: 'boolean',
      value: true,
    },
  },
  {
    name: 'hidden_authority',
    data: {
      type: 'boolean',
      value: false,
    },
  },
  {
    name: 'no_disabled_full_company_name',
    data: {
      type: 'boolean',
      value: true,
    },
  },
  {
    name: 'show_team_manage',
    data: {
      type: 'boolean',
      value: true,
    },
  },
  {
    name: 'need_workspace',
    data: {
      type: 'boolean',
      value: true,
    },
  },
  {
    name: 'need_salesiq',
    data: {
      type: 'boolean',
      value: true,
    },
  },
];

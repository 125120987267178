import { VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

type SelectComp = Vue & { popperElm?:HTMLElement, value?:string } | undefined;

const getParentEl = (el:HTMLElement, targetClass:string):HTMLElement => {
  if (el.classList.contains(targetClass)) return el;
  if (el.parentElement) return getParentEl(el.parentElement, targetClass);
  return document.documentElement;
};

const disabledEl = (el:HTMLElement) => {
  el.style.pointerEvents = 'none';
  setTimeout(() => {
    el.style.pointerEvents = 'initial';
  }, 500);
};

export default {
  bind(el:HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    const selectComp:SelectComp = vnode.componentInstance;

    selectComp?.$on('visible-change', async (show:boolean) => {
      if (!show) {
        // 关闭时，禁止快速点击重新打开，否则会由于动画还未关闭导致定位错误
        disabledEl(el);
        return;
      }
      await selectComp.$nextTick();
      const popEl = selectComp?.popperElm;
      const { value } = selectComp;
      if (!value) return;
      const selectedOptionEl = popEl?.querySelector('.selected') as HTMLElement;
      if (!selectedOptionEl) return;
      const scrollEl = getParentEl(selectedOptionEl, 'el-scrollbar__wrap');
      const maxOffsetHeight = (scrollEl.clientHeight - selectedOptionEl.clientHeight) / 2;
      const needOffsetHeight = selectedOptionEl.offsetTop - maxOffsetHeight;
      const resOffsetHeight = Math.max(Math.min(needOffsetHeight, maxOffsetHeight), 0);
      scrollEl.scrollTop += resOffsetHeight;
    });
  },
};

import '@/utils/document-domain';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import { parse } from 'query-string';
import { container } from '@/packages/container';
import * as Entry from '@/packages/Entry';
import * as AppIdConfig from '@/packages/AppIdConfig';
import { setId, setPlatformId } from './settings';
import app from './app';
import { i18n } from './i18n';
import { ready } from './utils/x-storage';
import resourceHelper from './resource-helper';
import { isIdeationPCClient } from './utils/is-pc-client';
import errorHandler from './utils/error-handler';


async function main() {
  const contain = container({
    app: null,
    store: app.$store,
    i18n,
  });

  await contain(async () => {
    const entry = Entry.useEntry();
    const appIdConfig = AppIdConfig.useAppIdConfig();

    const query = parse(window.location.search.substr(1));
    const tmp = Entry.create(query._appId as string, query.a as string);
    entry.setEntry(tmp);

    if (tmp._tag === 'one-entry') {
      setId(tmp.value);
    }

    await appIdConfig.init();
    if (tmp._tag === 'one-entry') {
      setPlatformId(appIdConfig.getCurrentPlatformId());
    }
  })();

  ready();
  if (!isIdeationPCClient()) {
    try {
      resourceHelper.activate();
    } catch (e) {
      console.warn(e);
    }
  }


  window.onrejectionhandled = (event) => {
    console.log(event)
    errorHandler(event.reason)
  }
  app.$mount('#app');
  // eslint-disable-next-line no-constant-condition
}

main();

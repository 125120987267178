import { i18n } from '@/i18n';

const defaults = {
  message: i18n.t('validator.not_empty', 'can not be empty'),
};
export function validFilled(v: any, { message = defaults.message } : { message ?: string } = defaults) {
  if (typeof v === 'string') {
    v = v.trim();
    if (v) return;
  } else if (typeof v === 'number') {
    return;
  } else if (typeof v === 'boolean') return;
  else if (
    v
    && (Array.isArray(v) ? v.length : Object.values(v).filter((item) => item !== undefined).length)
  ) {
    return;
  }
  throw new Error(message);
}

export default validFilled;

import Company from './Company';
import modelOf from './utils/model-of';

enum PlatformType {
  FIM = '100',
  MATERIAL = '200'
}
export class Package {
  id!: string;
  appId!: PlatformType;
  authStatus!: number;
  bussinessType!: number | null;
  code!: string;
  serviceConfList!:Record<string, number>;
  type!: number;
  name!: string;
  packageName() {
    return this.name;
  }
  packageCode() {
    return this.code;
  }
  packageId() {
    return this.id;
  }
}

class ComponayPackage {
  companyId!: string;
  company!: Company;
  packageId!: string;
  package!: Package;
}

// export class Package {
//   packageId!: string;
//   packageName!: string;
//   packageCode?:string;
// }

export const CompanyPackageOrm = modelOf(ComponayPackage, {
  entity: 'companyPackages',
  primaryKey: ['packageId', 'companyId'],
  fields() {
    return {
      packageId: this.attr(''),
      companyId: this.attr(''),
    };
  },
});

export const PackageOrm = modelOf(Package, {
  entity: 'packageInfos',
  primaryKey: 'id',
  fields() {
    return {
      id: this.attr(''),
      appId: this.attr(''),
      authStatus: this.attr(null),
      bussinessType: this.attr(null),
      code: this.attr(''),
      serviceConfList: this.attr(null),
      type: this.attr(null),
      name: this.attr(''),
    };
  },
});

export default Package;

import tryParseJson from '_/try-parse-json';
import useDataCollector from './composables/use-data-collector';
import { getTypedDataCollector } from './typed-data-collector';


const storageKey = 'clipboard';
const storage = localStorage;

export async function readText() {
  if (document.queryCommandEnabled('paste')) {
    const { activeElement } = document;
    const pasteText = document.createElement('textarea');
    document.body.appendChild(pasteText);
    pasteText.focus();
    document.execCommand('paste');
    const result = pasteText.textContent;
    document.body.removeChild(pasteText);
    activeElement?.focus();
    return result;
  }
  return navigator.clipboard.readText();
}

const isIOS = () => navigator.userAgent.match('/ipad|iphone/i');
export async function writeText(text: string) {
  const t = document.createElement('textarea');
  t.readOnly = true;
  // @ts-ignore
  t.contentEditable = true;
  t.value = text;
  document.body.appendChild(t);

  let range; let selection;
  if (isIOS()) {
    range = document.createRange();
    range.selectNodeContents(t);
    selection = window.getSelection();
    selection!.removeAllRanges();
    t.setSelectionRange(0, 999999);
  } else {
    t.select();
  }

  const r = document.execCommand('copy');
  document.body.removeChild(t);
  return r;
}

const instance = useDataCollector(getTypedDataCollector, {
  access: () => {
    const d = storage.getItem(storageKey);
    if (!d) {
      return null;
    }
    return JSON.parse(d);
  },
  mutate: ({ data, command, type }) => {
    storage.setItem(storageKey, JSON.stringify({ data, command, type }));
  },
  clear: () => {
    storage.removeItem(storageKey);
  },
});

window.addEventListener('storage', (e) => {
  if (
    e.key === storageKey
  ) {
    instance.set(tryParseJson(e.newValue));
  }
});

export default instance;

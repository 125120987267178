import Company, { CompanyOrm } from '@/models/Company';
import { getCompanyInfo, getCompanyPackages } from '@/api/company';
import { getContextCompany } from './context';

// 更新Company对象
export async function updateCompany(optinalData?: Partial<Company>) {
  let data: Partial<Company>;
  // let currPackage = optinalData?.package;
  if (!optinalData) {
    data = (await getCompanyInfo()).data;
    const currPackage = (await getCompanyPackages()).data;
    data.packages?.push(currPackage);
  } else {
    data = optinalData;
  }
  // if (data.packageId) {
  //   data.package = {
  //     packageId: data.packageId,
  //     packageName: data.packageName!,
  //     packageCode: data.packageCode,
  //   };
  // }
  CompanyOrm.insertOrUpdate({
    data,
  });
  return data as Company;
}

/** @deprecated */
export const getCurrentCompany = getContextCompany;


export interface Controller<T> extends PromiseLike<T> {
  promise: Promise<T>;
  cancel: (reason?: any) => void;
}
export function cancellable<T>(promise: Promise<T>, cancelError = new Error('user abort')): Controller<T> {
  let cancel: (reason?: any) => void;
  const controlPromise = new Promise<never>((_, _reject) => {
    cancel = (e = cancelError) => _reject(e);
  });
  const newPromise = Promise.race([promise, controlPromise]);
  return {
    promise,
    then: (...args: any[]) => newPromise.then(...args),
    cancel: cancel!,
  };
}

export class CtrllablePromise<T = any> implements PromiseLike<T> {
  promise: Promise<T>;
  resolve!: (value: T | PromiseLike<T>) => void;
  reject!: (reason?: any) => void;
  constructor(fnFinally: () => void = () => { }) {
    this.promise = new Promise<T>((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    }).finally(fnFinally);
  }

  then<TResult1 = T, TResult2 = never>(
    onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
    onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null,
  ): PromiseLike<TResult1 | TResult2>;
  // eslint-disable-next-line no-dupe-class-members
  then(...args: any[]): Promise<any> {
    return this.promise.then(...args);
  }
}
export class ComponentCtrllablePromise<T = any, Props = Record<string, unknown>> extends CtrllablePromise<T> {
  props: Props;
  constructor(props: Props, fnFinally: () => void = () => { }) {
    super(fnFinally);
    this.props = props;
  }
}

export default cancellable;

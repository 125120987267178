import { withProps } from '@/router/utils/props';
import { RouteConfig } from 'vue-router';
import projectTabList from '@/views/project/project-setting/components/utils';
import AppError from '@/utils/error';
import { Form } from 'vxe-table';
// import i18n from '@/i18n';
// 项目配置
const project: RouteConfig = {
  name: 'PeojectSetting',
  path: 'project',
  redirect: 'project/field',
  beforeEnter(to, from, next) {
    if (!projectTabList.find(item => item.to === to.path)?.can()) {
      if (projectTabList.filter(item => item.can())[0]) {
        next(projectTabList.filter(item => item.can())[0]?.to);
      } else {
        throw AppError.auth;
      }
    }
    next();
  },
  props: withProps(),
  component: () => import(/* webpackChunkName: "project-settings" */ '@/views/project/project-setting/index.vue'),
  children: [
    {
      name: 'FieldSetting',
      path: 'field',
      meta: {
        title: 'Field Setting',
      },
      props: withProps(),
      component: () => import(/* webpackChunkName: "project-settings" */ '@/views/project/project-setting/field-setting/index.vue'),
    },
    {
      name: 'SampleSetting',
      path: 'sample',
      meta: {
        title: 'Sample Setting',
      },
      props: withProps(),
      component: () => import(/* webpackChunkName: "project-settings" */ '@/views/project/project-setting/sample-setting/index.vue'),
    },
    {
      name: 'TypeSetting',
      path: 'type',
      meta: {
        title: 'Type Setting',
      },
      props: withProps(),
      component: () => import(/* webpackChunkName: "project-settings" */ '@/views/project/project-setting/type-setting/index.vue'),
    },
    {
      name: 'Basics',
      path: 'basics',
      meta: {
        title: 'Basics Setting',
      },
      props: withProps(),
      component: () => import(/* webpackChunkName: "project-settings" */ '@/views/project/project-setting/basics-setting/index.vue'),
    },
    {
      name: 'Material',
      path: 'material',
      meta: {
        title: 'Material Setting',
      },
      props: withProps(),
      component: () => import(/* webpackChunkName: "project-settings" */ '@/views/lib/field/index.vue'),
    },
    {
      name: 'OtherConfiguration',
      path: 'other',
      meta: {
        title: 'Other Configuration',
      },
      props: withProps(),
      component: () => import(/* webpackChunkName: "project-settings" */ '@/views/project/project-setting/other-configuration/index.vue'),
    },
    {
      name: 'DevStageConfig',
      path: 'dev-stage',
      props: withProps(),
      component: () => import(/* webpackChunkName: "project-settings" */ '@/views/project/project-setting/dev-stage-setting/index.vue'),
    },
  ],
};

const taskSetting: RouteConfig = {
  name: 'TaskSetting',
  path: 'task',
  meta: {
    title: 'Task Setting',
  },
  props: withProps(),
  component: () => import(/* webpackChunkName: "project-settings" */ '@/views/project/project-setting/task-setting/index.vue'),
};

export default [project, taskSetting];

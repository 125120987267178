
import { getLang } from '@/i18n';
import apiTranslate from '@/i18n/langs/apiTranslate.json';

const isCn = getLang() !== 'en';
const translate = (key:string | undefined | null) => {
  if (!key) return '';
  if (isCn) return key;
  return apiTranslate[key] || key;
};

export default translate;

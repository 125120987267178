var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.visible)?_c('transition',{attrs:{"duration":500,"name":"dialog-wrapper-fade"}},[_c('div',{ref:"dialogWrapper",staticClass:"el-dialog__wrapper",class:[
      { 'with-modal': _vm.modal, 'is-compact': _vm.compact },
      _vm.customClass,
      _vm.type ? `is-${_vm.type}` : '',
      _vm.size ? `is-${_vm.size}` : '',
    ],style:({
      display: 'flex',
      flexDirection: 'column',
    }),on:{"mousedown":function($event){if($event.target !== $event.currentTarget)return null;return _vm.handleClickOutside.apply(null, arguments)}}},[(_vm.visible && _vm.modal)?_c('div',{staticClass:"v-modal",attrs:{"tabindex":"0"},on:{"click":_vm.handleClickOutside}}):_vm._e(),_c('div',{ref:"dialog",class:['el-dialog', { 'is-fullscreen': _vm.fullscreen, 'el-dialog--center': _vm.center }],style:({
        width: _vm.width,
        height: _vm.height,
        'max-height': _vm.maxHeight,
        margin: _vm.isWS ? `auto ${_vm.right} ` : `auto `,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '4px',
        ..._vm.dialogStyle,
      }),attrs:{"role":"dialog","aria-modal":"true","aria-label":_vm.title || 'dialog'}},[(_vm.showClose)?_c('button',{staticClass:"el-dialog__headerbtn",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.$emit('update:visible', false)}}},[_c('i',{staticClass:"el-dialog__close el-icon el-icon-close"})]):_vm._e(),(_vm.title || _vm.$scopedSlots.title || _vm.$slots.title)?_c('div',{staticClass:"el-dialog__header",style:({ textAlign: _vm.titleAlign, height: _vm.headerHeight })},[_vm._t("title",function(){return [_c('span',{staticClass:"el-dialog__title"},[_vm._v(_vm._s(_vm.title))])]})],2):_vm._e(),_c('div',{staticClass:"el-dialog__body",class:{ 'no-padding': _vm.noPadding },style:(_vm.flex
            ? {
              display: 'flex', flexDirection: _vm.flex, flex: 1, overflowY: 'auto', position: 'relative',
            }
            : { position: 'relative' })},[_vm._t("default")],2),(_vm.$slots.footer)?_c('div',{staticClass:"el-dialog__footer"},[_vm._t("footer")],2):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
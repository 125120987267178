
import first from '@4dst-saas/public-utils/dist/first';
import Cookies from 'js-cookie';
import { parseUrl } from 'query-string';
import getCookieConfig from './get-cookie-config';
import axios from './axios';
import TeamsHost from './hosts/teams';


const TOKEN_NAME = 't';
function getCookiesToken() {
  return Cookies.get(TOKEN_NAME);
}
function getQueryToken() {
  return first(parseUrl(globalThis.location.href).query.token);
}
export default {
  get: () => {
    return getQueryToken() ?? getCookiesToken();
  },
  getQueryToken() {
    return first(parseUrl(globalThis.location.href).query.token);
  },
  getCookiesToken() {
    return Cookies.get(TOKEN_NAME);
  },
  set(token: string) {
    Cookies.set(TOKEN_NAME, token, { ...getCookieConfig(), expires: 7 });
    // localStorage.setItem(TOKEN_NAME, token);
  },
  remove() {
    Cookies.remove(TOKEN_NAME, getCookieConfig());
    // localStorage.removeItem(TOKEN_NAME);
  },
  getUserId() {
    const token = this.get();
    if (!token) return undefined;
    const id = token.split(':')[0];
    return id || undefined;
  },
};


let _pAnonymousToken: Promise<string | undefined> | undefined;
async function _getAnonymousToken() {
  if (await TeamsHost.detect()) {
    const { userObjectId } = await TeamsHost.exec('getContext');
    const { data: timToken } = await axios.post<string>('authcenter/v1.0/user/login/third', {
      code: userObjectId,
      // 1: Ideation
      platform: '1',
      terminal: 'TEAMS',
      channel: '3',
    });
    if (timToken && timToken.startsWith('ANONYMOUS_TOKEN:')) {
      return timToken;
    }
  }
  return undefined;
}
export function getAnonymousToken() {
  _pAnonymousToken ??= _getAnonymousToken();
  return _pAnonymousToken;
}

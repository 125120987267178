<template>
  <div class="attachment-image">
    <h2 class="attachment-image-title" :style="titleStyle">
      <span>{{ $t("opinion.picture") }}</span>
    </h2>
    <UploadWithPreviewer
      ref="uploadWithPreviewer"
      :urls="paths"
      :maxSize="3 * 1024 * 1024"
      :width="100"
      :height="100"
      :max="3"
      accept=".png, .jpeg, .jpg"
      multiple
      :disabled="disabled"
      :exceedMaxErr="true"
      @change="files = $event"
    />
    <div class="priviewer-desc">{{ $t("opinion.attchment.tip") }}</div>
  </div>
</template>

<script>
import UploadWithPreviewer from '@/components/upload/UploadWithPreviewer';
import uploadToOSS from '../oss/uploadToOSS';


export default {
  components: {
    UploadWithPreviewer,
  },
  props: ['titleStyle', 'disabled'],
  data() {
    return {
      files: [],
      paths: [], // 记录已上传的oss地址
    };
  },
  methods: {
    // 获取上传文件oss地址的列表（外部调用）
    /**
     * @return Array
     * 返回图片链接的Array
     */
    async getImageOssPaths() {
      const result = this.$refs.uploadWithPreviewer.fileList.reduce((acc, cur) => {
        if (cur.file) {
          acc.uploading.push(cur.file);
        } else {
          acc.uploaded.push(cur.url);
        }
        return acc;
      }, { uploaded: [], uploading: [] });
      const uploadedPaths = result.uploaded.map(url => url.split('?')[0].split('/').slice(-2).join('/'));
      const paths = await Promise.all(result.uploading.map(file => uploadToOSS(file, 'BASE')));
      return [...uploadedPaths, ...paths];
    },
  },
};
</script>

<style lang="scss" scoped>
// #{$dark-theme-selector} {
//   .attachment-image-title {
//     color: $--color-dark-font;
//   }
//   .priviewer-desc {
//     color: $--color-dark-font;
//   }
// }
//
</style>

<style lang="scss" scoped>
.attachment-image-title {
  margin-bottom: 10px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #606060;
}
.priviewer-desc {
  font-size: 12px;
  color: #999;
  line-height: 30px;
}
#{$dark-theme-selector} {
  .attachment-image-title {
    color: $--color-dark-font;
  }
  .priviewer-desc {
    color: $--color-dark-font;
  }
}
</style>


import {
  defineComponent,
  onMounted, onBeforeUnmount, nextTick,
  ref, computed,
} from 'vue';
import { throttle } from 'throttle-debounce';
import loadMarkdown from '@/utils/load-markdown';

type Catalog = {
  getOffsetTop: () => number,
  id: string,
  text: string,
  childs?: Catalog[],
};

const useCatalog = () => {
  const catalog = ref<Catalog[]>([]);
  const current = ref<string>();
  const catalogTmp = computed(() => {
    return catalog.value
      .reduce((result, a) => {
        result.push(a);
        if (a.childs) result.push(...a.childs);
        return result;
      }, [] as Catalog[])
      .reverse();
  });
  const offsetTopTmp = computed(() => {
    return catalogTmp.value.reduce((result, a) => {
      result[a.id] = a.getOffsetTop();
      return result;
    }, {} as Record<string, number>);
  });

  const select = (id: string) => {
    current.value = id;
    window.location.href = `#${id}`;
    window.location.href = `#${id}`;
  };

  const mountedHandle = (listenerEl: HTMLElement | HTMLDocument, getScrollTop: () => number) => {
    const scrollHandle = throttle(120, () => {
      const target = catalogTmp.value.find(a => {
        return offsetTopTmp.value[a.id] <= getScrollTop();
      });

      if (target) current.value = target.id;
    });

    listenerEl.addEventListener('scroll', scrollHandle);
    return () => {
      listenerEl.removeEventListener('scroll', scrollHandle);
    };
  };

  const initSource = (containerDom: HTMLElement) => {
    const headerList = [...containerDom.querySelectorAll('h3, h2')] as HTMLHeadElement[];
    catalog.value = headerList.reduce((result, a) => {
      if (a.nodeName === 'H2') {
        result.push({
          getOffsetTop: () => a.offsetTop,
          id: a.id,
          text: a.innerText,
          childs: [],
        });
      } else if (a.nodeName === 'H3' && result[result.length - 1].childs) {
        result[result.length - 1].childs!.push({
          getOffsetTop: () => a.offsetTop,
          id: a.id,
          text: a.innerText,
        });
      } else if (a.nodeName === 'H3') {
        result.push({
          getOffsetTop: () => a.offsetTop,
          id: a.id,
          text: a.innerText,
        });
      }
      return result;
    }, [] as Catalog[]);
  };

  return {
    initSource,
    mountedHandle,
    select,
    getSource: () => catalog.value,
    getCurrent: () => current.value,
  };
};

const Policy = defineComponent({
  name: 'Policy',
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const catalog = useCatalog();

    const html = ref<string | null>(null);
    const containerRef = ref<HTMLElement>();

    onMounted(async () => {
      const { data: dom } = await loadMarkdown(props.url);
      html.value = dom.innerHTML;

      await nextTick();

      catalog.initSource(containerRef.value!);
    });

    onMounted(() => {
      const unMounted = containerRef.value!.parentElement?.classList.contains('el-dialog__body')
        ? catalog.mountedHandle(
          containerRef.value!.parentElement,
          () => containerRef.value!.parentElement!.scrollTop,
        )
        : catalog.mountedHandle(
          document,
          () => document.documentElement.scrollTop,
        );

      onBeforeUnmount(unMounted);
    });

    return {
      containerRef,
      catalog,
      html,
    };
  },
});
export default Policy;

/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-use-before-define */

// import { instance as history } from './history';

import extract from '@4dst-saas/public-utils/dist/extract';
import ErrorLike from '../typings/error-like.d';
import { RawHistoryLocation } from '../typings/history.d';

type Mixin = ErrorLike | Record<string, unknown>;
// @ts-ignore
export default class AppError extends Error implements ErrorLike {
  errors?: ErrorLike[];

  message = '';

  name: string;

  position?: string;

  code?: number;

  level?: number;

  data?: unknown;

  redirect?: RawHistoryLocation;

  type?: string;

  muted?: boolean;

  instance?: unknown;

  isFECodeError?: boolean;

  [otherProps: string]: unknown

  constructor();
  constructor(mixin: Mixin);
  constructor(message: string, mixin?: Mixin);
  constructor(error: ErrorLike, mixin?: Mixin);
  constructor(errors: ErrorLike[], mixin?: Mixin);
  constructor(mixinOrMessageOrErrors?: Mixin | string | ErrorLike[], mixin?: Mixin) {
    super();
    if (typeof mixinOrMessageOrErrors === 'string') {
      mixin = {
        message: mixinOrMessageOrErrors,
        ...mixin,
      };
    } else if (Array.isArray(mixinOrMessageOrErrors)) {
      mixin = {
        errors: mixinOrMessageOrErrors,
        ...mixin,
      };
    } else if (mixinOrMessageOrErrors) {
      mixin = {
        ...extract(mixinOrMessageOrErrors, ['message', 'name', 'stack', 'code', 'data', 'position', 'level', 'redirect', 'errMsg', 'title']),
        ...mixin,
      };
    }
    if (!mixin) {
      mixin = {
        message: 'Error! Something bad happened...',
      };
    }
    Object.assign(this, mixin);
    this.name = this.constructor.name;
  }

  static CODE_USER_ABORT = 499 as const;

  static CODE_AUTH_ERROR = 100403 as const;

  static CODE_NEED_LOGIN = 100401 as const;

  static CODE_EMPTY = 100404 as const;

  static CODE_UNKNOWN_ERROR = 100500 as const;

  static CODE_NO_IMAGE = 'who cares' as unknown as number;

  static CODE_NO_RECORD = 'no-record' as unknown as number;

  static CODE_NO_SEARCH_RESULT = 'who cares3' as unknown as number;

  static get abort(): AbortError {
    return new AbortError();
  }

  static get auth(): AuthError {
    return new AuthError();
  }

  static get muted(): MutedError {
    return new MutedError();
  }

  static get authMuted(): AuthError {
    return new AuthError(`CODE:${AppError.CODE_AUTH_ERROR}`, true);
  }

  static get needLogin(): NeedLoginError {
    return new NeedLoginError();
  }

  static get empty(): EmptyError {
    return new EmptyError();
  }

  static get unknown(): UnknownError {
    return new UnknownError();
  }

  get [Symbol.iterator](): undefined | (() => Generator<ErrorLike, void, undefined>) {
    const { errors } = this;
    if (!errors) {
      return undefined;
    }
    // eslint-disable-next-line func-names
    return function* () {
      for (const err of errors) {
        yield err;
      }
    };
  }

  static LEVELS = {
    INFO: -2,
    WARN: -1,
    ERROR: 0,
    DANGER: 1,
  };
}

export class ValidateError extends AppError {
  constructor(message = 'Validate Error', mixin: { instance?: Record<PropertyKey, unknown> | undefined } = {}) {
    super(message, { ...mixin, code: 498 });
  }
}

export class MutedError extends AppError {
  constructor(message = 'Unknow Error') {
    super(message, {
      muted: true,
    });
  }
}

export class AbortError extends AppError {
  constructor(message = 'User Abort') {
    super(message, {
      code: AppError.CODE_USER_ABORT,
      level: -2,
      muted: true,
    });
  }
}

export class AuthError extends AppError {
  constructor(message = `CODE:${AppError.CODE_AUTH_ERROR}`, muted = false) {
    super(message, {
      code: AppError.CODE_AUTH_ERROR,
      level: -1,
      muted,
    });
  }
}

export class EmptyError extends AppError {
  constructor(message = `CODE:${AppError.CODE_AUTH_ERROR}`) {
    super(message, {
      code: AppError.CODE_EMPTY,
      level: -2,
      muted: true,
    });
  }
}

export class UnknownError extends AppError {
  constructor(message = `CODE:${AppError.CODE_UNKNOWN_ERROR}`) {
    super(message, {
      code: AppError.CODE_UNKNOWN_ERROR,
    });
  }
}

export class NeedLoginError extends AppError {
  constructor(message = `CODE:${AppError.CODE_NEED_LOGIN}`) {
    super(message, {
      code: AppError.CODE_NEED_LOGIN,
      level: -1,
    });
  }
}

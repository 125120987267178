import first from '@4dst-saas/public-utils/dist/first';
import {
  computed, customRef, watch, ref,
} from 'vue';
// import { isMobile } from '@/views/mobile-ideation/utils/common';
import { parseUrl, stringifyUrl } from 'query-string';
import { Route } from 'vue-router';
import envs from '../envs';
import isQt from '../is-qt';

let initQueryTheme = first(parseUrl(window.location.href).query.theme);
if (initQueryTheme) {
  localStorage.setItem('theme', initQueryTheme);
  const newHref = stringifyUrl({
    url: window.location.href,
    query: { theme: undefined },
  });
  try {
    window.history.replaceState(null, '', newHref);
  } catch (err) {
    console.error(err);
  }
}

const routeTheme = ref<string>();
const domainTheme = envs.VUE_APP_COOPERATION === 'anta' ? 'light' : null;

export function setRoute(route: Route) {
  const routeRecordWithTheme = [...route.matched].reverse().find(item => item.meta.theme);
  const metaTheme = routeRecordWithTheme?.meta.theme ?? null;
  const queryTheme = first(route.query.theme);
  routeTheme.value = metaTheme ?? queryTheme;
}

const urlTheme = computed(() => {
  return domainTheme ?? routeTheme.value;
});

export const themeList = [
  {
    key: null,
    label_cn: '随系统设置',
    label_en: 'Follow system setting',
  }, {
    key: 'dark',
    label_cn: '深色主题',
    label_en: 'Dark',
  }, {
    key: 'light',
    label_cn: '浅色主题',
    label_en: 'Light',
  }];

const systemTheme = customRef((track, trigger) => {
  if (window.matchMedia) {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
      trigger();
    });
  }
  return {
    get() {
      track();
      if (window.matchMedia) {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
          return 'dark';
        }
        if (window.matchMedia('(prefers-color-scheme: light)').matches) {
          return 'light';
        }
      }
      return null;
    },
    set() {
    },
  };
});
const storageTheme = customRef((track, trigger) => {
  return {
    get() {
      track();
      return localStorage.getItem('theme');
    },
    set(newValue: string | null) {
      if (!newValue) {
        localStorage.removeItem('theme');
      } else {
        localStorage.setItem('theme', newValue);
      }
      trigger();
    },
  };
});
const theme = computed(() => {
  const isOldQtVersion = isQt() && !envs.VUE_APP_PC_CLIENT_VERSION;
  if (isOldQtVersion) {
    return 'light';
  }
  const shareTheme = parseUrl(globalThis.location.href).query?.shareTheme;
  if (shareTheme) {
    const themeConfig = themeList.find((item) => shareTheme === item.key);
    // if (themeConfig && !isMobile()) { // 移动端暂时没有暗黑ui稿
    if (themeConfig) { // 移动端暂时没有暗黑ui稿
      return themeConfig.key;
    }
  }
  return urlTheme.value ?? storageTheme.value ?? systemTheme.value ?? 'light';
});
watch(theme, (newValue) => {
  document.documentElement.setAttribute('data-theme', newValue);
}, {
  immediate: true,
});
export const isDark = computed(() => theme.value === 'dark');
const switchTheme = (newValue: string | null) => {
  initQueryTheme = null;
  storageTheme.value = newValue;
};

export function useTheme() {
  return {
    /** 当前的的主题是否为黑色 */
    isDark,
    /** 当前的主题，'dark' | 'light'，只读 */
    theme,
    /** 用户主动设置的主题，'dark' | 'light' | null，读写 */
    storageTheme,
    /** 系统设置的主题，'dark' | 'light' | null，只读 */
    systemTheme,
    /** 设置主题，等同于storageTheme.value = newTheme  */
    switchTheme,
  };
}

export default useTheme;

import { ContextOrm } from '@/models/Context';
import User from '@/models/User';
import tokenUtils from '@/utils/token';
import {
  isAuthError,
} from '@/utils/error-handler';
import axios from '@/utils/axios';
import {
  PermissionOrm,
} from '@/models';
import Company, { CompanyOrm, ResourceInfo } from '@/models/Company';

import companyUtils from '@/utils/company';
import extract from '@4dst-saas/public-utils/dist/extract';
import Package from '@/models/Package';
// import { LocalStorageUtil } from '@/views/mobile-ideation/utils';
import clipboard from '@/utils/clipboard';
// import { parse } from 'query-string';
// import i18n from '@/i18n';
// import SdSocket from '@/utils/sd-socket';
import { refreshRole } from '@/log/store';
import { getAppId } from '@/utils/platform-type';
import startPingDatapipe from '@4dst-saas/datapipe/libs/start';
import { getUserCompaniesInfo, Company as ApiCompany } from '@/api/company';
import { updateSystemPermissions } from './permission';
import { updateBrandSupplier } from './brandSupplier';
import PlatformType from './platform-type';


// 获取公司信息
export function getContextCompany() {
  return ContextOrm.query().whereId(1).with(['company.packages', 'company.resourceInfo'])
    .first()?.company;
}

export function getContextPlatformInfoList() {
  return ContextOrm.query().whereId(1).with(['company.platformInfoList'])
    .first()?.company?.platformInfoList || [];
}


/** @deprecated */
export const getContextUserId = () => tokenUtils.getUserId();

// 通过用户ID get User对象
export function getContextUser(): User | null {
  return ContextOrm.query().with(['user.companies']).whereId(1).first()?.user ?? null;
}

// set User对象
export async function setContextUser(user: User | Partial<User> | null) {
  await ContextOrm.insertOrUpdate({
    data: {
      id: 1,
      user,
    },
  });
}

// 局部更新context数据
export async function updateContextPartially(user: Partial<User>) {
  const id: string | undefined = user?.id;
  const computedUser = id ? user! : { ...user, id: getContextUserId() };
  await setContextUser(computedUser);
}

export const updateContextUserAccount = (account: string) => {
  updateContextPartially({ account });
};

let stopPingDataPipe: (() => void) | undefined;

export async function updateContextCompany(id?: string) {
  // id = id ?? Cookies.get(ORGID_NAME);
  id = id ?? companyUtils.get();
  // await updateCompany();
  if (stopPingDataPipe) {
    stopPingDataPipe();
  }
  stopPingDataPipe = await startPingDatapipe();
}

// // 移除公司信息（登出）
export function removeCompany() {
  // companyUtils.remove();
  if (stopPingDataPipe) {
    stopPingDataPipe();
    stopPingDataPipe = undefined;
  }
  const currentCompany = CompanyOrm.query().first();
  if (currentCompany) {
    currentCompany.$delete();
  }
}


type UserInfo = {
  id: string,
  headImg: string,
  mobile: string,
  name: string,
  mail: string,
  account: string
  phone: string,


  companyId: string,
  roleId: string,

  auditStatus: number,
  businessType: number,
  packageInfo: Package,
};

type CompanyIdAndName = Pick<Company, 'id' | 'name' | 'packages' | 'isPersonalCom' | 'isExpireTime'>;

export { PlatformType };


export async function getCompanyIdsAndNames() {
  /** @see {@link http://yapi.corp.4dstc.com/project/31/interface/api/15926} */
  return (await axios.get<CompanyIdAndName[]>('authcenter/v1.0/account/tenant/relate', {
    params: {
      appId: getAppId(),
      userId: tokenUtils.getUserId(),
    },
  })).data;
}


// 获取当前context
export async function updateContext({
  fallbackCompany = !companyUtils.getQueryCompany(),
  companies,
}: {
  /** 当提供公司id非法的时候，是否尽量选择可用公司 */
  fallbackCompany?: boolean
  companies?: ApiCompany[],
} = {}) {
  try {
    /** @see {@link http://yapi.corp.4dstc.com/project/31/interface/api/2547} */
    const { data: _userInfo } = await axios.get<Omit<UserInfo, 'packageInfo'> & {
      packageInfo: {
        packageId: string,
        packageCode: string,
        packageName: string,
      }
    }>('/authcenter/user/info/get');
    const userInfo = {
      ..._userInfo,
      packageInfo: _userInfo.packageInfo ? {
        id: _userInfo.packageInfo.packageId,
        code: _userInfo.packageInfo.packageCode,
        name: _userInfo.packageInfo.packageName,
      } : null,
    };
    const companyId = companyUtils.get();
    let contextCompany: ApiCompany;
    let relateCompanies: ApiCompany[];
    try {
      ({ company: contextCompany, companies: relateCompanies } = await getUserCompaniesInfo({
        companyId,
        fallbackCompany,
      }, companies));
    } catch (err) {
      companyUtils.remove();
      throw err;
    }

    if (contextCompany.isPersonalCom) {
      refreshRole.value = '504';
    } else if (contextCompany.isPersonalCom === false) {
      refreshRole.value = '503';
    } else {
      refreshRole.value = '';
    }

    // // 重新设置正确的companyId;
    // if (!companyId && !companyUtils.getQueryCompany()) {
    //   companyUtils.set(contextCompany.id);
    // }

    if (companyUtils.get() !== contextCompany.id) {
      companyUtils.set(contextCompany.id);
    }


    // 读取当前sdtc-tenant-id对应的套餐信息
    let userCompany: Company | undefined;

    if (userInfo.companyId) {
      const companyInCompanies = relateCompanies.find((item): item is Company => {
        return item.id === userInfo.companyId;
      });
      if (companyInCompanies) {
        companyInCompanies.packages ??= [];
        const userPackageInfo = userInfo.packageInfo as Package;
        if (userPackageInfo) {
          const _package = companyInCompanies.packages.find(__package => __package.id === userPackageInfo.id);
          if (_package) {
            Object.assign(_package, userPackageInfo, _package);
          } else {
            companyInCompanies.packages.unshift(userPackageInfo);
          }
        }
        Object.assign(companyInCompanies, {
          ...extract(userInfo, ['auditStatus', 'businessType']),
          ...companyInCompanies,
        });
        userCompany = companyInCompanies;
      } else {
        userCompany = {
          id: userInfo.companyId,
          name: '',
          packages: userInfo.packageInfo ? [userInfo.packageInfo] : [],
        };
        relateCompanies.push(userCompany!);
      }
    }


    const user = {
      ...extract(userInfo, ['id', 'mobile', 'headImg', 'phone', 'name', 'mail', 'account', 'companyId']),
      company: userCompany,
      companies: relateCompanies,
    };

    // let company: Company;
    // if (!companyId) {
    //   ([company] = relateCompanies);
    //   companyUtils.set(company.id);
    // } else {
    //   company = relateCompanies.find(item => {
    //     return item.id === companyId;
    //   }) ?? relateCompanies[0];
    //   companyUtils.set(company.id);
    // }
    const {
      orgQuantity, // 公司总数
      orgUsed, // 公司使用总数
      userQuantity, // 个人总数
      userUsed, // 个人使用总数
    } = (await axios.get('/authcenter/manage/groupapp/resourceInfo', {
      params: {
        orgId: contextCompany.id,
      },
      headers: {
        'Sdtc-Tenant-Id': contextCompany.id,
      },
    })).data;

    const orgQuantityHandler = (orgQuantity > 0 || userQuantity === 0) ? orgQuantity : '';
    const userQuantityHandler = (userQuantity > 0 || userQuantity === 0) ? userQuantity : '';

    contextCompany.resourceInfo = {
      ...(await axios.get<ResourceInfo>('/authcenter/resource/info/all', {
        headers: {
          'Sdtc-Tenant-Id': contextCompany.id,
        },
      })).data,
      orgQuantity: orgQuantityHandler,
      orgUsed: orgUsed || 0,
      userQuantity: userQuantityHandler,
      userUsed: userUsed || 0,
      companyId: contextCompany.id,
    };

    await ContextOrm.insertOrUpdate({
      data: {
        id: 1,
        user,
        company: contextCompany,
      },
    });
    await updateBrandSupplier();
    await updateContextCompany();
    // 后果未知
    await updateSystemPermissions([parseInt(getAppId(), 10), 6], contextCompany.id);
    // SdSocket.getInstance().open();
  } catch (error) {
    console.error(error);
    if (isAuthError(error)) {
      await setContextUser(null);
    } else {
      throw error;
    }
  }
}


export enum InviteType {
  NORMAL = 0,
  COMPANY_INVITE = 1,
  WORKSPACE_INVITE = 2,
  MEETING_INVITE = 3
}

export enum TerminalType {
  WEB = 'WEB',
  CLIENT = 'CLIENT',
  SCANNER = 'SCANNER',
  MOBILE = 'MOBILE',
  SHOE_SAMPLE_DESIGN = 'SHOE_SAMPLE_DESIGN',
  MA = 'MA',
  TEAMS = 'TEAMS',
  SHARE = 'SHARE'
}

export type AnonymousToken = string;
export const platformData = {
  terminal: TerminalType.WEB,
  platform: 1,
};

export enum LoginType {
  BY_PASSWORD = 'by-password',
  BY_PASSWORD_AND_CODE = 'by-password-and-code',
  BY_CODE = 'by-code'
}

export async function clearContext() {
  // setContextUser(null);
  ContextOrm.deleteAll();
  // 每次刷新需要更新2D权限，避免缓存
  PermissionOrm.deleteAll();
  removeCompany();
  // SdSocket.getInstance().close(true);
  clipboard.clear();
  // SessionStorageUtil.clear();
  // LocalStorageUtil.clear();
}

export async function logout() {
  clearContext();
  tokenUtils.remove();
}

export async function changeCompany(companyId: string) {
  companyUtils.set(companyId);
  clipboard.clear();
  globalThis.location.reload();
}

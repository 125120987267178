import strLength from '@/utils/validators/str-length';
import isFilled from '@/utils/validators/is-filled';
import validator2ElRule from '@/utils/validators/utils/validator-to-elem-rule';
import i18next from '@/i18n';


export default {
  // 为了在UI上显示必填项（*），添加{ required: true }， 有优化的可能？
  opinions: [validator2ElRule([isFilled, strLength], { max: 300 }), { required: true }],
  email: validator2ElRule((str) => {
    try {
      isFilled(str);
    } catch (e) {
    }
    strLength(str, { max: 30, message: i18next.t('opinion.exceed_email_max') });
  }),
};

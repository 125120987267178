import { keys } from '_/keys';
import { VueConstructor } from 'vue';
import clickoutside from './clickoutside';
import focus from './focus';
import elSelectMiddle from './el-select-middle';
import sticky from './sticky';

const directives = {
  clickoutside,
  focus,
  sticky,
  elSelectMiddle,
};

export default {
  install(_Vue: VueConstructor) {
    keys(directives).forEach(k => {
      _Vue.directive(k, directives[k]);
    });
  },
};

/* eslint-disable no-restricted-syntax */
/* eslint-disable no-cond-assign */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { throttle } from 'throttle-debounce';
import extract from '@4dst-saas/public-utils/dist/extract';
import { Message, MessageBox } from 'element-ui';
import { F } from 'ts-toolbelt';
import api from '@/utils/axios';
import {
  clearContext, getContextUser,
} from '@/loaders/context';
import md5 from '@4dst-saas/public-utils/dist/md5';
import { LogPoster } from '@4dst-saas/public-utils/dist/log-poster';
import {
  parseUrl, stringifyUrl,
} from 'query-string';
import tokenUtils from '@/utils/token';
import companyUtils from '@/utils/company';
import { TOptions } from '@4dst-saas/public-utils/dist/i18n';
import i18n from '../i18n';
import { HistoryLocation, RawHistoryLocation } from '../typings/history.d';
import ErrorLike from '../typings/error-like.d';
import envs from './envs';
import history from './history';
import AppError, { NeedLoginError } from './error';
import { isIdeationPCClient } from './is-pc-client';
import exec from './exec';

const sblOriginalEvent = Symbol('original event');
const HANDLED = Symbol('error has handled');
type NeedHandleError = ErrorLike & {
  [HANDLED]?: boolean
};
type HandledError = NeedLoginError & {
  [HANDLED]: true
};
const { LEVELS } = AppError;
type ComputedError = Omit<ErrorLike, 'type' | 'muted' | 'redirect'> & {
  [sblOriginalEvent]?: Error
  errors?: ComputedError[]
  type: string,
  muted: boolean,
  redirect: false | RawHistoryLocation,
  feCodeError: boolean
} & { [otherProps: string]: unknown };

let logPoster: LogPoster<ErrorLike & { stack: string }> | undefined;
if (envs.VUE_APP_IS_REMOTE && ['staging', 'uat', 'production'].includes(envs.VUE_APP_MODE)) {
  logPoster = new LogPoster({
    async post(logs) {
      return (await api.post('https://log.4dshoetech.com/errorlog', logs)).data;
    },
  });
}

function isHandled(e: NeedHandleError): e is HandledError {
  return !!e[HANDLED];
}

function isNativeFunction(fun: F.Function) {
  return fun.toString().includes('[native code]');
}


function isError(e: unknown): e is ErrorLike {
  return typeof e === 'object' && !!e && ('message' in e || 'code' in e);
}

function isAggregateError(e: unknown): e is ErrorLike & Iterable<ErrorLike> {
  return isError(e) && !!e[Symbol.iterator];
}

function _isCode(e: ErrorLike, code: string | number) {
  return `${e.code}`.endsWith(`${code}`);
}


export function isCode(e: unknown, code: string | number): e is ErrorLike {
  return isError(e) && e.code != null && _isCode(e, code);
}

function isAuthError(e: ErrorLike): boolean;
function isAuthError(e: unknown): e is ErrorLike;
function isAuthError(e: unknown): boolean {
  return isError(e) && !!e.code
    && (_isCode(e, 403)
      || _isCode(e, 401));
}
function isNeedLoginError(e: ErrorLike): boolean;
function isNeedLoginError(e: unknown): e is ErrorLike;
function isNeedLoginError(e: unknown): boolean {
  return isError(e) && !!e.code
    && _isCode(e, 401);
}
function isAbortError(e: ErrorLike): boolean;
function isAbortError(e: unknown): e is ErrorLike;
function isAbortError(e: unknown): boolean {
  return isError(e) && (
    (!!e.name && e.name === 'AbortError')
    || (!!e.message
      && ((/\buser\b/i.test(e.message) && (
        /\babort\b/i.test(e.message) || /\bcancel\b/i.test(e.message) || /\bcancelled\b/i.test(e.message) || /\baborted\b/i.test(e.message)
      ))))
    || (!!e.code && _isCode(e, 499)));
}
function isEmptyError(e: ErrorLike): boolean;
function isEmptyError(e: unknown): e is ErrorLike;
function isEmptyError(e: unknown): boolean {
  return isError(e) && !!e.code && _isCode(e, 404);
}
function isValidateError(e: ErrorLike): boolean;
function isValidateError(e: unknown): e is ErrorLike;
function isValidateError(e: unknown): boolean {
  return isError(e) && !!e.code && _isCode(e, 498);
}
function isNoRecordError(e: ErrorLike): boolean;
function isNoRecordError(e: unknown): e is ErrorLike;
function isNoRecordError(e: unknown): boolean {
  return isError(e) && !!e.code && AppError.CODE_NO_RECORD === e.code;
}

function isNoImageError(e: ErrorLike): boolean;
function isNoImageError(e: unknown): e is ErrorLike;
function isNoImageError(e: unknown): boolean {
  return isError(e) && !!e.code && AppError.CODE_NO_IMAGE === e.code;
}

function isFECodeError(e: ErrorLike): boolean;
function isFECodeError(e: ErrorLike): e is ErrorLike;
function isFECodeError(e: ErrorLike): boolean {
  const _e: Error = e[sblOriginalEvent] ?? e;
  return (!!_e.constructor
    // 必须是Error的的实例但不是Error的直接实例，因为一般的Error一般情况并不是系统抛出来的
    // 或者系统抛出来的错误，例如Edge，是携带description的
    // 因为new Error api 是没有 description 的选项的
    && _e instanceof Error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    && (_e.constructor !== Error || (_e.constructor === Error && !!_e.description))
    // 必须是浏览器自带的构造器，而不是AppError或者其他
    && isNativeFunction(_e.constructor as F.Function));
}
function isCodeError(e: ErrorLike): boolean;
function isCodeError(e: ErrorLike): e is ErrorLike;
function isCodeError(e: ErrorLike): boolean {
  return isError(e) && (_isCode(e, 500) || isFECodeError(e));
}

export {
  isNoRecordError, isNoImageError, isNoSearchResultError,
  isError, isAggregateError, isAuthError, isNeedLoginError, isAbortError, isEmptyError, isValidateError, isFECodeError, isCodeError,
};


const errorLevelNames: {
  [level: number]: string,
} = {
  '-2': '提示',
  '-1': '警告',
  0: '一般',
  1: '严重',
};

const badgeStyle = 'padding-left: .5em; margin-left: .5em; padding-right: .5em; margin-right: .5em; border-radius: 2px';

const errorLevelColor: {
  [level: number]: string,
} = {
  '-2': '#09c',
  '-1': '#f80',
  0: '#ff8080',
  1: '#f44',
};

function getTypedBadgeStyle(level: number) {
  return `color: white; background: ${errorLevelColor[level]}; ${badgeStyle}`;
}


export function getMessage(e: ErrorLike): string {
  const { message } = e;
  const { code, hash } = e;
  const data = typeof e.data === 'object' && e.data && !Array.isArray(e.data) ? { ...e.data } : { data: e.data };
  // 如果是校验器错误，则去找找校验的国际化
  if (isValidateError(e)) {
    return `${i18n.t(`validator.${e.message}`, {
      ...data,
      defaultValue: message,
    })}`;
  }
  // 如果是代码的错误，对于客户来说是屏蔽的，所以后端100500的错误，以及前端的逻辑错误都显示系统异常，
  if (isCodeError(e) && envs.VUE_APP_MODE !== 'development') {
    const isFE = isFECodeError(e);
    return `${i18n.t('public.code_msg._100500')} ${isFE && hash ? `(F-${hash.substr(0, 10)})` : (hash ?? '')}`;
  }
  if (isCode(e, 404) && message) {
    return message;
  }

  function batchT(paths: string[], options: TOptions) {
    for (let i = 0; i < paths.length; i++) {
      const path = paths[i];
      try {
        return i18n.translate(path, options);
        // eslint-disable-next-line @typescript-eslint/no-shadow
      } catch (e) { }
    }
    return options.defaultValue ?? paths[paths.length - 1];
  }
  return batchT([`public.code_msg.${code}`, `public.code_msg._${code}`, `public.code_msg._100${code}`], {
    ...data,
    defaultValue: message,
  });
}

function isNoSearchResultError(e: ErrorLike): boolean;
function isNoSearchResultError(e: unknown): e is ErrorLike;
function isNoSearchResultError(e: unknown): boolean {
  if (!isError(e)) return false;
  const parseMessage = getMessage(e);
  return parseMessage === '暂无搜索结果'
    || parseMessage === 'No Search Results Found'
    || parseMessage === 'No search results found';
}

const level2errType: {
  [level: number]: 'error' | 'info' | 'warning'
} = {
  [LEVELS.ERROR]: 'error',
  [LEVELS.INFO]: 'info',
  [LEVELS.WARN]: 'warning',
};

const noAuthRedirect = (e: ErrorLike) => {
  return {
    path: '/403',
    query: {
      message: e.message,
      code: e.code,
    },
  };
};

const needLoginRedirect = (e: ErrorLike) => {
  clearContext();
  if (globalThis.location.href.includes(envs.VUE_APP_LOGIN_URL)) {
    return false;
  }
  let redirect = globalThis.location.href;
  const redirectPath = parseUrl(globalThis.location.href).url;
  const redirectQuery = parseUrl(globalThis.location.href).query;
  if (tokenUtils.getQueryToken()) {
    delete redirectQuery.token;
  }
  if (companyUtils.getQueryCompany()) {
    delete redirectQuery._companyId;
  }
  redirect = stringifyUrl({ url: redirectPath, query: redirectQuery });
  return {
    path: envs.VUE_APP_LOGIN_URL,
    query: {
      message: e.message,
      code: e.code,
      redirect,
    },
  };
};
const code2RedirectParams: {
  [code: number]: (e: { code: number, message: string, name: string }) => false | HistoryLocation;
} = {
  401: needLoginRedirect,
  403: needLoginRedirect,
  100401: needLoginRedirect,
  100403: needLoginRedirect,
};


const defaultRedirectParams = (e: { code: number | string, message: string }): RawHistoryLocation => {
  return {
    path: `/${Number.isNaN(Number(e.code)) ? '400' : e.code}`,
    query: { message: e.message, code: e.code },
  };
};

const showError = throttle(2000, (e: ComputedError) => {
  const {
    code, type,
  } = e;
  const _message = getMessage(e);
  if (code === 100403 || code === 100404) {
    MessageBox.alert(_message, i18n.t('public.dialog.notice'));
  } else if (type === 'success' || type === 'warning' || type === 'error' || type === 'info') {
    Message[type](_message);
  }
});

export const logError = (e: ErrorLike): void => {
  const {
    level = 0, redirect, name, message, position, instance, hash,
  } = e;

  const originE = e[sblOriginalEvent];
  const { feCodeError } = e;

  const groupTitle = {
    desc: [] as string[],
    params: [] as unknown[],
  };


  if (position) {
    groupTitle.desc.push(`在 %c${position}%c`);
    groupTitle.params.push('color: #2878ff', '');
  }

  if (isAbortError(originE)) {
    groupTitle.desc.push('用户触发了一次取消行为的错误，该错误仅供开发调试用');
  } else if (isAuthError(originE)) {
    groupTitle.desc.push('触发了一次权限的错误');
  } else if (isAggregateError(originE)) {
    groupTitle.desc.push('触发了一个聚合错误');
  } else {
    groupTitle.desc.push(`触发了%c${errorLevelNames[level]}错误%c\n%c${name}: ${message}%c`);
    groupTitle.params.push(getTypedBadgeStyle(level), '', `color: ${errorLevelColor[level]}`, '');
  }

  if (redirect) {
    groupTitle.desc.push('该错误会标记了以下错误转跳页，请排查:\n%o');
    groupTitle.params.push(redirect);
  }

  const now = new Date();
  let componentTree: string | undefined;
  let file: string | undefined;

  let serverLog;
  if (feCodeError) {
    const user = getContextUser();
    serverLog = {
      versionHash: envs.VUE_APP_VERSION_HASH,
      env: envs.VUE_APP_MODE,
      userAgent: '',
      componentTree: '',
      file: '',
      message,
      stack: originE.stack,
      position,
      name,
      level,
      hash,
      project: '',
      ref: '',
      user: user ? {
        id: user.id,
        account: user.account,
      } : null,
      url: globalThis.location.href,
      date: now.toISOString(),
    };
  }
  if ('groupCollapsed' in console) {
    // @ts-ignore
    console.groupCollapsed(groupTitle.desc.join('\n'), ...groupTitle.params);
  } else {
    console.group(groupTitle.desc.join('\n').replace('%c', ''));
  }
  if (componentTree) {
    console.info(
      '组件树:\n%c%s%c',
      'color: #2878ff',
      componentTree,
      '',
    );
  }
  if (file) {
    console.info(
      '文件:\n%s',
      file,
    );
  }
  if (instance) {
    console.info('实例:\n%o', instance);
  }
  if (serverLog) {
    console.info('以下信息会被提交到服务端：\n%o', serverLog);
  }
  if (isAggregateError(originE)) {
    for (const err of originE) {
      console.error(err);
    }
  } else {
    console.error(originE);
  }
  console.groupEnd();

  if (logPoster && serverLog) {
    logPoster.push(serverLog);
  }
};

type WxErr = {
  errMsg: string,
};
function isWxError(target: unknown): target is WxErr {
  return typeof target === 'object' && !!target && 'errMsg' in target;
}
export function parseWxError(err: WxErr): AppError {
  const e = err as WxErr;
  if (e.errMsg.includes(' deny') || e.errMsg.includes(' abort') || e.errMsg.includes(' cancel')) {
    return AppError.abort;
  }
  return new AppError(e.errMsg);
}

export function parseError<T>(e: ErrorLike, instance?: T, mixinOrLevel?: number | boolean | Partial<ErrorLike>): ComputedError {
  let errors: ErrorLike[] | undefined;
  if (isWxError(e)) {
    return parseError(parseWxError(e));
  }
  if (isAggregateError(e)) {
    errors = [];
    for (const err of e) {
      errors.push(parseError(err));
    }
  }
  let levelOrTopLevel: number | boolean | undefined;
  let mixin: Partial<ErrorLike> = {};
  if (typeof mixinOrLevel === 'number' || typeof mixinOrLevel === 'boolean') {
    levelOrTopLevel = mixinOrLevel;
  }
  if (typeof mixinOrLevel === 'object') {
    mixin = mixinOrLevel;
  }
  const feCodeError = isFECodeError(e);


  let level: number | undefined;
  if (typeof levelOrTopLevel === 'boolean') {
    level = levelOrTopLevel ? LEVELS.DANGER : LEVELS.ERROR;
  } else if (typeof levelOrTopLevel === 'number') {
    level = levelOrTopLevel;
  } else {
    level = e.level ?? (isAbortError(e) ? LEVELS.INFO : undefined) ?? 0;
  }

  const hash = e.hash ?? ((feCodeError && e.stack) ? md5(e.stack) : undefined);
  const code = e.code ?? (errors?.[0]?.code) ?? 400;
  const message = e.message || e.errMsg || (errors?.[0]?.message) || (errors?.[0]?.errMsg) || e?.message || 'Unknow Error';
  const name = e.name || (errors?.[0]?.name) || 'Error';

  let redirect: false | RawHistoryLocation;
  const enableRedirect = !!e.redirect || (e.redirect === undefined && level === LEVELS.DANGER);

  if (enableRedirect) {
    redirect = (e.redirect === true ? undefined : e.redirect) ?? (
      code && code2RedirectParams[code] ? code2RedirectParams[code] : defaultRedirectParams)({ code, message, name });
  } else {
    redirect = false;
  }
  const type = e.type
    ?? level2errType[level] ?? level2errType[LEVELS.ERROR];
  const muted = e.muted ?? (level === LEVELS.INFO || e.name === 'NavigationDuplicated');

  return {
    ...extract(e, ['position', 'data']),
    ...mixin,
    level,
    message,
    name,
    redirect,
    type,
    muted,
    code,
    instance,
    feCodeError,
    hash,
    [sblOriginalEvent]: e as Error,
  };
}

export function markAsHandled(errorLike: ErrorLike) {
  errorLike[HANDLED] = true;
}

function errHandler<T>(this: T, e: unknown, level?: number): void;
function errHandler<T>(this: T, e: unknown, topLevel?: boolean): void;
function errHandler<T>(this: T, e: unknown, mixin?: Partial<ErrorLike>): void;
function errHandler<T>(
  this: T,
  e: unknown,
  mixinOrLevel?: number | boolean | Partial<ErrorLike>,
): void {
  console.error(e);
  let errorLike: ErrorLike;

  if (typeof e === 'object' && e) {
    errorLike = e;
  } else if (typeof e === 'string') {
    errorLike = new AppError(e);
  } else {
    errorLike = new AppError('Unknow Error');
  }

  if (isHandled(errorLike)) {
    return;
  }
  markAsHandled(errorLike);

  const computedError: ComputedError = parseError(errorLike, this, mixinOrLevel);

  logError(computedError);
  // TODO 暂时解决不知名的报错问题
  if (computedError.position === 'vue: directive el-table-infinite-scroll unbind hook') { return; }

  const errors: ComputedError[] = computedError.errors ?? [computedError];

  const redirectParams = errors.find(_err => !!_err.redirect)?.redirect;
  const muted = errors.every(_err => _err.muted);
  console.log(redirectParams);
  if (isCode(computedError, 401) && isIdeationPCClient()) {
    exec('webToLocal4DClient', { action: 'isTokenValid', isTokenValid: false });
  } else if (redirectParams) {
    history.replace(redirectParams);
  } else if (!muted) {
    showError(errors[0]);
  }
}
export default errHandler;
export { errHandler, errHandler as handler };

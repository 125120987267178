<template>
  <div
    class="detetable-box"
    :class="{ 'preview-bg': canPreview }"
    :style="{ width: `${width}px`, height: `${height}px` }"
  >
    <slot />
    <i v-if="!disabled" class="iconfont iconguanbi" @click.stop="$emit('delete')" />
    <i v-if="canDownload" class="i-xiazai" @click.stop="$emit('download')"></i>
    <i v-if="canPreview" class="iconfont iconfangdajing" @click="$emit('preview')" />
  </div>
</template>

<script>
export default {
  name: 'DeletableBox',
  props: {
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 100,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canDownload: {
      type: Boolean,
      default: false,
    },
    canPreview: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.detetable-box {
  position: relative;
  transition: all 200ms;
  &.preview-bg::after {
    content: "";
    position: absolute;
    right: -16px;
    bottom: -15px;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-top: 15px solid rgba(237, 237, 237, 0.6);
    transform: rotate(-45deg);
    opacity: 0;
    transition: opacity 200ms;
  }
  &:hover .iconfont {
    opacity: 1;
  }
  &:hover::after {
    opacity: 1;
  }
  &:hover {
    .i-xiazai {
      transition: all 300ms ease-in-out;
      visibility: visible;
    }
  }

  .iconfont {
    position: absolute;
    z-index: 1;
    padding: 4px;
    opacity: 0;
    cursor: pointer;
    transition: all 200ms;

    &.iconguanbi {
      top: 0;
      right: 0;
      color: #333;
      &::before {
        border-radius: 50%;
        background-color: rgba(237, 237, 237, 0.6);
      }
    }
    &.iconfangdajing {
      right: -6px;
      bottom: -3px;
      font-size: 12px;
      color: white;
    }
  }
  .i-xiazai {
    font-size: 12px;
    bottom: 0;
    left: 0;
    visibility: hidden;
    position: absolute;
    cursor: pointer;
    color: #666;
    // color: #fff;
    padding: 4px;
  }
}
</style>

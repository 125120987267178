

import {
  defineComponent, getCurrentInstance, ref, computed,
} from 'vue';
import { useI18n } from '@/utils/vue/i18n';
import projectTabList from '@/views/project/project-setting/components/utils';

export default defineComponent({
  name: 'Sidebar',
  setup() {
    const instance = getCurrentInstance()?.proxy;
    const { t } = useI18n();

    const can = computed(() => {
      return {
        OP0165: instance?.$can('', 'OP0165'),
        PDM2521: instance?.$can('', 'PDM2521'),
        OP0007: instance?.$can('', 'OP0007'),
      };
    });
    const routerMsg = [
      {
        to: '/setting/user',
        label: t('user_management'),
        can: can.value.OP0165,
      },
      {
        to: '/setting/project',
        label: t('setting'),
        can: projectTabList.some(item => item.can()),
      },
      {
        to: '/setting/task',
        label: t('work_flow'),
        can: can.value.PDM2521,
      },
      {
        to: '/setting/enterprise',
        label: t('company_msg'),
        can: can.value.OP0007,
      },
    ];

    const curRouterPath = computed(() => {
      if (instance?.$route.path.startsWith('/setting/library') || instance?.$route.path.startsWith('/setting/woop')) {
        return '/setting/project';
      }
      return instance?.$route.path;
    });
    const handleChangeRouter = (routerName: string) => {
      curRouterPath.value = routerName;
    };
    return {
      routerMsg,
      curRouterPath,
      handleChangeRouter,
    };
  },
});

import { VueConstructor } from 'vue';
import AppError from '@/utils/error';
import errHandler from '@/utils/error-handler';
import ErrorLike from '@/typings/error-like';


export default {
  install(_Vue: VueConstructor) {
    _Vue.prototype.$Error = AppError;
    _Vue.prototype.$errHandler = errHandler;
    _Vue.prototype.$errorHandler = errHandler;
    _Vue.config.errorHandler = function errorHandler(err: ErrorLike, vm, info) {
      errHandler.call(vm, err, {
        position: `vue: ${info}`,
        ...(info.startsWith('created') || info.startsWith('mounted') || info.startsWith('v-on') ? {
          level: err.level ?? AppError.LEVELS.ERROR,
          muted: err.muted ?? false,
        } : {
          level: err.level ?? AppError.LEVELS.WARN,
          muted: err.muted ?? true,
        }),
      });
    };
  },
};

declare module 'vue/types/vue' {
  interface Vue {
    $errHandler: typeof errHandler
    $errorHandler: typeof errHandler
  }
}

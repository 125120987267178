/**
 * 具体配置说明见：customizeNames
 */
export default [
  {
    name: 'appid',
    data: {
      type: 'string',
      value: '300',
    },
  },
  {
    name: 'platformid',
    data: {
      type: 'number',
      value: 3,
    },
  },
  {
    name: 'init_platform',
    data: {
      type: 'boolean',
      value: false,
    },
  },
  {
    name: 'logo_user_profile',
    data: {
      type: 'logoType',
      value: {
        url: require('@/assets/img/header/verseset-light.png'),
        darkUrl: require('@/assets/img/header/verseset-dark.png'),
      },
    },
  },
  {
    name: 'logo_login',
    data: {
      type: 'logoType',
      value: {
        url: require('@/assets/img/header/verseset-light.png'),
        darkUrl: require('@/assets/img/header/verseset-dark.png'),
      },
    },
  },
  {
    name: 'hidden_authority',
    data: {
      type: 'boolean',
      value: true,
    },
  },
  {
    name: 'hidden_switch_company',
    data: {
      type: 'boolean',
      value: true,
    },
  },
  {
    name: 'hidden_switch_language',
    data: {
      type: 'boolean',
      value: true,
    },
  },
  {
    name: 'hidden_switch_theme',
    data: {
      type: 'boolean',
      value: true,
    },
  },
  {
    name: 'hidden_back',
    data: {
      type: 'boolean',
      value: true,
    },
  },
  {
    name: 'disabled_role_type_select',
    data: {
      type: 'boolean',
      value: true,
    },
  },
  {
    name: 'disabled_user_type_select',
    data: {
      type: 'boolean',
      value: true,
    },
  },
  {
    name: 'hidden_login_form',
    data: {
      type: 'boolean',
      value: true,
    },
  },
  {
    name: 'hidden_register',
    data: {
      type: 'boolean',
      value: true,
    },
  },
  {
    name: 'hidden_feedback',
    data: {
      type: 'boolean',
      value: true,
    },
  },
];

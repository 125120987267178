var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"viewerWrapper",staticClass:"pswp",attrs:{"tabindex":"-1","role":"dialog","aria-hidden":"true"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"arrow-left",undefined,$event.key,undefined))return null;$event.stopPropagation();return _vm.prev.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"arrow-right",undefined,$event.key,undefined))return null;$event.stopPropagation();return _vm.next.apply(null, arguments)}]}},[_c('div',{staticClass:"pswp__bg",style:({ backgroundColor: _vm.backgroundColor })}),_c('div',{staticClass:"pswp__scroll-wrap"},[_vm._m(0),_c('div',{staticClass:"pswp__ui pswp__ui--hidden"},[_c('div',{staticClass:"pswp__top-bar"},[_c('div',{staticClass:"pswp__counter"}),_c('button',{staticClass:"pswp__button pswp__button--close",attrs:{"title":_vm.$t('public.img_viewer.close')}}),_c('button',{staticClass:"pswp__button pswp__button--zoom",staticStyle:{"display":"none !important"},attrs:{"title":_vm.$t('public.img_viewer.zoom')}}),_vm._m(1)]),_vm._m(2),_c('button',{staticClass:"pswp__button pswp__button--arrow--left",attrs:{"title":_vm.$t('public.img_viewer.prev')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.prev.apply(null, arguments)}}}),_c('button',{staticClass:"pswp__button pswp__button--arrow--right",attrs:{"title":_vm.$t('public.img_viewer.next')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.next.apply(null, arguments)}}}),(_vm.showRuler)?_c('div',{staticClass:"ruler-container flex-center flex-column",style:({
          left: `calc(50vw - ${_vm.rulerInfo.height / 2}px)`,
        })},[_c('span',{style:({
            textShadow: '0 0 5px #000',
          })},[_vm._v(_vm._s(_vm.rulerCentimeters)+"mm")]),_c('div',{style:({
            boxShadow: '0 0 5px #000',
            marginTop: '10px',
            height: '3px',
            width: `${_vm.rulerInfo.height}px`,
            border: '1px solid #fff',
            borderTop: 0,
          })})]):_vm._e(),_vm._m(3)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pswp__container"},[_c('div',{staticClass:"pswp__item"}),_c('div',{staticClass:"pswp__item"}),_c('div',{staticClass:"pswp__item"}),_c('div',{staticClass:"pswp__item"}),_c('div',{staticClass:"pswp__item"}),_c('div',{staticClass:"pswp__item"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pswp__preloader"},[_c('div',{staticClass:"pswp__preloader__icn"},[_c('div',{staticClass:"pswp__preloader__cut"},[_c('div',{staticClass:"pswp__preloader__donut"})])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pswp__share-modal pswp__share-modal--hidden pswp__single-tap"},[_c('div',{staticClass:"pswp__share-tooltip"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pswp__caption"},[_c('div',{staticClass:"pswp__caption__center"})])
}]

export { render, staticRenderFns }
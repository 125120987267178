<template>
  <div class="flex-center">
    <div
      :style="{
        background: `url(${require('@/assets/img/login/revofim-icon.png')}) no-repeat center / contain`,
        height: '26px',
        width: '26px',
        'margin-right': '6px'
      }">
    </div>
    <router-link
      v-if="!$customize($customizeNames.hiddenLoginForm)"
      class="logo"
      :class="logo"
      :style="{ backgroundImage: `url(${$customize($customizeNames.loginLogo, 'url') || defaultLogo})` }"
      to="/login" />
    <div
      v-if="$customize($customizeNames.hiddenLoginForm)"
      class="logo"
      :class="logo"
      :style="{ backgroundImage: `url(${$customize($customizeNames.loginLogo, 'url') || defaultLogo})` }"></div>
  </div>
</template>
<script>
import history from '@/utils/history';
import envs, { getHostParamsFromPath } from '@/utils/envs';

export default {
  data() {
    return {
      defaultLogo: require('@/assets/img/login/revofim.png'),
    };
  },
  props: {
    redirect: String,
  },
  computed: {
    logo() {
      if (envs.VUE_APP_COOPERATION === 'anta') {
        return 'anta';
      }
      if (this.redirect) {
        const redirectLocation = history.parse(this.redirect);
        const [, , , , , module] = getHostParamsFromPath(redirectLocation.fullPathWithDomain); // srm
        if (module === 'srm') {
          return 'srm';
        } if (module === 'entlib') {
          // TODO: 判断路径中有没有entlib。待优化
          return 'dragonfly-logo';
        }
      }
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
.logo {
  display: block;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;

  height: 18px;
  width: 80px;
}
</style>

import BrandSuppliers from '@/models/BrandSupplier';
import axios from '@/utils/axios';

export async function updateBrandSupplier() {
  const res = await axios({
    url: '/authcenter/supplier/brands',
    method: 'get',
  });
  BrandSuppliers.insertOrUpdate({
    data: res.data,
  });
}

export function getAntaCompany() {
  return BrandSuppliers.query().where('flag', 'anta').first();
}

export default {};

const policy = {
  name: 'Policy',
  path: '/policy',
  redirect: '/policy/license',
  component: () => import(/* webpackChunkName: "policy" */ '@/views/policy/index.vue'),
  children: [
    {
      name: 'PolicyLicense',
      path: '/policy/license',
      meta: {
        title: 'license agreement',
      },
      component: () => import(/* webpackChunkName: "policy" */ '@/views/policy/LicenseAgreement.vue'),
    },
    {
      name: 'PolicyPrivacy',
      path: '/policy/privacy',
      meta: {
        title: 'privacy policy',
      },
      component: () => import(/* webpackChunkName: "policy" */ '@/views/policy/PrivacyPolicy.vue'),
    },
    {
      name: 'PolicyTerms',
      path: '/policy/terms',
      meta: {
        title: 'terms & conditions',
      },
      component: () => import(/* webpackChunkName: "policy" */ '@/views/policy/TermsConditions.vue'),
    },
  ],
};

export default policy;

import axios from '@/utils/axios';
import { Permission, PermissionOrm } from '@/models/Permission';
import companyUtils from '../utils/company';

export async function updateSystemPermissions(systemTypes = [100, 4, 8, 9], companyId: string = companyUtils.get()!) {
  if (!companyId) throw new Error();
  const permissions: Permission[] = (await axios.post('/authcenter/operation/auth/systems', systemTypes)).data;
  const cache = {} as { [code: string]: Permission };
  permissions.forEach(item => {
    if (cache[item.code]) {
      console.warn('权限码存在冲突', [
        item,
        cache[item.code],
      ]);
      return;
    }
    cache[item.code] = {
      ...item, groupAppId: 'system', code: item.code, value: item.value, companyId,
    } as Permission;
  });
  const _permissions = Object.values(cache);
  // setTimeout(() => {
  //   const rt = PermissionOrm.query().where('groupAppId', 'system').where('code', 'OP9001').first();
  //   console.log('rt============', rt);
  // }, 1000);
  await PermissionOrm.delete(({ groupAppId }) => groupAppId === 'system');
  return (await PermissionOrm.insertOrUpdate({
    data: _permissions,
  }))[PermissionOrm.entity] as Permission[];
}

// export async function updateCompanyPermissions() {}

export default {};

import router from '@/router';
import { AbortError } from '@/utils/error';
import onCreatedByVue from '@/utils/composables/on-created';
import deepmerge from 'deepmerge';
import { F } from 'ts-toolbelt';
/* eslint-disable camelcase */
import Vue, {
  ComputedRef,
  onMounted as onMountedByVue,
  onUnmounted,
  toRefs,
  watch,
} from 'vue';
import { getContextCompany } from '@/loaders/context';
import envs from '@/utils/envs';
import {
  fetchUniqSessionId,
  logPoster,
  syncRequest,
} from './poster';
import logStore, {
  cacheStore,
  FrontLogEventKey,
  openTermalCollectCached,
  openTermalCollectData,
} from './store';
import {
  DynamicTrackerParams,
  getElementAttrs,
  getTrackerAttrs,
  setUniqSessionId,
} from './tracker';

export type LogOptions = { page_code: string };

type LogParams = DynamicTrackerParams;
type LogRouteItem = { route?: string, log: LogOptions };// route命名保持跟小程序一致

export const falsy = (_value: any) => !_value && _value !== 0;
export const logList: LogRouteItem[] = []; // 保存三个LogRouteItem,为了获取referrer
export const logRouteItem2logParams = (logRouteItem?: LogRouteItem) => {
  const { route = '', log } = logRouteItem || {};
  const { page_code = route } = log || {};
  return {
    url: route,
    page_code,
  };
};


const sdEventTrack = (frontLogEventKey: FrontLogEventKey, e: Event) => {
  try {
    const posterParams = { dynamic_property: getElementAttrs(e) };
    logStore.set(frontLogEventKey, { posterParams });
  } catch (error) {
    // 如果报错,删除logStore的数据
    logStore.remove(frontLogEventKey);
    // 在这里catch掉错误,埋点上报不影响业务
    console.log('sdEventTrack失败:', error);
  }
};

/**
 * 针对退出登录清空缓存数据的页面离开和page_view
 * @param posterParams
 */
const logoutPageLeaveDetect = (posterParams) => {
  const cached = cacheStore.getCurrent();
  if (!posterParams.dynamic_property.token && cached && posterParams.dynamic_property.event_name === 'page_leave') {
    cached.posterParams.dynamic_property.local_time = new Date().getTime();
    cached.posterParams.dynamic_property.event_name = 'page_leave';
    cacheStore.clear();
    return cached.posterParams;
  }
  cacheStore.setCurrent({ posterParams });
  return posterParams;
};


// eslint-disable-next-line consistent-return
const sdLog = async (frontLogEventKey: FrontLogEventKey, params?: LogParams) => {
  try {
    const currentPosterParams = await getTrackerAttrs(frontLogEventKey, params);
    const existPosterParams = logStore.get(frontLogEventKey)?.posterParams || {};
    let posterParams = deepmerge(existPosterParams, currentPosterParams);
    posterParams = logoutPageLeaveDetect(posterParams);
    openTermalCollectData(frontLogEventKey, posterParams);
    if (!(envs.VUE_APP_AREA === 'en' && envs.VUE_APP_MODE === 'staging')) {
      await logPoster(posterParams);
    }
    logStore.remove(frontLogEventKey);
  } catch (error) {
    console.error('埋点上报失败:', error);
  }
};

const onCreated = async (fn: F.Function) => {
  onCreatedByVue(async () => {
    await fn();
  });
  onUnmounted(() => {
    cacheStore.clear();
  });
};
const onMounted = async (fn: F.Function) => {
  onMountedByVue(async () => {
    await fn();
  });
  onUnmounted(() => {
    cacheStore.clear();
  });
};

export const calcSection = (add = true) => {
  const openSection = localStorage.getItem('openSection');
  let sec = Number(openSection || 0);
  add ? sec++ : sec--;
  localStorage.setItem('sec', String(sec < 0 ? 0 : sec));
};
let watchStopHandle = () => { };
// 跟踪应用周期内时长
export const trackAppDuration = (() => {
  let hasTrack = false;
  return () => {
    watchStopHandle && watchStopHandle();
    watchStopHandle = watch(() => {
      return getContextCompany();
    }, (current, pre) => {
      if (hasTrack) return;
      if ((current && current.id !== pre?.id) || (!current && !pre && window.location.pathname === '/login')) {
        sdLog('open_terminal');
        hasTrack = true;
      }
    }, {
      immediate: true,
    });
    window.addEventListener('unload', () => {
      // sdLog('close_terminal'); // 关闭终端被cancel了；
      const postData = JSON.parse(JSON.stringify(openTermalCollectCached.value));
      if (postData.dynamic_property) {
        postData.dynamic_property.local_time = new Date().getTime();
        postData.dynamic_property.event_name = 'close_terminal';
        if ('sendBeacon' in navigator) {
          const blob = new Blob([JSON.stringify({ content: postData })], {
            type: 'application/json; charset=UTF-8',
          });
          const result = navigator.sendBeacon(`${envs.VUE_APP_LOG_API_DOMAIN}/backend/data-transit/data/collect`, blob);
          if (result) {
            if (globalThis.location.protocol === 'http:') {
              console.log('%c[close_termal-埋点上报]:动态属性', 'color: green;');
              console.table(postData.dynamic_property);
              console.log('%c[close_termal-埋点上报]:静态属性', 'color: green;');
              console.table(postData.static_property);
            }
          }
        } else {
          syncRequest('/data-transit/data/collect', postData);
          if (globalThis.location.protocol === 'http:') {
            console.log('%c[close_termal-埋点上报]:动态属性', 'color: green;');
            console.table(postData.dynamic_property);
            console.log('%c[close_termal-埋点上报]:静态属性', 'color: green;');
            console.table(postData.static_property);
          }
        }
      }
    });
  };
})();


// 跟踪路由时长
const trackRouteDuration = (() => {
  let fromTime: number = new Date().getTime();

  const route2LogRouteItem = (route: any) => {
    const componentOptions = (route.matched[route.matched.length - 1].components.default || {}) as any & { log: LogOptions };
    const { log } = componentOptions;
    return {
      route: route.fullPath,
      log,
    };
  };

  const handleEmptyLogList = (route?: any) => {
    // 按理说第一次进项目的时候就需要保存当前路由
    if (logList.length === 0) {
      logList.push(route2LogRouteItem(route || router.currentRoute.value));
    }
  };

  /**
   * page_leave上报
   */
  const logPageLeave = (logRouteItem: LogRouteItem, { onlyLog = false } = {}) => {
    const handleLogListAndGetParams = () => {
      // onlyLog不增加到logList
      if (!onlyLog) logList.push(logRouteItem);
      const leaveLogRouteItem = onlyLog ? logList[logList.length - 1] : logList[logList.length - 2];
      const referrerLogRouteItem = onlyLog ? logList[logList.length - 2] : logList[logList.length - 3];
      const { url, page_code } = logRouteItem2logParams(leaveLogRouteItem);
      const { url: referrer, page_code: referrerPageCode } = logRouteItem2logParams(referrerLogRouteItem);
      if (logList.length > 3) logList.shift();
      // 相同page_code不重复上报,用于多个路由上报同个page_code的情况,比如买家颜色库的color颜色库和我的搭配色板
      if (page_code === referrerPageCode && !onlyLog) throw new AbortError('埋点上报：重复的page_code');
      return {
        url,
        page_code,
        referrer,
        referrerPageCode,
      };
    };
    const {
      url,
      page_code,
      referrer,
    } = handleLogListAndGetParams();
    if (falsy(page_code) || falsy(url)) return;
    const duration = fromTime ? new Date().getTime() - fromTime : 0;
    sdLog('page_leave', {
      event_duration: duration, url, page_code, referrer,
    });
    fromTime = new Date().getTime();
  };

  /**
   * page_view上报，业务未定哪些页面要记录pageView
   */
  const logPageReview = async () => {
    const currentFrontLogEventItem = cacheStore.getCurrent();
    if (!currentFrontLogEventItem) return;
    const { posterParams } = currentFrontLogEventItem;
    const { event_name } = posterParams.dynamic_property;
    if (event_name !== 'page_view') { // 暂时没有pageView必要的字段
      posterParams.dynamic_property.event_name = 'page_view';
    }
    await logPoster(posterParams);
  };

  return {
    watchRouter() {
      // 监听路由
      router.afterEach((to, from) => {
        if (to.fullPath === from.fullPath || from.fullPath === '/') return;
        handleEmptyLogList(from);
        const logRouteItem = route2LogRouteItem(to);
        logPageLeave(logRouteItem);
      });

      // 监听window聚焦状态
      // window.onfocus = async () => {
      //   // 重置fromTime，校正时间统计
      //   fromTime = new Date().getTime();
      //   await logPageReview();
      // };
      // window.onblur = () => {
      //   handleEmptyLogList();
      //   const currentLogRouteItem = route2LogRouteItem(router.currentRoute.value);
      //   logPageLeave(currentLogRouteItem, { onlyLog: true });
      // };
    },
    toggle(toggleLogRouteItem: LogRouteItem) {
      handleEmptyLogList();
      // 手动切换
      const logRouteItem = { route: router.currentRoute.value.fullPath, ...toggleLogRouteItem };// 合并logROuteItem,保持logRouteItem.route
      logPageLeave(logRouteItem);
    },
  };
})();


export const init = async () => {
  try {
    await setUniqSessionId();
    logStore.init();
    if (!(envs.VUE_APP_AREA === 'en' && envs.VUE_APP_MODE === 'staging')) {
      trackAppDuration();
    }
    trackRouteDuration.watchRouter();
  } catch (error) {
    console.error('初始化埋点失败:', error);
  }
};

const useLog = () => {
  // 跟踪组件周期内时长
  const trackComponentDuration = () => {
    let startTime: Date;
    onMountedByVue(() => {
      startTime = new Date();
    });
    onUnmounted(() => {
      const duration = new Date().getTime() - startTime.getTime();
      sdLog('page_leave', { event_duration: duration });
    });
  };


  return {
    ...toRefs(state),
    init,
    sdEventTrack,
    sdLog,
    fetchUniqSessionId,
    trackComponentDuration,
    trackAppDuration,
    trackRouteDuration,
    onCreated,
    onMounted,
  };
};

export default useLog;

type ComputedOrRawValue<T> = ComputedRef<T> | T;

declare module 'vue/types/options' {
  interface ComponentOptions<// eslint-disable-next-line @typescript-eslint/no-unused-vars
    V extends Vue,
    > {
    log?: ComputedOrRawValue<LogOptions>;
  }
}



/* eslint-disable import/no-named-as-default-member */
import Vue, {
  VNode,
  computed,
  reactive,
  Ref,
  ref,
} from 'vue';
import getCurrentInstance from '@/utils/composables/get-current-public-instance';
import I18n, { TOptions, ResourceNotFoundError, Options } from '@4dst-saas/public-utils/dist/i18n';

import { FunctionalComponentOptions } from 'vue/types/umd';
import tryParseJson from '@4dst-saas/public-utils/dist/try-parse-json';
import own from '@4dst-saas/public-utils/dist/own';
import { getLang } from '@/i18n';
import many from '@4dst-saas/public-utils/dist/many';

/* eslint-disable import/no-named-as-default-member */

type VueApp = typeof Vue;

const I18nComponent: FunctionalComponentOptions<{ path: string, tag?: string, options?: TOptions }> = {
  functional: true,
  render(h, {
    parent, props,
    scopedSlots,
  }) {
    const {
      path, tag = 'span', options = {},
    } = props;
    const placeSlots: { [x: string]: VNode[] } = {};
    const {
      default: defaultScopedSlots,
      ...otherScopedSlots
    } = scopedSlots;
    if (defaultScopedSlots) {
      const nodes = many(defaultScopedSlots(props));
      nodes.forEach(node => {
        const place: string | undefined = node.data?.attrs?.place;
        console.log(node.data?.attrs);
        if (place) {
          placeSlots[place] = placeSlots[place] ?? [node];
        }
      });
    }
    Object.entries(otherScopedSlots).forEach(([place, scopedSlot]) => {
      placeSlots[place] = placeSlots[place] ?? many(scopedSlot(props));
    });
    const now = Date.now();
    // 这三个变量足够复杂是用于避免错误发生的
    const splitToken = `*$^@&${now}`;
    const replacePlaceholderPrefix = '_&@^$*';
    const replacePlaceholderSuffix = '*$^@&_';
    const getReplacePlaceholder = (key: string) => {
      return `${replacePlaceholderPrefix}${key}${replacePlaceholderSuffix}`;
    };
    const getPlaceholder = (key: string) => {
      return `${splitToken}${getReplacePlaceholder(key)}${splitToken}`;
    };
    const placeSlotsEntries = Object.entries(placeSlots);
    const placeKeyPlaceholders = Object.fromEntries(Object.keys(placeSlots).map(k => [k, getPlaceholder(k)]));
    const translatedString = parent.$t(path, { ...options, ...placeKeyPlaceholders });
    const nodes: (string | VNode)[] = [];
    translatedString.split(splitToken).forEach((replacePlaceholder) => {
      if (replacePlaceholder.startsWith(replacePlaceholderPrefix) && replacePlaceholder.endsWith(replacePlaceholderSuffix)) {
        const replaceEntry = placeSlotsEntries.find(([key]) => getReplacePlaceholder(key) === replacePlaceholder);
        if (replaceEntry) {
          const [, replaceNodes] = replaceEntry;
          nodes.push(...replaceNodes);
        } else {
          nodes.push('');
        }
      } else {
        nodes.push(replacePlaceholder);
      }
    });
    return h(tag, nodes);
  },
};


function merge(target: Record<string, unknown>, ...args: Record<string, unknown>[]): Record<string, unknown> {
  const output = Object(target);
  for (let i = 0; i < args.length; i++) {
    const source = args[i];
    if (source !== undefined && source !== null) {
      let key;
      // eslint-disable-next-line no-restricted-syntax
      for (key in source) {
        if (own(source, key)) {
          if (source[key] && typeof source[key] === 'object') {
            output[key] = merge(output[key], source[key]);
          } else {
            output[key] = source[key];
          }
        }
      }
    }
  }
  return output;
}

class I18nPlugin extends I18n {
  refLanguage: Ref<string>;
  static instances: I18nPlugin[] = [];
  static async changeLanguage(lang: string) {
    await this.instances.map(instance => instance.changeLanguage(lang));
  }
  constructor(...args: [] | [Options]) {
    super(...args);
    this.refLanguage = ref(args[0]?.lang ?? 'cn')
    I18nPlugin.instances.push(this);
  }
  get language(): string {
    this.refLanguage ??= ref<string>('cn');
    return this.refLanguage.value;
  }
  set language(lang: string) {
    this.refLanguage ??= ref<string>(lang);
    this.refLanguage.value = lang;
  }

  // 增加第三参是为了兼容旧版的t写法(src\i18n_bak\index.ts-t)
  t(key: string, option: TOptions): string;
  t(key: string, defaultStr?: string): string;
  t(key: string, data?: Record<string | number, string>, defaultStr?: string): string;
  t(key: string, defaultStrOrOptions: TOptions | string = {}, defaultStr?: string): string {
    if (defaultStr) {
      if (typeof defaultStrOrOptions === 'string') {
        defaultStrOrOptions = defaultStr;
        return super.t(key, defaultStrOrOptions);
      }
      defaultStrOrOptions = { ...defaultStrOrOptions, defaultValue: defaultStr };
    }
    return super.t(key, defaultStrOrOptions as TOptions);
  }

  install(_Vue: VueApp): void {
    Vue.component('i18n', I18nComponent as unknown as any);
    Object.defineProperty(Vue.prototype, '$i18n', {
      get(this: Vue) {
        return this.$options.i18n;
      },
    });
    // const rootI18n = this;
    _Vue.mixin({
      i18n: this,
      beforeCreate() {
        if (this.$options.__i18ns) return;
        if (this.$parent) {
          this.$options.__i18ns = [...this.$parent.$options.__i18ns ?? []];
        } else {
          this.$options.__i18ns = [];
        }
        if (this.$options.i18n && this.$options.i18n !== this.$options.__i18ns[this.$options.__i18ns.length - 1]) {
          this.$options.__i18ns = [this.$options.i18n, ...this.$options.__i18ns];
        }
        if (this.$options.__i18n) {
          const resources: { [lang: string]: Record<string, unknown> } = {};
          this.$options.__i18n.forEach((item) => {
            merge(resources, tryParseJson(item) as Record<string, unknown>);
          });
          this.$options.__i18ns = [new I18nPlugin({
            lang: this.$options.__i18ns[this.$options.__i18ns.length - 1].language,
            resources: resources as unknown as any,
            loadResource: () => Promise.resolve({}),
          }), ...this.$options.__i18ns];
        }
      },
    });
    _Vue.prototype.$t = function $t(path: string, defaultStrOrOptions: TOptions | string = {}) {
      const options: TOptions = typeof defaultStrOrOptions === 'string' ? {
        defaultValue: defaultStrOrOptions,
      } : defaultStrOrOptions;
      const { defaultValue } = options;
      const i18ns = this.$options.__i18ns!;
      for (let i = 0; i < i18ns.length; i++) {
        const i18n = i18ns[i];
        try {
          return i18n.translate(path, options);
        } catch (e) {
          if (!(e instanceof ResourceNotFoundError)) {
            throw e;
          }
        }
      }
      const pathArr = path.split('.');
      return defaultValue ?? pathArr[pathArr.length - 1];
    };
  }
}

export const useI18n = () => {
  const instance = getCurrentInstance();
  if (!instance) {
    throw new Error('Please use hook in setup');
  }
  return reactive({
    language: computed(() => {
      const i18ns = instance.$options.__i18ns!;
      return i18ns[i18ns.length - 1].language;
    }),
    changeLanguage: async (lang: string) => {
      const i18ns = instance.$options.__i18ns!;
      await Promise.all(i18ns.map((i18n) => i18n.changeLanguage(lang)));
    },
    t(key: string, defaultStrOrOptions: TOptions | string[] | string = {}): string {
      return instance.$t(key, defaultStrOrOptions);
    },
  });
};

export const useI18nResources = (resources: NonNullable<Options['resources']>) => {
  const instance = getCurrentInstance();
  if (instance) {
    const i18ns = instance.$options.__i18ns ?? [];
    instance.$options.__i18ns = i18ns;
    i18ns.push(new I18nPlugin({
      resources,
      lang: getLang(),
      loadResource: async () => ({}),
    }));
  }
};

export const i18nRef = (path: string, options: TOptions) => {
  return computed(() => {
    return getCurrentInstance()?.$t(path, options);
  });
};


export default I18nPlugin;
declare module 'vue/types/options' {
  interface ComponentOptions<
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    V extends Vue,
    > {
    i18n?: I18nPlugin;
    __i18ns?: I18nPlugin[];
    __i18n?: string[];
    __i18nRes?: { [lang: string]: Record<string, unknown> }
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $i18n: I18nPlugin
    $t(key: string, { lang }: TOptions): string
    $t(key: string, defaultStr?: string): string
    $t(key: string, defaultStrOrOptions: TOptions | string[] | string): string
  }
}

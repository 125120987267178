import { camelCase } from '@4dst-saas/public-utils/dist/case';
import {
  Attr,
  Model,
  Model as OrmModel,
} from '@vuex-orm/core';
import { C, O } from 'ts-toolbelt';

type ExcludeKey<K extends PropertyKey> = Exclude<K, keyof OrmModel>;
export type Raw<M> = M extends OrmModel ?
  {
    [k in ExcludeKey<O.OptionalKeys<M>>]?: Raw<M[k]>
  } & {
    [x in ExcludeKey<O.RequiredKeys<M>>]: Raw<M[x]>
  } : M extends any[] ? Raw<M[0]>[] : M;


type OmitTypeFields<T, K> = { [P in keyof T]: T[P] extends K ? never : P }[keyof T];

type ModelCtorOf<C extends C.Class, T = InstanceType<C>> = C.Class<C.Parameters<C>, {
  [i in keyof typeof Model]: typeof Model[i]
} & {
    [i in keyof T]: T[i] extends null ? null : T[i] extends Object ? ModelOf<T[i]> : T[i]
  }> & typeof Model & C;

export type ModelOf<T extends Object> = InstanceType<ModelCtorOf<C.Class<any[], T>>>;
type StaticsFields<T extends C.Class> = (this: ModelCtorOf<T>) =>
  { [key in Exclude<OmitTypeFields<T, Function>, 'constructor' | 'prototype'>]?: Attr };
type Statics<T extends C.Class> = Partial<Pick<typeof Model, 'primaryKey' | 'baseEntity'>> & {
  entity: string,
  fields: StaticsFields<T>
};

function getDefaultFields<T extends C.Class>(Klass: T) {
  return function fields(this: ModelOf<T>) {
    return Object.fromEntries(Object.entries(Klass.prototype)
      .filter(([key, item]) => {
        return typeof item !== 'function' && !Object.getOwnPropertyDescriptor(Klass.prototype, key)?.get;
      })
      .map(([key]) => {
        return [key, this.attr(undefined)];
      }));
  } as StaticsFields<T>;
}
function getRootClass(Klass: C.Class) {
  // eslint-disable-next-line no-constant-condition
  while (1) {
    // eslint-disable-next-line no-proto
    const superPrototype = Klass.prototype.__proto__;
    if (superPrototype && superPrototype !== Object.prototype) {
      Klass = superPrototype.constructor;
    } else {
      return Klass;
    }
  }
  return Klass;
}


export const modelOf = <T extends C.Class>(Klass: T,
  statics: Partial<Statics<T>> = {}) => {
  const entity = statics.entity ?? camelCase(Klass.name);
  const rootClass = getRootClass(Klass);
  const baseEntity = statics.baseEntity ?? (rootClass !== Klass ? camelCase(rootClass.name, false) : undefined);
  const computedStatics = {
    ...statics,
    primaryKey: statics.primaryKey ?? 'id',
    fields: statics.fields ?? getDefaultFields(Klass),
    entity: statics.entity ?? camelCase(Klass.name),
  };
  if (baseEntity !== entity) {
    computedStatics.baseEntity = baseEntity;
  }
  // eslint-disable-next-line no-proto
  let superPrototype = Klass.prototype.__proto__;
  if (superPrototype && superPrototype !== Object.prototype) {
    superPrototype = modelOf(superPrototype.constructor).prototype;
  } else {
    superPrototype = Model.prototype;
  }

  function _MixClass(this: any, ...args: any[]) {
    Model.call(this, ...args);
    const s = new (Klass.bind.call(Klass, [undefined].concat(args)))();
    Object.assign(this, JSON.parse(JSON.stringify(s)));
  }
  Object.setPrototypeOf(_MixClass, superPrototype.constructor);
  const prototype = Object.create(superPrototype, Object.getOwnPropertyDescriptors(Klass.prototype));
  prototype.constructor = _MixClass;
  _MixClass.prototype = prototype;
  Object.assign(_MixClass, computedStatics);
  return _MixClass as unknown as ModelCtorOf<T>;
};

export default modelOf;



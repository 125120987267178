import { Obj } from '@/typings/common';
import CompanyRole, { CompanyRoleOrm } from './CompanyRole';
import Package, { CompanyPackageOrm, PackageOrm } from './Package';
import modelOf from './utils/model-of';


export enum CompanyType {
  BRAND = 1,
  MATERIAL_SUPPLIER = 3,
  PERSONAL_STUDIO = 7,
  OTHER = 8,
}

export enum PartnerStatus {
  NORMAL = 1,
  STOP = 2,
}

// 数据来源（0：用户 1：后台创建）
export enum SourceStatus {
  BY_USER = 1,
  BY_ADMIN = 2,
}

function generateAccessor(model: Obj, propKey: string, relationKey: string, {
  internalKey,
  foreignKey = 'id',
}: { internalKey: string, foreignKey?: string }) {
  Object.defineProperty(model, propKey, {
    set(value: string | undefined) {
      if (!value) return;
      if (!this[internalKey]) throw new Error('no package id');
      this[relationKey] ??= { [foreignKey]: this[internalKey] };
      Object.assign(this.package, {
        [propKey]: value,
      });
    },
    get() {
      return this[relationKey]?.[propKey];
    },
  });
}
// 0：待审批 1：审核不通过 2：有效 3：过期
enum AuditStatus {
  NOAPPLY = -1,
  PENDING,
  NOPASS,
  VALID,
  EXPIRE
}

export class ResourceInfo {
  companyId!: string;
  usedSubAccount!: number;
  freeSubAccount!: number;
  totalSubAccount!: number;
  totalDataSize!: number;
  usedWorkspace!: number;
  totalWorkspace!: number | string;
  selfTotalWorkspace!: number | string;
  userTotal!: number | string;
  orgQuantity!: number | string;
  orgUsed!: number;
  userQuantity!: number | string;
  userUsed!: number;
}

type Attachment = { name: string, downloadUrl: string };
class Company {
  /** @deprecated 国家 */
  country?: number;
  /** @deprecated 城市 */
  city?: string;

  /** @deprecated 套餐信息、服务等级 */
  get package() {
    return this.packages?.[0];
  }
  get packageName() {
    return this.package?.name;
  }
  get packageId() {
    return this.package?.id;
  }

  packages?: Package[];
  businessType?: 0 | 1 | 2 | 3;

  id!: string;
  // 公司类型
  type!: CompanyType;
  // 公司规模
  scale?: number;
  // 公司注册代码
  certificateCode?: string;
  // 公司全称
  name!: string;
  // 公司简称
  abbreviation?: string;
  // 合作状态
  partnerStatus!: PartnerStatus;
  // 有效期至
  expireTime!: number;
  // 创建人
  creatorName?: string;
  creatorLastName?: string;
  creatorFirstName?: string;
  creatorPhone?: string;
  creatorMail?: string;

  auditStatus?: AuditStatus;
  // 联系人
  contactName?: string;
  contactMobile?: string;

  resourceInfo?: ResourceInfo;
  roles?: CompanyRole[];
  // 地址
  addressCountry?: string;
  addressProvince?: string;
  addressCity?: string;
  address?: string;
  // 公司邮箱
  email?: string;
  // 公司LOGO
  logo?: string;
  // 公司LOGO(完整url, 显示用该字段)
  logoUrl?: string;
  // 附件
  additionalFiles?: Attachment[];
  // 公司简介
  industry?: string;
  // 主营产品：皮料；人造革；针织；梭织；薄膜料；其他；鞋带；织带；织唛；鞋垫；沿条；五金配饰；线；鞋底
  leadingProduct!: string | string[];
  // 固定电话
  landline?: string;
  // 经营模式：制造商、工厂；贸易公司；批发零售；其他
  businessModel!: string;
  // 经营范围
  businessScope!: string;
  // 合作品牌
  cooperationBrand!: string;
  // 数据来源（0：用户 1：后台创建）
  sourceStatus?: SourceStatus;
  // 用户在当前租户中是否为主账号
  isAdmin?: boolean;
  // 是不是公司
  isPersonalCom!: boolean;
  // 是否过期
  isExpireTime?: boolean;
  isForbid?: boolean;
  isInit!: boolean;

  isCompanyAdmin?: boolean;
  // 是否红蜻蜓
  isRedDragonflyCom!: boolean;

  platformInfoList!: any[];
}

export const ResourceInfoOrm = modelOf(ResourceInfo, {
  entity: 'resourceInfos',
  primaryKey: 'companyId',
  fields() {
    return {
      companyId: this.attr(''),
      usedSubAccount: this.attr(0),
      freeSubAccount: this.attr(0),
      totalSubAccount: this.attr(0),
      totalDataSize: this.attr(0),
      usedWorkspace: this.attr(0),
      totalWorkspace: this.attr(''),
      selfTotalWorkspace: this.attr(''),
      userTotal: this.attr(''),
      orgQuantity: this.attr(''),
      orgUsed: this.attr(0),
      userQuantity: this.attr(''),
      userUsed: this.attr(0),
    };
  },
});


export const CompanyOrm = modelOf(Company, {
  entity: 'companies',
  primaryKey: 'id',
  fields() {
    return {

      country: this.attr(''),
      /** @deprecated 城市 */
      city: this.attr(''),

      isPersonalCom: this.attr(true),
      isCompanyAdmin: this.attr(false),

      // 是否红蜻蜓
      isRedDragonflyCom: this.attr(false),
      // 是否红蜻蜓
      isExpireTime: this.attr(false),
      isForbid: this.attr(false),
      resourceInfo: this.belongsTo(ResourceInfoOrm, 'id'),
      packages: this.belongsToMany(PackageOrm, CompanyPackageOrm, 'companyId', 'packageId'),
      id!: this.attr(''),
      // 公司类型
      type!: this.attr(8),
      // 公司规模
      scale: this.attr(''),
      // 公司注册代码
      certificateCode: this.attr(''),
      // 公司全称
      name!: this.attr(''),
      // 公司简称
      abbreviation: this.attr(''),
      // 合作状态
      partnerStatus: this.attr(1),
      // 有效期至
      expireTime: this.attr(null),
      // 创建人
      creatorName: this.attr(''),
      creatorLastName: this.attr(''),
      creatorFirstName: this.attr(''),
      creatorPhone: this.attr(''),
      creatorMail: this.attr(''),


      // 联系人
      contactName: this.attr(''),
      contactMobile: this.attr(''),


      // 地址
      addressCountry: this.attr(''),
      addressProvince: this.attr(''),
      addressCity: this.attr(''),
      address: this.attr(''),

      roles: this.hasMany(CompanyRoleOrm, 'orgId'),

      // 公司邮箱
      email: this.attr(''),

      // 公司LOGO
      logo: this.attr(''),

      businessType: this.attr(0),
      auditStatus: this.attr(-1),

      industry: this.attr(''),

      // 主营产品：皮料；人造革；针织；梭织；薄膜料；其他；鞋带；织带；织唛；鞋垫；沿条；五金配饰；线；鞋底
      leadingProduct: this.attr(''),
      // 固定电话
      landline: this.attr(''),
      // 经营模式：制造商、工厂；贸易公司；批发零售；其他
      businessModel: this.attr(''),
      // 经营范围
      businessScope: this.attr(''),
      // 合作品牌
      cooperationBrand: this.attr(''),
      // 数据来源（0：用户 1：后台创建）
      sourceStatus: this.attr(undefined),
      platformInfoList: this.attr([]),
    };
  },
});
export default Company;

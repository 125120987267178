<template>
  <input
    :accept="accept"
    :multiple="multiple"
    type="file"
    class="hidden"
    ref="inputFile"
    @change="uploadFiles" />
</template>

<script lang="javascript">
export default {
  name: 'UploadFileInput',
  inject: ['accept', 'multiple'],
  props: {
    disabled: {
      type: Boolean,
    },
  },
  methods: {
    triggerUpload() {
      if (!this.disabled) this.$refs.inputFile.click();
    },
    uploadFiles() {
      const { files } = this.$refs.inputFile;
      if (files.length) {
        this.$emit('file', files);

        // 每次上传完，把input(file)的值设置空
        // 解决两次选取同一个文件不能触发相应事件的问题
        this.$refs.inputFile.value = '';
      }
    },
  },
};
</script>

import first from '@4dst-saas/public-utils/dist/first';
import Cookies from 'js-cookie';
import { parseUrl } from 'query-string';
import getCookieConfig from './get-cookie-config';

const ORGID_NAME = 'Sdtc-Org-Id';

export default {
  set(companyId: string) {
    Cookies.set(ORGID_NAME, companyId, {
      ...getCookieConfig(),
      expires: 30,
    });
  },
  getCookiesCompany() {
    return Cookies.get(ORGID_NAME);
  },
  getQueryCompany() {
    return first(parseUrl(globalThis.location.href).query._companyId);
  },
  get() {
    return this.getQueryCompany() ?? this.getCookiesCompany();
  },
  remove() {
    Cookies.remove(ORGID_NAME, getCookieConfig());
  },
};

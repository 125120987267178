import axios from './axios';

/**
 * 查询指引状态
 * @returns {Promise}
 */
export const getGuideStatus = () => {
  return axios({
    method: 'get',
    url: '/msgcenter/guide',
  });
};

/**
 * 修改指引状态为已读
 */
export const setGuideStatus = (data) => {
  return axios({
    method: 'post',
    url: '/msgcenter/guide',
    data,
  });
};

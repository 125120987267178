<template>
  <div
    :class="{ 'dragover': dragoverStatus }"
    style="width: 100%; height: 100%;"
    @drop.prevent="$emit('file', $event.dataTransfer.files)"
    @dragover.prevent="handleDragOver"
    @dragleave="dragoverStatus = false">
    <slot>
      <upload-style-normal v-if="styleType === 'normal'" />
    </slot>
  </div>
</template>

<script>
import UploadStyleNormal from './upload-style-normal.vue';

export default {
  name: 'UploadDrag',
  components: {
    UploadStyleNormal,
  },
  inject: ['styleType'],
  data() {
    return {
      dragoverStatus: false,
    };
  },
  methods: {
    handleDragOver() {
      if (!this.dragoverStatus) this.dragoverStatus = true;
    },
  },
};
</script>

// _itemProgress(string id, int pos)
// _itemDownloadFail(string id)
// _itemDownloadFinish(string id)
// 0：已下载，1：未下载，2：等待更新, 3: 正在下载， 4：暂停下载，5: 失败

// import { history } from '@/utils/history';
import { stringifyUrl, parseUrl } from 'query-string';
import { getFileName } from '_/get-ext';
import tryParseJson from '_/try-parse-json';
import token from '@/utils/token';

const handlers = {
  log: {
    key: '_jsLog',
    handler(fn, [str]) {
      fn(str);
    },
  },
  goToInitPage: {
    key: '_updatePage',
    handler(fn) {
      fn();
    },
  },
  // modelUploaded: {
  //   key: '_modelUploaded',
  //   handler(fn) {
  //     fn();
  //   },
  // },
  goToPage: {
    key: '_gotoPage',
    handler(fn, [appType]) {
      fn({ path: '/qt/file-list', query: { appType } });
    },
  },
  goToPageByUrl: {
    key: '_goToPageByUrl',
    handler(fn, [url]) {
      fn(url);
    },
  },
  refresh: {
    key: '_updateCurrentPage',
    handler(fn) {
      fn();
    },
  },
  winClosed: {
    key: '_msgBoxCallback',
    handler(fn, [result]) {
      let r = result;
      try {
        r = JSON.parse(r);
        // eslint-disable-next-line no-empty
      } catch (e) { }
      fn(r);
    },
  },
  downloadProcess: {
    key: '_itemProgress',
    handler(fn, [relateId, downloadProgress]) {
      fn({ relateId, downloadProgress });
    },
  },
  downloadFail: {
    key: '_itemDownloadFail',
    handler(fn, [relateId]) {
      fn({ relateId, downloadState: 5 });
    },
  },
  downloadFinish: {
    key: '_itemDownloadFinish',
    handler(fn, [relateId]) {
      fn({ relateId, downloadState: 0, downloadProgress: undefined });
    },
  },
  // 4ddat文件解压后三种展示图片
  give4DdatThumailPng: {
    key: '_give4DdatThumailPng',
    handler(fn, [flat, thumbnail, toeThumbnail]) {
      fn({ flat, thumbnail, toeThumbnail });
    },
  },
};

export class MissingConnectorError extends Error {}

export default class IdeationHost {
  static type = 'qt';
  static instance;

  static detect() {
    return /Qt(?:WebEngine)?\//.test(navigator.userAgent);
  }
  static exec(methodName, args) {
    this.instance = this.instance ?? new this();
    return this.instance.exec(methodName, args);
  }

  qwobject;

  #listenerItems = {};
  _run(method, ...args) {
    console.log(`执行方法${method}, 参数:`);
    console.log(args);
    return new Promise(resolve => {
      if (typeof this.qwobject[method] !== 'function') {
        throw new TypeError(`qtHost.${method} is not a function`);
      }
      this.qwobject[method](
        ...args, resolve,
      );
    });
  }
  async exec(methodName, args) {
    await this;
    if (this[methodName]) {
      return this[methodName](...args);
    }
    return this._run(methodName, ...args);
  }

  async init() {
    if (this.then) {
      this.then = undefined;
      const { QWebChannel } = (await import('qwebchannel')).default;
      this.qwobject = await (new Promise((resolve, reject) => {
        const qt = global.qt || (global.top && global.top.qt); // 使iframe也支持调用qt
        if (!qt) {
          reject(new Error('No QT Injected'));
          return;
        }
        // eslint-disable-next-line no-new, no-undef
        new QWebChannel(qt.webChannelTransport, (channel) => {
          resolve(channel.objects.webcom);
        });
      }));

      // channel.objects.webcom.;
    }

    return this;
  }

  then(...args) {
    return Promise.resolve(this.init()).then(...args);
  }

  once(type, fn) {
    return new Promise((resolve) => {
      const _fn = (...args) => {
        const result = fn ? fn.call(this, ...args) : args[0];
        this.off(type, _fn);
        resolve(result);
        return result;
      };
      this.on(type, _fn);
    });
  }

  _on(type, fn) {
    const mixin = handlers[type] ?? {
      key: this.qwobject[type] ? type : `_${type}`,
      handler(_fn, args) {
        _fn(...args.map(item => tryParseJson(item)));
      },
    };
    const handlerItem = {
      connection: (...args) => {
        mixin.handler(fn, args);
      },
      ...mixin,
    };
    if (!this.qwobject[handlerItem.key]) {
      throw new MissingConnectorError();
    }
    this.qwobject[handlerItem.key].connect(handlerItem.connection);
    this.#listenerItems[type] = this.#listenerItems[type] ?? new WeakMap();
    this.#listenerItems[type].set(fn, handlerItem);
  }

  off(type, fn) {
    const handlerItem = this.#listenerItems[type].get(fn);
    this.qwobject[handlerItem.key].disconnect(handlerItem.connection);
    this.#listenerItems[type].delete(fn);
  }

  on(type, fn) {
    let map = {};
    if (typeof type === 'string') {
      map[type] = fn;
    } else {
      map = type;
    }
    Object.keys(map).forEach(k => {
      this._on(k, map[k]);
    });
    return () => {
      Object.keys(map).forEach(k => {
        this.off(k, map[k]);
      });
    };
  }

  async reportCurrentPage(to) {
    if (to.name === 'QtFileList') {
      await this._run('curPageType', to.query.appType);
    } else if (to.name === 'QtWorkspaceList') {
      await this._run('curPageType', 0);
    }
  }

  async getCatalogLocalState({
    relateId, relateType, id,
  }) {
    const state = await this._run('getItemState', relateType, id, relateId);
    return {
      downloadState: state,
      downloadProgress: undefined,
    };
  }

  async openBrowser(url) {
    const route = parseUrl(url);
    route.query = {
      ...route.query,
      token: token.get(),
    };
    const _url = stringifyUrl(route);
    await this._run('openBrowser', _url);
  }
  async openBrowserBlank(url) {
    await this._run('openBrowser', url);
  }

  closeWin(result) {
    return this._run('wndAction', 0, JSON.stringify(result));
  }

  /**
   * 批注事件传递信息
   * @param {Object} payload { action: string, params: any }
   */
  actAnnotationOperation(payload) {
    return this._run('onTagsOperation', JSON.stringify(payload));
  }

  viewAnnotation(payload) {
    return this._run('viewAnnotation', payload);
  }

  viewAnnotations(payload) {
    return this._run('viewAnnotations', payload);
  }
  saveAnnotation(payload) {
    return this._run('saveAnnotation', payload);
  }
  cancelAnnotation() {
    return this._run('cancelAnnotation');
  }
  receiveWebSocketMsg(msg) {
    return this._run('receiveWebSocketMsg', JSON.stringify(msg));
  }
  sigClose() {
    return this._run('sigClose');
  }
  closeAnnotationView(result) {
    return this._run('wndAction', 1, JSON.stringify(result));
  }

  /**
   * 根据4Ddat文件解析合成的三种展示图片；
   * 图片返回要在 give4DdatThumailPng 事件中捕获
   * @param {string} ossPath
   */
  analyze4Ddat(ossPath) {
    return this._run('analyze4Ddat', ossPath);
  }

  /**
   * 把4ddat文件切换后再传给客户端；
   * 图片返回要在 give4DdatThumailPng 事件中捕获
   * @param {Object} chunkInfo {totalSize: number, curPosSize: number, contentArray: string}
   */
  rev4DdatProgress({ totalSize, curPosSize, contentArray }) {
    return this._run('rev4DdatProgress', totalSize, curPosSize, contentArray);
  }

  downloadStaticFile(url, filename = getFileName(url), options = { method: 'GET', body: null }) {
    return this._run('downloadFile', url, filename, options);
  }


  async CADload4dxmModelData({ modelFileList, name, modelId }) {
    return this._run('itemClickedDownloadLibCallback', { name, modelId, data: { modelFileList } });
  }

  // 客户端打开浏览器窗体
  async openWin({ url, params = {}, query = {} }) {
    if (url) {
      const a = document.createElement('a');
      a.href = stringifyUrl({ url, query });
      return this._run('browse', a.href, JSON.stringify(params), false);
    }
    return null;
  }

  async cancelDownloadFile({
    relateId, relateType, id, hostData,
  }) {
    const result = await this._run('itemCancelClickCallback', relateType, id, relateId);
    if (hostData) {
      hostData.downloadState = 1;
      hostData.downloadProgress = undefined;
    }
    return result;
  }

  async downloadFile({
    relateId, relateType, id, hostData, workspaceId,
  }) {
    let result;
    if (hostData.downloadState === 4) {
      result = await this._run('itemContinueClickCallback', relateType, id, relateId);
      if (hostData) {
        hostData.downloadState = 3;
      }
    } else {
      result = await this._run('itemDownloadClickCallback', relateType, id, workspaceId ?? '', relateId);
      if (hostData) {
        hostData.downloadState = 3;
        hostData.downloadProgress = 0;
      }
    }
    return result;
  }

  async pauseDownloadFile({
    relateId, relateType, id, hostData,
  }) {
    const result = await this._run('itemPauseClickCallback', relateType, id, relateId);
    if (hostData) {
      hostData.downloadState = 4;
    }
    return result;
  }

  async openRenderer(item, additionalParams = {}) {
    return this._run('itemOpenclickedCallback', {
      ...item,
      history: additionalParams.isHistory ?? false,
      annotation: additionalParams.showAnnotation ?? false,
    });
  }


  async uploadModel({ appType, workspaceId, id }) {
    return this._run('uploadDesign', appType, workspaceId ?? '', id ?? '-1');
  }

  // cad保存到pdm
  async uploadNotify(styleId) {
    return this._run('uploadNotify', { styleId });
  }

  // async uploadModel(item) {
  //   const { appType, workspaceId, id } = item;
  //   return new Promise((resolve) => {
  //     this._run('uploadDesign', appType, workspaceId ?? '', id ?? '-1');
  //     this.one('modelUploaded', () => {
  //       resolve();
  //     });
  //   });
  // }

  async uploadAntaMaterial({
    appType, workspaceId, id, workspaceBusinessType, isAntaDomain, workspaceType,
  }) {
    console.log({ workspaceBusinessType, isAntaDomain });
    if (workspaceType === 2 || isAntaDomain) {
      console.log('uploadAtMaterial');
      return this._run('uploadAtMaterial', appType, workspaceId ?? '', id ?? '-1');
    }
    console.log('uploadMaterial');
    return this._run('uploadMaterial', appType, workspaceId ?? '', id ?? '-1');
  }

  // 批量上传面料
  async uploadMaterialMultiple({ appType, workspaceId, id }) {
    return this._run('batchUploadMaterial', appType, workspaceId ?? '', id ?? '-1');
  }

  // 单个上传面料
  async uploadMaterialSingle({ appType, workspaceId, id }) {
    return this._run('uploadMaterial', appType, workspaceId ?? '', id ?? '-1');
  }

  async uploadMaterialLibrary({
    appType, workspaceId, id,
  }) {
    return this._run('uploadSupplierMaterial', appType, workspaceId ?? '', id);
  }

  /**
   * 上传安踏面料
   * @param {*} material 面料Item
   */
  async uploadMaterialBaseOn({
    entryAppType, workspaceId, parentId, relateId, fileInfo,
  }) {
    const uploadStyle = +(!!fileInfo.fileInfo || !!fileInfo.flat || !!fileInfo.thumbnail || !!fileInfo.texture);
    return this._run('uploadAtTemplateMaterial', relateId, entryAppType, workspaceId ?? '', parentId ?? '-1', uploadStyle);
  }

  /**
   * 上传安踏面料4ddat文件
   * @param {*} material 面料Item
   */
  async uploadAtMaterialFile({
    entryAppType, workspaceId, parentId, relateId,
  }) {
    return this._run('uploadAtMaterialFile', relateId, entryAppType ?? 13, workspaceId ?? '', parentId ?? '-1');
  }
  /**
   * 上传平台面料4ddat文件
   * @param {*} material 面料Item
   */
  async replace4ddat({
    workspaceId, relateId, id,
  }) {
    return this._run('onReplaceMat', { materialId: relateId, workspaceId: workspaceId ?? '', catalogId: id ?? '' });
  }

  // 客户端获取安踏上传面料表单数据
  async recvAntaForm(result) {
    return this._run('recvAntaForm', result);
  }

  // 客户端获取材料库上传面料表单数据
  async recvSupplierForm(result) {
    return this._run('recvSupplierForm', result);
  }

  // 客户端获取安踏上传面料表单宽高
  async recvWebSizeChanged({ width, height }) {
    return this._run('recvWebSizeChanged', width, height);
  }

  // 客户端获取安踏上传面料滚动事件
  async onWebScrollValue(height) {
    return this._run('onWebScrollValue', height);
  }

  // 客户端打开展厅
  async enterShowRoom(url) {
    return this._run('enterShowRoom', url);
  }

  // 客户端打开会场
  async openMeetingRoom(meetingId) {
    return this._run('openMeetingRoom', meetingId);
  }

  // 客户端打开会议库
  async openMeetingLibrary({ entryAppType, id, meetingId }) {
    return this._run('openMeetingLibrary', entryAppType, id, meetingId);
  }

  // 2D设计信息保存后传给客户端
  async revWebDesignInfo(form, isValid) {
    return this._run('revWebDesignInfo', JSON.stringify(form), isValid);
  }


  // 客户端打开会议库
  async onpenUploadBatchWidget({ appType, workspaceId }) {
    return this._run('onpenUploadBatchWidget', appType, workspaceId);
  }

  // 我的材料提交目录
  // {
  //     "optType": 0,  // 操作类型，0是上传取消，1是上传确定，2是打开文件
  //     "folderId": "123456"  // 要上传到的文件夹id，optType为1时必传
  // }
  async myMaterialWebCallBack(params) {
    return this._run('myMaterialWebCallBack', params);
  }

  // coloro色板相关事件
  async clickedColor(color) {
    return this._run('onClickedColor', JSON.stringify(color));
  }
  async dragColor(color) {
    return this._run('onDragColor', JSON.stringify(color));
  }
  async moveColorTo(paletteId, color) {
    return this._run('onMoveColorTo', paletteId, JSON.stringify(color));
  }

  // 导出材料配色单-action: openPDF 打开pdf新窗口; action: printPDF 打印pdf
  async onReceivWebExportSignal(data) {
    return this._run('onReceivWebExportSignal', data);
  }
  async webToLocal4DClientStartProcess(data) {
    return this._run('webToLocal4DClientStartProcess', data);
  }
}


/* eslint-disable import/no-dynamic-require */

import {
  computed, defineComponent, getCurrentInstance,
} from 'vue';

import {
  isEmptyError, isAuthError, isNoRecordError, isNoSearchResultError, isNoImageError,
} from '@/utils/error-handler';
import AppError from '@/utils/error';
import useTheme from '@/utils/composables/use-theme';
import ErrorLike from '@/typings/error-like';
import useError from './use-error';

function error2Image(error: ErrorLike) {
  if (isNoRecordError(error)) {
    return 'no-record';
  }
  // if (lf2br(getMessage(error)) === '暂无操作记录') {
  //   return 'no-record';
  // }
  if (isNoSearchResultError(error)) {
    return 'no-result';
  }
  if (isEmptyError(error)) {
    return 'empty';
  }
  if (isAuthError(error)) {
    return 'no-auth';
  }
  if (isNoImageError(error)) {
    return 'no-image';
  }
  return 'network';
}
type ButtonConfig = {
  type: '' | 'primary' | 'danger',
  handler: Function,
  label: string
};

export default defineComponent({
  props: {
    align: {
      default: 'vertical',
    },
    error: {
      type: undefined as unknown as () => ErrorLike,
      default() {
        return AppError.unknown;
      },
    },
    size: {
      default: '',
    },
    img: {
      type: String,
    },
    buttons: {
      type: Array as () => ButtonConfig[],
    },
  },
  setup(props) {
    const that = getCurrentInstance()?.proxy;
    const { isDark } = useTheme();
    const { message, messageHTML, title } = useError(props);

    function handlerButton(item: ButtonConfig) {
      item.handler.call(that);
    }


    const computedImg = computed(() => {
      if (props.img) return props.img;
      const imgName = error2Image(props.error);
      const name = isDark.value ? `${imgName}-dark` : `${imgName}`;
      return require(`@/assets/img/error/${name}.png`);
    });
    return {
      title,
      computedImg,
      message,
      messageHTML,
      handlerButton,
    };
  },
});

/* eslint-disable import/prefer-default-export */
import axios from '@/utils/axios';
import { DictOption, DictItem } from '@/models/Dictionary';
import i18next from '@/i18n';

// export type StsParams = {
//   region?: string,
//   accessKeyId: string,
//   accessKeySecret: string,
//   securityToken: string,
//   endPoint: string,
//   expiration:number,
//   bucket: string,
// };


/**
 * 根据类型获取该字典数据
 * @param {String} type 类型
 */
export async function getDict(params : {type: string}) {
  return (await axios.get<DictItem[]>(
    '/bff-center/showCommon/getDictionaryTypeItem', {
      headers: { language: i18next.language },
      params: { typeCode: params.type },
    },
  )).data.map(item => {
    return {
      usName: item.label,
      name: item.label,
      value: item.itemValue,
    };
  }) as DictOption[];
}

/**
 * 根据类型获取该字典数据
 * @param {String} type 类型
 */
export const getDictByType = (_type: string | { type: string }) => {
  const type = typeof _type === 'object' ? _type.type : _type;
  return getDict({ type });
};

/**
 * 根据类型获取该字典数据，可通过headers带上language
 * @param {String} typeCode
 * @see http://yapi.4dshoetech.local/project/115/interface/api/6104
 */
export async function getDictionaryByTypeCode(typeCode: string, language: string) {
  return (await axios({
    method: 'get',
    url: '/bff-center/showCommon/getDictionaryTypeItem',
    headers: { language },
    params: { typeCode },
  })).data as DictItem[];
}

/**
 * 根据title获取属性值列表
 * @param {String} typeCode
 * @see http://yapi.4dshoetech.local/project/140/interface/api/7168
 */
export async function fetchAntaPropertyByCode(title: string) {
  return (await axios({
    method: 'get',
    url: '/anta-material/dynamics/property/value/title',
    params: { title },
  })).data;
}

/**
 * 根据id获取属性值label
 * @param {String} typeCode
 * @see http://yapi.4dshoetech.local/project/140/interface/api/7635
 */
export async function fetchAntaPropertyById(id: string) {
  return (await axios({
    method: 'get',
    url: '/anta-material/dynamics/property/value/id',
    params: { id },
  })).data;
}

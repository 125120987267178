

import { upperCamelCase } from '@4dst-saas/public-utils/dist/case';
import own from '@4dst-saas/public-utils/dist/own';
import { defineComponent, VNode } from 'vue';

const presudos = ['', 'webkit', 'moz'] as const;
const CHILD_DATA_ATTR_NAME = 'resiable-panel-child';
function _getComputedStyle(el: HTMLElement, k: string) {
  const computedStyle = getComputedStyle(el);
  for (let i = 0; i <= presudos.length; i++) {
    const key = presudos[i] ? `${presudos[i]}${upperCamelCase(k)}` : k;
    if (own(computedStyle, key)) {
      return computedStyle[key as keyof CSSStyleDeclaration];
    }
  }
  return undefined;
}
function getFlexDirection(el: HTMLElement) {
  const flexDirection = _getComputedStyle(el, 'flexDirection') as string;
  const [direction, reverse] = flexDirection.split('-');
  return {
    vertical: direction === 'column',
    reverse: !!reverse,
  };
}

/*
flex: none => flex: 0 0 auto
flex: 0 => flex: 0 1 0%
flex: 1 => flex: 1 1 0%
没有flex => flex: 0 1 auto
*/
function isAutoFlexBasis(el: HTMLElement) {
  const flexBasis = _getComputedStyle(el, 'flexBasis') as string;
  return flexBasis === 'auto';
}

export default defineComponent({
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    seperatorSize: {
      type: Number,
      default: 10,
    },
    direction: {
      type: String,
      default: 'row',
    },
  },
  setup(props, { emit }) {
    const dataId = `${Date.now()}`;
    function handleDragSeperator(parentEl: HTMLElement, e: MouseEvent, idx: number) {
      const { vertical } = getFlexDirection(parentEl);
      const wOrH = vertical ? 'height' : 'width';
      const children = parentEl.querySelectorAll(`[data-${CHILD_DATA_ATTR_NAME}]`) as ArrayLike<HTMLElement>;
      const element = children[idx];
      const prevElement = children[idx - 1];
      element.dataset.dragStartSize = _getComputedStyle(element, wOrH) as string;
      prevElement.dataset.dragStartSize = _getComputedStyle(prevElement, wOrH) as string;
      const totalStarSize = parseFloat(element.dataset.dragStartSize) + parseFloat(prevElement.dataset.dragStartSize);
      const start = e[vertical ? 'screenY' : 'screenX'];
      const moveHandle = async (_e: MouseEvent) => {
        const delta = _e[vertical ? 'screenY' : 'screenX'] - start;
        if (!delta) return;
        // TODO: 以下代码缺少判断reverse的情况，以后有需要再添加
        // delta 方向的那个元素
        const deltaElement = delta > 0 ? element : prevElement;
        const otherElement = element === deltaElement ? prevElement : element;
        const deltaElementSize = parseFloat(deltaElement.dataset.dragStartSize as string) - Math.abs(delta);
        // const minDeltaElementSize = parseInt(_getComputedStyle(deltaElement, minWOrH) as string, 10);
        deltaElement.style[wOrH] = `${deltaElementSize}px`;
        deltaElement.style.flex = '0 1 auto';
        // const deltaElementResultSize = deltaElementSize;
        const deltaElementResultSize = parseFloat(_getComputedStyle(deltaElement, wOrH) as string);
        // const deltaElementResultSize = Math.max(deltaElementSize, minDeltaElementSize);
        // deltaElement.style[wOrH] = `${deltaElementResultSize}px`;
        otherElement.style[wOrH] = `${totalStarSize - deltaElementResultSize}px`;
      };
      const mouseupHandle = () => {
        document.removeEventListener('mousemove', moveHandle, false);
        document.removeEventListener('mouseup', mouseupHandle, false);
        emit('resize');
      };

      document.addEventListener('mousemove', moveHandle, false);
      document.addEventListener('mouseup', mouseupHandle, false);
    }
    return { handleDragSeperator, dataId };
  },
  render() {
    const Tag = this.tag;
    const defaultSlots = this.$scopedSlots.default ? this.$scopedSlots.default({}) : [];
    const { handleDragSeperator, dataId } = this;
    return <Tag class={this.direction}>
      {!defaultSlots ? null : ([] as VNode[]).concat(...defaultSlots.map((vNode, idx) => {
        if (vNode.data) {
          vNode.data.attrs = vNode.data.attrs ?? {};
          vNode.data.attrs[`data-${CHILD_DATA_ATTR_NAME}`] = dataId;
        }
        if (idx === 0) {
          return vNode;
        }
        return [
          <div class="seperator" onMousedown={(evt: MouseEvent) => handleDragSeperator(this.$el as HTMLElement, evt, idx)}></div>,
          vNode,
        ];
      }))}
    </Tag>;
  },
});


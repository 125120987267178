import {
  isNeedLoginError, parseError, ErrorLike, logError,
} from '@/utils/error-handler';
import { NavigationGuardNext, RawLocation, Route } from 'vue-router';
import history from '@/utils/history';
import isRouteInited from './is-route-inited';

type NextResult = RawLocation | false | void;

export function handleErrorGuard(fn: (to: Route, from?: Route) => NextResult | Promise<NextResult>) {
  return async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    const pageInited = isRouteInited(to, from);
    try {
      const result = await fn(to, from);
      if (result) {
        next(result);
      } else {
        next();
      }
    } catch (e) {
      let err: ErrorLike = e;
      if (pageInited) {
        if (typeof err !== 'object' || !err) {
          err = new Error(typeof err === 'string' ? err : 'Unknow Error');
        }
        if (isNeedLoginError(err)) {
          err.redirect = true;
        }
        next(err);
      } else {
        // eslint-disable-next-line no-nested-ternary
        const parsedError = parseError(e, null, true);
        const parsedRoute = history.parse(parsedError.redirect || '/500');
        // 如果是同一个项目，通过next跳
        logError(parsedError);
        if (parsedRoute.useRouterToNavigate) {
          next(parsedRoute);
        } else {
          history.replace(parsedRoute);
          next(false);
        }
      }
    }
  };
}

export default handleErrorGuard;

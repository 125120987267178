import axios from '@/utils/axios';

export enum AnnouncementType {
  SHUTDOWN = 1,
  NOTICE = 2,
  OTHER = 3,
}
// export const announcementTypeOptions = [
//   { label: '停机维护公告', value: AnnouncementType.SHUTDOWN },
//   { label: '通知', value: AnnouncementType.NOTICE },
//   { label: '其他', value: AnnouncementType.OTHER },
// ];

export type AvailableAnnouncement = {
  type: AnnouncementType,
  content: string,
  topic: string,
}
/**
 * 获取当前公告
 * http://yapi.corp.4dstc.com/project/550/interface/api/33060
 */
export const fetchAvailableAnnouncement = async (appId: string) => {
  let res = (await axios<AvailableAnnouncement | null>({
    method: 'get',
    url: `/announcement/current/${appId}`,
  })).data;
  // @ts-ignore
  if (res && res.type === -1) {
    res = null; // 后端说type为-1时，表示没有公告
  }
  return res;
};

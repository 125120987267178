
<template>
  <transition :duration="500" name="dialog-wrapper-fade" v-if="visible">
    <div
      ref="dialogWrapper"
      class="el-dialog__wrapper"
      :style="{
        display: 'flex',
        flexDirection: 'column',
      }"
      :class="[
        { 'with-modal': modal, 'is-compact': compact },
        customClass,
        type ? `is-${type}` : '',
        size ? `is-${size}` : '',
      ]"
      @mousedown.self="handleClickOutside">
      <div class="v-modal" tabindex="0" v-if="visible && modal" @click="handleClickOutside" />
      <div
        role="dialog"
        aria-modal="true"
        :aria-label="title || 'dialog'"
        :class="['el-dialog', { 'is-fullscreen': fullscreen, 'el-dialog--center': center }]"
        ref="dialog"
        :style="{
          width,
          height,
          'max-height': maxHeight,
          margin: isWS ? `auto ${right} ` : `auto `,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '4px',
          ...dialogStyle,
        }">
        <button
          type="button"
          class="el-dialog__headerbtn"
          aria-label="Close"
          v-if="showClose"
          @click="$emit('update:visible', false)">
          <i class="el-dialog__close el-icon el-icon-close" />
        </button>
        <div
          class="el-dialog__header"
          :style="{ textAlign: titleAlign, height: headerHeight }"
          v-if="title || $scopedSlots.title || $slots.title">
          <slot name="title">
            <span class="el-dialog__title">{{ title }}</span>
          </slot>
        </div>
        <div
          class="el-dialog__body"
          :class="{ 'no-padding': noPadding }"
          :style="
            flex
              ? {
                display: 'flex', flexDirection: flex, flex: 1, overflowY: 'auto', position: 'relative',
              }
              : { position: 'relative' }
          ">
          <slot />
        </div>
        <div class="el-dialog__footer" v-if="$slots.footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  methods: {
    handleClickOutside() {
      if (this.closeOnClickModal) {
        this.$emit('update:visible', false);
      }
    },
  },
  props: {
    title: String,
    compact: Boolean,
    size: {
      type: String,
      default: 'large',
    },
    flex: {
      type: String,
    },
    type: {
      type: String,
      required: false,
    },
    closeOnClickModal: {
      default: true,
      type: Boolean,
    },
    center: {
      type: Boolean,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    titleAlign: {
      type: String,
      default: 'left',
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    fullscreen: Boolean,
    modal: {
      type: Boolean,
      default: true,
    },
    top: {
      type: String,
      default: '15vh',
    },
    right: {
      type: String,
      default: '42%',
    },
    bottom: {
      type: String,
      default: '50px',
    },
    width: {
      type: String,
      default: '30%',
    },
    height: {
      type: String,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    // dialog 自定义的class
    customClass: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: String,
    },
    headerHeight: {
      type: String,
    },
    isWS: {
      type: Boolean,
      default: false,
    },
    dialogStyle: {
      type: Object,
      default: () => { },
    },
  },
  name: 'ElDialog',
  mounted() {
    setTimeout(() => {
      try {
        const wrapDiv = this.$refs.dialogWrapper?.parentElement?.parentElement;
        // 屏蔽el-dialog__wrapper父级元素的drop事件，避免拖拽图片时触发默认的图片预览行为，在pc端上会导致页面被覆盖
        // 详见 http://jira.corp.4dstc.com/browse/MAC-83
        wrapDiv.addEventListener('dragover', e => { e.stopPropagation(); e.preventDefault(); });
        wrapDiv.addEventListener('drop', e => { e.stopPropagation(); e.preventDefault(); });
      } catch (e) {
        console.info('el-dialog__wrapper 父级结构可能发生变化，需要修改wrapDiv');
      }
    });
  },
};
</script>
<style lang="scss" scoped>

</style>

<script>
export default {
  functional: true,
  render: (h, {
    data, children, props: { flex }, parent,
  }) => {
    if (parent.$options._scopeId) {
      data.attrs = data.attrs || {};
      data.attrs[`${parent.$options._scopeId}`] = '';
    }
    delete data.attrs.flex;
    return <div class="row" style={{ flex }} {...data}>{children}</div>;
  },
};
</script>
<style scoped>
.row {
  display: flex;
  flex-direction: row;
}
</style>

import modelOf from './utils/model-of';

class _BrandSuppliers {
  id !: string;
  flag!: string;
  name?: string;
}

export const BrandSuppliers = modelOf(_BrandSuppliers, {
  entity: 'brandSuppliers',
  primaryKey: 'id',
  fields() {
    return {
      id: this.attr(undefined),
      flag: this.attr(undefined),
      name: this.attr(undefined),
    };
  },
});

export default BrandSuppliers;

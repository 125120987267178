import { withProps } from '@/router/utils/props';
import can from '@/utils/can';
import { RouteConfig } from 'vue-router';

const user: RouteConfig = {
  name: 'UserSetting',
  path: 'user',
  redirect: 'user/account',
  meta: {
    // title: `Revofim-${i18n.t('sidebar.project_management')}`,
  },
  props: withProps(),
  component: () => import(/* webpackChunkName: "project-settings" */ '@/views/user/components/UserLayout.vue'),
  children: [
    {
      name: 'Authority',
      path: 'authority',
      meta: {
        title: 'Account Authority',
      },
      component: () => import(/* webpackChunkName: "user" */ '@/views/user/profile/authority/index.vue'),
    },

    {
      name: 'Role',
      path: 'role',
      meta: {
        title: 'Role Setting',
      },
      props: withProps(() => ({
        selectedType: 'role',
        authCode: 'ILI050401',
      })),
      component: () => import(/* webpackChunkName: "profile-setting" */ '@/views/user/profile/account/index.vue'),
    },
    {
      name: 'Org',
      path: 'org',
      meta: {
        title: 'Organization Setting',
      },
      props: withProps(() => ({
        selectedType: 'org',
        authCode: 'ILI050408',
      })),
      component: () => import(/* webpackChunkName: "profile-setting" */ '@/views/user/profile/account/index.vue'),
    },
    {
      name: 'Account',
      path: 'account',
      meta: {
        title: 'Account Setting',
      },
      props: withProps(() => ({
        selectedType: 'account',
        authCode: 'ILI050413',
      })),
      component: () => import(/* webpackChunkName: "profile-setting" */ '@/views/user/profile/account/index.vue'),
    },
    {
      name: 'Team',
      path: 'team',
      meta: {
        title: 'Team Setting',
      },
      props: withProps(() => ({
        selectedType: 'team',
        authCode: 'SYS0001',
      })),
      component: () => import(/* webpackChunkName: "profile-setting" */ '@/views/user/profile/account/index.vue'),
    },
    {
      name: 'Apps',
      path: 'apps',
      meta: {
        title: 'Tools & Applications',
      },
      component: () => import(/* webpackChunkName: "user" */ '@/views/user/profile/applications/index.vue'),
    },
  ],
};
export default user;

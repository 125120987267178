import { can as validatorCan } from './validators/can';

export const can = (groupAppId: string, code: string | number, allow: boolean = false) => {
  try {
    validatorCan(code, { workspaceId: groupAppId }, allow);
    return true;
  } catch (e) {
    return false;
  }
};

export default can;

declare module 'vue/types/vue' {
  interface Vue {
    $can: (
      groupAppId: string,
      code: string | number,
      allow?: boolean,
    ) => boolean,
  }
}

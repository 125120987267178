import { CookieAttributes } from 'js-cookie';
import envs from './envs';

const rIpv4 = /\d+\.\d+\.\d+\.\d+/;

let _supportSameSite: boolean | undefined;
function _detectSupportSameSiteNone() {
  const secure = globalThis.location.protocol.startsWith('https');
  if (!secure) {
    return false;
  }
  document.cookie = 'supportSameSite=1; SameSite=None; Secure';
  return document.cookie.indexOf('supportSameSite') !== -1;
}
function detectSupportSameSiteNone() {
  _supportSameSite = _supportSameSite ?? _detectSupportSameSiteNone();
  return _supportSameSite;
}

export function getCookieConfig(): CookieAttributes {
  const cookieDomain = envs.VUE_APP_COOKIE_DOMAIN;
  const supportSameSiteNone = detectSupportSameSiteNone();
  const path = '/';
  if (cookieDomain === 'localhost' || rIpv4.test(cookieDomain)) {
    return {
      path,
    };
  }
  return {
    ...(supportSameSiteNone ? {
      sameSite: 'None',
      secure: true,
    } : {}),
    domain: `.${cookieDomain}`,
    path,
  };
}
export default getCookieConfig;

import { getCurrentInstance } from 'vue';
// eslint-disable-next-line no-unused-vars
import type { Store } from 'vuex';
import instance from './instance';

const [getStore, setStore] = instance<Store<unknown>>();

// @ts-ignore
const useStore = () => getStore() || getCurrentInstance()!.proxy!.$store;

/**
 * @example
 * const useUser = simpleStore('user', () => ({ name: 'A', age: 18 }));
 * const user = useUser();
 */
const simpleStore = <S extends any>(path: string, fn: () => S) => () => {
  const store: Store<any> = useStore();

  if (!store.state[path]) {
    store.registerModule(path, { state: fn });
  } else if (!store.hasModule(path)) {
    store.registerModule(path, { state: fn }, { preserveState: true });
  }

  return store.state[path] as S;
};

export {
  setStore,
  getStore,
  simpleStore,
};

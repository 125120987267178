import Vue, {
  VueConstructor, Component, VNodeData,
} from 'vue';
import {
  DefaultData, DefaultMethods, DefaultComputed, DefaultProps,
} from 'vue/types/options';
import { CombinedVueInstance } from 'vue/types/vue';
import { O } from 'ts-toolbelt';
import keys from '@4dst-saas/public-utils/dist/keys';

const controllerKey = Symbol('dialog controller');
const componentConfigKey = Symbol('component config');
const dialogConfigKey = Symbol('dialog config');
const componentKeys = new WeakMap<Component, string>();

type DialogComponentProps = DefaultProps & {
  visible: Boolean
};
type ContentComponentMethods = DefaultMethods<never> & {
  $init?(): any
};
type DialogComponent<
  Data = DefaultData<never>,
  Methods = DefaultMethods<never>,
  Computed = DefaultComputed,
  Props = DialogComponentProps> =
  Component<Data, Methods, Computed, Props>;
type DialogInstance = CombinedVueInstance<
  Vue,
  DefaultData<never>,
  DefaultMethods<never>,
  DefaultComputed,
  DialogComponentProps
>;
type ContentComponent<
  Data = DefaultData<never>,
  Methods = ContentComponentMethods,
  Computed = DefaultComputed,
  Props = DefaultProps> =
  Component<Data, Methods, Computed, Props>;
type ContentInstance = CombinedVueInstance<
  Vue,
  DefaultData<never>,
  ContentComponentMethods,
  DefaultComputed,
  DefaultProps
>;
type ComponentData = {
  is: ContentComponent<any, any, any, any>
} & VNodeData;

type ComputedCloseWith = (result: unknown) => any;

type DialogifyConfig = {
  is?: DialogComponent<any, any, any, any>,
  key?: string,
  group?: string,
  closeWith?: string | ComputedCloseWith,
  confirmEvent?: string,
  cancelEvent?: string,
  props?: VNodeData['props'],
};

type DialogComponentData = DialogifyConfig & VNodeData;

type ComputedDialogComponentConfig = O.Overwrite<Required<DialogifyConfig> & VNodeData, {
  closeWith?: ComputedCloseWith
}>;

type DialogifyItem = {
  [controllerKey]: DialogItemController
  [componentConfigKey]: ComponentData
  [dialogConfigKey]: ComputedDialogComponentConfig
} & ComputedDialogComponentConfig;

function close(item: DialogifyItem) {
  item.props.visible = false;
}

function getDefaultKey(Com: Component) {
  if (!componentKeys.has(Com)) {
    componentKeys.set(Com, `${Date.now()}`);
  }
  return componentKeys.get(Com) as string;
}


const Injector: any = Vue.extend({
  props: {
    DialogComponent: {
      default() { return (this.$options as any).is; },
      type: [Object, Function] as unknown as () => DialogComponent<any, any, any, any>,
    },
    closeWith: {
      type: [String, Function] as unknown as () => ComputedCloseWith,
    },
  },
  render(h) {
    const group = this.dialogGroup as { [key: string]: DialogifyItem[] };
    return h('div', keys(group).map(k => {
      const dialogItems = group[k];
      return h('div', dialogItems.map((item) => {
        return h(item.is, { ...item, ref: item.key });
      }));
    }));
  },
  data() {
    return {
      componentData_: {} as {
        [key: string]: VNodeData
      },
      dialogGroup: {} as { [key: string]: DialogifyItem[] },
      cacheDialogItems_: {} as {
        [cacheKey: string]: DialogifyItem,
      },
    };
  },
  methods: {
    getDialogifyItemData(key: string) {
      return this.cacheDialogItems_[key];
    },
    createController(item: DialogifyItem) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return new DialogItemController(item, this);
    },
    getDialog(key: string) {
      return this.$refs[key] as DialogInstance;
    },
    getInstance(key: string) {
      return this.$refs[`instance-${key}`] as ContentInstance;
    },
    closeAll(group?: string) {
      if (group) {
        this.closeGroup(group);
      } else {
        Object.keys(this.dialogGroup).forEach(key => {
          this.closeGroup(key);
        });
      }
    },
    closeGroup(group: string) {
      this.dialogGroup[group].forEach(item => {
        item[controllerKey].close();
      });
    },
    dialogify(componentOptionsOrCtor: ComponentData | VueConstructor, dialogComponentData: DialogComponentData = {}) {
      let componentData: ComponentData;
      if (typeof componentOptionsOrCtor === 'function') {
        // @ts-ignore
        componentData = componentOptionsOrCtor.options;
      } else {
        componentData = componentOptionsOrCtor;
      }
      const { is: Is } = componentData;
      let computedComponentData = { ...componentData };

      const componentInsetDialogifyConfig: DialogifyConfig = {
        ...(('dialogify' in Is ? Is.dialogify : {}) as DialogifyConfig),
      };

      let {
        closeWith: _tmpCloseWith,
        ..._componentInsetDialogifyConfig
      } = componentInsetDialogifyConfig;
      _tmpCloseWith = dialogComponentData.closeWith
        ?? _tmpCloseWith;
      const closeWith = typeof _tmpCloseWith === 'string'
        // @ts-ignore
        ? function fnCloseWith(this: InstanceType<ComponentData['is']>) {
          return typeof _tmpCloseWith === 'string' && _tmpCloseWith in this ? this[_tmpCloseWith as keyof typeof this] : undefined;
        }
        : _tmpCloseWith;
      // @ts-ignore
      const computedDialogComponentData: ComputedDialogComponentConfig = {
        is: dialogComponentData.is || this.DialogComponent,
        // @ts-ignore
        key: dialogComponentData.key || getDefaultKey(Is),
        group: dialogComponentData.group || getDefaultKey(
          // @ts-ignore
          dialogComponentData.is || this.DialogComponent,
        ),
        ...dialogComponentData,
        confirmEvent: dialogComponentData.confirmEvent ?? ('confirm' as string),
        cancelEvent: dialogComponentData.cancelEvent ?? ('cancel' as string),
        ..._componentInsetDialogifyConfig,
        ...(closeWith ? { closeWith } : {}),
        props: {
          ...componentInsetDialogifyConfig.props,
          ...(dialogComponentData.props ?? {}),
        },
      };


      const {
        key, group, confirmEvent, cancelEvent,
      } = computedDialogComponentData;


      let item = this.getDialogifyItemData(key);
      if (item) {
        const _componentData = this.componentData_[item.key];
        _componentData.props = {
          ..._componentData.props,
          ...(computedComponentData.props || {}),
        };
        _componentData.scopedSlots = {
          ..._componentData.scopedSlots,
          ...(computedComponentData.scopedSlots || {}),
        };
        item.props = { ...item.props, ...(computedDialogComponentData.props || {}) };
        item.scopedSlots = {
          ...item.scopedSlots,
          ...(computedDialogComponentData.scopedSlots || {}),
        };
        return item[controllerKey];
      }
      computedComponentData = {
        props: {},
        scopedSlots: {},
        ...computedComponentData,
        on: {
          ...computedComponentData?.on,
          [confirmEvent]: (data: unknown) => {
            this.getDialog(key).$emit('update:visible', false, data, confirmEvent);
          },
          [cancelEvent]: (e: unknown) => {
            this.getDialog(key).$emit('update:visible', false, e, cancelEvent);
          },
        },
      };
      this.componentData_[key] = computedComponentData;

      let updateVisible = (computedDialogComponentData.on || {})['update:visible'] || [];
      if (!Array.isArray(updateVisible)) {
        updateVisible = [updateVisible];
      }

      item = {
        [componentConfigKey]: computedComponentData,
        [dialogConfigKey]: computedDialogComponentData,
        ...computedDialogComponentData,
        props: {
          visible: false,
          ...(computedDialogComponentData.props || {}),
        },
        scopedSlots: {
          ...(computedDialogComponentData.scopedSlots || {}),
          default: () => {
            const h = this.$createElement;
            return h(Is, {
              ref: `instance-${computedDialogComponentData.key}`,
              ...computedComponentData,
            });
          },
        },
        on: {
          'update:visible': [
            ...updateVisible,
          ],
        },
      } as unknown as DialogifyItem;

      item[controllerKey] = this.createController(item);
      const hasList = !!this.dialogGroup[group];
      const list = this.dialogGroup[group] || [];
      list.push(item);
      if (!hasList) {
        Vue.set(this.dialogGroup, group, list);
      }
      this.cacheDialogItems_[key] = item;
      return item[controllerKey];
    },
  },
});

class DialogItemController {
  injector: InstanceType<InjectorComponent>;

  item: DialogifyItem;

  constructor(item: DialogifyItem, injector: InstanceType<InjectorComponent>) {
    this.injector = injector;
    this.item = item;
  }

  then(...fns: Parameters<ReturnType<DialogItemController['open']>['then']>) {
    return this.open().then(...fns);
  }

  close(e?: unknown) {
    this.injector.getDialog(this.item.key).$emit('update:visible', false, e);
  }

  async open() {
    const { item, injector } = this;
    const list = injector.dialogGroup[item.group];
    const oldIndex = list.indexOf(item);
    if (oldIndex !== -1) {
      list.splice(oldIndex, 1);
      list.push(item);
    }
    item.props.visible = true;
    await injector.$nextTick();
    const dialog = injector.getDialog(item.key);
    const instance = injector.getInstance(item.key);
    if (instance) {
      if (typeof instance.$init === 'function') {
        await instance.$init();
      }
    }
    let { [dialogConfigKey]: { closeWith } } = item;
    closeWith = closeWith || this.injector.closeWith;
    return new Promise((resolve, reject) => {
      const bind = (visible: boolean, result: unknown, causeBy: undefined | string) => {
        if (!visible) {
          close(item);
          let isResolved = causeBy === item.confirmEvent;
          if (!causeBy || (causeBy === item.cancelEvent && result === undefined)) {
            result = result || (closeWith ? closeWith.call(instance, result) : undefined);
            isResolved = !(result instanceof Error);
          }
          dialog.$off('update:visible', bind);
          if (isResolved) {
            resolve(result);
          } else {
            reject(result);
          }
        }
      };
      dialog.$on('update:visible', bind);
    });
  }
}

export { Injector };
export type InjectorComponent = typeof Injector;
export type InjectorInstance = InstanceType<typeof Injector>;
type El = Element | string;

export default {
  install(_Vue: VueConstructor, mixin: Parameters<typeof Injector['extend']>[0] & { el: El }) {
    const { el, ...innerMixin } = mixin;
    const injector = new (Injector.extend(innerMixin))();
    injector.$mount(el);
    _Vue.prototype.$Dialog = injector;
    _Vue.prototype.$dialogInjector = injector;
    _Vue.prototype.$injectDialog = injector.dialogify.bind(injector);
  },
};

declare module 'vue/types/options' {
  interface ComponentOptions<
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    V extends Vue,
    > {
    dialogify?: DialogifyConfig;
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $injectDialog: (...params: Parameters<InjectorInstance['dialogify']>) =>
      ReturnType<InjectorInstance['dialogify']>
  }
}

<template>
  <div class="form-submit-buttons">
    <el-button :size="size" round :style="btnStyle" @click="$emit('cancel')" class="button-cancel">{{
      cancelText
    }}</el-button>
    <el-button :size="size" :style="btnStyle" round type="primary" @click="$emit('submit')">{{ submitText }}</el-button>
  </div>
</template>

<script>
import { i18n } from '@/i18n';

export default {
  name: 'FormSubmitButtons',
  props: {
    size: {
      type: String,
      default: '',
    },
    btnStyle: {
      type: Object,
      default: () => ({}),
    },
    cancelText: {
      type: String,
      default: i18n.t('public.dialog.cancel'),
    },
    submitText: {
      type: String,
      default: i18n.t('public.dialog.save'),
    },
  },
};
</script>

<style lang="scss" scoped>
.form-submit-buttons {
  text-align: right;
  // .button-cancel {
  //   border: none;
  // }
}
</style>

import Vue, { VueConstructor } from 'vue';

const LOADING_VALUE = Symbol('loading value');

type NonUndefined<T> = T extends null | undefined ? never : T;

class WrapperLoading<T extends NonUndefined<any>> {
  ['__proto__']: T;
  [LOADING_VALUE]: T;
  toJSON: () => T;
  toString: () => string;
  constructor(data: T | WrapperLoading<T>) {
    let _data: T;
    if (data instanceof WrapperLoading) {
      _data = data[LOADING_VALUE];
    } else {
      _data = data;
    }
    // eslint-disable-next-line no-proto
    this.__proto__ = _data;
    this[LOADING_VALUE] = _data;
    this.toJSON = () => {
      return this[LOADING_VALUE];
    };
    this.toString = () => {
      return JSON.stringify(this[LOADING_VALUE]);
    };
  }
  static isLoading(v: any) {
    return !!v && !!v[LOADING_VALUE];
  }
}

export const Loading = WrapperLoading as
  // eslint-disable-next-line no-use-before-define
  (new <T>(data: T) => (T & WrapperLoading<T>)) & { isLoading: (v: any) => boolean};

const defaults = {
  loading: new Loading(null),
};


export default {
  install(_Vue: VueConstructor, { loading = defaults.loading } = defaults) {
    _Vue.prototype.$loadingState = loading;
    _Vue.prototype.$isLoading = (v: any) => {
      return Loading.isLoading(v);
    };
  },
};

declare module 'vue/types/vue' {
  interface Vue {
    $isLoading: (v: any) => v is InstanceType<typeof Loading>,
  }
}


import { defineComponent } from 'vue';
import ErrorLike from '@/typings/error-like';
import useError from './use-error';

export default defineComponent({
  props: {
    error: {
      type: Object as unknown as () => ErrorLike,
      required: true,
    },
  },
  setup(props) {
    return useError(props);
  },
});

import can from '@/utils/can';
import { computed } from 'vue';
import i18n from '@/i18n';

const canShowCategory = () => can('system', 'EL1600')
  || can('system', 'EL1700')
  || can('system', 'EL1800')
  || can('system', 'EL1900')
  || can('system', 'EL2000');

const canShowSpecification = () => can('system', 'EL3700')
  || can('system', 'EL3701')
  || can('system', 'EL3702')
  || can('system', 'EL3703')
  || can('system', 'EL3704');


const permission = {
  field: () => can('', 'PDM2508')
    || can('', 'PDM2509')
    || can('', 'PDM2510')
    || can('', 'PDM2511')
    || can('', 'PDM2512')
    || can('', 'PDM25013'),
  sample: () => can('', 'PDM2514')
    || can('', 'PDM2515'),
  basics: () => can('', 'PDM2516')
    || can('', 'PDM2517')
    || can('', 'PDM2518')
    || can('', 'PDM2519')
    || can('', 'PDM2520'),
  task: () => can('', 'PDM2521'),
  type: () => can('', 'PDM2522')
    || can('', 'PDM2523'),
  other: () => can('', 'PDM2525'),
  libraryField: () => can('system', 'EL2100'),
  category: canShowCategory,
  specification: canShowSpecification,
  template: () => can('', 'EL2200'),
  group: () => can('', 'EL2300'),
  tag: () => can('', 'EL2400'),
  fieldRule: () => can('', 'EL5401'),
  woopStyleLib: () => can('', 'WOOP0010'),
};


const tabList = [
  {
    value: 'field',
    label: i18n.t('project.field_setting.name'),
    to: '/setting/project/field',
    can: () => permission.field(),
  },
  {
    value: 'libraryField',
    label: i18n.t('project.material_setting.name'),
    to: '/setting/library/field',
    can: () => permission.libraryField(),
  },
  {
    value: 'basics',
    label: i18n.t('project.basic_lib.name'),
    to: '/setting/project/basics',
    can: () => permission.basics(),
  },
  {
    value: 'type',
    label: i18n.t('project.type_setting.name'),
    to: '/setting/project/type',
    can: () => permission.type(),
  },
  {
    value: 'category',
    label: i18n.t('project.category.name'),
    to: '/setting/library/category',
    can: () => permission.category(),
  },
  {
    value: 'specification',
    label: i18n.t('project.specification.name'),
    to: '/setting/library/specification',
    can: () => permission.specification(),
  },

  {
    value: 'woopStyleLib',
    label: i18n.t('woop.title'),
    to: '/setting/woop/style-lib',
    can: () => permission.woopStyleLib(),
  },
  // {
  //   value: 'template',
  //   label: i18n.t('project.template.name'),
  //   to: '/setting/library/template?type=material',
  //   can: () => permission.template(),
  // },
  // {
  //   value: 'group',
  //   label: i18n.t('project.group.name'),
  //   to: '/setting/library/group?type=material',
  //   can: () => permission.group(),
  // },
  // {
  //   value: 'tag',
  //   label: i18n.t('project.tag.name'),
  //   to: '/setting/library/tag?type=material',
  //   can: () => permission.tag(),
  // },
  {
    value: 'other',
    label: i18n.t('project.other_config.name'),
    to: '/setting/project/other',
    can: () => permission.other(),
  },
  {
    value: 'devStage',
    label: i18n.t('project.stage_management'),
    to: '/setting/project/dev-stage',
    can: () => can('', 'PDM3404') || can('', 'PDM3405') || can('', 'PDM3406'),
  },
];

export default tabList;


import {
  defineComponent,
} from 'vue';
import i18n from '@/i18n';

/**
 *统一清除按钮样式
*/
export default defineComponent({
  name: 'ButtonClear',
  components: {

  },
  emits: ['click'],
  props: {
    defaultText: {
      type: String,
      default: i18n.t('components.btn_clear'),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const handlerClick = () => {
      emit('click');
    };
    return {
      handlerClick,
    };
  },
});


import { getLang, i18n } from '@/i18n';
import { defineComponent, ref, computed, onMounted, watch } from 'vue'
import getCurrentInstance from '@/utils/composables/get-current-public-instance';

import BaseImage from '@/components/base/Image.vue'
// import WorkspaceSettings from '@/views/workspace/components/WorkspaceSettings.vue'
import Opinion from '@/views/opinion/index.vue'
import { logout } from '@/loaders/context';
import useTheme, { themeList } from '@/utils/composables/use-theme';
import envs from '@/utils/envs';
import setLang, {
  langConfigList,
} from '@/router/utils/set-lang';
import * as AppIdConfig from '@/packages/AppIdConfig';
import * as Platform from '@/packages/Platform';

function usePopVisible() {
  const visible = ref(false)
  function setVisible(v: boolean) {
    visible.value = v
  }
  return {
    visible, setVisible
  }
}

export default defineComponent({
  name: 'UserDropdown',
  components: {
    BaseImage
  },
  props: {
    user: {
      type: Object
    }
  },
  setup(props, { emit }) {
    const { $can, $router, $injectDialog, $confirm } = getCurrentInstance()!
    const { storageTheme: themeType, isDark, switchTheme } = useTheme()
    const { visible } = usePopVisible()
    const { visible: langV, setVisible: setLangV } = usePopVisible()
    const { visible: themeV, setVisible: setThemeV } = usePopVisible()

    const lang = getLang()

    const defaultImage = computed(() => {
      return require('@/assets/img/header/default-avatar-circle.png');
    })
    const userImage = computed(() => props.user?.headImg);

    const isIndependentDesigner = computed(() => props.user?.mail !== 'designer_helper@163.com')

    const currentLangName = computed(() => {
      const currentLang = langConfigList.find(e => e.lang === i18n.language)
      return currentLang?.name
    })

    async function switchLanguage(langNum: number) {
      const langConfig = langConfigList.find((item) => langNum === item.num);
      try {
        const langstr = langConfig?.lang as 'en' | 'cn'
        await $confirm(i18n.t('setting.confirm_change_language', {
          language: i18n.t(`setting.${langstr}`),
        }), i18n.t('setting.confirm'));
        setLang(langstr);
      } catch (e) {
        emit('reset-lang')
      }
    }

    async function handleClickItem(e: any) {
      const type = e.target.dataset.type
      switch (type) {
        case 'profile':
          const { href } = $router.resolve({ name: 'Profile' });
          window.open(href, '_blank');
          break;
        // case 'setting':
        //   visible.value = false
        //   await $injectDialog({
        //     is: WorkspaceSettings,
        //   }, {
        //     props: {
        //       width: '496px',
        //     }
        //   })
        //   break;
        case 'service-sample':
          $router.push({ name: 'ServiceSample' })
          break;
        case 'help':
          $router.push({ name: 'HelpCenter' })
          break;
        case 'opinion':
          await $injectDialog({
            is: Opinion
          })
          break;
        case 'logout':
          await logout()
          $router.push({ name: 'Login' });
          break;
        default:
      }
      if (type) {
        visible.value = false
      }
    }


    const { getApplicationLink } = Platform.useApplicationLink();
    const { hasPermission: hasAuthorityPermission } = Platform.usePermissionPlatform();
    const { hasProfileAccount } = Platform.usePlatformAuth();
    const sourceItem = (text: string, handle: Function) => ({
      text,
      handle,
    });
    const source = computed(() => {
      let result: ReturnType<typeof sourceItem>[] = [];

      result = [...result, sourceItem(i18n.t('profile.personal_info'), () => {
        $router.push('/info');
      })];
      result = [...result, sourceItem(i18n.t('profile.system_setting'), () => {
        $router.push('/setting');
      })];

      // if (hasAuthorityPermission()) {
      //   result = [...result, sourceItem(i18n.t('profile.account_authority'), () => {
      //     $router.push('/setting/authority');
      //   })];
      // }

      // if (hasProfileAccount()) {
      //   result = [...result, sourceItem(i18n.t('profile.account_settings'), () => {
      //     $router.push('/setting/user');
      //   })];
      // }

      // if (getApplicationLink().length > 0) {
      //   result = [...result, sourceItem(i18n.t('profile.application_link'), () => {
      //     $router.push(`/applicationLink?applicationLink=${encodeURIComponent(getApplicationLink()[0].links!)}`);
      //   })];
      // }
      return result;
    });


    return {
      lang,
      isDark,
      themeType,
      defaultImage,
      userImage,
      themeList,
      switchTheme,
      envs,
      currentLangName,
      langConfigList,
      visible,
      langV,
      setLangV,
      themeV,
      setThemeV,
      handleClickItem,
      source,
      switchLanguage,
    }
  }
})

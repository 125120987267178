import { tryParseJson } from '@4dst-saas/public-utils';
import FimHostParams from './host-params/fim';
import HostParams from './host-params';
import { RevobitHostParams } from './host-params/revobit';
import { config } from '@/config';

const { env2HostEnv } = FimHostParams;


function getIdealabHostUrl(hostParams: HostParams) {
  const tmp = hostParams.hostEnv ? `${hostParams.hostEnv}.` : '';
  return `https://${tmp}versebook.${hostParams.tld}`;
}


const rTLD = /\.[a-z|A-Z]{2,8}$/;


/**
 * @deprecated
 * @param TLD 顶级域名
 * @param FLD 一级域名
 * @param hostEnv 当前环境
 * @param cooperation 合作公司名
 * @param lang 语言
 * @param locationHostAppName 当前APP
 * @returns 返回地址
 */
export function getHostAndBasePath(
  TLD: string,
  FLD: string,
  hostEnv: string,
  cooperation: string,
  lang: string,
  locationHostAppName: string,
  ...paths: string[]
) {
  return (new FimHostParams([TLD, FLD, hostEnv, cooperation, lang, locationHostAppName, ...paths])).getBasePathAndPaths();
}

/**
 * @deprecated
 * @param path 地址
 * @returns [TLD, FLD,  ] 从地址path中获取相关数据
 */
export function getHostParamsFromPath(path: string) {
  return new FimHostParams(path);
}


function getMobileDomain(hostParams: HostParams) {
  return hostParams.getModuleBasePath('m');
}


const hybridFramework = navigator.userAgent.includes('Qt') ? 'qt' : null;
// eslint-disable-next-line max-len
// Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Teams/1.4.00.14170 Chrome/85.0.4183.121 Electron/10.4.3 Safari/537.36
// Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.107 Safari/537.36 Ideation/3.13.xxx
const m = /(Ideation|Teams)\/([0-9.]+)/.exec(navigator.userAgent);
const hostVersion = m?.[2];
const hostApp = m?.[1];
const isPcClient = hostApp === 'Ideation';
const staticEnvs = {
  VUE_APP_OSS_SIGN: process.env.VUE_APP_OSS_SIGN as boolean,
  VUE_APP_MODE: process.env.VUE_APP_MODE as string,
  BASE_URL: process.env.BASE_URL as string,
  VUE_APP_LOGIN_PORT: process.env.VUE_APP_LOGIN_PORT as string,
  // VUE_APP_API_DOMAIN: 'sit.4dshoetech.local',
  VUE_APP_HELP_CENTER_URL: process.env.VUE_APP_HELP_CENTER_URL as string,
  VUE_APP_POLICY_URL: process.env.VUE_APP_POLICY_URL as string,
  VUE_APP_VERSION_HASH: process.env.VUE_APP_VERSION_HASH as string,
  VUE_APP_COOPERATION: process.env.VUE_APP_COOPERATION as string ?? localStorage.getItem('VUE_APP_COOPERATION'),
  VUE_APP_MODULE_NAME: process.env.VUE_APP_MODULE_NAME as string,
  VUE_APP_REF_NAME: process.env.VUE_APP_REF_NAME as string,
  VUE_APP_HOST_APP: hostApp,
  VUE_APP_PC_CLIENT_VERSION: isPcClient ? hostVersion : null,
  VUE_APP_HOST_VERSION: hostVersion,
  VUE_APP_PC_CLENT: hostApp,
  VUE_APP_IS_PC_CLIENT: isPcClient,
  VUE_APP_HYBRID_FRAMEWORK: hybridFramework,
  VUE_APP_CAPTCHA_APP_ID: process.env.VUE_APP_CAPTCHA_APP_ID as string,
  VUE_APP_HOST_ENV: process.env.VUE_APP_HOST_ENV as string,
  VUE_APP_IDEALAB_DOMAIN: process.env.VUE_APP_IDEALAB_DOMAIN as string,
  VUE_APP_PKG: tryParseJson(process.env.VUE_APP_PKG) as { name: string, version: string },
  VUE_APP_RELEASE: process.env.RELEASE as string,
  VUE_APP_IDEALAB_HOST_NAME: getIdealabHostUrl(process.env.VUE_APP_MODE),
  VUE_APP_MS_TEAMS_APP_ID: process.env.VUE_APP_MS_TEAMS_APP_ID as string,
};


function _getEnvs(href: string = window.location.href) {
  const a = document.createElement('a');
  a.href = href;
  const { hostname, protocol } = a;
  const isRemote = rTLD.test(hostname);
  const mode = staticEnvs.VUE_APP_MODE;
  // const mode = 'staging';
  let hostParams: HostParams;
  if (isRemote) {
    hostParams = new FimHostParams(href);
  } else {
    const cooperation = staticEnvs.VUE_APP_COOPERATION || '';
    const hostAppName = staticEnvs.VUE_APP_MODULE_NAME;
    let FLD = '4dshoetech';
    let TLD = 'local';
    if (protocol === 'https:') {
      FLD = 'revofim';
      TLD = 'co';
    }
    hostParams = new FimHostParams([TLD, FLD, 'local', cooperation, '', hostAppName]);
  }
  // console.log(isRemote);
  const apiHostEnv = hostParams.hostEnv === 'local' ? env2HostEnv[mode] : hostParams.hostEnv;
  const apiHostParams = new FimHostParams([
    hostParams.tld,
    hostParams.fld,
    apiHostEnv,
    hostParams.cooperation,
    hostParams.fld === '4dshoetech' ? hostParams[4] : '', '']);
  const env = hostParams.env ?? 'development';
  const apiDomain = apiHostParams.getBasePath();
  const datapipeDomain = config.dataPipeDomain || hostParams.fld === '4dshoetech' ? apiDomain : (new RevobitHostParams([
    hostParams.area === 'en' ? 'ai' : 'cn', 'revobit', apiHostEnv, '', '', 'datapipe'])).getBasePath();
  const loginDomain = hostParams.getModuleBasePath('user');
  // const subModule = !isSubmodule ? '' : hostAppName;
  const userCenterDomain = isRemote ? loginDomain
    : `${a.hostname}${staticEnvs.VUE_APP_LOGIN_PORT === '80' ? '' : `:${staticEnvs.VUE_APP_LOGIN_PORT}`}`;
  return {
    VUE_APP_MODE: env,
    VUE_APP_API_DOMAIN: apiHostParams.getBasePath(),
    VUE_APP_LOGIN_DOMAIN: userCenterDomain,
    VUE_APP_USER_CENTER_DOMAIN: userCenterDomain,
    VUE_APP_MOBILE_DOMAIN: getMobileDomain(hostParams),
    VUE_APP_HOSTNAME: hostname,
    VUE_APP_IS_REMOTE: isRemote,
    VUE_APP_COOPERATION: hostParams.cooperation,
    VUE_APP_AREA: hostParams.area,
    // VUE_APP_SUBMODULE: subModule,
    VUE_APP_HOST_PARAMS: hostParams,
    VUE_APP_MODULE_NAME: hostParams.moduleName,
    VUE_APP_IS_SUBMODULE: hostParams.submodule,
    VUE_APP_DATAPIPE_DOMAIN: datapipeDomain,
  };
}

export function getEnvs() {
  const envs = _getEnvs();
  const {
    VUE_APP_HOSTNAME: locationHostname,
    VUE_APP_IS_REMOTE: isRemote,
    VUE_APP_MODE: env,
    VUE_APP_DATAPIPE_DOMAIN: datapipeDomain,
  } = envs;
  let cookieDomain: string;
  if (isRemote) {
    cookieDomain = locationHostname.split('.').slice(-2).join('.');
  } else {
    cookieDomain = locationHostname;
  }
  let wxAppId = '';
  switch (envs.VUE_APP_MODE) {
    case 'staging':
      wxAppId = 'wx18b60a3562272586';
      break;
    case 'development':
      wxAppId = 'wx18b60a3562272586';
      break;
    case 'uat':
      wxAppId = 'wx18b60a3562272586';
      break;
    case 'poc':
      wxAppId = 'wx18b60a3562272586';
      break;
    default:
      wxAppId = 'wx18b60a3562272586';
  }

  return {
    ...staticEnvs,
    VUE_APP_LOGIN_URL: `//${envs.VUE_APP_LOGIN_DOMAIN}/login`,
    VUE_APP_COOKIE_DOMAIN: cookieDomain,
    VUE_APP_JSAPI_AUTH: wxAppId,
    VUE_APP_LOG_API_DOMAIN: `//${envs.VUE_APP_API_DOMAIN}`,
    VUE_APP_DATAPIPE_BASEPATH: `//${datapipeDomain}/datapipe/`,
    VUE_APP_METADATA_BASEPATH: `//${datapipeDomain}/metaData/`,
    VUE_APP_HELP_CENTER_URL: `//sdtc-public-picture.4dshoetech.com/help-center/${env === 'sit' ? 'development' : env}`,
    VUE_APP_POLICY_URL: `//sdtc-public-picture.4dshoetech.com/policy/${env === 'sit' ? 'development' : env}`,
    VUE_APP_IDEALAB_HOST_NAME: getIdealabHostUrl(envs.VUE_APP_HOST_PARAMS),
    VUE_APP_MS_TEAMS_APP_ID: ({
      staging: '182fae7c-e625-4a5d-992e-5522c7b70d25',
      uat: 'f111ec49-6d44-4d52-9d43-80c78a6dca0e',
      production: '01abd092-a8f1-4bfb-a1ea-9bc103316abd',
    } as { [mode: string]: string })[env],
    VUE_APP_CUSTOMER_SERVICE_EMAIL: envs.VUE_APP_HOST_PARAMS.area === 'en' ? 'customer@revobit.ai' : 'customersuccess@4dstc.com',
    ...envs,
  };
}

export const envs = getEnvs();
// console.log(envs);

export default envs;

// if (!envs.VUE_APP_IS_REMOTE) {
//   console.table([
//     // 以下这些domain应该也要合法
//     'local.4dshoetech.com',
//     'dev1.4dshoetech.local',
//     'dev.4dshoetech.local',
//     'sit.4dshoetech.local',
//     'uat.4dshoetech.com',
//     'opt.dev.4dshoetech.local',
//     'art.4dshoetech.com',
//     // opt不再兼容
//     // 'opt.4dshoetech.com',
//     'cn.4dshoetech.com',
//     'en.4dshoetech.com',
//     'optuat.4dshoetech.com',
//     // cnuat不再兼容
//     'cnuat.4dshoetech.com',
//     'anta.4dshoetech.com',
//     // anta不再兼容
//     // 'antauat.4dshoetech.com',
//     'anta.dev.4dshoetech.local/material-admin',
//     'anta.dev.4dshoetech.local',
//     'anta.dev.4dshoetech.local/login',
//     'anta.dev.4dshoetech.local/profile/info',
//     'dev.4dshoetech.local/profile/info',
//     'dev.4dshoetech.local/login',
//     'anta.4dshoetech.com/material-admin',
//     'sit.4dshoetech.local/test',
//     'sit1.4dshoetech.local/test',
//     'anta.dev.4dshoetech.local/test/error',
//     'callback-sit.4dshoetech.com',
//     'sit.4dshoetech.local/srm',
//     'art.4dshoetech.com/srm',
//     'art.sit.4dstc.com',
//     'art.dev.4dstc.com',
//     'revofim.co',
//     'dev.revofim.co',
//     'revofim.co/srm',
//     'trend.revofim.co',
//     'trend.revofim.ai',
//     'trend-sit.revofim.co',
//     'user.revofim.co',
//     'user-sit.revofim.co',
//     'clothes-image.revofim.co',
//     'clothes-image-sit.revofim.co',
//     'bi.revofim.co',
//     'revofim.ai',
//   ].map((path) => {
//     const params = new FimHostParams(path);
//     const resultArr = params.getBasePathAndPaths();
//     let result: string;
//     const loginDomain = params.getModuleBasePath('user');
//     const apiDomainHttp = params.getMainModuleBasePath();
//     // const mobileDomain = getMobileDomain(params);
//     if (resultArr.length > 1) {
//       result = `${resultArr[0]}/${resultArr[1]}`;
//     } else {
//       [result] = resultArr;
//     }
//     return {
//       pass: result === path,
//       path,
//       // params,
//       // result,
//       loginDomain,
//       apiDomainHttp,
//       // mobileDomain,
//     };
//   }));
// }



// import envs from '@/utils/envs';
// import axios from '@/utils/axios';
// import SystemUnderMaintenance from '@/views/components/SystemUnderMaintenance.vue';
import LoginFooter from './LoginFooter.vue';
import Logo from './Logo.vue';
import { fetchAvailableAnnouncement, AvailableAnnouncement } from '@/api/announcement';
import { getAppId } from '@/settings';
import AnnouncementSlider from './AnnouncementSlider.vue';
// async function showMaintenance(maintenance: AvailableAnnouncement) {
// const end = maintenance.end ?? maintenance.to;
// const maintenanceStorage = window.localStorage.getItem('maintenanceReaded') ? +window.localStorage.getItem('maintenanceReaded') : 0;
// if (maintenanceStorage < maintenance.start && Date.now() < end) {
//   await this.$injectDialog({
//     is: SystemUnderMaintenance,
//     props: maintenance,
//   }, {
//     group: 'unclosable',
//   });
//   window.localStorage.setItem('maintenanceReaded', `${maintenance.start}`);
// }
// }
export default {
  data() {
    return {
      url: '',
      annoucement: null as AvailableAnnouncement | null,
    };
  },
  components: {
    LoginFooter,
    Logo,
    AnnouncementSlider,
  },
  async mounted() {
    try {
      const appId = getAppId();
      this.annoucement = (await fetchAvailableAnnouncement(appId));
      // maintenance = null;
      // maintenance = (await axios.get(`${envs.VUE_APP_PUBLIC_PATH}/${envs.VUE_APP_MODE}/maintenance.json`)).data;
    } catch { }
    // if (this.annoucement) {
    //   this.$act(this.showMaintenance, [maintenance]);
    // }
  },
  methods: {
    // showMaintenance,
  },
};

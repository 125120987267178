import { i18n } from '@/i18n';

const accountRole = (roleType: number) => {
  const roles = [
    i18n.t('public.accountRole.master_account'),
    i18n.t('public.accountRole.member_account'),
    i18n.t('public.accountRole.guest_account'),
  ];

  return roles[roleType - 1] || 'Unmatch Type';
};

export default accountRole;

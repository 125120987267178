import * as MsTeams from '@microsoft/teams-js';
import AbortError from '@4dst-saas/public-utils/dist/abort-error';
import { Query } from '@4dst-saas/public-utils/dist/typings/request';
import { stringifyUrl } from 'query-string';
import { ref } from 'vue';
import envs from '../envs';
import Host from '.';

const ua = navigator.userAgent;
export enum TeamsClientType {
  DESKTOP = 1,
  WEB
}
export function getTeamsClientType() {
  if (ua.includes(' Teams/')) {
    return TeamsClientType.DESKTOP;
  }
  const { ancestorOrigins } = globalThis.location;
  if (ancestorOrigins) {
    return Array.from(ancestorOrigins).some(item => item.includes('teams.microsoft')) ? TeamsClientType.WEB : undefined;
  }
  return window.name === 'embedded-page-container' || window.name === 'extension-tab-frame' ? TeamsClientType.WEB : undefined;
}
export function isInMsTeams() {
  return getTeamsClientType() !== undefined;
}


interface StringifyUrlOptions { url: string, query: Query }
function getContext(msTeams: typeof MsTeams) {
  return new Promise<MsTeams.Context>(_resolve => {
    msTeams.getContext(context => {
      console.log(context);
      _resolve(context);
    });
  });
}


export class TeamsHost extends Host {
  static instance: TeamsHost;
  private initiallation!: Promise<typeof MsTeams>;
  #theme = ref<string>();
  get theme() {
    return this.#theme.value;
  }
  #cacheContext?: Promise<MsTeams.Context>;
  async getMsTeams() {
    return this.initiallation;
  }
  private async _init() {
    console.log(this);
    const msTeams = await import('@microsoft/teams-js');
    await new Promise<typeof MsTeams>(resolve => {
      msTeams.initialize(() => {
        resolve(msTeams);
      });
    });
    this.#cacheContext ??= getContext(msTeams);
    const context = await this.#cacheContext;
    this.#theme.value = context.theme;
    // msTeams.registerOnThemeChangeHandler((theme) => {
    //   console.log(theme);
    //   this.#theme.value = theme;
    // });
    return msTeams;
  }
  async getTabInstances(tabInstanceParameters?: MsTeams.TabInstanceParameters | undefined): Promise<MsTeams.TabInstance[]> {
    const msTeams = await this.initiallation;
    return new Promise((resolve, reject) => {
      msTeams.getTabInstances((tabInfo) => {
        const { teamTabs } = tabInfo;
        if (!teamTabs || !teamTabs.length) {
          reject(new Error('No team tabs found'));
          return;
        }
        resolve(teamTabs);
      }, tabInstanceParameters);
    });
  }
  async getContext() {
    const msTeams = await this.initiallation;
    this.#cacheContext ??= getContext(msTeams);
    return this.#cacheContext;
  }
  async setSettings(settings: MsTeams.settings.Settings) {
    const msTeams = await this.initiallation;
    msTeams.settings.setSettings(settings);
  }
  async setSettingsValidatityState(state: boolean) {
    const msTeams = await this.initiallation;
    msTeams.settings.setValidityState(state);
  }
  async untillSaveSettingsRegister(settings?: MsTeams.settings.Settings): Promise<MsTeams.settings.SaveEvent> {
    const msTeams = await this.initiallation;
    return new Promise<MsTeams.settings.SaveEvent>((resolve) => {
      msTeams.settings.registerOnSaveHandler((evt) => {
        if (settings) {
          this.setSettings(settings);
        }
        resolve(evt);
      });
    });
  }
  async selectPeople(peoplePickerInputs?: MsTeams.people.PeoplePickerInputs) {
    const msTeams = await this.initiallation;
    return new Promise<MsTeams.people.PeoplePickerResult[]>((resolve, reject) => {
      msTeams.people.selectPeople((error, people) => {
        if (error) {
          if (error.errorCode === msTeams.ErrorCode.USER_ABORT) {
            reject(new AbortError());
          }
          reject(error);
        } else {
          resolve(people);
        }
      }, peoplePickerInputs);
    });
  }
  async shareDeepLink(deepLinkParameters: MsTeams.DeepLinkParameters) {
    const msTeams = await this.initiallation;
    msTeams.shareDeepLink(deepLinkParameters);
  }
  async shareUrlDeepLink(url: string | StringifyUrlOptions, entityLabel: string) {
    const _url = typeof url === 'string' ? url : stringifyUrl(url);
    const a = document.createElement('a');
    a.href = _url;
    const subEntityId = `${a.pathname}${a.search}`;
    this.shareDeepLink({
      subEntityId,
      subEntityLabel: entityLabel,
      subEntityWebUrl: _url,
    });
  }
  async generateDeeplink(deepLinkParameters: MsTeams.DeepLinkParameters): Promise<string> {
    const context = await this.getContext();
    const content = {
      subEntityId: deepLinkParameters.subEntityId,
      ...context.channelId ? { channelId: context.channelId } : undefined,
      ...context.chatId ? { chatId: context.chatId, contextType: 'chat' } : undefined,
    };
    const webUrlQuery = deepLinkParameters.subEntityWebUrl ? `webUrl=${encodeURIComponent(deepLinkParameters.subEntityWebUrl)}&` : '';
    const appId = envs.VUE_APP_MS_TEAMS_APP_ID;
    // eslint-disable-next-line max-len
    return `https://teams.microsoft.com/l/entity/${appId}/${context.entityId}?${webUrlQuery}label=${encodeURIComponent(deepLinkParameters.subEntityLabel)}&context=${encodeURIComponent(JSON.stringify(content))}`;
  }
  async generateUrlDeepLink(url: string | StringifyUrlOptions, entityLabel: string): Promise<string> {
    const _url = typeof url === 'string' ? url : stringifyUrl(url);
    const a = document.createElement('a');
    a.href = _url;
    const subEntityId = `${a.pathname}${a.search}`;
    return this.generateDeeplink({
      subEntityId,
      subEntityLabel: entityLabel,
      subEntityWebUrl: _url,
    });
  }
  async init() {
    this.initiallation = this.initiallation ?? this._init();
    await this.initiallation;
  }
  static async detect() {
    return isInMsTeams();
  }
}
export default TeamsHost;

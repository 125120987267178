import { getLang } from '@/i18n';
import { parseUrl } from 'query-string';
import envs, { getHostParamsFromPath } from '../envs';
/* eslint-disable class-methods-use-this */

import _History, { RawHistoryLocation } from './history';

type HistoryLocation = {
  path?: string
  query?: Record<string, string | null | (null | string)[] | undefined>
  hash?: string
  domain?: string,
};

export type ComputedHistoryLocation = {
  isSameDomain: boolean,
  domain: string,
  fullPath: string,
  fullPathWithDomain: string,
  useRouterToNavigate: boolean,
} & Required<Omit<HistoryLocation, 'name'>> & { name?: string };

class History extends _History {
  parse(_to: RawHistoryLocation) {
    const to = super.parse(_to);
    if (!envs.VUE_APP_IS_REMOTE) {
      return to;
    }

    const urlToken = parseUrl(globalThis.location.href).query.token;
    const urlCompany = parseUrl(globalThis.location.href).query._companyId;
    if (urlToken) {
      to.query.token = urlToken;
    }
    if (urlCompany) {
      to.query._companyId = urlCompany;
    }

    const a = document.createElement('a');
    a.href = to.fullPathWithDomain;
    const { hostname, pathname } = a;
    const [,,,,, moduleName] = getHostParamsFromPath(`${hostname}${pathname}`);
    const queryLang = to.query.lang;
    const sameLang = !queryLang || getLang() === queryLang;
    to.useRouterToNavigate = sameLang && to.isSameDomain && envs.VUE_APP_MODULE_NAME === moduleName;
    return to;
  }
}


export const instance = new History();

export default instance;
export { instance as history };

<template>
  <div class="flex">
    <!-- <div class="flex-start picture">
      <img src="@/assets/img/opinion/opinion@2x.png" alt="插画" width="120" height="120">
    </div> -->
    <div class="flex-end">
      <div class="title">
        <p>{{ $t("opinion.successResult.title") }}</p>
      </div>
      <div class="desc">
        <p class="desc-content">{{ $t("opinion.successResult.desc") }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  dialogify: {
    props: {
      width: '500px',
    },
  },
};
</script>
<style lang="scss" scoped>
.picture {
  padding-right: 50px;
}
.title {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 900;
  color: #333;
  line-height: 24px;
}
.desc {
  padding-right: 18px;
  font-size: 14px;
  font-weight: 400;
  color: #666;
  line-height: 20px;
  .desc-content {
    word-break: break-word;
  }
}
</style>

import { i18n } from '@/i18n';
import getByteLength from '@4dst-saas/public-utils/dist/get-byte-length';

type WithLength = { length: number };
type Options = { min?: number, max?: number, message?: string };

export function strLength(withLength: WithLength, { min = 0, max = Infinity, message }: Options) {
  const { length } = withLength;
  const below = length < min;
  const above = length > max;

  // max 和 min 同时校验时
  if (max && min && (below || above)) {
    throw new Error(message ?? i18n.t('validator.char_len_between', { min, max }));
  }
  // 只校验 min 时
  if (below) {
    throw new Error(message ?? i18n.t('validator.char_len_at_least', { min }));
  }
  // 只校验 max 时
  if (above) {
    throw new Error(message ?? i18n.t('validator.char_len_not_exceed', { max }));
  }
}

export function strBytes(str: string, { min = 0, max = Infinity, message }: Options) {
  const length = getByteLength(str);
  const below = length < min;
  const above = length > max;
  // max 和 min 同时校验时
  if (max && min && (below || above)) {
    throw new Error(message ?? i18n.t('validator.byte_len_between', { min, max }));
  }
  // 只校验 min 时
  if (below) {
    throw new Error(message ?? i18n.t('validator.byte_len_at_least', { min }));
  }
  // 只校验 max 时
  if (above) {
    throw new Error(message ?? i18n.t('validator.byte_len_not_exceed', { max }));
  }
}

export default strLength;

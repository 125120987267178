import { render, staticRenderFns } from "./BlockError.vue?vue&type=template&id=51ed5a93&scoped=true&"
import script from "./BlockError.vue?vue&type=script&lang=ts&"
export * from "./BlockError.vue?vue&type=script&lang=ts&"
import style0 from "./BlockError.vue?vue&type=style&index=0&id=51ed5a93&prod&lang=scss&scoped=true&"
import style1 from "./BlockError.vue?vue&type=style&index=1&id=51ed5a93&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51ed5a93",
  null
  
)

export default component.exports
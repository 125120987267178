import modelOf from './utils/model-of';

export type Key2ValueType = {
  /** 协作空间文件大小比例 */
  // fileListItemSize: number,
  /** 协作空间文件数量增量，-8 ~ +8 */
  fileListItemIncrement: number
  workspaceItemIncrement: number
  fileListViewType: string,
  fileListShownInfo: boolean,
  fileListSortOrderBy: string,
  fileListSortDesc: boolean,
  fileListTableView: boolean,
  modelDetailViewType: string,
  sidebarCollaped: boolean,
  [otherKey: string]: string | number | boolean
};

export class Setting<Key extends keyof Key2ValueType> {
  key!: Key;
  value?: Key2ValueType[Key];
}

export const SettingOrm = modelOf(Setting, {
  primaryKey: 'key',
  entity: 'settings',
  fields() {
    return {
      key: this.attr(''),
      value: this.attr(''),
    };
  },
  get<K extends keyof Key2ValueType>(key: K) {
    return SettingOrm.find(key) as Setting<K>;
  },
});

export default SettingOrm;

<template>
  <div class="login-footer" :class="{ 'login-footer-anta': cooperationName === 'anta' }">
    <!-- 各种友情链接 -->
    <ul class="lint-list">
      <li v-for="link in links" :key="link.id" class="link-item">
        <router-link v-if="link.route" :to="{ name: link.route, query }" :target="link.target">{{ link.title }}
        </router-link>
        <span v-else @click="link.click()">{{ link.title }}</span>
      </li>
      <div>Copyright <span class="copyright-icon">©</span>
        <a :href="revobitLink.href" target="blank">
          {{ revobitLink.text }}
        </a>
      </div>
      <div>
        <!-- <span class="right-reserved">{{ $t("login.index.all_rights_reserved") }}</span> -->
        <a href="https://beian.miit.gov.cn/" target="blank" class="on-record">粤ICP备17078055号</a>
      </div>
    </ul>

    <!-- 网站版权说明 -->
    <!-- <p class="copyright">
      Copyright
      <span class="copyright-icon">©</span>
      <a :href="revobitLink.href" target="blank">{{ revobitLink.text }},</a>
      <span class="right-reserved">{{ $t("login.index.all_rights_reserved") }}</span>
      <span class="on-record">粤ICP备17078055号</span>
    </p> -->
  </div>
</template>

<script>
import { i18n } from '@/i18n';
import envs from '@/utils/envs';
import LicenseAgreement from '@/views/policy/LicenseAgreement.vue';

export default {
  data() {
    const links = [
      { title: i18n.t('login.index.collaborate'), route: 'Feedback' },

    ];
    if (envs.VUE_APP_HOST_PARAMS[4] === 'us') {
      links.push(
        { title: i18n.t('login.index.privacy_policy'), route: 'PolicyPrivacy', target: '_blank' },
        { title: i18n.t('login.index.license_agreement'), route: 'PolicyLicense', target: '_blank' },
      );
    } else {
      links.push({
        title: i18n.t('login.index.revobit_user_agreements'),
        click: this.openPolicyLicense,
      });
    }
    const query = {
      a: this.$route.query.a,
    };
    return {
      links,
      query,
    };
  },
  computed: {
    revobitLink() {
      return {
        text: envs.VUE_APP_REVOBIT_HOST,
        href: `//${envs.VUE_APP_REVOBIT_HOST}/`,
      };
    },
    cooperationName() {
      return envs.VUE_APP_COOPERATION;
    },
  },
  methods: {
    async openPolicyLicense() {
      await this.$injectDialog({
        is: LicenseAgreement,
      }, {
        props: {
          width: '1000px',
          height: '800px',
          flex: 'column',
          top: 'auto',
          bottom: 'auto',
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-footer {
  font-size: 12px;
  text-align: center;
  color: #999999;


  .copyright {
    font-size: 12px;
    text-align: center;

    // .copyright-icon {
    //   display: inline-block;
    //   padding-right: 5px;
    // }

    .right-reserved {
      display: inline-block;
      padding-left: 5px;
    }

    .on-record {
      display: block;
      margin-top: 5px;
    }
  }
}

.lint-list {
  display: flex;
  justify-content: center;

  >div,
  >li {
    margin-left: 12px;
  }

  .link-item {
    cursor: pointer;
  }
}

// .login-footer-anta {
//   color: rgba(255, 255, 255, 0.6);

//   .link-item::after {
//     background-color: rgba(255, 255, 255, 0.6);
//   }
// }
</style>

<template>
  <div id="app" :class="['flex-column', isFullscreen ? 'full-screen' : '']">

    <router-view> </router-view>
  </div>
</template>
<style lang="scss" scoped>
.content-main {
  min-height: 100vh;
}

.watermark {
  position: fixed;
  pointer-events: none;
  z-index: 9999999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
<script lang="jsx">
import screenfull from 'screenfull';
import { getContextUser, getContextCompany } from '@/loaders/context';
import envs from '@/utils/envs';
import store from '@/store';
import LayoutBase from './LayoutBase';

export default {
  name: 'apptop',
  extends: LayoutBase,
  data() {
    try {
      screenfull.on('change', () => {
        this.isFullscreen = screenfull.isFullscreen;
      });
    } catch (e) {
      console.log('苹果手机不支持');
      console.log(e);
    }
    return {
      menu: [],
      isFullscreen: screenfull.isFullscreen,
    };
  },
  computed: {
    isLogin() {
      return store.state.UserStore.isLogin;
    },
    /** @returns {any} */
    user() {
      return getContextUser();
    },
    company() {
      return getContextCompany();
    },
  },
};
</script>

<style lang="scss">
#app {
  min-width: $container-min-width;
  min-height: 100vh;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.margin-top-100 {
  position: relative;
  bottom: 30%;
}
</style>

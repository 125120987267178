import envs from '@/utils/envs';
import { setLang as elSetLang } from '@/components/element';
import axios from '@/utils/axios';
import first from '@4dst-saas/public-utils/dist/first';
import { parseUrl } from 'query-string';
import keys from '@4dst-saas/public-utils/dist/keys';
import I18nPlugin from '@/utils/vue/i18n';
import getCurrentInstance from '@/utils/composables/get-current-public-instance';
import { TOptions } from '@4dst-saas/public-utils/dist/i18n';
import Cookies from 'js-cookie';
import { getCookieConfig } from '@/utils/get-cookie-config';
// @ts-ignore
import cn from './langs/cn.json';
// @ts-ignore
import en from './langs/en.json';


type Values<O> = O extends { [k in any]: infer T } ? T : never;

const langNumMapLang = {
  1: 'en',
  2: 'cn',
} as const;

export type LanguageNum = keyof typeof langNumMapLang;
export type Language = Values<typeof langNumMapLang>;

interface LangConfig {
  name: string
  num: LanguageNum
  lang: Language
  icon: string
}

export const langConfigList: LangConfig[] = [
  {
    name: 'English(英)',
    num: 1,
    lang: 'en',
    icon: require('@/assets/img/lang/icon_en.png'),
  },
  {
    name: '中文(CN)',
    num: 2,
    lang: 'cn',
    icon: require('@/assets/img/lang/icon_cn.png'),
  },
];


export function langNumToLang(langNum: keyof typeof langNumMapLang) {
  return langNumMapLang[langNum];
}

export function getSystemLang() {
  return (navigator.language.substring(0, 2) === 'zh' ? 'cn' as const : 'en' as const);
}

export function getLang(): Language {
  if (envs.VUE_APP_COOPERATION.includes('anta')) return 'cn';
  const shareLang = first(parseUrl(window.location.href).query.shareLang);
  const queryLang = first(parseUrl(window.location.href).query.language);
  // @ts-ignore
  const _queryLang = Object.values(langNumMapLang).includes(queryLang) ? (queryLang as Language) : undefined;
  // @ts-ignore
  const _shareLang = Object.values(langNumMapLang).includes(shareLang) ? (shareLang as Language) : undefined;
  return Cookies.get('lang') as Language
    ?? localStorage.getItem('lang') as Language
    ?? _shareLang
    ?? _queryLang
    ?? getSystemLang();
}

if (localStorage.getItem('lang') === 'unknown') {
  localStorage.removeItem('lang');
}
// eslint-disable-next-line import/no-mutable-exports
export let currentLang = getLang();

export { currentLang as defaultLang };
export const plugin = new I18nPlugin({
  lang: currentLang,
  resources: { cn, en },
  loadResource(lang) {
    return import(`./langs/${lang}.json`);
  },
});

export const langToLangNum = (
  lang: Language | 'unknown',
) => {
  const _lang = (lang === 'unknown' ? currentLang : lang) ?? currentLang;
  return keys(langNumMapLang).find(e => langNumMapLang[e] === _lang);
};

/** @return 是否建议刷新 */
export async function setLang(targetLang: Language) {
  const currentStorageLang = Cookies.get('lang') ?? localStorage.getItem('lang') ?? '';
  if (currentStorageLang !== targetLang) {
    Cookies.set('lang', targetLang, getCookieConfig());
  }

  if (currentLang !== targetLang) {
    console.log(targetLang, '////aaaaaa');
    elSetLang(targetLang);
    await I18nPlugin.changeLanguage(targetLang);
    const value = langToLangNum(targetLang);
    if (value) {
      try {
        await axios({
          url: '/msgcenter/config/update',
          method: 'post',
          data: {
            type: 4,
            value,
          },
        });
      } catch {
      }
    }
    currentLang = targetLang;
    return true;
  }
  return false;
}

/** @deprecated */
const i18n = {
  t(path: string, defaultValueOrOptions: TOptions | string = {}) {
    const instance = getCurrentInstance();
    if (!instance) return plugin.t(path, defaultValueOrOptions as unknown as any);
    return instance.$t(path, defaultValueOrOptions);
  },
  get language() {
    return plugin.language;
  },
};

export {
  i18n,
  /** @deprecated */
  i18n as i18next,
};
export default plugin;

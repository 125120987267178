<template>
  <div class="header-container">
    <div class="header">
      <!-- Logo -->
      <div class="logo-wrapper">
        <div
          v-if="!isDark"
          class="header-logo"
          :style="{ backgroundImage: `url(${$customize($customizeNames.userProfileLogo, 'url') || defaultLogo})` }"
          @click="gotoIndex" />
        <div
          v-else
          class="header-logo"
          :style="{
            backgroundImage: `url(${$customize($customizeNames.userProfileLogo, 'darkUrl') || defaultDarkLogo})`,
          }"
          @click="gotoIndex" />
        <el-dropdown
          v-if="company && !$customize($customizeNames.hiddenSwitchCompany) && $route.path !== '/profile/authority'"
          class="company-selector"
          @command="handleChangeCompany">
          <span class="el-dropdown-link cursor-pointer">{{ company.name }}<i
              class="el-icon-arrow-down el-icon--right"></i> </span>
          <el-dropdown-menu slot="dropdown" class="company-selector-popover scrollable-y">
            <el-dropdown-item
              v-for="(company, index) in user.companies"
              :key="index + '-' + company.id"
              :command="company">{{ company.name }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="header-info">

        <!-- 虚拟样品入口 -->
        <!-- <el-tooltip :content="$t('public.header.iconTip.order_manage')" v-if="user && user.tradeFairWorkspace">
          <i class="iconfont iconkehubianhao element-icon text-link" @click="$router.push({ name: 'TaskOrder' })" />
        </el-tooltip> -->

        <!-- 群聊 -->
        <!-- <el-tooltip
          v-if="!isAntaDomain"
          slot="tools"
          :content="$t('workspace.im')"
          placement="top"
          :popper-class="!isAntaDomain ? 'tool-icon-tooltip-margin' : ''"
          :visible-arrow="isAntaDomain"
          style="position: relative"
        >
          <i class="iconfont iconim-24 element-icon" :class="{ badge: !!imUnread }" @click="$act(initIm)" />
        </el-tooltip> -->

        <!-- 消息中心 -->
        <!-- <el-tooltip v-if="user" :content="$t('public.header.iconTip.notification')" style="position: relative">
          <i
            @click="showInfoCenter"
            class="iconfont iconxiaoxi-24 element-icon"
            :class="{ badge: readRecord && readRecord.unreadTotal > 0 }"
          >
          </i>
        </el-tooltip> -->

        <UserDropdown :user="this.user" @reset-lang="resetLang" />
      </div>
    </div>
  </div>
</template>

<script>
import { i18n } from '@/i18n';
import { ref, computed, onMounted, onUnmounted, getCurrentInstance } from 'vue'
// import InfoCenter from '@/views/notification';
// import SideSlider from '@/components/SideSlider';
import { getGuideStatus } from '@/api/guide';
import { getMyUploadIds } from '@/api/catalogue';
import { changeCompany, getContextUser } from '../../loaders/context';
import { langMapLangNum } from '@/router/utils/set-lang';
import * as Login from '@/packages/Login';
import { getCurrentCompany } from '../../loaders/company';
// import { getUnread } from '../../loaders/im';
import useBusinessTypes from '@/utils/composables/use-business-types'
import envs from '../../utils/envs';
import axios from '@/utils/axios';
import { updateSystemPermissions } from '@/loaders/permission';
import UserDropdown from './UserDropdown.vue'
// import { isMac } from '@/utils/ua'
import useTheme from '@/utils/composables/use-theme';
// import companyUtils from '@/utils/company';
// import eventBus from '@/views/model/detail/renderer/eventBus.js'
/** @typedef {import('@/models/User').User} User */
// const antaLogo = require('@/assets/img/header/logo-anta.png');
// const ideationLogo = require('@/assets/img/header/revofim.png');
// const ideationLogoDark = require('@/assets/img/header/revofim-dark.png');

export function useUnreadStatus() {

  const readRecord = ref({})
  // 未读消息统计
  const notRead = async () => {
    const { data } = await axios({
      method: 'get',
      url: '/msgcenter/message/detail',
    });
    readRecord.value = data;
  }

  const timer = ref();

  onMounted(async () => {
    await notRead();
    if (readRecord.value.unreadTotal === 0) {
      timer.value = setInterval(() => { notRead(); }, 60000);
    }
  })


  onUnmounted(() => {
    clearInterval(timer.value);
    timer.value = undefined;
  })

  return { readRecord }
}

export default {
  name: 'Header',
  components: {
    UserDropdown
  },
  setup() {
    const login = Login.useLogin();
    const { theme: themeType, isDark } = useTheme()
    const currentInstance = getCurrentInstance()?.proxy
    // const logoType = computed(() => {
    //   return envs.VUE_APP_COOPERATION
    // });
    // const { readRecord } = useUnreadStatus()
    const initIm = async () => {
      currentInstance?.$root?.$refs?.im?.maximizeIm();
    };

    const gotoIndex = () => {
      const result = login.gotoNext();
      if (result._tag === 'goto_platform_index') {
        window.location.href = result.url;
      } else {
        currentInstance?.$router.push('/');
      }
    };
    return {
      // logoType,
      businessTypes: useBusinessTypes(),
      isDark,
      themeType,
      // readRecord,
      // getUnread,
      initIm,
      gotoIndex,
    }
  },
  filters: {
    type(value) {
      const types = ['Brands', 'Material Supplier', 'Designer', 'Subaccount'];
      return types[value];
    },
  },
  data() {
    return {
      imUnread: false,
      rect: '',
      body: '',
      which: 1,
      /** @type {any} */
      // readRecord: {},
      infoCenterSign: false,
      timer: undefined, //消息中心未读数获取计时器
      uploadMenu: [
        {
          text: i18n.t('public.header.dropdownMenu.upload_design'),
          icon: 'icon-cube',
          route: {
            name: 'UploadModel',
            query: { parentFolderId: this.user?.shoeUploadCId || 0 },
          },
        },
        {
          text: i18n.t('public.header.dropdownMenu.upload_material'),
          icon: 'icon-material',
          route: {
            name: 'MaterialUpload',
            query: { parentFolderId: this.user?.materialUploadCId || 0 }
          }
        },
      ],
      helpMenu: [
        { id: 0, text: i18n.t('public.header.dropdownMenu.user_tutorial_menu'), icon: 'icon-file-list-video', param: 1, },
        { id: 1, text: i18n.t('public.header.dropdownMenu.maneuvering_3d_models'), icon: 'icon-renderer-video', param: 2, },
        { id: 2, text: i18n.t('public.header.dropdownMenu.uploading_files'), icon: 'icon-upload-video', param: 3, },
      ],
      tmpLang: 0,
      defaultLogo: require('@/assets/img/header/revofim.png'),
      defaultDarkLogo: require('@/assets/img/header/revofim-dark.png'),
    }
  },
  computed: {
    /** @return {boolean} */
    isIndependentDesigner() {
      return this.user.mail === 'designer_helper@163.com';
    },
    /** @return { User } */
    user() {
      return getContextUser();
    },
    /** @return { boolean } */
    isCloth() {
      return this.user && this.user.businessType === 2;
    },
    /** @return { string } */
    company() {
      return getCurrentCompany();
    },
    /** @return { string }*/
    cooperation() {
      return envs.VUE_APP_COOPERATION;
    },
    /** @return { string } */
    width() {
      return this.cooperation.includes('anta') ? '164px' : '111px';
      // return (this.user && this.user.businessType === 1) || this.cooperation.includes('anta')
      //   ? '164px' : '124px';
    },
    /** @return { string } */
    height() {
      return this.cooperation.includes('anta') ? '16px' : '24px';
      // return (this.user && this.user.businessType === 1) || this.cooperation.includes('anta')
      //   ? '164px' : '124px';
    },

    /** @return { string } */
    userBusinessType() {
      return this.businessTypes ?
        this.businessTypes.find(e => e.value === (this.company && this.company.type))?.[i18n.language === 'en' ? 'usName' : 'name']
        || 'loading'
        : 'loading'
    },
    /** @return {boolean} */
    isMaterialSupplier() {
      return this.company && this.company.type === 3;
    },
    /** @return {string} */
    currentLangName() {
      const currentLang = langConfigList.find(e => e.lang === i18n.language);
      return currentLang ? currentLang.name : '';
    },
    /** @return {boolean} */
    showSwitchStyle() {
      return ['staging', 'development', 'uat'].includes(envs.VUE_APP_MODE)
    }
  },
  destroyed() {
    // this._msgCenterDialogController.close();
  },
  async mounted() {
    const _this = this
    // eventBus.$on('has-unread', (v) => {
    //   _this.imUnread = v;
    // })
    // this._msgCenterDialogController = this.$Dialog.dialogify({
    //   is: InfoCenter,
    //   on: {
    //     'update-has-new-message': readRecord => {
    //       this.readRecord = readRecord;
    //     },
    //   }
    // }, {
    //   is: SideSlider
    // })

    this.tmpLang = langMapLangNum(i18n.language).toString();
    this.oldLang = this.tmpLang; // 用于取消确认时回退
  },
  methods: {

    handleChangeCompany(company) {
      if (company.isExpireTime) {
        this.$message.error(i18n.t('common.expired-tip'))
        return;
      }
      changeCompany(company.id);
    },
    // 消息中心
    // async showInfoCenter() {
    //   await this._msgCenterDialogController;
    // },
    // 获取引导是否已读状态
    async getGuideStatus() {
      const res = await getGuideStatus();
      // this.guideNotRead = !res.data.filter(it => it.type === 1)[0].status;
      return res.data.filter(it => it.type === 1)[0].status;
    },
    resetLang() {
      this.tmpLang = this.oldLang
    },
    async updateUploadMenu(uploadMenu) {
      const target = (await getMyUploadIds()).data
      uploadMenu[0].route.query.parentFolderId = target.shoeUploadCId
      uploadMenu[1].route.query.parentFolderId = target.materialUploadCId
    },
    async handleJumpToUpload(uploadMenu, route) {
      if (!route.query.parentFolderId) {
        await this.updateUploadMenu(uploadMenu)
      }
      this.$router.push(route)
    }
  },
};
</script>
<style lang="scss" scoped>
#{$dark-theme-selector} {
  .company-selector {
    color: #666;
  }

  .header {
    background-color: $--color-dark-bg-2;
    box-shadow: none;
    border-bottom: 1px solid $--color-dark-border;
  }

  .iconfont {
    color: #575b5f;
  }
}
</style>
<style lang="scss" scoped>
.header {
  z-index: 1;
  position: fixed;
  width: 100%;
  height: $header-height;
  padding: 0 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;

  &.anta {
    height: $header-height-anta;
  }
}

.company-selector-popover {
  max-height: 500px;
}

.logo-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}

.header-logo {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  margin-right: 20px;

  height: 18px;
  width: 80px;

  // background-image: url("~@/assets/img/header/revofim.png");
  @include in-dark {
    // background-image: url("~@/assets/img/header/revofim-dark.png");
  }
}

.header-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.element-icon {
  color: #9ca0a4;
  padding: 5px 5px;
  margin: 0 5px;
  font-size: 18px;
  cursor: pointer;
  transition: all linear 150ms;
}

.element-icon:hover {
  color: #1e95f7;
  transform: scale(1.1);
}

.header-info-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66px;
  height: 64px;
  border-left: 2px solid #f6f7fa;
  border-right: 2px solid #f6f7fa;
}

.badge::after {
  content: "";
  position: absolute;
  top: 3px;
  right: 4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #ff6678;
}

.mask-hole {
  position: fixed;
  transition: opacity 1s;
  border-color: rgba(0, 0, 0, 0.3);
  border-style: solid;
  top: 0;
  left: 0;
  box-sizing: content-box;
  height: 33px;
  width: 42px;
  z-index: 2000;
}

.mask-dialog {
  position: absolute;
  top: 79px;
  right: 87px;
  width: 297px;
  height: 138px;
  background: #2878ff;
  z-index: 2001;
  border-radius: 6px;
  box-shadow: 0px 4px 10px 0px rgba(45, 52, 65, 0.2);
}

.mask-dialog img {
  padding-top: 35px;
}

.mask-dialog p {
  color: #fff;
  text-align: center;
  padding-top: 12px;
}

.triangle {
  position: absolute;
  top: -35px;
  left: 81px;
  width: 0;
  height: 0;
  border-width: 18px;
  border-style: solid;
  border-color: transparent transparent #2878ff transparent;
}

.iconfont {
  outline: none;
}

.header-container {
  :fullscreen & {
    display: none;
  }

  height: $header-height;
  top: 0;
  z-index: 4;
}
</style>

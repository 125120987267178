/* eslint-disable class-methods-use-this */
import { F } from 'ts-toolbelt';

type PickTypeFields<T, K> = { [P in keyof T]: T[P] extends K ? P : never }[keyof T];
type Command<Klass extends typeof Host> = Exclude<
  PickTypeFields<InstanceType<Klass>, F.Function>, 'init'>;
/** 所有host的基类，ideation host（原qthost）没有继承，但implemented，后面会迁移过来 */
export abstract class Host {
  constructor() {
    this.init();
  }
  static instance: Host;
  static async exec<T extends typeof Host, Cmd extends Command<T>>(
    this: T, cmd: Cmd, args: Parameters<InstanceType<T>[Cmd]> = [] as Parameters<InstanceType<T>[Cmd]>,
  ): Promise<ReturnType<InstanceType<T>[Cmd]>> {
    // @ts-ignore
    this.instance ??= new this();
    await this.instance.init();
    return (this.instance as InstanceType<T>)[cmd](...args);
  }
  /** @abstract */
  static detect(): Promise<boolean> {
    throw new Error('detect function is not implemented');
  }
  abstract init (): Promise<void>;
  // abstract on(evtType: string, fn: F.Function): F.Function;
  // abstract off(evtType: string, fn: F.Function): void;
  // abstract once(evtType: string, fn: F.Function): Promise<unknown>;
}
export default Host;

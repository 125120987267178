import PlatformType from '@/loaders/platform-type';
import AppError from '@/utils/error';
import tokenUtils from '@/utils/token';
import { getAppId, isNeedInitPlatform } from '@/settings';
import axios from './axios';
import envs from '@/utils/envs';

interface CompanyPlatform {
  appId: PlatformType
  isLastLoginCompany: boolean
  isExpireTime: boolean
  isForbid: boolean
  isCompanyAdmin: boolean
  isInit: boolean
}
interface CompanyAndPlatforms {
  id: string
  name: string
  logo: string
  logoUrl: string
  isPersonalCom: boolean
  businessType: number
  platformInfoList: CompanyPlatform[]
}

export interface Company {
  id: string
  name: string
  logo?: string
  logoUrl?: string
  isPersonalCom: boolean
  isRedDragonflyCom: boolean
  businessType?: number
  isExpireTime?: boolean | undefined
  isForbid?: boolean | undefined
  isInit: boolean
}
/**
 * 根据用户token获取所在公司的信息
 * @returns
 */
export const getCompanyInfo = () => {
  return axios.get<Company>('authcenter/v1.0/tenant/detail');
};

/**
 * 根据Sdtc-Tenant-Id获取当前用户公司的套餐信息
 * url: http://yapi.corp.4dstc.com/project/31/interface/api/16238
 */
export const getCompanyPackages = () => {
  return axios.get('/authcenter/v1.0/tenant/package');
};

/**
 * 根据公司id获取公司名称
 * @param companyId 公司的id
 */
export const getCompanyNameById = (companyId: string) => {
  return axios({
    method: 'get',
    url: '/authcenter/company/get/name',
    params: { companyId },
  });
};

/**
 * 修改用户信息
 * @param data 公司修改信息对象
 */
export const updateCompanyInfo = (data: unknown) => {
  return axios({
    method: 'post',
    url: '/authcenter/company/update/info',
    data,
  });
};

/**
 * 修改用户所属公司信息
 * @param data 公司修改信息对象
 */
export const updateUserCompanyInfo = async (data: Partial<Company>) => {
  return (await axios({
    method: 'post',
    url: '/authcenter/v1.0/tenant/update',
    // url: '/authcenter/company/update/info/v2',
    data,
  })).data;
};

/**
 * 修改公司资质
 * @param data 修改资质对象
 */
export const updateCompanyCredentials = (data: unknown) => {
  return axios({
    method: 'post',
    url: '/authcenter/company/update/credentials',
    data,
  });
};

/**
 * 修改公司面料商城首页
 * @param data
 */
export const updateCompanyHome = (data: unknown) => {
  return axios({
    method: 'post',
    url: '/authcenter/company/update/home',
    data,
  });
};

/**
 * 获取公司面料商城首页
 * @param companyId 公司id
 */
export const getCompanyHome = (companyId: string) => {
  return axios({
    method: 'get',
    url: '/authcenter/company/home',
    params: { id: companyId },
  });
};

/**
 * 校验公司简称是否重复
 * @param abbreviation 公司的名称
 * @returns {Promise}
 */
export const checkExistentOfCompanyName = (abbreviation: string) => {
  return axios({
    method: 'get',
    url: '/authcenter/company/check/abbreviation',
    params: { abbreviation },
  });
};

export async function getAllUserCompanies(token = tokenUtils.get() ?? '', appId = getAppId()): Promise<Company[]> {
  // const redDragonflyCompanyId = (await axios.get<string>('/authcenter/ka/getRedDragonfly')).data;
  return (await axios.post<CompanyAndPlatforms[]>('/authcenter/v1.0/company/relate/all', {}, {
    headers: {
      token,
    },
  })).data.map(item => ({
    ...item,
    isExpireTime: undefined,
    isForbid: undefined,
    isCompanyAdmin: undefined,
    isInit: false,
    // 红蜻蜓
    isRedDragonflyCom: false,
    ...item.platformInfoList.find(p => p.appId === appId),
  }));
}

export function validCompany(company: Company) {
  if (company.isExpireTime) {
    throw new AppError({
      message: '这个公司过期',
      code: 'company_expire',
      data: {
        email: envs.VUE_APP_CUSTOMER_SERVICE_EMAIL,
      },
    });
  }
  if (company.isForbid) {
    throw new AppError({
      message: '这个公司被禁用',
      code: 'company_forbid',
      data: {
        email: envs.VUE_APP_CUSTOMER_SERVICE_EMAIL,
      },
    });
  }
}
export function validCompanyId(companyId: string, allCompanies: Company[]) {
  const contextCompany = allCompanies.find(_company => _company.id === companyId);
  if (!contextCompany) {
    throw new AppError({
      message: '你不在这个公司',
      code: 'your_are_not_in_this_company',
    });
  }
  validCompany(contextCompany);
  return contextCompany;
}


export async function getUserCompaniesInfo({
  fallbackCompany = true,
  companyId,
}: {
  /** 当提供公司id非法的时候，是否尽量选择可用公司 */
  fallbackCompany?: boolean
  companyId?: string
}, companies?: Company[]) {
  const allCompanies = companies ?? await getAllUserCompanies();
  let contextCompany: Company | undefined;
  const firstValidCompany = allCompanies.find(item => (item.isPersonalCom || item.isInit) && !item.isExpireTime && !item.isForbid);
  if (companyId) {
    try {
      contextCompany = validCompanyId(companyId, allCompanies);
    } catch (e) {
      if (fallbackCompany) {
        contextCompany = firstValidCompany;
      } else {
        throw e;
      }
    }
  } else {
    contextCompany = firstValidCompany;
  }


  if (!contextCompany) {
    throw new AppError({
      message: '这个公司过期',
      code: 'company_expire',
    });
  }

  if (!contextCompany.isInit && getAppId() && isNeedInitPlatform()) {
    /** @see {@link http://yapi.corp.4dstc.com/project/31/interface/api/22812} */
    await axios.post('authcenter/v1.0/account/init/platform/admin', {
      appId: getAppId(),
      userId: tokenUtils.getUserId(),
      tenantId: contextCompany.id,
    });
    contextCompany.isInit = true;
  }

  return {
    allCompanies,
    companies: allCompanies.filter(item => item.isInit),
    company: contextCompany,
  };
}

import Vue from 'vue';
import date from './date';
import byte from './byte';
import accountRole from './account-role';
import companyRole from './company-role';
import lf2p from './lf2p';
import lf2br from './lf2br';
import thumb from './thumb';
import translate from './translate';

const filters = {
  thumb, date, byte, accountRole, companyRole, lf2p, lf2br, translate,
};
export {
  thumb, date, byte, accountRole, companyRole, lf2p, lf2br, translate,
};

declare module 'vue/types/vue' {
  interface Vue {
    $filters: typeof filters
  }
}

export default {
  install(_Vue: typeof Vue) {
    const keys = Object.keys as <T>(o: T) => (Extract<keyof T, string>)[];
    Vue.prototype.$filters = filters;
    keys(filters).forEach(k => {
      _Vue.filter(k, filters[k]);
    });
  },
};

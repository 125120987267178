import lf2br from '@/filters/lf2br';
import ErrorLike from '@/typings/error-like';
import { logError, parseError, getMessage } from '@/utils/error-handler';
import { computed, watch } from 'vue';

export function useError(props: {
  error: ErrorLike
}) {
  const error = computed(() => parseError(props.error));

  watch(error, (e) => {
    if (e.feCodeError) {
      logError(e);
    }
  }, { immediate: true });

  const message = computed(() => {
    return props.error.message || getMessage(error.value);
  });
  const messageHTML = computed(() => {
    return lf2br(message.value);
  });
  const title = computed(() => {
    if (error.value.title) {
      return `${error.value.code} ${error.value.title}`;
    }
    return '';
  });
  return {
    message,
    title,
    messageHTML,
  };
}
export default useError;

import {
  isRef,
  onMounted, ref, Ref,
} from 'vue';
import getCurrentInstance from '@/utils/composables/get-current-public-instance';


/** @deprecated */
function useEl(useDocumentElement: true): Ref<Element | undefined>;
/** @deprecated */
function useEl(refElement: Ref<Element | undefined>): Ref<Element | undefined>;
function useEl(): Ref<Element | undefined>;
function useEl(target: Element | string): Ref<Element | undefined>;
function useEl(target?: Ref<Element | undefined> | Element | string | true): Ref<Element | undefined>;
function useEl(target?: Ref<Element | undefined> | Element | string | true): Ref<Element | undefined> {
  if (isRef(target)) {
    return target;
  }
  const el = ref<Element | undefined>();
  const currentInstance = getCurrentInstance();

  onMounted(() => {
    // el.value = new Element();
    if (target instanceof Element) {
      el.value = target;
    } else if (typeof target === 'string') {
      el.value = currentInstance?.$el.querySelector(target) ?? undefined;
    } else if (!target) {
      el.value = currentInstance?.$el;
    } else {
      el.value = document.documentElement;
    }
  });
  return el;
}

export { useEl };

export default useEl;

import { withProps } from '@/router/utils/props';
import { RouteConfig } from 'vue-router';
// import i18n from '@/i18n';
// 项目配置
const woop: RouteConfig = {
  name: 'WoopSetting',
  path: 'woop',
  // redirect: 'woop/style-lib',
  // props: withProps({

  // }),
  component: () => import(/* webpackChunkName: "woop-settings" */ '@/views/project/project-setting/index.vue'),
  children: [
    {
      name: 'style-lib',
      path: 'style-lib',
      props: withProps({
        page: Number,
        fieldKind: Number,
      }),
      component: () => import(/* webpackChunkName: "woop-settings" */ '@/views/woop/style-lib/index.vue'),
    },
  ],
};
export default woop;

import { withProps } from '@/router/utils/props';
import { RouteConfig } from 'vue-router';
// import i18n from '@/i18n';
// 项目配置
const library: RouteConfig = {
  name: 'LibrarySetting',
  path: 'library',
  redirect: 'library/field',
  // props: withProps({

  // }),
  component: () => import(/* webpackChunkName: "library-settings" */ '@/views/project/project-setting/index.vue'),
  children: [
    {
      name: 'field',
      path: 'field',
      props: withProps({
        page: Number,
        fieldKind: Number,
      }),
      component: () => import(/* webpackChunkName: "library-settings" */ '@/views/lib/field/index.vue'),
    },
    {
      name: 'Category',
      path: 'category',
      props: withProps(),
      component: () => import(/* webpackChunkName: "library-settings" */ '@/views/lib/category/index.vue'),
    },
    {
      name: 'Specification',
      path: 'specification',
      props: withProps(),
      component: () => import(/* webpackChunkName: "library-settings" */ '@/views/lib/specification/index.vue'),
    },
    {
      name: 'Tag',
      path: 'tag',
      props: withProps(),
      component: () => import(/* webpackChunkName: "library-settings" */ '@/views/lib/tag/index.vue'),
    },
    {
      name: 'Group',
      path: 'group',
      props: withProps(),
      component: () => import(/* webpackChunkName: "library-settings" */ '@/views/lib/group/index.vue'),
    },
    {
      name: 'Template',
      path: 'template',
      props: withProps(),
      component: () => import(/* webpackChunkName: "library-settings" */ '@/views/lib/template/index.vue'),
    },
    {
      name: 'Relation',
      path: 'relation',
      props: withProps(),
      component: () => import(/* webpackChunkName: "library-settings" */ '@/views/lib/relation/index.vue'),
    },
    {
      name: 'FieldRule',
      path: 'field-rule',
      props: withProps(),
      component: () => import(/* webpackChunkName: "library-settings" */ '@/views/lib/field-rule/index.vue'),
    },
  ],
};
export default library;

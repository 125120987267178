import * as Entry from '@/packages/Entry';
import { useAppIdConfig } from '@/packages/AppIdConfig/index';

export const useProtocol = () => {
  const { getEntry } = Entry.useEntry();
  const { get } = useAppIdConfig();

  // 服务协议
  const getService = () => {
    const entry = getEntry();
    let en;
    let cn;
    if (entry._tag === 'one-entry') {
      cn = get(entry.value)?.protocolRespList?.find(a => a.type === 1 && a.language === 2)?.url;
      en = get(entry.value)?.protocolRespList?.find(a => a.type === 1 && a.language === 3)?.url;
    }
    return { cn, en };
  };
  // 隐私协议
  const getPrivate = () => {
    const entry = getEntry();
    let en;
    let cn;
    if (entry._tag === 'one-entry') {
      cn = get(entry.value)?.protocolRespList?.find(a => a.type === 2 && a.language === 2)?.url;
      en = get(entry.value)?.protocolRespList?.find(a => a.type === 2 && a.language === 3)?.url;
    }
    return { cn, en };
  };
  // 使用条款
  const getUse = () => {
    const entry = getEntry();
    let en;
    let cn;
    if (entry._tag === 'one-entry') {
      cn = get(entry.value)?.protocolRespList?.find(a => a.type === 3 && a.language === 2)?.url;
      en = get(entry.value)?.protocolRespList?.find(a => a.type === 3 && a.language === 3)?.url;
    }
    return { cn, en };
  };

  return {
    getService,
    getPrivate,
    getUse,
  };
};

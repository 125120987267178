import {
  onMounted, onUnmounted, Ref, ref, watch,
} from 'vue';
import useEl from './use-el';

import 'intersection-observer';

const observeVm = new WeakMap<Element, Ref<undefined | boolean>>();
const io = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.intersectionRatio > 0) {
      const intersect = observeVm.get(entry.target);
      if (intersect) {
        intersect.value = true;
      }
    }
  });
}, {
  root: null,
  rootMargin: '0px',
  threshold: 0.5,
});

function addLazyLoadListener(el: Element, intersect: Ref<boolean | undefined>) {
  if (!observeVm.has(el)) {
    observeVm.set(el, intersect);
    io.observe(el);
  }
}
function removeLazyLoadListener(el: Element) {
  if (observeVm.has(el)) {
    io.unobserve(el);
    observeVm.delete(el);
  }
}
export function useEnterScreen<InitialValue extends undefined | boolean>(
  target?: Ref<Element | undefined> | Element | string | true, initialValue?: InitialValue,
): Ref<InitialValue> {
  const intersect = ref<InitialValue>(initialValue as InitialValue) as Ref<InitialValue>;
  const el = useEl(target);

  onMounted(() => {
    watch(el, (_el, oldEl) => {
      if (oldEl) {
        removeLazyLoadListener(oldEl);
      }
      if (_el) {
        addLazyLoadListener(_el, intersect);
      }
    }, {
      immediate: true,
    });
    onUnmounted(() => {
      const _el = el.value;
      if (_el) {
        removeLazyLoadListener(_el);
      }
    });
  });
  return intersect;
}

export default undefined;


export default {
  data() {
    return {
      routerViewKey: Date.now(),
    };
  },
  watch: {
    '$route.query._': function refresh() {
      if (this.isLastLayout()) {
        this.routerViewKey = Date.now();
      }
    },
  },
  methods: {
    isLastLayout() {
      const i = this.$route.matched.length - 2;
      const lastLayoutInstance = i === -1 ? this : this.$route.matched[i]?.instances.default;
      return lastLayoutInstance === this;
    },
  },
};

import Vue from 'vue';
import Router, { Route } from 'vue-router';
import { Position } from 'vue-router/types/router';
import { stringifyUrl } from 'query-string';
import { upperCamelCase } from '_/case';
import { getHost } from '@/utils/exec';
import tokenUtils from '@/utils/token';
import companyUtils from '@/utils/company';
import history from '@/utils/history';
import {
  clearContext,
  getContextUser,
  // setContextWS,
  updateContext,
} from '@/loaders/context';
import first from '_/first';
import AppError from '@/utils/error';
import errHandler from '@/utils/error-handler';
import envs, { getHostAndBasePath } from '@/utils/envs';
import {
  i18n,
  Language,
  setLang,
} from '@/i18n';
import axios from '@/utils/axios';
import can from '@/utils/can';
// import { SessionStorageUtil, isMobileUrlNeedRedirect } from '@/views/mobile-ideation/utils';
import { setRoute } from '@/utils/composables/use-theme';
// import { shareCan } from '@/views/share-ideation/share-can';
import { getUniqSessionId } from '@/log/tracker';
import { init as initLog } from '@/log/useLog';
import store from '@/store';
import { container } from '@/packages/container';
import * as Entry from '@/packages/Entry';
import * as AppIdConfig from '@/packages/AppIdConfig';
import * as Platform from '@/packages/Platform';
import { enableBeterRouter } from './utils/props';
import routes from './routes';
import isNeedLogin from './utils/is-need-login';
// import getGroupAppId from './utils/get-group-app-id';
import refreshRoute from './utils/refresh-route';
import handleErrorGuard from './utils/handle-error-guard';

const contain = container({
  app: null,
  store,
  i18n,
});

let currentHref: string | undefined;
// let oldGroupAppId: string | undefined;
let cacheToken: string | undefined;

function getScrollCacheKey(route: Route) {
  const props = {
    ...route.query,
    ...route.params,
  };
  const cachePropKeys = route.meta.scrollCachePropKeys
    ?? Object.keys(props);
  return cachePropKeys.map((k: string) => {
    return JSON.stringify(props[k]);
  }).join('-');
}

const scrollBehavior = (to: Route, from: Route, savedPosition: void | Position) => {
  const kFrom = getScrollCacheKey(from);
  const kTo = getScrollCacheKey(to);
  if (kFrom && kTo && kFrom === kTo) {
    return undefined;
  }
  if (savedPosition) {
    return savedPosition;
  }
  if (to.hash) {
    return { selector: to.hash };
  }
  return { x: 0, y: 0 };
};

function getHref() {
  return `${globalThis.location.pathname}${globalThis.location.search}`;
}

// 临时解决路由跳转报"Uncaught (in promise) Error: Redirected when going from “...“ to “...“ via a navigation guard"的错误
// like: https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

Vue.use(Router);
const router = new Router({
  mode: 'history',
  scrollBehavior,
  routes,
});

router.beforeEach(handleErrorGuard(async (to) => {
  const query = to.query || {};
  const _companyId = first(query._companyId);
  // let token: string | undefined;
  if (_companyId && window.top === window.self) {
    companyUtils.set(_companyId);
    const _query = {
      ...to.query,
    };
    delete _query._companyId;
    return {
      ...to, query: _query, name: to.name!,
    };
  }
  return undefined;
}));


// 控制token不丢失
router.beforeEach(handleErrorGuard(async (to, from) => {
  const fromQuery = from?.query || {};
  const toQuery = to.query || {};
  const toToken = first(toQuery.token);
  const fromToken = first(fromQuery.token);
  // let token: string | undefined;
  if (fromToken && !toToken && to.name !== 'Login') {
    const _query = {
      ...to.query,
      token: fromToken,
    };
    return {
      ...to, query: _query, name: to.name!,
    };
  }
  return undefined;
}));

// 控制 companyId 不丢失
router.beforeEach(handleErrorGuard(async (to, from) => {
  const fromQuery = from?.query || {};
  const toQuery = to.query || {};
  const toCompanyId = first(toQuery._companyId);
  const fromCompanyId = first(fromQuery._companyId);
  // let _companyId: string | undefined;
  if (fromCompanyId && !toCompanyId && to.name !== 'Login') {
    const _query = {
      ...to.query,
      _companyId: fromCompanyId,
    };
    return {
      ...to, query: _query, name: to.name!,
    };
  }
  return undefined;
}));

// 控制 entry _appId 不丢失
router.beforeEach(handleErrorGuard(contain((to) => {
  const entry = Entry.useEntry();
  if (to.query._appId) return undefined;
  // 并不存在中途修改 _appId 的行为
  const tmp = entry.getEntry();
  if (tmp._tag === 'one-entry') {
    return {
      ...to,
      query: {
        ...to?.query,
        _appId: tmp.value,
      },
      name: to.name!,
    };
  }
  return undefined;
})));

// 控制 返回参数 redirect 不丢失
router.beforeEach(handleErrorGuard(async (to, from) => {
  const fromQuery = from?.query || {};
  const toQuery = to.query || {};
  const toKey = first(toQuery.redirect);
  const fromKey = first(fromQuery.redirect);
  if (fromKey && !toKey) {
    const _query = {
      ...to.query,
      redirect: fromKey,
    };
    return {
      ...to, query: _query, name: to.name!,
    };
  }
  return undefined;
}));

// 因为客户端旧版跳页面没用web端的方法，导致旧版客户端只从cookies里读token，这里保留兼容客户端的方法
// 用于从query里边获取并设置token
router.beforeEach(handleErrorGuard((to) => {
  const query = to.query || {};
  const token = first(query.token);
  if (token && window.top === window.self) {
    tokenUtils.set(token);
    const _query = {
      ...to.query,
    };
    delete _query.token;
    return {
      ...to, query: _query, name: to.name!,
    };
  }
  return undefined;
}));

// 用于从query里边获取并设置orgId
router.beforeEach(handleErrorGuard((to) => {
  const query = to.query || {};
  const orgId = first(query.orgId);
  if (orgId) {
    companyUtils.set(orgId);
    const _query = {
      ...to.query,
    };
    delete _query.orgId;
    return {
      ...to, query: _query, name: to.name!,
    };
  }
  return undefined;
}));


// 用于判断登录状态
router.beforeEach(handleErrorGuard(contain(async (to) => {
  const { updatePlatformAuth } = Platform.usePlatformAuth();
  const { initSource } = AppIdConfig.useApplicationLink();
  if (!getUniqSessionId()) { // 应用第一个请求加载雪花id
    await initLog(); // 初始化埋点
  }

  if (!isNeedLogin(to)) {
    return undefined;
  }
  const storageToken = tokenUtils.get();

  if (storageToken !== cacheToken) {
    await updateContext();
    await updatePlatformAuth();
    cacheToken = storageToken;
  }
  await initSource();
  const user = getContextUser();
  const hostParams = envs.VUE_APP_HOST_PARAMS;
  if (!user) {
    throw AppError.needLogin;
  } else if (hostParams[3] === 'anta' && can('', '6096', false)) {
    const antaHostAndParams = [...hostParams];
    antaHostAndParams[5] = 'material-admin';
    // @ts-ignore
    const [domainWithBasePath] = getHostAndBasePath(...antaHostAndParams, '');
    let fullPath = '/';
    if (envs.VUE_APP_IS_REMOTE) {
      fullPath = `//${domainWithBasePath}/`;
    } else {
      fullPath = '//localhost:9220/';
    }
    // globalThis.location.href = fullPath;
    throw new AppError({
      redirect: fullPath,
    });
  } else if (!can('', 'ID1500', false) && (to.name === 'projectList' || to.name === 'FieldSettings' || to.name === 'styleDetail')) {
    const fullPath = '/';
    throw new AppError({
      redirect: fullPath,
    });
  }
})));


enableBeterRouter(router);


// 设置语言
router.afterEach(async (to) => {
  // 查询是否有语言参数，有的话设置语言。pc端是通过url传进来的
  if (to.query.language) {
    // debugger
    setLang(to.query.language as Language);

    const newHref = stringifyUrl({
      url: window.location.href,
      query: { language: undefined },
    });
    try {
      window.history.replaceState(null, '', newHref);
    } catch (err) {
      console.error(err);
    }
  }
});

// 设置标题
router.afterEach(async (to) => {
  // Change the page title
  const name = to.name || upperCamelCase(to.path.replace(/\//g, '-'));
  document.documentElement.lang = i18n.language;
  document.title = to.meta.title ?? document.title;
  document.documentElement.dataset.currentPage = name;
});

router.afterEach((to) => {
  setRoute(to);
});

// QT相关
router.afterEach(async (to) => {
  if (to.path.startsWith('/qt/')) {
    try {
      const host = await getHost();
      if (!host) return;
      if (!currentHref) {
        currentHref = getHref();
        if (host) {
          host.on('refresh', async () => {
            await refreshRoute();
          });
          host.on('goToPage', async (_to: typeof to) => {
            history.push(_to);
          });
          host.on('goToPageByUrl', async (url: string) => {
            history.push(url);
          });
          host.on('sigClientLogout', () => {
            clearContext();
          });
        }
      } else {
        await host.reportCurrentPage(to);
      }
    } catch (e) {
      console.error(e);
    }
  }
});

router.onError((err) => {
  // console.error(err);
  // debugger;
  setTimeout(() => {
    errHandler(err);
  }, 0);
});


export default router;

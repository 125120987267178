import Vue, { VueConstructor } from 'vue';

export default {
  install(_Vue: VueConstructor) {
    _Vue.mixin(this);
  },
  beforeCreate(this: Vue) {
    const _children = Vue.observable({ children: this.$children ?? [] });
    Object.defineProperty(this, '$children', {
      get() {
        return _children.children;
      },
      set() {
        throw new Error('Please do not override $children');
      },
    });
  },
};

import { i18n } from '@/i18n';

const companyRole = (roleType: number) => {
  const roles = [
    i18n.t('public.header.userRole.brand'),
    i18n.t('public.header.userRole.agent_company'),
    i18n.t('public.header.userRole.material_suppplier'),
    i18n.t('public.header.userRole.manufacturer'),
    i18n.t('public.header.userRole.designer'),
    i18n.t('public.header.userRole.other'),
  ];

  return roles[roleType - 1] || 'Unmatch Type';
};

export default companyRole;

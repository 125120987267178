import Vue from 'vue';
import i18n from '@/i18n';
import HeadFilter from '@/components/table-filter/VxeTableHeadFilter.vue';
import VXETable from 'vxe-table';
// import { getFilter } from '@/views/material-management/components/utils';


VXETable.setup({
  i18n: (key, args) => i18n.t(key, args),
  icon: {
    TABLE_FILTER_MATCH: 'i-icon_filter',
    TABLE_FILTER_NONE: 'i-icon_filter',
    TABLE_SORT_ASC: 'el-icon-arrow-up',
    TABLE_SORT_DESC: 'el-icon-arrow-down',

    TABLE_EDIT: '', // 隐藏表头编辑按钮
  },
  table: {
    border: 'full',
  },
});

VXETable.renderer.add('FilterComponent', {
  // 不显示底部按钮，使用自定义的按钮
  isFooter: false,
  renderFilter(h, renderOptions, params) {
    return [
      <HeadFilter params={params}
        {...renderOptions}
        key={renderOptions.key}
      />,
    ];
  },
  // 重置数据方法
  // filterResetMethod({ column, options }) {
  //   const field = column.property.replace('extraProperty.', '');
  //   options.forEach(option => {
  //     option.data = getFilter(field);
  //   });
  // },
});

// 先安装依赖模块
// Vue.use(Column);
// Vue.use(Header);
// Vue.use(Footer);
// Vue.use(Filter);
// Vue.use(Edit);
// Vue.use(Menu);
// Vue.use(Export);
// Vue.use(Keyboard);
// Vue.use(Validator);

// Vue.use(Tooltip);
// Vue.use(Grid);
// Vue.use(Toolbar);
// Vue.use(Pager);
// Vue.use(Form);
// Vue.use(Checkbox);
// Vue.use(Radio);
// Vue.use(Switch);
// Vue.use(Input);
// Vue.use(Select);
// Vue.use(Button);
// Vue.use(Modal);
// Vue.use(List);
// Vue.use(Icon);

// // 再安装核心库
// Vue.use(Table);
Vue.use(VXETable);
export default VXETable;


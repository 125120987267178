import { RouteConfig } from 'vue-router';
import HomeLayout from '@/components/public/LayoutHome.vue';
import user from '@/views/user/routes';
import SysteamLayout from '@/views/components/SystemLayout.vue';
import can from '@/utils/can';
import projectTabList from '@/views/project/project-setting/components/utils';
import policy from './routes/policy';
import error from './routes/error';
import project from './routes/project';
import userRouter from './routes/user';
import library from './routes/library';
import woop from './routes/woop';

const routes: RouteConfig[] = [
  {
    name: 'refresh',
    path: '/refresh',
    component: {
      beforeRouteEnter(to, from, next) {
        next(vm => {
          vm.$router.replace(from.fullPath);
          // 跳到该路由页面后，再替换为from.path来源路径
        });
      },
    },
  },
  policy,
  ...user,
  {
    name: 'SysteamLayout',
    path: '/setting',
    component: SysteamLayout,
    redirect: '/setting/user',
    children: [
      ...project,
      library,
      woop,
      userRouter,
      {
        name: 'EnterpriseInfo',
        path: 'enterprise',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/user/profile/EnterpriseInfo.vue'),
      },
    ],

    beforeEnter: (to, form, next) => {
      const userRoutePremission = can('', 'OP0165');
      const projectRoutePremission = projectTabList.filter(item => item.to.startsWith('/setting/project')).some(item => item.can());
      const libraryRoutePremission = projectTabList.filter(item => item.to.startsWith('/setting/library')).some(item => item.can());
      const woopRoutePremission = projectTabList.filter(item => item.to.startsWith('/setting/woop')).some(item => item.can());
      const taskRoutePremission = can('', 'PDM2521');
      const enterpriseRoutePremission = can('', 'OP0007');
      if (userRoutePremission && to.path.startsWith('/setting/user')) next();
      else if (projectRoutePremission && to.path.startsWith('/setting/project')) next();
      else if (libraryRoutePremission && to.path.startsWith('/setting/library')) next();
      else if (woopRoutePremission && to.path.startsWith('/setting/woop')) next();
      else if (taskRoutePremission && to.path.startsWith('/setting/task')) next();
      else if (enterpriseRoutePremission && to.path.startsWith('/setting/enterprise')) next();
      else if (to.path.startsWith('/setting/library')) next();
      else { next('/403') };
    },
  },
  {
    name: 'LayoutHome',
    path: '/',
    component: HomeLayout,
    children: [
      ...error,
    ],
  },
];

export default routes;

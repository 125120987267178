/* eslint-disable class-methods-use-this */
import HostParams from '.';

export class RevobitHostParams extends HostParams {
  static mainModule = 'home';
  static modules = [
    'home', 'datapipe',
  ];
  getDomainsSeperator() {
    // eslint-disable-next-line no-nested-ternary
    return '-';
  }
}


export default RevobitHostParams;


import {
  defineComponent, getCurrentInstance,
} from 'vue';
import * as Platform from '@/packages/Platform';
import QuickSearch from './QuickSearch.vue';


export default defineComponent({
  components: {
    QuickSearch,
  },
  setup() {
    const vm = getCurrentInstance()?.proxy;
    const { getApplicationLink } = Platform.useApplicationLink();
    const { hasPermission: hasAuthorityPermission } = Platform.usePermissionPlatform();

    const {
      hasProfileAccount,
      hasProfileOrg,
      hasProfileRole,
      hasProfileTeam: _hasProfileTeam,
    } = Platform.usePlatformAuth();

    const hasProfileTeam = () => {
      return vm?.$customize(vm?.$customizeNames.showTeamManage) && _hasProfileTeam();
    };

    return {
      getApplicationLink,

      hasAuthorityPermission,

      hasProfileAccount,
      hasProfileOrg,
      hasProfileRole,
      hasProfileTeam,
    };
  },
});

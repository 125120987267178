type Instance<A> = A | null;

const instance = <A>() => {
  let current: Instance<A> = null;

  const get = () => current;
  const set = (a: Instance<A>) => {
    current = a;
  };

  return [get, set] as const;
};

export default instance;


import {
  getCurrentInstance,
  defineComponent,
  ref, computed,
} from 'vue';
import { useApplicationLink, usePlatformAuth } from '@/packages/Platform';
import { getContextUser } from '@/loaders/context';
import companyUtils from '@/utils/company';

type ApplicationLink = {
  _tag: 'application-link',
  text: string,
  url: string,
};
type Route = {
  _tag: 'route',
  text: string,
  url: string,
};
type Option = ApplicationLink | Route;
const applicationLink = (text: string, url: string): Option => ({
  _tag: 'application-link',
  text,
  url,
});
const route = (text: string, url: string): Option => ({
  _tag: 'route',
  text,
  url,
});
const isApplicationLink = (a: Option): a is ApplicationLink => a._tag === 'application-link';
const isRoute = (a: Option): a is Route => a._tag === 'route';

export default defineComponent({
  setup() {
    const vm = getCurrentInstance()?.proxy;
    const { hasProfileTeam, hasProfileRole, hasProfileOrg, hasProfileAccount } = usePlatformAuth();
    const { getApplicationLink } = useApplicationLink();

    const isAdmin = computed(() => {
      return getContextUser()?.companies?.find(item => item.id === companyUtils.get())?.isCompanyAdmin;
    });
    const needWorkspace = computed(() => {
      return vm?.$customize(vm?.$customizeNames.needWorkspace) && isAdmin.value;
    });

    const current = ref();

    const options = computed(() => {
      return [
        route(vm!.$t('profile.personal_info'), '/profile/info'),
        route(vm!.$t('profile.info.modify_avatar'), '/profile/info'),
        route(vm!.$t('profile.info.change_account'), '/profile/info'),
        route(vm!.$t('profile.info.change_password'), '/profile/info'),
        route(vm!.$t('profile.info.change_name'), '/profile/info'),
        route(vm!.$t('profile.info.change_mobile'), '/profile/info'),
        route(vm!.$t('profile.info.upgrade_enterprise_customer_applications'), '/profile/info'),

        route(vm!.$t('profile.account_authority'), '/profile/authority'),

        hasProfileRole() && route(vm!.$t('profile.roleTab'), '/profile/role'),
        hasProfileRole() && route(vm!.$t('profile.role_auth'), '/profile/role'),

        hasProfileOrg() && route(vm!.$t('profile.orgTab'), '/profile/org'),

        hasProfileAccount() && route(vm!.$t('profile.accountTab'), '/profile/account'),
        hasProfileAccount() && needWorkspace.value && route(vm!.$t('profile.workspace_config'), '/profile/account'),

        vm?.$customize(vm?.$customizeNames.showTeamManage) && hasProfileTeam() && route(vm!.$t('profile.teamTab'), '/profile/team'),

        ...getApplicationLink().map(a => applicationLink(a.name, a.links!)),
      ].filter(a => a) as Option[];
    });

    const confirmHandle = () => {
      const target = options.value.find(a => a.text === current.value);

      if (!target) return;
      if (isRoute(target)) {
        vm?.$router.push(target.url);
      } else if (isApplicationLink(target)) {
        vm?.$router.push(`/applicationLink?applicationLink=${encodeURIComponent(target.url)}`)
      }

      current.value = undefined;
    };

    return {
      current,
      options,
      confirmHandle,
    };
  },
});

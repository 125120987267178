import { Route } from 'vue-router';
import { getProps } from '@/router/utils/props';
import router from '../index';


export function getGroupAppId(to: Route = router.currentRoute): string | undefined {
  const props = {
    ...to.query,
    ...getProps(to),
  };
  let groupAppId = props.workspaceId ?? props.groupAppId;
  if (groupAppId === undefined) {
    if (to.name === 'ModelItem' || to.path.startsWith('/qt/')) {
      groupAppId = props.from === '0' ? '' : props.from;
    } else if (to.name === 'FileList' || to.name === 'QtFileList') {
      groupAppId = props.relateId;
    }
  }

  // 确保不是null
  return groupAppId ?? undefined;
}
export default getGroupAppId;

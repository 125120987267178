import { withProps } from '@/router/utils/props';
import { RouteConfig, Route } from 'vue-router';
import HomeLayout from '@/components/public/LayoutHome.vue';
import i18n from '@/i18n';
import LoginLayout from './components/LoginLayout.vue';

const profile = {
  path: '/',
  component: HomeLayout,
  redirect: { name: 'ProfileInfo' },
  children: [
    {
      name: 'ProfileInfo',
      path: 'info',
      meta: {
        title: 'Personal Information',
        meta: { noSidebar: true },
      },
      props: withProps({
        _appId: String,
      }),
      component: () => import(/* webpackChunkName: "user" */ './profile/info/index1.vue'),
    },
    {
      name: 'Profile',
      path: 'profile',
      meta: {
        title: 'profile',
      },
      component: () => import(/* webpackChunkName: "user" */ './profile/index.vue'),
      children: [
        {
          name: 'Info',
          path: 'info',
          redirect: '/info',
        },
        {
          name: 'AccountAuthority',
          path: 'authority',
          meta: {
            title: 'Account Authority',
          },
          component: () => import(/* webpackChunkName: "user" */ './profile/authority/index.vue'),
        },

        {
          name: 'ProfileRole',
          path: 'role',
          meta: {
            title: 'Role Setting',
          },
          props: withProps(() => ({
            selectedType: 'role',
            authCode: 'ILI050401',
          })),
          component: () => import(/* webpackChunkName: "profile-setting" */ './profile/account/index.vue'),
        },
        {
          name: 'ProfileOrg',
          path: 'org',
          meta: {
            title: 'Organization Setting',
          },
          props: withProps(() => ({
            selectedType: 'org',
            authCode: 'ILI050408',
          })),
          component: () => import(/* webpackChunkName: "profile-setting" */ './profile/account/index.vue'),
        },
        {
          name: 'ProfileAccount',
          path: 'account',
          meta: {
            title: 'Account Setting',
          },
          props: withProps(() => ({
            selectedType: 'account',
            authCode: 'ILI050413',
          })),
          component: () => import(/* webpackChunkName: "profile-setting" */ './profile/account/index.vue'),
        },
        {
          name: 'ProfileTeam',
          path: 'team',
          meta: {
            title: 'Team Setting',
          },
          props: withProps(() => ({
            selectedType: 'team',
            authCode: 'ILI050413',
          })),
          component: () => import(/* webpackChunkName: "profile-setting" */ './profile/account/index.vue'),
        },
        {
          name: 'ProfileApplications',
          path: 'apps',
          meta: {
            title: 'Tools & Applications',
          },
          component: () => import(/* webpackChunkName: "user" */ './profile/applications/index.vue'),
        },
      ],
    },
  ],
};

const routesWithloginLayout: RouteConfig = {
  path: '/',
  redirect: 'login',
  component: LoginLayout,
  meta: {
    theme: 'light',
  },
  children: [
    {
      name: 'Login',
      path: 'login',
      meta: {
        title: 'Login',
        logoVisible: true,
      },
      props: (to: Route) => ({
        ...to.params,
        ...to.query,
      }),
      component: () => import(/* webpackChunkName: "user" */ './login/index.vue'),
    },
    {
      name: 'Register',
      path: 'login/register',
      alias: '/register',
      meta: {
        title: 'Register New Account',
      },
      component: () => import(/* webpackChunkName: "user" */ './register/index.vue'),
    },
    {
      name: 'PasswordReset',
      path: 'login/password/reset',
      alias: '/password/reset',
      meta: {
        title: 'Password Reset',
      },
      component: () => import(/* webpackChunkName: "user" */ './password-reset/index.vue'),
    },
    {
      name: 'Subscribe',
      path: 'login/subscribe',
      alias: '/subscribe',
      meta: {
        title: 'Subscribe',
      },
      component: () => import(/* webpackChunkName: "user" */ './subscribe/index.vue'),
    },
    {
      name: 'Feedback',
      path: 'login/feedback',
      alias: '/feedback',
      meta: {
        title: 'Collaboration Request/Comments',
      },
      component: () => import(/* webpackChunkName: "user" */ './feedback/index.vue'),
    },
    {
      name: 'Trial',
      path: 'login/apply/trial',
      alias: '/apply/trial',
      meta: {
        title: 'Apply for Trial',
      },
      component: () => import(/* webpackChunkName: "user" */ './trial/index.vue'),
    },
  ],
};

const email: RouteConfig = {
  path: '/email/confirmation',
  meta: {
    title: 'Email Confirmation',
    isNeedLogin: false,
  },
  props: (to: Route) => ({
    ...to.params,
    ...to.query,
  }),
  component: () => import(/* webpackChunkName: "email" */ './email-confirmation/index.vue'),
};

const platform: RouteConfig = {
  path: '/',
  component: HomeLayout,
  redirect: '/platform/select',
  children: [
    {
      meta: { noSidebar: true },
      path: '/platform/select',
      component: () => import(/* webpackChunkName: "platform" */ './platform/select.vue'),
    },
  ],
};

const applicationLink: RouteConfig = {
  path: '/',
  component: HomeLayout,
  redirect: '/applicationLink',
  children: [
    {
      path: '/applicationLink',
      component: () => import(/* webpackChunkName: "platform" */ './application-link/index.vue'),
    },
  ],
};

export default [
  profile,
  routesWithloginLayout,
  email,
  platform,
  applicationLink,
];

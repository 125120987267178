/* eslint-disable camelcase */
import tokenUtils from '@/utils/token';
import { tryParseJsonWithFallback } from '@/utils/util';
import {
  AsyncReturnType,
  GetArrayItem,
  RecordUnknown,
} from '@/typings/common';
import { ref } from 'vue';
import { getTrackerAttrs } from './tracker';

// 前端对埋点事件的跟踪,比如有事件先触发点击,后续在onMounted周期再上报的情况,需要有一个管理过程

// 上报接口定义的事件
export type EventName = string;

// 前端定义的事件
const frontLogEventList = [
  'viewMaterialDetail' as const,
  'viewMaterialBookDetail' as const,
  'viewMerchantDetail' as const,
  'viewRecommendationDetail' as const,
  'open_terminal' as const,
  'close_terminal' as const,
  'page_leave' as const,
];
export type FrontLogEventKey = GetArrayItem<typeof frontLogEventList>;
export type FrontLogEventItem = {
  posterParams: AsyncReturnType<typeof getTrackerAttrs>
} & RecordUnknown;
type Store = Record<FrontLogEventKey, FrontLogEventItem | null>;

type SetConfig = Partial<{
  isMerge: boolean,
}>;

type RemoveConfig = Partial<{
  key: keyof FrontLogEventItem,
}>;


export const unique_session_id = ref('');
export const refreshRole = ref();

export const openTermalCollectCached = ref({});
export const openTermalCollectData = (frontLogEventKey: FrontLogEventKey, params?: AsyncReturnType<typeof getTrackerAttrs>) => {
  if (frontLogEventKey === 'open_terminal') {
    openTermalCollectCached.value = JSON.parse(JSON.stringify(params));
  }
};

const initStore = () => {
  const IDEALAB_LOG_STORE_BASE_KEY = 'IDEATION_LOG_STORE_KEY';
  const userId = tokenUtils.get();
  // storeKey需要跟用户关联
  const storeKey = userId ? `${IDEALAB_LOG_STORE_BASE_KEY}:${userId}` : IDEALAB_LOG_STORE_BASE_KEY;
  if (userId) {
    // 先清除不属于该用户的localStorage
    Object.keys(localStorage).forEach((key) => {
      if (key.includes(IDEALAB_LOG_STORE_BASE_KEY) && !key.includes(userId)) localStorage.removeItem(key);
    });
  }
  const localStorageStoreStr = localStorage.getItem(storeKey);
  const localStorageStore = tryParseJsonWithFallback(localStorageStoreStr);
  const store = localStorageStore || frontLogEventList.reduce((obj, frontLogEvent) => {
    obj[frontLogEvent] = null;
    return obj;
  }, {} as Store);
  const proxyStore = new Proxy(store, {
    get(target, propKey, receiver) {
      return Reflect.get(target, propKey, receiver);
    },
    set(target, propKey, value, receiver) {
      const isProxy = Reflect.set(target, propKey, value, receiver);
      localStorage.setItem(storeKey, JSON.stringify(target));
      return isProxy;
    },
  });
  return proxyStore;
};

let store = {} as Store;

const logStore = {
  init() {
    store = initStore();
  },
  get(key: FrontLogEventKey) {
    return store[key];
  },
  set(frontLogEventKey: FrontLogEventKey, value: FrontLogEventItem, config: SetConfig = {}) {
    const defaultConfig = { isMerge: true };
    config = { ...defaultConfig, ...config };
    const { isMerge } = config;
    const frontLogEventItem = isMerge ? { ...store[frontLogEventKey], ...value } : value;
    store[frontLogEventKey] = frontLogEventItem;
  },
  remove(frontLogEventKey: FrontLogEventKey, config: RemoveConfig = {}) {
    const { key } = config;
    if (key) {
      if (store[frontLogEventKey]) delete (store[frontLogEventKey]?.[key]);
    } else {
      store[frontLogEventKey] = null;
    }
  },
  clear() {
    store = initStore();
  },
};

// 主要用于退出登录后的数据缓存上报和page_view事件
let cacheFrontLogEventList: FrontLogEventItem[] = [];
export const cacheStore = {
  detectPageView(frontLogEventItem: FrontLogEventItem) {
    const { posterParams } = frontLogEventItem;
    return posterParams.dynamic_property.event_name !== 'page_leave';
  },
  setCurrent(frontLogEventItem: FrontLogEventItem) {
    if (!cacheStore.detectPageView(frontLogEventItem)) return;
    cacheFrontLogEventList = [frontLogEventItem];
  },
  getCurrent(): FrontLogEventItem | null {
    return cacheFrontLogEventList[0] || null;
  },
  clear() {
    // 暂时通过业务去清除
    cacheFrontLogEventList = [];
  },
};

export default logStore;


/* eslint-disable class-methods-use-this */
import own from '@4dst-saas/public-utils/dist/own';
import Cookies from 'js-cookie';
import { stringifyUrl } from 'query-string';
import EventEmitter from '@4dst-saas/public-utils/dist/event-emitter';
import envs from './envs';
import getCookieConfig from './get-cookie-config';

type XStorageEvent<V> = {
  storage: [V, { key: string, oldValue: any, newValue: any }]
};

type XStorageAll = XStorage | XSecStorage;

const storages: XStorageAll[] = [];


type BridgeCmdPost = {
  id: number,
  cmd: string,
  params: unknown[],
};
type BridgeCmdResultSuccess = {
  id: number,
  value: unknown,
};
type BridgeCmdResultFail = {
  id: number,
  reason: Error,
};
type BridgeCmdResult = BridgeCmdResultSuccess | BridgeCmdResultFail;
type BridgeEvent = {
  type: string,
};
type StorageEvent = {
  type: 'storage',
  key: string,
  oldValue: any,
  newValue: any,
};
function isBridgeEvent(message: unknown): message is BridgeEvent {
  return typeof message === 'object' && !!message && own(message, 'type') && typeof message.type === 'string';
}
function isStorageEvent(message: unknown): message is StorageEvent {
  return isBridgeEvent(message) && message.type === 'storage';
}
function isBridgeCmdResultOf(message: unknown, post: BridgeCmdPost): message is BridgeCmdResult {
  return typeof message === 'object' && !!message && own(message, 'id') && message.id === post.id;
}
function getOrigin(url: string): string {
  const aElement = document.createElement('a');
  aElement.href = url;
  return aElement.origin;
}

let pIframe: Promise<HTMLIFrameElement> | undefined;

// eslint-disable-next-line import/no-mutable-exports
export const ready = async () => {
  pIframe = pIframe ?? (async () => {
    const iframe = document.createElement('iframe');
    const src = `//${envs.VUE_APP_LOGIN_DOMAIN}/bridge.html`;
    const targetOrigin = getOrigin(src);
    const { origin } = window;
    iframe.src = stringifyUrl({ url: src, query: { origin } });
    iframe.setAttribute('target-origin', targetOrigin);
    iframe.style.position = 'fixed';
    iframe.style.visibility = 'hidden';
    iframe.id = `iframe-${Date.now()}`;
    document.body.appendChild(iframe);
    const win = iframe.contentWindow!;
    await (new Promise<void>((resolve) => {
      window.addEventListener('message', (e) => {
        if (isBridgeEvent(e.data) && e.data.type === 'handshake') {
          resolve();
        }
      });
    }));
    window.addEventListener('message', (e) => {
      if (e.source === win) {
        if (!isStorageEvent(e.data)) return;
        const evt = e.data;
        if (!evt.key) return;
        if (
          evt.newValue !== evt.oldValue
        ) {
          storages.forEach(storage => {
            const { key } = evt;
            if (key && key.startsWith(storage.prefix)) {
              const parsedNewValue = JSON.parse(evt.newValue ?? 'null');
              const parsedOldValue = JSON.parse(evt.oldValue ?? 'null');
              storage.emit('storage', parsedNewValue, {
                oldValue: parsedOldValue,
                newValue: parsedNewValue,
                key: key.substr(storage.prefix.length),
              });
            }
          });
        }
      }
    });
    return iframe;
  })();

  return pIframe;
};


async function exec(cmd: string, ...params: unknown[]) {
  const iframe = await ready();
  const win = iframe.contentWindow!;
  const post = {
    id: Date.now(),
    cmd,
    params,
  };
  win.postMessage(post, iframe.getAttribute('target-origin')!);
  return new Promise((resolve, reject) => {
    const bind = (evt: MessageEvent) => {
      const { data } = evt;
      if (isBridgeCmdResultOf(data, post)) {
        if (own(data, 'value')) {
          resolve(data.value);
        } else {
          reject(data.reason);
        }
        window.removeEventListener('message', bind);
      }
    };
    window.addEventListener('message', bind);
  });
}

async function setStorage(storageKey: string, value: any) {
  await exec('setItem', storageKey, JSON.stringify(value));
}
async function getStorage(storageKey: string) {
  const strItem = await exec('getItem', storageKey) as string;
  return JSON.parse(strItem ?? 'null');
}
async function removeStorage(storageKey: string) {
  await exec('removeItem', storageKey);
}


type XSecStorageSetOptions = {
  expires?: Date | number
};


export class XStorage<Value = any> extends EventEmitter<XStorageEvent<Value>> {
  static iframeUrl: string;
  __id: string;
  prefix: string;
  constructor(prefix: string = '__xstorage_') {
    super();
    this.prefix = prefix;
    this.__id = `__xstorage_${Date.now()}`;
    ready();
    storages.push(this);
  }
  async set(key: string, value: Value) {
    const storageKey = `__xstorage_${key}`;
    await setStorage(storageKey, value);
  }
  async get(key: string): Promise<Value | null> {
    const storageKey = `__xstorage_${key}`;
    return getStorage(storageKey);
  }
  async remove(key: string) {
    const storageKey = `__xstorage_${key}`;
    await removeStorage(storageKey);
  }
}


export class XSecStorage<Value = any> extends EventEmitter<XStorageEvent<Value>> {
  static iframeUrl: string;
  __id: string;
  prefix: string;
  cachePrefix: string;
  constructor(prefix = '__xstorage_sec_', cachePrefix = '__xstorage_cache_') {
    super();
    this.prefix = prefix;
    this.cachePrefix = cachePrefix;
    Object.keys(localStorage).forEach(storageKey => {
      if (storageKey.startsWith(prefix)) {
        const key = storageKey.substr(prefix.length);
        if (!Cookies.get(`${this.cachePrefix}${key}`)) {
          localStorage.removeItem(storageKey);
        }
      }
    });
    this.__id = `${prefix}${Date.now()}`;
    ready();
    storages.push(this);
  }
  async set(key: string, value: Value, { expires }: XSecStorageSetOptions = {}) {
    const timestamp = Date.now();
    const storageKey = `${this.prefix}${key}`;
    const cacheStorageKey = `${this.cachePrefix}${key}`;
    Cookies.set(cacheStorageKey, '1', {
      ...getCookieConfig(),
      expires: typeof expires === 'number' ? new Date(timestamp + expires) : expires,
    });
    await setStorage(storageKey, value);
  }
  async get(key: string): Promise<Value | null> {
    const cacheStorageKey = `${this.cachePrefix}${key}`;
    const storageKey = `${this.prefix}${key}`;
    if (!Cookies.get(cacheStorageKey)) return null;
    return getStorage(storageKey);
  }
  async remove(key: string) {
    const cacheStorageKey = `${this.cachePrefix}${key}`;
    const storageKey = `${this.prefix}${key}`;
    Cookies.remove(cacheStorageKey, getCookieConfig());
    await removeStorage(storageKey);
  }
}

export const xStorage = new XStorage();
export const xSecStorage = new XSecStorage();

export default xStorage;

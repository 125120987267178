<template>
  <div class="layout-home flex-column" :class="{ expand: $route.name === 'ModelItem' }">
    <!-- Header -->
    <Header @close-guide="showImPoppver" v-if="!$customize($customizeNames.hiddenHeader)" />
    <!-- Main content -->
    <main class="main-content" ref="main">
      <!-- <div class="main-sidebar" v-if="$route.meta ? !$route.meta.noSidebar : true">
        <sidebar />
      </div> -->
      <router-view :key="`${routerViewKey}`" ref="main-content" />
    </main>
  </div>
</template>

<script>
import Header from './Header';
import LayoutBase from '@/LayoutBase';
import Sidebar from './Sidebar.vue';

export default {
  name: 'LayoutHome',
  extends: LayoutBase,
  components: {
    Header,
    Sidebar,
  },
  async mounted() {
    // store.commit('updateTimReally', true);
  },
  async destroyed() {
    // store.commit('updateTimReally', false);
  },
  methods: {
    showImPoppver() {
      this.$refs['main-content'].showImPoppver = true;
    },
  },
};

</script>
<style lang="scss" scoped>
.layout-home {
  flex: 1;
}

.main-content {
  position: relative;
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: row;
}

.main-sidebar {
  position: relative;
}
</style>

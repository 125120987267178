// eslint-disable-next-line import/prefer-default-export
import { parseUrl } from 'query-string';
import history from '@/utils/history';
import { getHostParamsFromPath } from '@/utils/envs';

export const tryParseJsonWithFallback = (str: string, fallback = null) => {
  try {
    if (!str) return fallback;
    return JSON.parse(str);
  } catch (e) {
    console.error('tryParseJsonWithFallback错误:', e);
    return fallback;
  }
};

export const module2terminalid = () => {
  const redirectUrl = parseUrl(globalThis.location.href).query.redirect as string;
  const redirectLocation = redirectUrl ? history.parse(redirectUrl) : null;
  const redirectModule = redirectLocation ? getHostParamsFromPath(redirectLocation?.fullPathWithDomain)[5] : null; // srm
  switch (redirectModule) {
    case 'srm':
      return '206';
    case 'entlib':
      return '207';
    case 'opt':
      return '205';
    default:
      return undefined;
  }
};

export const queryTerminalId = parseUrl(globalThis.location.href).query.terminal_id;

import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import VuexORM, { Model } from '@vuex-orm/core';
import * as _models from '@/models';
import tryParseJson from '@4dst-saas/public-utils/dist/try-parse-json';
import { debounce } from 'throttle-debounce';
import delay from '@4dst-saas/public-utils/dist/delay';
import many from '@4dst-saas/public-utils/dist/many';
import { has } from '@4dst-saas/public-utils/dist/own';
// import {
//   ConversationStore,
//   GroupStore,
//   UserStore,
// } from '@/tim/store';
// import modules from './modules';

type PersistedStateOptions = NonNullable<Parameters<typeof createPersistedState>[0]>;
type Storage = NonNullable<PersistedStateOptions['storage']>;
Vue.use(Vuex);
const models = Object.fromEntries(Object.entries(_models).map(([, model]) => {
  return [model.entity, model];
}));

const database = new VuexORM.Database();
Object.entries(models).forEach(([, _Model]) => {
  database.register(_Model);
});


// const storageStr = globalThis.localStorage.getItem('vuex');
// if (storageStr) {
//   const storage = JSON.parse(storageStr);
//   if (storage.entities && storage.entities.permissions) {
//     delete storage.entities.permissions;
//   }
//   globalThis.localStorage.setItem('vuex', JSON.stringify(storage));
// }


function getStorageKey(entity: string) {
  return `vuex-orm:${entity}`;
}


type StorageSetting = {
  getState(entryName: string, storage: Storage, model: typeof Model): OrmEntry,
  setState(entryName: string, entry: OrmEntry, storage: Storage, model: typeof Model): void
};

type ZipPermissions = {
  [companyId: string]: {
    [workspaceId: string]: string
  }
};
type Permission = {
  code: string,
  companyId: string,
  groupAppId: string
  value: boolean,
};
const sepcificStorageSettings: {
  [k: string]: StorageSetting
} = {
  permissions: {
    getState(entryName: string, storage: Storage) {
      // 这里try一下为了兼容旧的
      try {
        const zipPermissions: ZipPermissions = tryParseJson(storage.getItem(getStorageKey(entryName))) as ZipPermissions ?? {};
        const permissions = Object.fromEntries((([] as Permission[]).concat(
          ...Object.entries(zipPermissions).map(([companyId, workspacePermissions]) => {
            return ([] as Permission[]).concat(
              ...Object.entries(workspacePermissions).map(([groupAppId, codeArrStr]) => {
                return codeArrStr.split(',').map((code) => ({
                  companyId,
                  groupAppId,
                  code,
                  value: true as const,
                }));
              }),
            );
          }),
        )).map(item => {
          return [
            [item.groupAppId, item.code, item.companyId].join('-'),
            item,
          ];
        }));
        return {
          data: permissions,
          $connection: 'entities',
          $name: entryName,
        };
      } catch (err) {
        return {
          data: {},
          $connection: 'entities',
          $name: entryName,
        };
      }
    },
    setState(entryName: string, entry: OrmEntry, storage: Storage) {
      type _ZipPermissions = {
        [companyId: string]: {
          [workspaceId: string]: string[];
        }
      };
      const _zipPermissions: _ZipPermissions = {};
      Object.values(entry.data).forEach((_permission) => {
        const permission = _permission as Permission;
        if (!permission.companyId || !permission.groupAppId || !permission.code || !permission.value) return;
        const companyPermissions = _zipPermissions[permission.companyId] ?? {};
        _zipPermissions[permission.companyId] = companyPermissions;
        const workspacePermissions = companyPermissions[permission.groupAppId] ?? [];
        companyPermissions[permission.groupAppId] = workspacePermissions;
        workspacePermissions.push(permission.code);
      });
      const zipPermissions: ZipPermissions = Object.fromEntries(Object.entries(_zipPermissions).map(([companyId, workspacePermissions]) => {
        return [companyId, Object.fromEntries(Object.entries(workspacePermissions).map(([workspaceId, codeArr]) => {
          return [workspaceId, codeArr.join(',')];
        }))];
      }));
      storage.setItem(getStorageKey(entryName), JSON.stringify(zipPermissions));
    },
  },
};


const defaultStorageSetting: StorageSetting = {
  setState(entryName, entry, storage, model) {
    const primaryKeys = many(model.primaryKey);
    storage.setItem(getStorageKey(entryName), JSON.stringify(Object.fromEntries(Object.entries(entry.data).filter(([, record]) => {
      // 保证有所有primaryKey才写入缓存
      return !!primaryKeys.every((key) => has(record, key));
    }))));
  },
  getState(entryName: string, storage: Storage) {
    const key = getStorageKey(entryName);
    return {
      $connection: 'entities',
      data: tryParseJson(storage.getItem(key)) ?? {},
      $name: entryName,
    } as OrmEntry;
  },
};

type OrmRecord = Record<string, unknown>;

type OrmEntry = {
  $connection: 'entities',
  $name: string
  data: Record<string, OrmRecord>
};

type OrmState = {
  entities: {
    [entry: string]: OrmEntry
  }
};


let cacheState: OrmState | undefined;

function getState(storage: Storage) {
  cacheState = cacheState ?? {
    entities: Object.fromEntries(Object.entries(models).map(([entryName, model]) => {
      const storageSetting = sepcificStorageSettings[entryName] ?? defaultStorageSetting;
      return [
        entryName, ({
          ...storageSetting.getState(entryName, storage, model),

        } as OrmEntry),
      ];
    })),
  };
  return cacheState!;
}


const setStorageState = debounce(200, (state: typeof Store, storage: Storage) => {
  const _state = state as typeof Store & OrmState;
  Object.entries(_state.entities).forEach(([entryName, entry]) => {
    const storageSetting = sepcificStorageSettings[entryName] ?? defaultStorageSetting;
    storageSetting.setState(entryName, entry, storage, models[entryName]);
  });
});

let isSettingState = false;

function setState(key: string, state: typeof Store, storage: Storage) {
  if (isSettingState) return;
  setStorageState(state, storage);
}

const store = new Vuex.Store<any>({
  getters: {
    hidden(state) {
      if (typeof document.hasFocus !== 'function') {
        return document.hidden;
      }
      return !document.hasFocus();
    },
  },
  modules: {
    // conversation,
    // group,
    // UserStore,
    // ConversationStore,
    // GroupStore,
  },
  // modules,
  // Handle vuex data missed, when page was reloaded.
  plugins: [
    VuexORM.install(database),
    createPersistedState({
      reducer: (_state: OrmState) => {
        const state: OrmState = {
          entities: {
            permissions: _state.entities.permissions,
            settings: _state.entities.settings,
          },
        };
        return state;
      },
      getState: (key, storage: Storage) => getState(storage),
      setState,
    }),
  ],
});


window.addEventListener('storage', async (evt) => {
  if (!cacheState) return;
  if (evt.key?.startsWith('vuex-orm:') && evt.newValue) {
    const entity = evt.key.replace(/^vuex-orm:/, '');
    if (evt.newValue) {
      if (evt.newValue !== JSON.stringify(cacheState.entities[entity])) {
        const parsedData = tryParseJson(evt.newValue) as Record<string, OrmRecord>;
        isSettingState = true;
        store.commit('entities/insertOrUpdate', {
          data: Object.values(parsedData).map(item => {
            const { $id, ...noId } = item;
            return noId;
          }),
          entity,
          result: {},
        });
        await delay(13);
        isSettingState = false;
      }
    }
  }
});

export default store;

/* eslint-disable class-methods-use-this */
import '@/styles/index.scss';
import i18n from '@/i18n';
/* 必须先引用xe-utils再引用VXETable, 不然会报错 */
import 'xe-utils';
// import { commands as catalogCommands } from '@/utils/catalog';
import VueDialogInjector from '@/utils/vue/dialog-injector';
import ImageViewer from '@/components/dialog/ImageViewer';
import AppError from '@/utils/error';
// import envs from '@/utils/envs';
import { exec, getHost } from '@/utils/exec';
// import { isDark } from '@/utils/composables/use-theme';
import { getTypedDataCollector } from '@/utils/typed-data-collector';
import { normalizeUrl, getImageDimension } from '@4dst-saas/datapipe';
// import VueContextMenu from './context-menu/index';
import VXETable from '@/plugins/VxTable';
import BaseImage from './base/Image';
import Column from './Column';
import Row from './Row';
import ElDailog from './ElDialog.vue';
import Can from './Can';
import FlexibleError from './error/index.vue';
import BlockError from './error/BlockError.vue';
import InlineError from './error/InlineError.vue';
import Element from './element';
import Scrollable from './Scrollable.vue';
import ResizablePanels from './ResizablePanels.vue';
import ButtonClear from './form/button/ButtonClear.vue';


export {
  confirmBool, confirm, alert, prompt,
} from './element';

// const ErrorInjectorComponent = (context) => {
//   return <FlexibleError {...context} style={{
//     top: 0,
//     right: 0,
//     bottom: 0,
//     left: 0,
//     position: 'absolute',
//     zIndex: 11,
//     backgroundColor: isDark.value ? 'rgba(44, 44, 44, 0.9)' : 'rgba(255,255,255,0.9)',
//   }} />;
// };

const demensionCache = Object.create(null);
export async function getAndCacheDemension(src) {
  const result = demensionCache[src] ?? await getImageDimension(src);
  demensionCache[src] = result;
  return result;
}

class QtMessage {
  success(message) {
    return exec('toast', { type: 'success', message });
  }
  error(message) {
    return exec('toast', { type: 'error', message });
  }
  warning(message) {
    return exec('toast', { type: 'error', message });
  }
}


/** @typedef {import('@/typings/messagebox-shortcut').MessageBoxOptions} MessageBoxOptions */
/**
 *
 * @param {string} message
 * @param {MessageBoxOptions | string} titleOrOptions
 * @param {MessageBoxOptions} options
 */
async function qtConfirm(message, titleOrOptions, options) {
  const computedOptions = {
    message,
    ...(typeof titleOrOptions === 'string' ? { title: titleOrOptions } : titleOrOptions),
    ...options,
  };
  const qtHost = await getHost();
  qtHost.exec('confirm', [computedOptions]);
  /** @type { {action: 'confirm' | 'cancel'} } */
  const confirmResult = await qtHost.once('confirmed');
  if (confirmResult.action === 'cancel') {
    throw AppError.abort;
  }
}

export default {
  install(Vue, { router, store }) {
    Vue.use(Element);
    Vue.prototype.$qtMessage = new QtMessage();
    Vue.prototype.$qtConfirm = qtConfirm;
    // Vue.use(VueErrorInjector, {
    //   component: ErrorInjectorComponent,
    // });
    // Vue.use(VueContextMenu, {
    //   commands: {
    //     catalog: catalogCommands,
    //   },
    //   el: '#context-menu',
    // });
    Vue.component('error', FlexibleError);
    Vue.component('inline-error', InlineError);
    Vue.component('block-error', BlockError);
    Vue.component('flexible-error', FlexibleError);
    Vue.component('scrollable', Scrollable);
    Vue.component('ButtonClear', ButtonClear);
    Vue.component('VXETable', VXETable);
    Vue.component('resizable-panels', ResizablePanels);
    const oldOpenContextMenu = Vue.prototype.$openContextMenu;
    const newOpenContextMenu = function open(e, _data, handlers) {
      const { type, data, ...res } = _data;
      const dc = Object.create(getTypedDataCollector(type, data));
      Object.assign(dc, { type, ...res });
      return oldOpenContextMenu.call(this, e, dc, handlers);
    };
    Vue.prototype.$openContextMenu = newOpenContextMenu;
    Vue.use(VueDialogInjector, {
      el: '#dialog-injector',
      is: ElDailog,
      props: {
        closeWith: {
          default: () => () => AppError.abort,
        },
      },
      router,
      store,
      i18n,
      computed: {
        isBodyOverflowHidden() {
          return Object.values(this.dialogGroup).some((list) => {
            return list.some(
              ({ props: { visible } }) => visible,
            );
          });
        },
      },
      watch: {
        '$route.path': async function watchRout() {
          await this.$nextTick();
          Object.keys(this.dialogGroup).forEach(key => {
            if (key !== 'unclosable') {
              this.closeGroup(key);
            }
          });
        },
        isBodyOverflowHidden(is) {
          if (is) {
            document.body.classList.add('el-popup-parent--hidden');
          } else {
            document.body.classList.remove('el-popup-parent--hidden');
          }
        },
      },
    });
    Vue.use(ImageViewer, {
      el: '#image-viewer',
      i18n,
      propsData: {
        getDimension: getAndCacheDemension,
        async beforeShow(options) {
          options.srcList = await Promise.all(options.srcList.map(async each => {
            const result = each;
            let { msrc = options.src, src } = options;
            ([src, msrc] = await Promise.all([
              normalizeUrl(result.src),
              normalizeUrl(result.msrc),
            ]));
            return {
              ...result,
              src,
              msrc,
            };
          }));
          return options;
        },
      },
    });
    Vue.component('can', Can);
    Vue.component('row', Row);
    Vue.component('column', Column);
    Vue.component('base-image', BaseImage);
    Vue.component('lazy-image', BaseImage);
    Vue.prototype.$hasRouterView = function hasRouterView(name) {
      const { matched } = this.$route;
      const idx = matched.findIndex((item) => item.instances.default === this);
      if (idx === -1 || !matched[idx + 1]) {
        return false;
      }
      return !!matched[idx + 1].components[name];
    };
  },
};


import {
  defineComponent, ref, watch,
} from 'vue';

import loadImage from '@4dst-saas/datapipe/libs/load-image';
import cancellable, { Controller } from '@/utils/cancellable';
import { normalizeUrl } from '@4dst-saas/datapipe';
import { useEnterScreen } from '@/utils/composables/use-intersect';
import { ThumbOptions } from '@/typings/thumb-options';
import isSupportWebp from '@4dst-saas/public-utils/dist/is-support-webp';
import calcResizeParams from '@4dst-saas/public-utils/dist/calc-resize-params';
import defaultImage from '@/assets/img/public/default-image.png';

const dpr = window.devicePixelRatio ?? 1;
const gradSize: number[] = [];
function getSize(el: Element) {
  const {
    clientWidth: width,
    clientHeight: height,
  } = el;
  return {
    width, height,
  };
}
function graded({ width: _width, height: _height }: { width: number, height: number }) {
  let grad = 5;
  let size: number;
  do {
    grad++;
    gradSize[grad] ??= 2 ** grad;
    size = gradSize[grad];
  } while (size <= _width && size <= _height);
  const { width, height } = calcResizeParams(_width, _height, {
    width: size,
    height: size,
    mode: 'contain',
  });
  return {
    width, height,
  };
}

function int(num: number) {
  return parseInt(`${num}`, 10);
}

const loadThumb = async (src: string, thumb?: ThumbOptions) => {
  const thumbSrc: string = await normalizeUrl(src, thumb);
  return loadImage(thumbSrc);
};

const enum ObjectFit {
  NONE = 'none',
  CONTAIN = 'contain',
  COVER = 'cover',
  FILL = 'fill',
  SCALE_DOWN = 'scale-down',
  /** @deprecated 请使用 scale-down */
  FIT = 'fit',
}

function _waitAnimation() {
  return new Promise((resolve) => {
    requestAnimationFrame(resolve);
  });
}

async function waitAnimation(loop = 1) {
  while (loop) {
    await _waitAnimation();
    loop--;
  }
}

export default defineComponent({
  inheritAttrs: false,
  props: {
    src: String,
    fit: {
      type: String as () => ObjectFit,
      default: ObjectFit.FILL,
    },
    draggable: Boolean,
    lazy: {
      type: Boolean,
      default: true,
    },
    defaultImage: {
      type: String,
      default: defaultImage,
    },
    thumb: {
      type: Object as unknown as () => ({
        width: number, height: number,
      } | number | boolean),
    },
  },
  setup(props, { emit }) {
    const host = ref<Element>();
    const entry = useEnterScreen(host);
    const loadControl = ref<Controller<HTMLImageElement>>();
    const error = ref<Error>();
    const _loadImage = async (entryTime: number) => {
      const src = props.src!;
      // const { grade } = props;
      // TODO: 保留关闭梯度的开关
      const grade = false;
      const _fit = props.fit === ObjectFit.FIT ? ObjectFit.SCALE_DOWN : props.fit;
      const _host = host.value!;
      let thumb: ThumbOptions | undefined;
      const defaultThumb = getSize(_host);
      const _thumb = (
        // eslint-disable-next-line no-nested-ternary
        props.thumb === true ? defaultThumb
          : (typeof props.thumb === 'number' ? {
            width: props.thumb,
            height: props.thumb,
          } : props.thumb)
      ) ?? defaultThumb;
      if (_fit === ObjectFit.NONE || _thumb === false) {
        thumb = undefined;
      } else {
        thumb = {
          ...(isSupportWebp() ? { outType: 'image/webp' } : undefined),
          ..._thumb,
          mode: _fit === ObjectFit.SCALE_DOWN || _fit === ObjectFit.CONTAIN ? 'fit' : _fit,
          maxRatio: _fit === ObjectFit.SCALE_DOWN ? 1 : undefined,
          width: int(_thumb.width * dpr),
          height: int(_thumb.height * dpr),
        };
      }
      if (grade && thumb) {
        thumb = {
          ...thumb,
          ...graded(thumb),
        };
      } try {
        if (loadControl.value) {
          loadControl.value.cancel();
        }
        loadControl.value = cancellable(loadThumb(src, thumb));
        const img = await loadControl.value.promise;
        if (_fit !== ObjectFit.NONE) {
          // img.classList.add('center');
        }
        img.style.objectFit = _fit;
        img.draggable = false;
        const oldImage = host.value!.querySelector('img');
        if (oldImage) {
          oldImage.replaceWith(img);
          // TODO: 预留用于动画开关
          const animateTime = 300;
          if (animateTime) {
            img.style.opacity = '0';
            img.style.transition = `opacity ${animateTime}ms`;
            // 我不知道为啥要两个周期，动画才生效
            await waitAnimation(2);
            img.style.opacity = '1';
          }
        }
      } catch (e) {
        // console.error(e);
        error.value = e as Error;
        emit('error', e);
      } finally {
        loadControl.value = undefined;
      }
    };
    watch(() => ({
      src: props.src,
      entry: entry.value,
      lazy: props.lazy,
      fit: props.fit,
      grade: props.grade,
    }), async ({
      src, entry: _entry, lazy, fit,
    }, old) => {
      const oldSrc = old?.src;
      if (src && (_entry || !lazy) && fit) {
        _loadImage(Date.now());
      } else if (!src && oldSrc) {
        const img = host.value!.querySelector('img');
        const placeholader = new Image();
        placeholader.style.display = 'none';
        if (img) {
          img.replaceWith(placeholader);
          // internalInstance?.proxy.$forceUpdate();
        }
      }
    }, {
      immediate: true,
    });

    return {
      error,
      host,
      loadControl,
    };
  },
});

import Vue, { VueConstructor } from 'vue';
import VueRouter from 'vue-router';
import history from '@/utils/history';

export default {
  install(_Vue: VueConstructor, { router }: {router: VueRouter}) {
    history.router = router;
    _Vue.prototype.$history = history;
  },
};

declare module 'vue/types/vue' {
  interface Vue {
    $history: typeof history
  }
}

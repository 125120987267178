/* eslint-disable class-methods-use-this */
import { config } from '@/config';
import HostParams from '.';

export class FimHostParams extends HostParams {
  static hostEnv2env: { [hostEnv: string]: string } = {
    sit1: 'staging',
    sit2: 'staging',
    'sit-2d': 'staging',
    'callback-sit': 'staging',
    sit: 'staging',
    dev1: 'development',
    dev2: 'development',
    'callback-dev': 'development',
    dev: 'development',
    uat: 'uat',
    poc: 'poc',
    pt: 'pt',
    local: 'local',
    '': 'production',
  };
  static mainModule = 'art';
  static cooperators = ['anta'];
  static modules = [
    'art', 'srm', 'ideation', 'entlib', 'idealab', 'm', 'trend', 'bi', 'clothes-image', 'user', 'adobeplugin',
  ];
  isSubmodule(module: string) {
    return module === 'srm' || module === 'entlib';
  }
  static areas = ['en', 'cn', 'us'];
  getDomainsSeperator(tld: string, fld: string) {
    // eslint-disable-next-line no-nested-ternary
    return fld === '4dstc' || tld === 'local' ? '.' : '-';
  }
  isJoinMainModuleName(tld: string, fld: string, otherDomains: string[]) {
    if (fld === config.alias_proxy.revofim) return false;
    if (fld === '4dstc') return true;
    return !otherDomains.length;
  }
}

export default FimHostParams;

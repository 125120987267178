import AppError from './error';
import Ideation from './hosts/ideation';
import Teams from './hosts/teams';

const hosts = [Ideation, Teams];

// 执行宿主上的部分方法

let cacheHost;
async function _getHost() {
  for (let i = 0; i < hosts.length; i++) {
    const HostConstructor = hosts[i];
    // eslint-disable-next-line no-await-in-loop
    if (await HostConstructor.detect()) {
      // eslint-disable-next-line no-await-in-loop
      const host = await (new HostConstructor());
      // eslint-disable-next-line no-await-in-loop
      return host;
    }
  }
  return undefined;
}
export function getHost(init = {}) {
  if (typeof init === 'object') {
    const hostEvents = init;
    init = (host) => {
      Object.keys(hostEvents).forEach(key => {
        host.on(key, hostEvents[key]);
      });
    };
  }
  cacheHost = cacheHost ?? _getHost();
  cacheHost.then((host) => {
    init(host);
  });
  return cacheHost;
}

export async function exec(command, ...args) {
  const host = await getHost();
  const isIframe = window.self !== window.top;
  if (host) {
    host.exec(command, args);
  }
  if (isIframe) {
    window.parent.postMessage(command, window.parent.origin);
  }
  if (!host && !isIframe) {
    console.groupCollapsed(`运行了宿主对象的${command}方法，运行参数如下`);
    console.table(args);
    console.groupEnd();
    throw new AppError(`No host injected for executing command "${command}"`);
  }
}
export default exec;

import {
  computed, reactive, ref, shallowReactive, shallowRef,
} from 'vue';
import DataCollector, { WithId, Reducers } from '../data-collector';


type Access<D extends WithId = WithId> = () => { command: string, data: D, type: string } | null;
type Mutate<D extends WithId = WithId> = (res: {command ?: string, data: D | D[] | undefined, type: string }) => void;
type Clear = () => void;

export type TypedDataCollector<Type extends string = string, Data extends WithId = WithId> = {
  type: Type,
  readonly data: Data | Data[] | undefined,
  readonly datum: Data | undefined,
  readonly dataArr: Data[],
  contains: (datum: Data) => boolean,
  toObject: () => { data: Data[] | Data }
  clear: () => void,
  command: string,
  get(): (DataCollector<Data> & { command: string, type: Type }) | null,
  set(rawDataCollector: { command ?: string, data?: any, type?: string, [otherKey: string]: any }): void
};

export function useDataCollector<Data extends WithId = WithId, DC extends DataCollector<Data> = DataCollector<Data>>(
  getCollector: (type: string, data: Data | Data[], idKey: string) => DC | null,
  {
    access, mutate, clear, idKey = 'id',
  } : {access?: Access<Data>, mutate?: Mutate<Data>, clear?: Clear, idKey?: string } = {},
): TypedDataCollector<string, Data> {
  const parsed = access?.() ?? { data: null, type: 'unknown' };
  let data = null;
  let type = 'unknown';
  if (parsed) {
    ({ data, type } = parsed);
    if (!parsed.data) {
      console.warn('data collector 所储存的data为空');
    }
  }

  const rDc = shallowRef(
    data ? getCollector(type, data, idKey) : null,
  );
  type WithId = { id: string } & { [otherKey in KeyType]: any };
  const rType = ref<string>(type);
  const rCommand = ref<string>('');
  const rClear = () => {
    rDc.value = null;
    clear?.();
  };
  return reactive({
    type: rType,
    data: computed(() => rDc.value?.data),
    datum: computed(() => rDc.value?.datum),
    dataArr: computed(() => rDc.value?.dataArr),
    contains: (item: WithId) => {
      return rDc.value?.contains(item) ?? false;
    },
    command: rCommand,
    set({
      command: _command,
      type: _type = 'unknown', ...obj
    }: { command ?: string, data?: any, type?: string, [otherKey: string]: any } = {}) {
      if (obj.data) {
        if (_command) {
          rCommand.value = _command;
        }
        rType.value = _type;
        if (!rDc.value || rDc.value.data !== obj.data) {
          let { data: _data } = obj;
          if (Array.isArray(_data)) {
            _data = [..._data];
          }
          const dc = getCollector(_type as string, _data, idKey);
          if (dc) {
            mutate?.({ command: _command, data: dc.data, type: rType.value });
            // @ts-ignore
            rDc.value = dc;
          }
        }
      } else {
        rClear();
      }
    },
    toObject() {
      const dc = rDc.value;
      if (!dc) return {};
      return dc.toObject();
    },
    toJSON() {
      const dc = rDc.value;
      if (!dc) return null;
      if (dc) {
        return { type: rType.value, data: dc.data, command: rCommand.value };
      }
      return null;
    },
    clear: rClear,
    get(): (DC & { value: string, command: string }) | null {
      if (rDc.value) {
        return Object.create(rDc.value, {
          command: {
            value: rCommand.value,
          },
          type: {
            value: rType.value,
          },
        });
      }
      return null;
    },
  }) as unknown as TypedDataCollector<string, Data>;
}

export default useDataCollector;

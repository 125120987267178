
import { onMounted, ref, VueConstructor } from 'vue';
import BlockError from './BlockError.vue';
import InlineError from './InlineError.vue';

export default {
  inheritAttrs: false,
  props: {
    error: {},
    mode: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const rComponent = ref<VueConstructor>();
    const rContainer = ref<Element>();
    onMounted(() => {
      if (props.mode) {
        rComponent.value = props.mode === 'block' ? BlockError : InlineError;
      } else {
        const container = rContainer.value!;
        if (container.clientWidth > 300 && container.clientHeight > 300) {
          rComponent.value = BlockError;
        } else {
          rComponent.value = InlineError;
        }
      }
    });
    return {
      container: rContainer,
      component: rComponent,
    };
  },
};

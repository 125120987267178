import axios from './axios';

/**
 * 提交意见反馈
 * @param {Object} data
 * @returns {Promise}
 */
// eslint-disable-next-line import/prefer-default-export
export const submitOpinion = (data) => {
  return axios({
    method: 'post',
    url: '/authcenter/feedback/save',
    data,
  });
};

import * as Entry from '@/packages/Entry';
import * as AppIdConfig from '@/packages/AppIdConfig';
import * as Auth from '@/packages/Auth';
import { getContextPlatformInfoList, getContextCompany } from '@/loaders/context';
import axios from '@/utils/axios';
import companyUtils from '@/utils/company';
import tokenUtils from '@/utils/token';

export const useValidPlatform = () => {
  const getSource = () => {
    return getContextPlatformInfoList();
  };

  const isValid = (appId: string) => {
    const platformInfoList = getSource();
    const target = platformInfoList.find(a => a.appId === appId);

    if (target && !target.isForbid && !target.isExpireTime) {
      return { _tag: 'valid_platform' } as const;
    } else if (target.isForbid) {
      return { _tag: 'forbid_platform' } as const;
    } else if (target.isExpireTime) {
      return { _tag: 'expire_platform' } as const;
    }
    return { _tag: 'not_found_platform' } as const;
  };

  return {
    getSource,
    isValid,
  }
};

export const usePermissionPlatform = () => {
  const { getEntry } = Entry.useEntry();
  const { getSource: getValidPlatformSource } = useValidPlatform();
  const appIdConfig = AppIdConfig.useAppIdConfig();


  const getPermissionPlatform = () => {
    const entry = getEntry();
    const platformInfoList = getValidPlatformSource();
    let result = platformInfoList
      .filter(a => appIdConfig.hasPermission(a.appId))
      .map(a => ({
        appId: a.appId,
        name: appIdConfig.getName(a.appId),
      }));
    if (entry._tag === 'one-entry') {
      result = result.sort((a) => {
        return a.appId === entry.value ? -1 : 1;
      });
    }
    return result;
  };
  const hasPermission = () => {
    return getPermissionPlatform().length > 0;
  };
  const getAppId = () => {
    return getPermissionPlatform()[0]?.appId;
  };

  return {
    getPermissionPlatform,
    hasPermission,
    getAppId,
  };
};

export const usePlatformExpire = () => {
  const { getEntry } = Entry.useEntry();

  const getSource = async () => {
    const { data } = await axios({
      method: 'POST',
      url: '/authcenter/v1.0/user/platform/info',
      data: {
        userId: tokenUtils.getUserId(),
        companyId: companyUtils.get(),
      },
    });
    return data;
  };
  const getExpireDays = async () => {
    const entry = getEntry();
    const data = await getSource();

    if (data.length === 0) {
      return { _tag: 'not_found_days' } as const;
    }

    if (entry._tag === 'self-entry') {
      const hasInfinity = data.find(a => a.days === null);
      return {
        _tag: 'valid_days',
        value: hasInfinity ? null : Math.max(...data.map(a => a.days)),
      } as const;
    }

    const target = data.find(a => a.appId === entry.value);
    return target
      ? { _tag: 'valid_days', value: target.days } as const
      : { _tag: 'not_found_days' } as const;
  };


  return {
    getSource,
    getExpireDays,
  };
};

export const usePlatformAuth = () => {
  const { getEntry } = Entry.useEntry();
  const auth = Auth.useAuth();
  const { getSource } = useValidPlatform();
  const { getPermissionPlatform } = usePermissionPlatform();
  const { getName } = AppIdConfig.useAppIdConfig();

  const updatePlatformAuth = async () => {
    await auth.updateAuth(getSource().map(a => a.appId));
  };

  const getSourceWithAuth = (code: string) => {
    const entry = getEntry();
    // console.log(getPermissionPlatform())
    let result = getPermissionPlatform()
      .filter(a => {
        return auth.auth(a.appId, code);
      })
      .map(a => ({
        appId: a.appId,
        name: getName(a.appId),
      }));
    if (entry._tag === 'one-entry') {
      result = result.sort((a) => {
        return a.appId === entry.value ? -1 : 1;
      });
    }
    return result;
  };

  const hasProfileAccount = () => {
    const platformList = getSourceWithAuth('ILI050413');
    return platformList.length > 0;
  };
  const hasProfileRole = () => {
    const platformList = getSourceWithAuth('ILI050401');
    return platformList.length > 0;
  };
  const hasProfileOrg = () => {
    const platformList = getSourceWithAuth('ILI050408');
    return platformList.length > 0;
  };
  const hasProfileTeam = () => {
    const platformList = getSourceWithAuth('SYS001');
    return platformList.length > 0;
  };

  return {
    updatePlatformAuth,
    getSourceWithAuth,

    hasProfileAccount,
    hasProfileRole,
    hasProfileOrg,
    hasProfileTeam,
  };
};

export const useApplicationLink = () => {
  const { getSource } = AppIdConfig.useApplicationLink();

  const getApplicationLink = () => {
    const company = getContextCompany();
    return getSource()
      .filter(a => !!a.links)
      .filter(a => {
        if (a.accessLevel === 0 && company?.isCompanyAdmin) return true;
        if (a.accessLevel === 1 && company) return true;
        if (a.accessLevel === 2) return true;
        return false;
      });
  };

  return {
    getApplicationLink,
  };
};


import Vue, { VueConstructor } from 'vue';


export function closest(this: Vue, componentName: string) {
  let vm = this;
  let result;
  while (vm && !result) {
    const name = vm.$options.name || vm.$options;
    if (name === componentName) {
      result = vm;
    }
    vm = vm.$parent;
  }
  return result;
}

export default {
  install(_Vue: VueConstructor) {
    _Vue.prototype.$closest = closest;
  },
};

declare module 'vue/types/vue' {
  interface Vue {
    $closest: typeof closest,
  }
}

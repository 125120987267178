import { getCurrentInstance } from 'vue';
import type { default as Vue } from 'vue';
import renderTemplate from '_/render-template';
import instance from './instance';

const [getI18n, setI18n] = instance<typeof Vue>();

const useI18n = () => getI18n() || getCurrentInstance()?.proxy.$i18n;

// 内部使用的 i18nPlugin 不支持动态扩展
const resource = {
  en: {} as Record<string, any>,
  cn: {} as Record<string, any>,
};
const simpleI18n = (path: string, { en, cn }: typeof resource) => () => {
  const i18n = useI18n();

  if (!resource.en[path]) resource.en[path] = en;
  if (!resource.cn[path]) resource.cn[path] = cn;

  const t = (key: string, options?: Record<string, any>) => {
    const template = resource[i18n.language][path][key];
    if (template) return renderTemplate(template, options || {});
    return [path, key].join(' ') + 'invalid';
  };

  return {
    t,
    language: i18n.language,
  };
};

export {
  setI18n,
  getI18n,
  simpleI18n,
};

/**
 * 与目录相关的所有接口
 */
import axios from './axios';

/* ======================= 与登录账号相关 ============================ */
/**
 * 获取登录账号的鞋模型和材质的my-upload文件目录的Id
 * @returns {Promise}
 */
export const getMyUploadIds = () => {
  return axios({
    method: 'get',
    url: '/catalogue/yunpanCatalog/getPersonalUploadCIds',
  });
};

export const getCatalogUrl = (catalogId) => {
  return axios.get('/catalogue/yunpanCatalog/getCatalogUrl', {
    params: {
      catalogId,
    },
  });
};

// 初始化目录树
export const initModuleCatelog = () => {
  return axios({
    method: 'get',
    url: '/catalogue/yunpanCatalog/initOrg',
  });
};

// 获取模块根目录的列表信息
export const getModuleRootCatalog = (appType, isAllFile, relateId, descs, ascs) => {
  return axios({
    method: 'get',
    url: '/catalogue/yunpanCatalog/getRelateCatalog',
    params: {
      appType,
      isAllFile,
      relateId,
      descs,
      ascs,
    },
  });
};

/**
 * @see http://yapi.4dshoetech.local/project/40/interface/api/3203
 * @param {(
 *  ({ from: string } | {groupAppId: string})
 *  && {catalogId: string, relateType: 1 | 0}
 * )} params
 * @returns {PromiseLike<{data: string}>}
 */
export const getFileRelateIdByCatalogId = ({
  groupAppId,
  from,
  ...params
}) => {
  return axios({
    headers: {
      groupAppId: groupAppId || from || '',
    },
    method: 'get',
    url: '/catalogue/appInteraction/getCurrentRelateId',
    params,
  });
};


// 创建文件夹
export const createCatalog = (appType, name, parentFolderId = '-1', relateId) => {
  return axios({
    method: 'post',
    url: '/catalogue/yunpanCatalog/create',
    headers: {
      groupAppId: relateId,
    },
    data: {
      appType,
      name,
      parentFolderId,
      // relateId,
    },
  });
};

// 重命名文件
export const renameCatalog = (id, name, groupAppId = '') => {
  return axios({
    method: 'post',
    url: '/catalogue/yunpanCatalog/rename',
    headers: {
      groupAppId,
    },
    data: {
      catalogId: id,
      name,
    },
  });
};

// 删除文件
export const deleteCatalog = (data, groupAppId) => {
  return axios({
    method: 'post',
    url: '/catalogue/yunpanCatalog/delete',
    headers: {
      groupAppId,
    },
    data,
  });
};


// 复制文件
export const copyCatalog = (appType, oldFolderIds, targetFolderId, relateId) => {
  return axios({
    method: 'post',
    url: '/catalogue/yunpanCatalog/copy',
    headers: {
      groupAppId: relateId,
    },
    data: {
      appType,
      oldFolderIds,
      targetFolderId,
      fileType: -1,
    },
  });
};

// 解锁或锁定文件
export const toggleCatalogStatus = (folderIds, status) => {
  return axios({
    method: 'put',
    url: '/catalogue/yunpanCatalog/status',
    data: {
      folderIds,
      status,
    },
  });
};

// 在快捷方式栏添加文件的快捷方式
export const createShortcut = data => {
  return axios({
    method: 'post',
    url: '/catalogue/shortcut/addShortcut',
    data,
  });
};

// 获取快捷方式栏的快捷方式
export const fetchShortcut = () => {
  return axios({
    method: 'get',
    url: '/catalogue/shortcut/getShortcut',
  });
};

// 删除快捷方式
export const deleteShortcut = data => {
  return axios({
    method: 'post',
    url: '/catalogue/shortcut/delShortcut',
    data,
  });
};

// 搜索文件
export const searchCatalog = (params, groupAppId) => {
  return axios({
    method: 'get',
    url: '/catalogue/yunpanCatalog/homeSearch',
    headers: {
      groupAppId,
    },
    params,
  });
};

// 重定向模型对应的渲染页url
export const reloadModelRendererUrl = (params) => {
  return axios({
    method: 'get',
    url: '/catalogue/appInteraction/model',
    headers: {
      groupAppId: params.from === 0 ? '' : params.from,
    },
    params,
  });
};

// 获取模型或面料详情
export const getModelOrMaterialDetail = ({
  catalogId,
  relateType,
  relateId,
}) => {
  return axios({
    method: 'get',
    url: '/catalogue/appInteraction/getAppInfo',
    headers: {
      groupAppId: relateId,
    },
    params: {
      catalogId,
      relateType,
    },
  });
};

// 获取最近文件的路径
export const getCurrentFilePath = params => {
  return axios({
    method: 'get',
    url: '/catalogue/yunpanCatalog/getRecentlyBrowsedPath',
    params,
  });
};

// 获取最近文件
export const getCurrentFiles = params => {
  return axios({
    method: 'get',
    url: '/catalogue/yunpanCatalog/getRecentlyBrowsedRecords',
    params,
  });
};

// 获取根目录文件夹和文件（分组）
export const getRootFoldersAndFiles = (params, groupAppId) => {
  return axios({
    method: 'get',
    headers: {
      groupAppId,
    },
    url: '/catalogue/yunpanCatalog/getRelateCatalogHome',
    params,
  });
};

export const getFolderFoldersAndFiles = (params, groupAppId) => {
  return axios({
    method: 'get',
    headers: {
      groupAppId,
    },
    url: '/catalogue/yunpanCatalog/getChildCatalogHome',
    params,
  });
};

export const getPanelBoardList = (groupAppId = '') => {
  return axios({
    method: 'get',
    url: '/catalogue/yunpanCatalog/getPanelBoardList',
    headers: {
      groupAppId,
    },
  });
};

/*  =====================================  workspace相关  =====================================  */

export const fetchWorkspaces = appId => {
  return axios({
    method: 'get',
    url: '/authcenter/manage/groupapp/list',
    params: {
      appId,
    },
  });
};
// 更改workspace列表的顺序
export const changeSequence = (data) => {
  return axios({
    method: 'post',
    url: '/authcenter/manage/groupapp/sort/update',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
};
// 置顶workspace
export const stickWorkspace = (groupAppId, operation) => {
  return axios({
    method: 'post',
    url: '/authcenter/manage/groupapp/stick',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      groupAppId,
      operation,
    },
  });
};
// export const changeSequence = (groupAppId, data) => {
//   return axios({
//     method: 'post',
//     url: '/authcenter/manage/groupapp/offset',
//     headers: {
//       groupAppId,
//       'Content-Type': 'application/json',
//     },
//     data,
//   });
// };

// 协同空间重命名
export const renameWorkspace = (groupAppId, name) => {
  return axios({
    method: 'post',
    url: '/authcenter/manage/groupapp/rename',
    headers: {
      groupAppId,
    },
    data: {
      name,
    },
  });
};

// 获取内部成员列表
export const fetchInnerMembers = () => {
  return axios({
    method: 'get',
    url: '/authcenter/user/list',
    params: {
      keyWord: '',
      accountType: '',
      isOuter: 0,
    },
  });
};

// 去重版
export const fetchDeduplicatedInnerMembers = (groupAppId) => {
  return axios({
    method: 'get',
    url: '/authcenter/user/list/invite',
    headers: {
      groupAppId,
    },
    params: {
      accountType: 0,
    },
  });
};
export const fetchDeduplicatedOuterMembers = (groupAppId) => {
  return axios({
    method: 'get',
    url: '/authcenter/user/list/invite',
    headers: {
      groupAppId,
    },
    params: {
      accountType: 1,
    },
  });
};

export const fetchOuterMembers = () => {
  return axios({
    method: 'get',
    url: '/authcenter/user/outer/nostatus/list',
  });
};

// 最近邀请成员成员列表
export const fetchInvitMembers = (id) => {
  return axios({
    method: 'get',
    url: '/authcenter/ugroup/current',
    headers: {
      groupAppId: id,
    },
  });
};


// 获取子目录
export const getResourceSublist = (config, groupAppId = '') => {
  return axios({
    method: 'get',
    url: '/catalogue/yunpanCatalog/getChildCatalog',
    params: config,
    headers: {
      groupAppId,
    },
  });
};

// 创建新目录
export const createResourceItem = (data, groupAppId = '') => {
  return axios({
    method: 'post',
    url: '/catalogue/yunpanCatalog/create',
    data,
    headers: {
      groupAppId,
    },
  });
};

// 重命名目录名称
export const updateResourceItemName = (data, groupAppId = '') => {
  return axios({
    method: 'post',
    url: '/catalogue/yunpanCatalog/rename',
    data,
    headers: {
      groupAppId,
    },
  });
};

// 改变目录的状态
export const updateResourceItemStatus = (folderIds, status, groupAppId = '') => {
  return axios({
    method: 'put',
    url: '/catalogue/yunpanCatalog/status',
    data: {
      folderIds,
      status,
    },
    headers: {
      groupAppId,
    },
  });
};

// 删除目录 -- api只做单个删除
export const deleteResourceItem = (id, groupAppId = '') => {
  return axios({
    method: 'post',
    url: '/catalogue/yunpanCatalog/delete',
    data: [id],
    headers: {
      groupAppId,
    },
  });
};

// 目录复制
export const copyFilesToFolders = (data, groupAppId = '') => {
  return axios({
    method: 'post',
    url: '/catalogue/yunpanCatalog/copy',
    data,
    headers: {
      groupAppId,
    },
  });
};


// 获取固定工作版和临时工作版列表
export const getAllCategoriesList = (config) => {
  return axios({
    method: 'get',
    url: '/catalogue/yunpanCatalog/getTempAndFixedCatalog',
    params: config,
  });
};

// 公共材质目录
export const getAllCatagoriesOfPublic = (config) => {
  return axios({
    method: 'get',
    url: '/catalogue/yunpanCatalog/getMaterialLibrary',
    params: config,
  });
};

/* ================================ 资源中心 -- 搜索 ========================= */

export const searchResourceByKeyword = (params, groupAppId) => {
  return axios({
    method: 'get',
    url: '/catalogue/yunpanCatalog/search',
    headers: {
      groupAppId,
    },
    params,
  });
};

export const getHiddenCatalog = (catalogId, groupAppId) => {
  return axios({
    method: 'get',
    url: '/catalogue/yunpanCatalog/getHiddenCatalog',
    params: {
      catalogId,
    },
    headers: {
      groupAppId,
    },
  });
};

import axios from '@/utils/axios';
import { simpleStore } from '@/packages/container';

const useStore = simpleStore<Record<string, any>>('auth-state', () => ({}));
const useAuthStore = () => {
  const state = useStore();

  const setData = (appId: string, data: any) => {
    state[appId] = data;
  };
  const getData = (appId: string) => {
    return state[appId];
  };

  return {
    setData,
    getData,
  };
};

const getAuth = async (appIds: string[]) => {
  const { data } = await axios.post('/authcenter/v1.0/operation/auth/systems', appIds);
  return data;
};
export const useAuth = () => {
  const authStore = useAuthStore();

  const updateAuth = async (appIds: string[]) => {
    const source = await getAuth(appIds);
    source.forEach(a => {
      authStore.setData(a.appId, a.operateInfo.reduce((result, a) => {
        result[a.code] = a;
        return result;
      }, {} as Record<string, any>));
    });
  };
  const auth = (appId: string, code: string) => {
    // 临时这样处理，实在改不动了
    if (['ILI050401', 'ILI050408', 'ILI050413'].includes(code)) {
      const data = authStore.getData(appId);
      if (data) return true;
    }
    const data = authStore.getData(appId);
    if (!data) return false;

    const target = data[code];
    return target ? target.value : false;
  };

  return {
    updateAuth,
    auth,
  };
};

// import Context from '@/models/context';
import Permission, { PermissionOrm } from '@/models/Permission';
import getGroupAppId from '@/router/utils/get-group-app-id';
import router from '@/router';
import { Route } from 'vue-router';
import AppError from '../error';

function getId(groupId: string, code: string): string {
  return [groupId, code].join('-');
}
type CanCache = { [id: string]: Permission };
const canCacheMap = new WeakMap<Route, CanCache>();

/**
 *
 * @param code 权限码
 * @param param 包含workspaceId的对象
 * @param allow 权限表没有找到对应的权限时是否允许
 */
export function can(code: number | string, { workspaceId = '' } = {}, allow = false) {
  let computedWorkspaceId: string | undefined = workspaceId;
  if (computedWorkspaceId === 'workspace') {
    computedWorkspaceId = getGroupAppId();
  } else if (computedWorkspaceId === 'system') {
    computedWorkspaceId = '';
  }
  if (computedWorkspaceId == null) {
    return;
  }
  if (!code) return;
  code = `${code}`;
  // if (!code.startsWith('OP')) {
  //   code = `OP${code.padStart(4, '0')}`;
  // }
  if (/^\d+$/.test(code)) {
    code = `OP${code.padStart(4, '0')}`;
  }
  const groupAppId = computedWorkspaceId || 'system';
  let operation: any;
  const id = getId(groupAppId, code);

  // console.log('code =  ', code);
  // console.log('id =  ', id);
  let cache: CanCache | undefined;
  if (router.currentRoute) {
    cache = canCacheMap.get(router.currentRoute) ?? {};
    canCacheMap.set(router.currentRoute, cache);
  }
  if (cache) {
    operation = cache[id];
    if (operation === undefined) {
      cache[id] = PermissionOrm.query().where('groupAppId', groupAppId).where('code', code).first() as Permission;
      operation = cache[id];
    }
  } else {
    operation = PermissionOrm.query().where('groupAppId', groupAppId).where('code', code).first() as Permission;
  }
  if (operation) {
    if (!operation.value) throw AppError.auth;
  } else if (!allow) throw AppError.auth;
}

export default can;

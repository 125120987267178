import { getCurrentInstance } from 'vue';
import type { default as Vue } from 'vue';
import instance from './instance';

const [getApp, setApp] = instance<typeof Vue>();

const useApp = () => getApp() || getCurrentInstance()?.root;

export {
  setApp,
  getApp,
  useApp,
};

import { A } from 'ts-toolbelt';
import keys from '_/keys';
import sameReducer from '_/same-reducer';


// 这个模块用于整理特定类型的数据，便于多个模块传递的时候的数据统一

/*

  比如
  全局的vue实例，附有一些全局对象

  contextMenuData: 右键的目标对象
  draggingData: 拖动时候暂存的目标对象
  clipBoardData: 复制的目标对象
  上述三个对象设置的方法都能通过赋值来设置
  this.$root.draggingData = {
    data: {}, // 目标的数据，对象或者对象的数组
    type: '', // 数据类型，请跟models名尽量保持一致
    command: '', // 所做的操作。比如拖动时会复制，则为 copy
  }
  获取该对象会跟设置的不一致
  this.$root.draggingData = {
    data:  {},
    type: '',
    command: '', // 上述三个同上

    datum: '', // 如果赋值时，data为数组，datum则是data的第一项，否则为data,
    dataArr: '', // 如果赋值时，data为数组，dataArr则为data，否则为[data]
    ...sumUpData, // 剩余的键值则是data为数组时，对data的合计属性，比如data如果是商品，则sumUpData可能含有总价
                  // 合计属性的计算方法由reducer提供，reducer为一个函数，具体看以下代码
  }

*/

export type WithId<IdKey extends string = 'id'> = {
  [id in IdKey]: string | number
} & {
  [key: A.Key]: unknown
};
type Nullable<T> = T | null | undefined;
type ReducerFunction<T> =
  (a: Nullable<T>, b: Nullable<T>) => Nullable<T>;
export type Reducers<T> = {
  [key in (keyof T)]?: ReducerFunction<T[key]>
};


type SumupData<D = WithId, R = Reducers<D>> = {
  [sumUpProp in keyof R]: sumUpProp extends keyof D ? Nullable<D[sumUpProp]> : never;
};

export { sameReducer };

export class DataCollector<Data extends WithId = WithId, R extends Reducers<Data> = Reducers<Data>> {
  data: Data | Data[] | undefined;
  idKey: string;
  get dataArr() {
    if (Array.isArray(this.data)) {
      return this.data;
    }
    return this.data ? [this.data] : this.data;
  }
  get datum() {
    if (Array.isArray(this.data)) {
      return this.data[0];
    }
    return this.data;
  }
  push(item: Data) {
    if (!this.dataArr) {
      this.data = [item];
    } else if (!Array.isArray(this.data)) {
      this.data = [this.data!];
    }
    this.data.push(item);
  }
  clear() {
    this.data = undefined;
  }
  contains(item: WithId) {
    if (!this.dataArr) return false;
    return this.dataArr.some((_item: any) => _item[this.idKey] === item[this.idKey]);
  }
  toObject() {
    if (!this.dataArr) return {};
    return Object.fromEntries(this.dataArr.map(item => {
      return [item[this.idKey], item];
    }));
  }
  toJSON() {
    if (this.data) {
      return this.data;
    }
    return undefined;
  }
  static create<
    _Data extends WithId = WithId,
    _R extends Reducers<_Data> = Reducers<_Data>
  >(data: _Data | _Data[], options?: { reducers?: _R, idKey?: string }) {
    return (new DataCollector(data, options)) as DataCollector<_Data, _R> & SumupData<_Data, _R>;
  }
  constructor(data: Data | Data[], {
    reducers, idKey = 'id',
  }: { reducers?: R, idKey?: string } = {}) {
    this.data = data;
    this.idKey = idKey;
    if (reducers) {
      Object.defineProperties(this, Object.fromEntries(keys(reducers!).map((key) => {
        const r = reducers[key] ?? sameReducer;
        return [key, {
          get() {
            if (!this.dataArr) return undefined;
            return this.dataArr.map(item => item[key]).reduce((sumup, v) => {
              /** @ts-ignore */
              return r(sumup, v);
            }, undefined as any);
          },
        } as PropertyDescriptor & ThisType<DataCollector<Data>>];
      })));
    }
  }
}


export default DataCollector;

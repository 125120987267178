import { setApp, getApp } from './app';
import { setStore, getStore } from './store';
import { setI18n, getI18n } from './i18n';

interface I18n {
  language: string,
}

type Params = {
  app: ReturnType<typeof getApp> | null,
  store: ReturnType<typeof getStore>,
  i18n: I18n,
};

const container = ({ app, store, i18n }: Params) => {
  return <T extends readonly any[], R>(fn: (...t: T) => R) => {
    return function (...t: T): R {
      const prevApp = getApp();
      const prevStore = getStore();
      const prevI18n = getI18n();

      setApp(app);
      setStore(store);
      setI18n(i18n);

      try {
        // @ts-ignore
        return fn.call(this, ...t);
      } finally {
        setApp(prevApp);
        setStore(prevStore);
        setI18n(prevI18n);
      }
    };
  };
};

export {
  container,
};

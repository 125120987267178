
import { i18n } from '@/i18n';
import {
  defineComponent,
  ref,
} from 'vue';
import envs from '@/utils/envs';
import { useProtocol } from '@/packages/AppIdConfig/useProtocol';
import ShowTemplate from './Template.vue';

const Policy = defineComponent({
  name: 'Policy',
  components: {
    ShowTemplate,
  },
  setup() {
    const { getService } = useProtocol();

    const url = ref<string | null>(getService()[i18n.language as 'cn' | 'en']
      || `${envs.VUE_APP_POLICY_URL}/${i18n.language}/4DSTC_THE LICENSE AGREEMENT.md`);
    return {
      url,
    };
  },
});
export default Policy;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { F } from 'ts-toolbelt';
import relation from '@4dst-saas/public-utils/dist/relation';


type App = {
  mixin(options: unknown): unknown
};
type ComponentPublicInstance = Record<string | number, unknown>;


const sblAction = Symbol('action');
type ActFunction = F.Function & { [sblAction]?: F.Function };
type ActCache = {[cacheKey: string]: Promise<unknown> | null };
type PickTypeFields<T, K> = { [P in keyof T]: T[P] extends K ? P : never }[keyof T];
type FnNameOf<V extends ComponentPublicInstance> = Extract<PickTypeFields<V, F.Function>, string>;


function getActionMethod<Fn extends F.Function>(vm: ComponentPublicInstance, fn: Fn): Fn;
function getActionMethod<V extends ComponentPublicInstance, FnName extends FnNameOf<V>>(vm: ComponentPublicInstance, fnName: FnName): V[FnName];
function getActionMethod(vm: ComponentPublicInstance, fnName: string): F.Function;
function getActionMethod(vm: ComponentPublicInstance, fnOrFnName: F.Function | string): F.Function {
  if (typeof fnOrFnName === 'function') {
    return fnOrFnName;
  }
  const v = vm[fnOrFnName as keyof typeof vm];
  if (typeof v === 'function') {
    return v as F.Function;
  }
  throw new Error('Not a function');
}
function set(vm: ComponentPublicInstance, target: Record<string, unknown>, key: string, value: unknown): void {
  if (typeof vm.$set === 'function') {
    vm.$set(target, key, value);
  } else {
    target[key] = value;
  }
}
const cacheMapKey = 'actCache$' as const;

type VueWithActCache = ComponentPublicInstance & { [cacheMapKey]: ActCache };
function isActing(this: ComponentPublicInstance, fn: ActFunction) {
  fn = getActionMethod(this, fn);
  const cacheKey = relation(this, fn);
  // debugger;
  const self = this as VueWithActCache;
  return !!self[cacheMapKey][cacheKey];
}
type Hook = (vm: ComponentPublicInstance, ...args: unknown[]) => unknown;
type Options<F extends F.Function = F.Function> = {
  extraParams?: unknown | unknown[],
};
function isOptions(extraParamsOrOptions: unknown): extraParamsOrOptions is Options {
  return typeof extraParamsOrOptions === 'object' && !Array.isArray(extraParamsOrOptions);
}
export default {
  install<S extends Hook, E extends Hook, B extends Hook>(
    _Vue: App, { before, success, error }: { before?: B, success?: S, error?: E },
  ): void {
    function action<Fn extends F.Function>(this: ComponentPublicInstance, fn: Fn, options: Options<Fn>): Fn;
    function action<Fn extends F.Function>(this: ComponentPublicInstance, fn: Fn, extraParams: Options<Fn>['extraParams']): Fn;
    function action<V extends ComponentPublicInstance, FnName extends FnNameOf<V>>(vm: ComponentPublicInstance,
      fnName: FnName, options: Options<V[FnName]>): V[FnName];
    function action<V extends ComponentPublicInstance, FnName extends FnNameOf<V>>(vm: ComponentPublicInstance,
      fnName: FnName, extraParams: Options<V[FnName]>['extraParams']): V[FnName];
    function action(
      this: ComponentPublicInstance,
      fnOrFnName: unknown,
      extraParamsOrOptions: Options | Options['extraParams'] = {
        extraParams: [],
      },
    ) {
      const _f = getActionMethod(this, fnOrFnName as string);
      // @ts-ignore
      const fn: typeof _f & {[sblAction]: typeof _f} & F.Function = _f;
      const options: Options = isOptions(extraParamsOrOptions) ? extraParamsOrOptions : { extraParams: extraParamsOrOptions };
      if (fn[sblAction]) {
        return fn[sblAction];
      }
      async function wrapFn(this: VueWithActCache, ...args: unknown[]) {
        const { extraParams: _extraParams } = options;
        const extraParams = (Array.isArray(_extraParams) ? _extraParams : [_extraParams]) as unknown[];
        const cacheKey = relation(this, fn);
        if (before) {
          before(this, extraParams, fn, ...args);
        }
        try {
          let cache = this[cacheMapKey][cacheKey];
          if (!cache) {
            cache = fn.call(this, ...args);
            set(this, this[cacheMapKey], cacheKey, cache);
          }
          const r = await cache;
          if (success) {
            success(this, extraParams, fn, ...args);
          }
          return r;
        } catch (e) {
          if (error) {
            error(this, e as Error, extraParams, fn, ...args);
          }
          return e;
        } finally {
          set(this, this[cacheMapKey], cacheKey, null);
        }
      }
      fn[sblAction] = wrapFn;
      return fn[sblAction];
    }

    // @ts-ignore
    function act<Fn extends F.Function>(this: ComponentPublicInstance,
      fn: Fn, args?: unknown[], options?: Options<Fn>): ReturnType<Fn>;
    function act<Fn extends F.Function>(this: ComponentPublicInstance,
      fn: Fn, args?: unknown[], extraParams?: Options<Fn>['extraParams']): ReturnType<Fn>;
    function act<V extends ComponentPublicInstance, FnName extends FnNameOf<V>>(vm: ComponentPublicInstance,
      fnName: FnName, args?: unknown[], options?: Options<V[FnName]>): ReturnType<V[FnName]>;
    function act<V extends ComponentPublicInstance, FnName extends FnNameOf<V>>(vm: ComponentPublicInstance,
      fnName: FnName, args?: unknown[], extraParams?: Options<V[FnName]>['extraParams']): ReturnType<V[FnName]>;
    function act(
      this: ComponentPublicInstance, fnOrFnName: unknown,
      ...args: [Options | Options['extraParams']] | [unknown[], Options | Options['extraParams']] | never[]
    ) {
      const params = Array.isArray(args[0]) ? args.shift() : [];
      // @ts-ignore
      const actionFn = action.call(this, fnOrFnName as string, ...args);
      // @ts-ignore
      return actionFn.call(this, ...params);
    }

    _Vue.mixin({
      data() {
        return {
          [cacheMapKey]: {},
        };
      },
      methods: {
        $isActing: isActing,
        $act: act,
        $action: action,
      },

    });
  },
};

declare module 'vue/types/vue' {
  interface Vue {
    $act: (fn: F.Function | string, args?: unknown[], successParams?: unknown, errorParams?: unknown) => any
    $action: (fn: F.Function | string, successParams?: unknown, errorParams?: unknown) =>
      (this: Vue, ...args: unknown[]) => unknown
    $isActing: (fn: F.Function) => boolean
    $customize: (name: string, attr?: string) => any
    $customizeNames: any
  }
}

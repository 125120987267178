import { getCurrentInstance } from 'vue';

// const rVersion = /(\d+)\.(?:[\d.]+)(?:-(beta|rc)\.(\d+))?/;
// /** @deprecated */
// export function getCurrentPublicInstance(): Vue | null {
//   // 1.0.0-beta.22
//   const [, strMajorVersion, affix = '', strAffixVersion = ''] = version.match(rVersion)!;
//   const majorVersion = Number(strMajorVersion);
//   const affixVersion = Number(strAffixVersion);
//   if (majorVersion < 1 || (majorVersion === 1 && affix === 'beta' && affixVersion < 22)) {
//     // @ts-ignore
//     return getCurrentInstance();
//   }
//   // @ts-ignore
//   return getCurrentInstance()?.proxy ?? null;
// }

export function getCurrentPublicInstance() {
  return getCurrentInstance()?.proxy;
}

/** @deprecated */
export default getCurrentPublicInstance;

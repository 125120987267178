/* eslint-disable camelcase */
import {
  getContextCompany,
  getContextUser,
} from '@/loaders/context';
import companyUtils from '@/utils/company';
import envs from '@/utils/envs';
import tokenUtils from '@/utils/token';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { RecordUnknown } from '@/typings/common';
import Bowser from 'bowser';
import {
  ref,
  watch,
} from 'vue';
import { FrontLogEventKey, unique_session_id } from '@/log/store';
import isQt from '@/utils/is-qt';
// import axiosBackEnd from '@/utils/axios';

import {
  BaseAttrs,
  DeviceAttrs,
  DynamicAttrs,
  ElementAttrs,
  PageAttrs,
  PlatformAttrs,
  PositionAttrs,
} from './type';
import {
  logList,
  logRouteItem2logParams,
} from './useLog';
import { fetchUniqSessionId } from './poster';

export const setUniqSessionId = async () => {
  unique_session_id.value = await fetchUniqSessionId();
  // axiosBackEnd.defaults.headers['unique_session_id'] = unique_session_id.value;
};

export const getUniqSessionId = () => unique_session_id.value;

// watch(() => unique_session_id.value, () => {
// axiosBackEnd.defaults.headers['unique_session_id'] = getUniqSessionId();
// });

const checkUniqSessionId = () => {
  if (!unique_session_id.value) throw new Error('没有unique_session_id,停止埋点');
};

const getBowserInfo = () => {
  const info = Bowser.detect(window.navigator.userAgent);
  return {
    browser: {
      name: info.name,
      version: info.version,
    },
    os: {
      name: info.osname,
      version: info.osversion,
    },
  };
};

const getDeviceId = (() => {
  let visitorId = '';
  return async (): Promise<string> => {
    if (visitorId) return visitorId;
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    visitorId = result.visitorId;
    return visitorId;
  };
})();

const getContextUserId = () => {
  return getContextUser()?.id ?? undefined;
};

const getContextUserType = () => {
  switch (getContextCompany()?.isPersonalCom) {
    case true:
      return '504';
    case false:
      return '503';
    default:
      return '';
  }
};


const getTimezoneOffset = () => {
  const timezoneOffset = new Date().getTimezoneOffset();
  const offset = Math.abs(timezoneOffset / 60 || 0);
  const minute = timezoneOffset % 60 || 0;
  // 时区偏移是世界协调时和当地时间之间的差别，以分钟为单位.请注意，这意味着如果本地时区落后于UTC，偏移量是正的，如果它在前面，则为负
  const symbol = timezoneOffset < 0 ? '+' : '-';
  const pad = (number: number) => `${number}`.padStart(2, '0');
  return `${symbol}${pad(offset)}:${pad(minute)}`;
};


const getPlatformAttrs = async (): Promise<PlatformAttrs> => {
  return {
    unique_session_id: unique_session_id.value,
    device_id: await getDeviceId(),
    user_id: getContextUserId(),
    product_id: '100',
    business_id: '3',
    terminal_id: isQt() ? '201' : '200',
    terminal_type_id: isQt() ? '301' : '300',
    terminal_version: process.env.VUE_APP_APPLICATION_VERSION,
    install_channel: '413',
  };
};

const getPositionAttrs = (): PositionAttrs => {
  return {
    timezone_offset: getTimezoneOffset(),
  };
};

const getDeviceAttrs = (): DeviceAttrs => {
  const { browser, os } = getBowserInfo();
  const {
    width, height, orientation, mozOrientation, msOrientation,
  } = window.screen;
  const screen_orientation = (orientation || {}).type || mozOrientation || msOrientation || '';
  const screen_info = `screen1:${width}*${height}#${screen_orientation}`;
  return {
    os: os.name,
    os_version: os.version,
    screen_info,
    browser_name: browser.name,
    browser_version: browser.version,
  };
};


export const getStaticAttrs = async () => {
  return {
    ...await getPlatformAttrs(),
    ...getPositionAttrs(),
    ...getDeviceAttrs(),
  };
};

export type DynamicTrackerParams = {
  e?: Event,
} & RecordUnknown;

const getBaseAttrs = (event_name: FrontLogEventKey | string): BaseAttrs => {
  return {
    unique_session_id: unique_session_id.value,
    token: tokenUtils.get(),
    user_id: getContextUserId(),
    company_id: companyUtils.get(),
    local_time: new Date().getTime(),
    event_name,
    server_time: '',
    role: getContextUserType(),
  };
};

const getPageAttrs = (): PageAttrs => {
  const { url: referrer } = logRouteItem2logParams(logList[0]);
  const { href } = window.location;
  return {
    url: href,
    referrer,
  };
};


export const getElementAttrs = (event?: Event): ElementAttrs | {} => {
  if (!event) return {};
  const { target } = event as unknown as { target: HTMLElement };
  const {
    id, className, textContent, tagName,
  } = target as HTMLElement;
  const { left, top } = target.getBoundingClientRect();
  const element_name = target.getAttribute('name') || '';
  return {
    element_id: id,
    element_class_name: className,
    element_name,
    element_content: textContent || '',
    element_type: tagName,
    element_position: [left, top].join(),
  };
};

export const getDynamicAttrs = (frontLogEventKey: FrontLogEventKey, params: DynamicTrackerParams = {}): DynamicAttrs => {
  const { e, event_name, ...otherParams } = params; // e通过params的方式传递进来,也可能不传,比如先调用sdEventTrack的情况
  const eventName = event_name || frontLogEventKey; // 优先取event_name的属性,否则取frontLogEventKey
  const elementAttrs = e ? getElementAttrs(e) : {};
  return {
    ...getBaseAttrs(eventName),
    ...getPageAttrs(),
    ...elementAttrs,
    ...otherParams,
    ip: '',
  };
};
type TrackerParams = DynamicTrackerParams;
export const getTrackerAttrs = async (frontLogEventKey: FrontLogEventKey, params?: TrackerParams) => {
  checkUniqSessionId();

  return {
    static_property: await getStaticAttrs(),
    dynamic_property: getDynamicAttrs(frontLogEventKey, params),
  };
};


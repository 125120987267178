/* eslint-disable camelcase */
import envs from '@/utils/envs';
import originAxios from 'axios';
// import axios, {
//   module2terminalid,
//   queryTerminalId,
//   defaultError,
//   pipeAxios,
// } from '@/utils/axios';
import { module2terminalid, queryTerminalId } from '@/utils/util';
import { DynamicAttrs, StaticAttrs } from './type';

const snowIdUrl = '/data-transit/data/snow/id';
const termialId = queryTerminalId ?? module2terminalid() ?? '200';
const logApi = originAxios.create({
  baseURL: `${envs.VUE_APP_LOG_API_DOMAIN}/backend`,
  headers: {
    'Sdtc-Terminal-Id': termialId,
  },
});

// Response Interception
// logApi.interceptors.response.use(
//   response => {
//     if (response.request.responseURL.indexOf(snowIdUrl) > -1) { // 应用第一个请求获取雪花id
//       axios.config.headers['unique_session_id'] = response.data.data;// 同步埋点标志
//     }
//     let data = response.data ?? defaultError;
//     data = { ...data };
//     return pipeAxios({ ...data, hash: response.headers.track_code });
//   },
//   err => {
//     let response;
//     if (err.response) {
//       ({ response } = err);
//     } else if (err.message.includes('timeout')) {
//       response = { status: 408, data: { code: 408, message: err.message }, headers: {} };
//     }
//     response = response ?? { status: 426, data: { code: 426, message: err.message }, headers: {} };
//     const data = typeof response.data === 'string' ? {
//       message: response.data,
//       code: response.status,
//     } : response.data;
//     return pipeAxios({ ...data, hash: response.headers.track_code });
//   },
// );

type PosterParams = {
  static_property: StaticAttrs,
  dynamic_property: DynamicAttrs
};


export const fetchUniqSessionId = async () => {
  const res = (await logApi.get(snowIdUrl)).data;
  return res.data;
};

export const syncRequest = (url, data = {}) => {
  const xhr = new XMLHttpRequest();
  xhr.open('POST', `${envs.VUE_APP_LOG_API_DOMAIN}/backend${url}`, false);
  xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
  xhr.setRequestHeader('Sdtc-Terminal-Id', termialId);
  xhr.send(JSON.stringify({ content: data }));
};

export const logPoster = async (params: PosterParams) => {
  if (!params.dynamic_property.user_id) {
    params.dynamic_property.company_id = undefined;
  }
  console.log('%c[埋点上报]:', 'color: green;', params);
  const res = (await logApi.post('/data-transit/data/collect', { content: params })).data;
  return res;
};

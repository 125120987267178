import { upload, UploadOptions } from '@4dst-saas/datapipe/libs/upload';
import _getParams from '@4dst-saas/datapipe/libs/get-key-params';
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

/** @deprecated */
export const getParams = _getParams;


const defaultOptions = {
  sourceType: 'BASE',
};


type UploaderOptions = Partial<UploadOptions>;

/**
 * OSS上传类
 * @deprecated
 */
export class OSSUploader {
  options: UploaderOptions;
  constructor(options: UploaderOptions = {}) {
    if ('sendAs' in options) {
      console.warn('OSSUploader sendAs 参数不再支持');
    }
    this.options = {
      ...defaultOptions,
      ...options,
    };
  }
  send(options: UploaderOptions = {}) {
    const mixOptions = {
      ...this.options,
      ...options,
    };
    if (!mixOptions.body) {
      throw new Error('body must be set');
    }
    return upload(mixOptions as UploadOptions);
  }
  static singleton: undefined | OSSUploader;
  static send(options: UploaderOptions = {}) {
    const mixOptions = {
      ...defaultOptions,
      ...options,
    };
    if (!mixOptions.body) {
      throw new Error('body must be set');
    }
    return upload(mixOptions as UploadOptions);
  }
}

export default OSSUploader;

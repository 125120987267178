/* eslint-disable @typescript-eslint/ban-ts-comment */
import cacheable from '@4dst-saas/public-utils/dist/cacheable';
import axios from '@/utils/axios';
import companyUtils from '@/utils/company';
import envs from '@/utils/envs';
import delay from '@4dst-saas/public-utils/dist/delay';

type OssUrlDirs = {
  [sourceType: string]: string
};

export const getDir = cacheable(async () => {
  return (await axios.get<OssUrlDirs>('/show/dictionary/getOSSUrl')).data;
}, {
  expires: Infinity,
  context: globalThis,
});

type ApiAuthConfig = {
  cfgs: {
    defaultRegion: string
    region: string
    upCh: number
  },
  ossSTS: {
    [key: string]: ({
      expiration: string,
      urlPrefix: string
    }) & ({
      bucket: string,
      endPoint: string,
      keyId: string,
      keySecret: string,
      token: string,
      storageType?: StorageType.AliOss
    } | {
      keySecret: string
      bucket: string,
      storageType: StorageType.AzureBlob
    })
  }
};

export interface StorageConfig {
  expiration: number,
  storageType: string,
  urlPrefix: string,
  region: string,
  [otherKey: string]: unknown,
}

export enum StorageType {
  AliOss = 'ALI',
  AzureBlob = 'BLOB'
}

export interface AliOssStorageConfig extends StorageConfig {
  bucket: string,
  endPoint: string,
  accessKeyId: string,
  accessKeySecret: string,
  securityToken: string,
  storageType: StorageType.AliOss
}


export interface AzureBlobStorageConfig extends StorageConfig {
  sas: string,
  storageType: StorageType.AzureBlob
}


type AuthConfig = {
  cfgs: {
    defaultRegion: string
    region: string
    upCh: number
  },
  /** @deprecated */
  ossSTS: {
    [key: string]: StorageConfig
  }
  storageConfigs: {
    [key: string]: StorageConfig
  }
};


export const getStorageConfigs = cacheable(async (): Promise<AuthConfig> => {
  const apiConfig = (await axios.get<ApiAuthConfig>(
    `//${envs.VUE_APP_API_DOMAIN}/metaData/storageService/getStorageServiceConfig?companyId=${companyUtils.get()!}`,
  )).data;
  const storageConfigs = Object.fromEntries(Object.entries(apiConfig.ossSTS).map(([key, value]) => {
    if (!value.storageType || value.storageType === StorageType.AliOss) {
      const storageConfig: AliOssStorageConfig = {
        storageType: StorageType.AliOss,
        region: key,
        accessKeyId: value.keyId,
        accessKeySecret: value.keySecret,
        securityToken: value.token,
        endPoint: value.endPoint,
        expiration: +value.expiration,
        bucket: value.bucket,
        urlPrefix: value.urlPrefix,
      };
      return [key, storageConfig];
    }
    const storageConfig: AzureBlobStorageConfig = {
      storageType: StorageType.AzureBlob,
      sas: `${value.urlPrefix}/${value.bucket}?${value.keySecret}`,
      expiration: +value.expiration,
      urlPrefix: value.urlPrefix,
      region: key,
    };
    return [key, storageConfig];
  }));
  return {
    cfgs: apiConfig.cfgs,
    storageConfigs,
    ossSTS: storageConfigs,
  };
}, {
  context: globalThis,
  key() {
    return companyUtils.get()!;
  },
  async expires() {
    await delay(60 * 1000);
  },
});

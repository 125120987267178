import i18n from '@/i18n';
import { Route } from 'vue-router';
import { isIdeationPCClient } from '@/utils/is-pc-client';

const code2Error = {
  403: {
    code: 403,
    message: i18n.t('public.error.no-auth-desc'),
  },
  404: {
    code: 404,
    message: i18n.t('public.error.not-found-desc'),
  },
  500: {
    code: 500,
    message: i18n.t('public.error.system-error-desc'),
  },
};

const defaultButtons = [
  { type: 'primary', label: i18n.t('public.error.back'), handler(this: Vue) { globalThis.location.replace('/'); } },
];
// pc端里面不显示返回首页按钮
if (isIdeationPCClient()) {
  defaultButtons.pop();
}

const code2Props = (code: number, to: Route) => {
  const params = {
    ...to.params,
    ...to.query,
  };
  const { img, ...error } = params;
  return {
    error: Object.assign(code2Error[code as unknown as keyof typeof code2Error] ?? { code }, error),
    img,
    buttons: defaultButtons,
  };
};

const error = {
  path: '/(\\d+)',
  props(to: Route) {
    return code2Props(+to.params.pathMatch, to);
  },
  component: () => import('@/components/error/index.vue'),
};

export const e404 = {
  path: '*',
  props(to: Route) {
    return code2Props(404, to);
  },
  component: () => import('@/components/error/index.vue'),
};

export default [error, e404];


import { defineComponent } from 'vue';
import Sidebar from '@/views/components/Sidebar.vue';
import Header from '@/components/public/Header.vue';

export default defineComponent({
  name: 'SysteamLayout',
  components: { Sidebar, Header },
  setup() {
    const showImPoppver = () => {

    };
    return {
      showImPoppver,
    };
  },
});

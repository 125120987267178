<template>
  <div class="opinion-form">
    <el-form :model="form" :rules="rules" ref="form">
      <el-form-item prop="opinions" :label="$t('opinion.desc')">
        <el-input
          v-model="form.opinions"
          :placeholder="$t('opinion.placeholder')"
          type="textarea"
          resize="none"
          :rows="5"
          maxlength="300"
          show-word-limit
        />
      </el-form-item>
      <div class="desc">
        <p>{{ $t("opinion.help") }}</p>
        <p>{{ $t("opinion.help_example") }}</p>
        <p>{{ $t("opinion.help_example_1") }}</p>
        <p>{{ $t("opinion.help_example_2") }}</p>
      </div>
      <el-form-item :label="$t('opinion.type_title')">
        <el-radio-group v-model="form.type">
          <el-radio :label="1">{{ $t("opinion.type_1") }}</el-radio>
          <el-radio :label="2">{{ $t("opinion.type_2") }}</el-radio>
          <el-radio :label="3">{{ $t("opinion.type_3") }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <AttachmentImage class="attachment-image" ref="attachmentImage" />
      <el-form-item :label="$t('opinion.contact')" prop="email" class="email-input">
        <el-input v-model="form.email" clearable />
      </el-form-item>
      <div class="desc">
        <p>{{ $t("opinion.contact_desc") }}</p>
      </div>
    </el-form>
  </div>
</template>

<script>
import { validateFormFields } from '@/utils/validators/rules-validation.js';
import opinionRules from '@/views/opinion/rules.js';
import AttachmentImage from './AttachmentImage';

export default {
  name: 'OpinionForm',
  components: {
    AttachmentImage,
  },
  data() {
    return {
      form: {
        opinions: '',
        type: null,
        email: '',
      },
      rules: opinionRules,
    };
  },
  methods: {
    // 取得图片链接(外部调用)
    async getImageData() {
      const images = await this.$refs.attachmentImage.getImageOssPaths();
      return images;
    },
    async validateForm() {
      console.log(this.$refs.form);
      await validateFormFields(this.$refs.form);
      return this.form;
    },
  },
};
</script>


<style lang="scss" scoped>
#{$dark-theme-selector} {
  .opinion-form {
    background-color: $--color-dark-bg-4;
  }
  :deep(.attachment-image) {
    .attachment-image-title,
    .priviewer-desc,
    p {
      color: $--font-dark-normal !important;
    }
  }
}
.desc {
  padding-bottom: 24px;
  line-height: 18px;
  font-size: 12px;
  color: #999;
  word-break: break-word;
}
.email-input {
  width: 240px;
}
.opinion-form {
  padding-right: 16px;
}
</style>

import trend from './trend';
import verseset from './verseset';
import fim from './fim';

/// logo 类型，区分浅色主题，深色主题
/// url: 浅色主题url地址
/// darkUrl：深色主题url地址
type logoType = {
  url: string;
  darkUrl: string;
};

/// 配置项的数据类型，暂时没有做校验
type dataType = string | number | boolean | logoType | null;

/// 配置项名称(name)定义
export enum customizeNames {
  // AppId 配置, 默认是FIM业务线 100, 业务数据按照AppId来过滤
  appId = 'appid',
  // platform id 平台对应的id，标准的是appId / 100, eg： 趋势中心 800 / 100 = 8
  platformId = 'platformid',
  // initPlatform 初始化体验账号权限，个别平台是不需要这个的
  initPlatform = 'init_platform',
  // TerminalId 配置, 默认是FIM业务线 200, 用户行为分析id
  terminalId = 'terminalid',
  // 登录页面logo的url地址, 默认是Revofim, 尺寸: 160*36
  loginLogo = 'logo_login',
  // 个人中心页面logo的url地址, 默认是Revofim, 尺寸: 160*36
  userProfileLogo = 'logo_user_profile',
  // 登录页面背景的url地址，尺寸: 2331*1558
  loginBackground = 'background_login',
  // 隐藏登录页面
  hiddenLoginForm = 'hidden_login_form',
  // 是否隐藏忘记密码入口
  hiddenForgetPassword = 'hidden_forget_password',
  // 是否隐藏个人中心的header部分
  hiddenHeader = 'hidden_user_header',
  // 是否隐藏账号权限入口
  hiddenAuthority = 'hidden_authority',
  // 是否隐藏切换语言
  hiddenSwitchLanguage = 'hidden_switch_language',
  // 是否隐藏切换主题
  hiddenSwitchTheme = 'hidden_switch_theme',
  // 是否隐藏意见反馈
  hiddenFeedback = 'hidden_feedback',
  // 是否隐藏注册账号入口
  hiddenRegister = 'hidden_register',
  // 是否隐藏切换公司入口
  hiddenSwitchCompany = 'hidden_switch_company',
  // 是否禁用选择角色类型，默认是普通角色
  disabledRoleTypeSelect = 'disabled_role_type_select',
  // 是否禁用选择账号类型，默认是普通账号
  disabledUserTypeSelect = 'disabled_user_type_select',
  // 个人信息页，不要禁止公司全称的修改，默认是 undefined (false)
  noDisabledFullCompanyName = 'no_disabled_full_company_name',
  // 展示团队管理，默认是 undefined (false)
  showTeamManage = 'show_team_manage',
  // 需要 workspace 功能，默认 undefined (false)
  needWorkspace = 'need_workspace',
  // 需要使用客服功能，默认 undefined (false)
  needSalesiq = 'need_salesiq',
}

/// UI自定义设置对象
/// name：自定义项的名称
/// name：自定义项的备注，帮助理解
type UISetting = {
  name: string;
  data: { type: dataType; value: any; }
  remark: string;
};

/// 应用配置信息映射
type UISettingMapping = {
  app: string;
  key: string;
  appId: string | null,
  SLD: string;
  json: any;
};

/**
 * @deprecated 注意顺序，勿修改，待删除
 * app 对应的UI配置信息
 * key 匹配query参数(a)对应app的md5信息，取前面16位（可以使用tool.js生成），匹配优先级最高
 * SLD 匹配的二级域名，或者三级域名，匹配优先级中
 * qSLD 匹配query参数(h)的二级域名，或者三级域名，匹配优先级低
 * eg:
 * user.revobit.com?a=ba9ff5528c1647ec
 * trend-user.revobit.com
 * user.revobit.com?s=trend.revobit.com
 */
const UI_SETTING_MAPPING: UISettingMapping[] = [
  {
    app: 'FIM',
    key: 'fim',
    appId: '100',
    SLD: '',
    json: fim,
  },
  {
    app: '趋势中心',
    key: 'ba9ff5528c1647ec',
    appId: '800',
    SLD: '',
    json: trend,
  },
  {
    app: '扫描仪',
    key: 'verseset',
    appId: '300',
    SLD: '',
    json: verseset,
  },
];

let _id: string;
const getId = () => _id;
export const setId = (appId: string) => {
  _id = appId;
};
export function getUISetting(): (UISettingMapping) {
  const _appId = getId();

  return UI_SETTING_MAPPING.find((item) => item.appId === _appId) as unknown as UISettingMapping
    || UI_SETTING_MAPPING[0];
}

export function getSettingData(name: string): ({ type: dataType; value: any; } | null) {
  const uiSetting = getUISetting();
  if (!uiSetting) {
    return null;
  }
  const configList = uiSetting.json as UISetting[];
  const target = configList.find(item => item.name === name);
  if (!target) {
    return null;
  }
  return target.data;
}

/**
 * 绑定到Vue.prototype上面的全局函数
 * @param name customizeNames 的枚举值
 * @param attr value对象的key，如果value是一个对象
 * @returns dataType
 */
export function customize(name: string, attr?: string) {
  const setting = getSettingData(name);
  if (attr && setting && typeof setting.value === 'object') {
    return setting.value[attr];
  }
  return setting?.value;
}

export enum PlatformType {
  FIM = '100',
  MATERIAL = '200',
  VERSESET = '300',
  CAD_2D = '500',
  CAD_3D = '600',
}


/**
 * @deprecated
 */
export function getAppId() {
  return getId() || customize(customizeNames.appId);
}

let _platformId: string;
export const setPlatformId = (val?: string) => {
  _platformId = val;
};

/**
 * @deprecated
 */
export function getPlatformId() {
  return _platformId || customize(customizeNames.platformId);
}

export function isNeedInitPlatform() {
  return customize(customizeNames.initPlatform) || false;
}

export default {};

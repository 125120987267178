<template>
  <section class="opinion-form-container" :class="{ 'on-host-container': !!host }">
    <div v-loading="isLoading" class="content">
      <div>
        <p>Hi, {{ user.name || user.account }}</p>
      </div>
      <opinion-form ref="opinionForm"></opinion-form>
    </div>
    <footer class="footer" :class="{ onHostFooter: !!host }">
      <FormSubmitButtons
        size="medium"
        :submitText="$t('opinion.formSubmitButtons.submit')"
        @cancel="closeDialog('cancel')"
        @submit="submit"
      />
    </footer>
  </section>
</template>

<script>
import { i18n } from '@/i18n';
import mixinLoading from '@/mixins/loading';
import mixinDialogBasic from '@/mixins/dialog-basic';
import { submitOpinion } from '@/api/opinion';
import { getHost } from '@/utils/exec';
import FormSubmitButtons from '@/components/form/FormSubmitButtons';
import { getContextUser } from '../../loaders/context';
import AppError from '../../utils/error';
import OpinionForm from './components/OpinionForm';
import submitSuccess from './components/submitSuccess';


export default {
  name: 'opinion',
  mixins: [mixinLoading, mixinDialogBasic],
  components: {
    OpinionForm,
    FormSubmitButtons,
  },
  data() {
    return {
      isLoading: false,
      host: null,
    };
  },
  async created() {
    // 用于PC端读取语言设置, 如果没有不改变语言
    const lang = this.$route.query.language;
    this.setLang(lang);
    // 存储host在组件内部供之后使用
    this.host = await getHost();
  },
  dialogify: {
    confirmEvent: 'confirm',
    props: {
      width: '600px',
      title: i18n.t('opinion.title'),
    },
  },
  methods: {
    async submit() {
      this.isLoading = true;
      try {
        // 表单检验是否通过
        const formData = await this.$refs.opinionForm.validateForm();
        // 取得图片链接
        const imageData = await this.$refs.opinionForm.getImageData();
        // 表单提交成功后打开提交成功页面
        await submitOpinion({
          content: formData.opinions,
          questionType: formData.type,
          contactWay: formData.email,
          imgList: imageData.join(','),
        });
        await this.closeDialog('submit');
        // await this.$Dialog.dialogify({
        //   is: submitSuccess,
        // });
        // await this.$alert(i18n.t('opinion.successResult.desc'), i18n.t('opinion.successResult.title'));
        this.$message.success(i18n.t('opinion.successResult.desc'));
      } catch (err) {
        this.$errHandler(this.warpErrMessage(err));
      } finally {
        this.isLoading = false;
      }
      return null;
    },
    async closeDialog(type) {
      // 根据host页面响应不同的事件
      // const host = await getHost();
      // eslint-disable-next-line no-unused-expressions
      this.host ? this.host.closeWin(`feedback:${type}`) : this.$emit('confirm');
    },
    setLang(lang) {
      if (lang) {
        this.$i18n.locale = lang;
      }
    },
    warpErrMessage(err) {
      const {
        level, invalidFields, instance,
      } = err;
      let errMessage;
      if (instance?.prop === 'opinions') {
        errMessage = i18n.t('opinion.empty_feedback');
      } else if (instance?.prop === 'email') {
        errMessage = i18n.t('opinion.exceed_email_max');
      }
      return errMessage ? new AppError(errMessage, { level, invalidFields, instance }) : err;
    },
  },
  computed: {
    user() {
      return getContextUser();
    },
  },
};
</script>

 <style lang="scss" scoped>
#{$dark-theme-selector} {
  .opinion-form-container {
    background-color: $--color-dark-bg-4;
    color: $--color-dark-font;
  }
  .opinion-form-container::-webkit-scrollbar-thumb {
    background-color: $--color-dark-bg-3;
  }
  .footer {
    background-color: $--color-dark-bg-4;
    box-shadow: 0px -1px 4px 0px $--color-dark-border;
  }
}
</style>

<style lang="scss" scoped>
.opinion-form-container {
  padding-bottom: 50px;
  background-color: #fff;
  height: 640px;
  width: 540px;
  overflow: scroll;
  background: #fff;
}
.opinion-form-container::-webkit-scrollbar {
  width: 8px;
}
.opinion-form-container::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 4px;
}
.opinion-form-container::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #cdcdcd;
}
.on-host-container {
  padding: 31px 24px 84px 24px;
  height: auto;
  overflow: auto;
  width: auto;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 600px;
  padding: 24px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 4px 0px rgba(221, 221, 221, 1);
  border-radius: 0px 0px 4px 4px;
}
.onHostFooter {
  position: fixed;
}
</style>

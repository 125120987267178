import Vue from 'vue';
import { DynamicReactiveRefs } from 'vue-reactive-refs';
import VueAct from '@/utils/vue/act';
import errHandler, { isNeedLoginError } from '@/utils/error-handler';
// 指令和过滤器
import Directives from '@/directives';
import Filters from '@/filters';
// 路由和vuex
import store from '@/store';
import router from '@/router';

import Components from '@/components';
// 权限方法
import can from '@/utils/can';
import axios from '@/utils/axios';
import VueClosest from '@/utils/vue/closest';
import VueReactiveChildren from '@/utils/vue/reactive-children';
import VueLoading from '@/utils/vue/loading';
import VueHistory from '@/utils/vue/history';
// vxe-table
// import 'xe-utils';
// import VXETable from 'vxe-table';

// import hookErrorHandler from '@/utils/hookErrorHandler';

import VueErrorHandler from '@/utils/vue/error-handler';
import tryParseJson from '_/try-parse-json';
import App from './App.vue';
import VueI18nPlugin from './i18n';
// import { im } from './get-im';
// 相关插件
import 'animate.css';
import AppError from './utils/error';
import isNeedLogin from './router/utils/is-need-login';
import { customize, customizeNames } from './settings';
import errorHandler from '@/utils/error-handler';

// 多语言支持

// import hookErrorHandler from '@/utils/hookErrorHandler';


Vue.prototype.$customize = customize;
Vue.prototype.$customizeNames = customizeNames;
Vue.prototype.$can = can;
// Vue.prototype.$im = im;

Vue.use(VueI18nPlugin);
Vue.use(DynamicReactiveRefs);
Vue.use(VueReactiveChildren);
Vue.use(VueClosest);
Vue.use(VueErrorHandler);
Vue.use(VueAct, {
  success(instance, extraParams) {
    if (extraParams.length) {
      instance.$message.success(extraParams[0]);
    }
  },
  error(instance, error) {
    errHandler.call(instance, error);
  },
});
Vue.use(VueHistory, {
  router,
});
Vue.use(Directives);
Vue.use(Filters);
Vue.use(Components, {
  router,
  store,
});
Vue.use(VueLoading);
// Vue.use(VXETable);

// Use imported models
Vue.config.productionTip = false;
const VueInstance = Vue.extend({
  router,
  store,
  ...App,
});

document.addEventListener('click', (
  {
    target,
    currentTarget,
  },
) => {
  try {
    while (target && target !== currentTarget) {
      const attr = tryParseJson(target.getAttribute('data-no-permission'));
      if (attr) {
        throw AppError.auth;
      } else {
        target = target.parentNode;
      }
    }
  } catch (e) {
    errHandler.call(this, e);
  }
});


const axiosUnbind = router.afterEach(async () => {
  axiosUnbind();
  axios.interceptors.response.use((a) => a, (err) => {
    if (isNeedLoginError(err) && isNeedLogin(router.currentRoute)) {
      setTimeout(() => {
        // 让其他handler优先处理
        errHandler(err, true);
      }, 0);
    }
    return Promise.reject(err);
  });
});

export default new VueInstance();

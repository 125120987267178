// import { Route } from 'vue-router';

import { Route } from 'vue-router';

export function isNeedLogin(to: Route): Boolean {
  const meta = to.meta ?? {};
  if (typeof meta.isNeedLogin !== 'undefined') {
    return meta.isNeedLogin;
  }
  const isTeams = /^\/ms-teams/.test(to.path);
  const isMobile = /^\/mobile-ideation\/mobile-share-validate/.test(to.path);
  const isEntry = /^\/login/.test(to.path);
  const isQtEntry = /^\/qt\/login/.test(to.path);
  const isTest = /^\/test/.test(to.path);
  const isPolicy = /^\/policy/.test(to.path);
  const isDoc = /^\/doc/.test(to.path);
  const isFrag = /^\/fragment/.test(to.path);
  const isQtHelpCenter = /^\/qt\/help-center/.test(to.path);
  const isError = /^\/\d+$/.test(to.path);
  const isSpecialPage = isTeams || isEntry || isTest || isPolicy || isDoc || isFrag || isQtHelpCenter || isQtEntry || isError || isMobile;
  return !isSpecialPage;
}
export default isNeedLogin;

var render, staticRenderFns
import script from "./VxeTableHeadFilter.vue?vue&type=script&lang=tsx&"
export * from "./VxeTableHeadFilter.vue?vue&type=script&lang=tsx&"
import style0 from "./VxeTableHeadFilter.vue?vue&type=style&index=0&id=7ec0d76a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports